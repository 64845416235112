import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {history} from '../helper/history';
import moment from 'moment';
import request from 'superagent';
import MetronicIndex from '../layout/metronic/index';
import {Form, FormGroup} from '../component/form/form';
import {Portlet, PortletHead, PortletBody, PortletFooter} from '../component/widget/portlet';
import BootstrapTable from 'react-bootstrap-table-next';
import ReactToPrint from "react-to-print";
import apis from '../helper/api';
import localdata from '../helper/localdata';
import config from '../helper/config';
import swal from 'sweetalert';
import action from '../helper/action';
import do_action from '../action';
import util from '../helper/util';
import Select from 'react-select';
import CetakStruk from '../component/form/cetak_struk'
import BatchExportExcel from '../component/widget/BatchExportExcel';

let tabel_data_pelanggan_selected = [];
let tabel_data_collective_selected = [];
let current_batch_id = -1;
let coll_next_load_url;
let timer_refresh_detail = null;
let timer_refresh_history = null;
let delay = 15 * 1000;

class AgentBatchTransaction2 extends Component {
    constructor(props) {
        super(props);
        this.data_product_all = [];
        this.state = {
            select_data_product: [],
            tabel_data_pelanggan: [],
            tabel_pending_tx: [],
            tabel_collective_detail: [],
            selected_tabel_collective: [],
            selected_data_product: '',
            nomor_pelanggan: '',
            nama_pelanggan: '',
            telepon_pelanggan: '',
        	batch_file_name: '',
        	batch_file_obj: '',
            tanggal_collective_detail: '',
            total_bayar_tabel_collective: 0,
        	flag_cek_processing: false,
        	flag_submit_processing: false,
            flag_loading_pending_transaction: false,
            flag_loading_collective_detail: false,
            show_daftar_pelanggan: true,
            show_pending_transaction: true,
            show_collective_detail: false,
            show_more_coll_btn: false,
            tx_show_struk: false,
            tx_result_success: '',
            tx_show_btn_print_all_struk: false
        };
        this.options = {hideSizePerPage: true};
        this.batch_file_input_changed = this.batch_file_input_changed.bind(this);
        this.on_btn_reset = this.on_btn_reset.bind(this);
        this.on_row_select = this.on_row_select.bind(this);
        this.on_select_all = this.on_select_all.bind(this);
        this.on_row_select_coll = this.on_row_select_coll.bind(this);
        this.on_select_all_coll = this.on_select_all_coll.bind(this);
        this.ref_tabel_collective = React.createRef();
        this.ref_tabel_batch_pending = React.createRef();
        this.on_btn_coll_load_more = this.on_btn_coll_load_more.bind(this);
    }

    componentDidMount() {
    	this.scroll_to_top();
        let data = [];
        let self = this;
        this.props.update_current_page('batch_tx');
        localdata.get_list_services().then((payload) => {
            payload.map(function(service, i) {
                let service_name = service.title;
                let products = service.list_products;
                products.map(function(product, j) {
                    if (product.list_denoms != undefined) {
                        product.list_denoms.map(function(denom, k) {
                            let opt = {value : product.product_id + '~' + denom.product_code, label : denom.product_name};
                            data.push(opt);
                        });
                    } else {
                        let opt = {value : product.product_id, label : product.product_name};
                        data.push(opt);
                    }
                })
            })
            self.data_product_all = data;
            self.setState({select_data_product: data});
        }).catch((err) => {
            util.log("load product", err);
        });

        this.load_pending_transaction();
    }

    load_pending_transaction() {
        let self = this
        let startOnlyDate = ''
        let date = moment().subtract(21, 'days').toDate(); // 21
        if (this.props.location.state) {
            if (this.props.location.state.month_year) {
                date = moment(this.props.location.state.month_year).startOf('month').toDate()
            }
        }
        if (date.length !== 0) {
            let bulan = (date.getMonth() + 1).toString()
            let hari = date.getDate().toString()
            let m = bulan.length === 1 ? '0' + bulan : bulan
            let h = hari.length === 1 ? '0' + hari : hari
            startOnlyDate = date.getFullYear() + "-" + m + "-" + h
        }

        date = moment().toDate()
        if (this.props.location.state) {
            if (this.props.location.state.month_year) {
                date = moment(this.props.location.state.month_year).endOf('month').toDate()
            }
        }
        let endOnlyDate = ''
        if (date.length !== 0) {
            let bulan = (date.getMonth() + 1).toString()
            let hari = date.getDate().toString()
            let m = bulan.length === 1 ? '0' + bulan : bulan
            let h = hari.length === 1 ? '0' + hari : hari
            endOnlyDate = date.getFullYear() + "-" + m + "-" + h
        }
        
        let body = {
            "session_id" : self.props.session,
            "date_start": startOnlyDate,
            "date_stop": endOnlyDate
        }
        // tabel_pending_tx:[] 
        this.setState({flag_loading_pending_transaction: true})
        request.post(apis.collective_list_history)
            .timeout(120000)
            .set("Content-Type", "application/json")
            .send(body)
            .end(function(err, res) {
                let message_error = ""
                if (err || !res.ok) {
                    util.log(err.message, err)
                } else {
                    util.log(res);
                    if (res.statusCode === 200) {
                        let payload = res.body
                        util.log(payload)
                        if (payload.status === "OK") {
                            if (payload.results != undefined && payload.results.list.length > 0) {
                                self.setState({
                                    tabel_pending_tx: payload.results.list, 
                                    show_pending_transaction: true
                                }, function() {
                                    self.refresh_schedule_history(true);
                                })
                            }
                        } else {
                            util.on_error(payload.error)
                            message_error = {"body": res.body.error.message, "title": "Transaksi Gagal"}
                            if (res.body.display_message !== undefined) {
                                message_error.title = res.body.error.message
                                message_error.body = res.body.display_message
                            }
                            if (res.body.error.code === 2007) {    // list kosong
                                // no-op
                            }
                        }
                    } else {
                        message_error = {"body": "HTTP error " + res.statusCode, "title": "Transaksi Gagal"}
                    }
                }
                if (message_error.length != 0) {
                    util.log("[error] pending tx", message_error.title, message_error.body)
                    // self.setState({show_pending_transaction: false})
                }
                self.setState({flag_loading_pending_transaction: false})
            });
    }

    on_error(err) {
        if (err.code == 2006) {
            localdata.clear_data().then((res) => {
                history.replace('/login')
            }).catch((err) => {
                util.log("error: ", err)
            })
        } else {
            swal("Ups!", "Error: " + err.code, "warning")
        }
    }

    scroll_step() {
        if (window.pageYOffset === 0) {
            clearInterval(this.state.scroll_interval_id);
        }
        window.scroll(0, window.pageYOffset - 50);
    }

    scroll_to_top() {
        let scroll_interval_id = setInterval(this.scroll_step.bind(this), 16.66);
        this.setState({scroll_interval_id: scroll_interval_id});
    }

    batch_file_input_changed = (event) => {
    	let b_file = event.target.files[0]
        util.log(b_file)
        if (b_file === undefined) {return}
        this.on_cek_batch_file(b_file)
    }

    on_cek_batch_file (b_file) {
        let self = this
        let reader = new FileReader()
        this.setState({batch_file_name: b_file.name})

        reader.onload = function() {
            let text = reader.result
            let lines = text.split(/[\r\n]+/g)
            let list_tagihan = []
            let i = 0
            lines.forEach(function(line) {
                if (line.length !== 0 && i !== 0) {
                    util.log(line)
                    let tagihan = line.split(',')
                    let item = {id: tagihan[0], produk_id: tagihan[1], produk_nama: tagihan[2], id_pelanggan: tagihan[3]}
                    if (tagihan[4] != undefined) {
                        item.nama_pelanggan = tagihan[4]
                    }
                    if (tagihan[5] != undefined) {
                        item.telepon_pelanggan = tagihan[5]
                    }
                    list_tagihan.push(item)
                }
                i++
            })
            self.setState({tabel_data_pelanggan: list_tagihan, batch_file_obj: b_file})
        }
        reader.readAsText(b_file)
    }

    on_inquiry_batch_file() {
        let self = this
        let new_data = this.state.tabel_data_pelanggan
        if (new_data.length === 0) {
            swal("Error", "Tidak ada daftar pelanggan", "warning")
            return
        }
        swal({
            title: "Konfirmasi",
            text: "Apakah Anda yakin untuk melanjutkan proses inquiry ini?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((sure) => {
            if (sure) {
                try {
                    let url = apis.collective_tx_inquiry;
                    let whitelabel_36 = config.whitelabel.toString(36).toUpperCase();
                    let user_id = this.props.profile.userid.toString(36).toUpperCase();
                    let prefix = whitelabel_36.substring(whitelabel_36.length - 3);
                    let suffix = user_id.substring(user_id.length - 3);

                    if (url.length !== 0) {
                        self.setState({flag_cek_processing: true})
                        let products = []
                        new_data.map(function(row, j) {
                            let product_id = row.produk_id;
                            let product_code = "";
                            if (row.produk_id.includes('~')) {
                                let p = row.produk_id.split('~');
                                product_id = p[0];
                                product_code = p[1];
                            }
                            let item = {
                                product_id: product_id, 
                                product_code: product_code,
                                bill_number: row.id_pelanggan
                            };
                            if (row.nama_pelanggan != undefined) {
                                item.customer_name = row.nama_pelanggan;
                            }
                            if (row.telepon_pelanggan != undefined) {
                                item.customer_phone = row.telepon_pelanggan;
                            }
                            products.push(item);
                        });
                        let body = {
                            "session_id" : self.props.session,
                            "batch_id" : 0,
                            "description" : "Transaksi batch inquiry " + products.length + " tagihan pelanggan",
                            "data" : products,
                            "has_next_data" : false
                        };
                        request.post(url)
                            .timeout(120000)
                            .set("Content-Type", "application/json")
                            .send(body)
                            .end(function(err, res) {
                                let message_error = ""
                                if (err || !res.ok) {
                                    util.log(err.message, err)
                                    message_error = {"body": err.message, "title": "Transaksi Gagal"}
                                    if (res !== undefined) {
                                        message_error.body = "Error code " + res.statusCode
                                    }
                                } else {
                                    util.log(res);
                                    if (res.statusCode === 200) {
                                        let payload = res.body
                                        util.log(payload)
                                        if (payload.status === "OK") {
                                            current_batch_id = payload.results.batch_id;
                                            let tanggal = util.date_time_local(new Date().getTime());
                                            let url = apis.collective_list_tx_batch.replace("{batch_id}", current_batch_id);
                                            self.on_refresh_pending_batch();
                                            self.load_collective_details(tanggal, url);
                                            self.scroll_tabel_batch_pending();
                                            let display_message = payload.display_message;
                                            swal(payload.error.message, display_message, "success");
                                        } else {
                                            util.on_error(payload.error);
                                            message_error = {"body": res.body.error.message, "title": "Transaksi Gagal"};
                                            if (res.body.display_message !== undefined) {
                                                message_error.title = res.body.error.message;
                                                message_error.body = res.body.display_message;
                                            }
                                        }
                                    } else {
                                        message_error = {"body": "HTTP error " + res.statusCode, "title": "Transaksi Gagal"};
                                    }
                                }
                                if (message_error.length != 0) {
                                    swal(message_error.title, message_error.body, "error");
                                }
                                self.setState({flag_cek_processing: false});
                            });
                    } else {
                        swal("Error", "Periksa kembali format file input, pastikan sesuai dengan aturan yang telah kita buat.", "error");
                    }
                } catch(err) {
                    swal("Error", err.message, "error");
                    self.setState({flag_cek_processing: false});
                }
            }
        })
    }

    on_submit_payment() {
        let self = this
        util.log(tabel_data_collective_selected)
        let new_data = tabel_data_collective_selected
        if (new_data.length === 0) {
            swal("Error", "Tidak ada tagihan yang terseleksi", "warning")
            return
        }
        let total_bayar = 0
        this.state.tabel_collective_detail.map(function(item, i) {
            if (new_data.includes(item.collective_id)) {
                total_bayar = total_bayar + item.amount
            }
        })
        swal({
            title: "Konfirmasi",
            text: "Tagihan bayar sejumlah " + new_data.length + " tagihan dengan total bayar Rp." + util.rupiah_format(total_bayar) + "\nApakah Anda yakin untuk melanjutkan transaksi bayar ini?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((sure) => {
            if (sure) {
                let url = apis.collective_tx_payment.replace("{batch_id}", current_batch_id)
                let whitelabel_36 = config.whitelabel.toString(36).toUpperCase()
                let user_id = this.props.profile.userid.toString(36).toUpperCase()
                let prefix = whitelabel_36.substring(whitelabel_36.length - 3)
                let suffix = user_id.substring(user_id.length - 3)
                let sign_token = util.gen_token(prefix, this.props.session, suffix)

                if (url.length !== 0) {
                    self.setState({flag_submit_processing: true})
                    let body = {
                        "session_id" : self.props.session,
                        "sign_token" : sign_token,
                        "collective" : new_data
                    }
                    request.post(url)
                        .timeout(120000)
                        .set("Content-Type", "application/json")
                        .send(body)
                        .end(function(err, res) {
                            let message_error = ""
                            if (err || !res.ok) {
                                util.log(err.message, err)
                                message_error = {"body": err.message, "title": "Transaksi Gagal"}
                                if (res !== undefined) {
                                    message_error.body = "Error code " + res.statusCode
                                }
                            } else {
                                util.log(res);
                                if (res.statusCode === 200) {
                                    let payload = res.body
                                    util.log(payload)
                                    if (payload.status === "OK") {
                                        self.on_refresh_collective_batch()
                                        let display_message = payload.display_message
                                        swal(payload.error.message, display_message, "success")
                                    } else {
                                        util.on_error(payload.error)
                                        message_error = {"body": res.body.error.message, "title": "Transaksi Gagal"}
                                        if (res.body.display_message !== undefined) {
                                            message_error.title = res.body.error.message
                                            message_error.body = res.body.display_message
                                        }
                                    }
                                } else {
                                    message_error = {"body": "HTTP error " + res.statusCode, "title": "Transaksi Gagal"}
                                }
                            }
                            if (message_error.length != 0) {
                                swal(message_error.title, message_error.body, "error")
                            }
                            self.setState({flag_submit_processing: false})
                        });
                } else {
                    swal("Error", "Periksa kembali format file input, pastikan sesuai dengan aturan yang telah kita buat.", "error")
                }
            } else {
                // no-op
            }
        })
    }

    on_btn_add() {
        util.log(this.state.selected_data_product)
        if (this.state.selected_data_product === null || this.state.selected_data_product.length === 0) {
            swal("Error", "Produk tidak boleh kosong", "warning")
            return
        }
        if (this.state.nomor_pelanggan.length === 0) {
            swal("Error", "Nomor pelanggan tidak boleh kosong", "warning")
            return
        }
        let self = this
        let data_pelanggan = this.state.tabel_data_pelanggan
        let new_items = [{id:util.md5(this.state.selected_data_product.value + this.state.nomor_pelanggan), produk_id: this.state.selected_data_product.value, produk_nama: this.state.selected_data_product.label, id_pelanggan: this.state.nomor_pelanggan, nama_pelanggan: this.state.nama_pelanggan, telepon_pelanggan: this.state.telepon_pelanggan}, ...data_pelanggan]
        self.setState({nomor_pelanggan: "", nama_pelanggan: "", telepon_pelanggan: '', tabel_data_pelanggan: new_items})
    }

    on_btn_remove() {
        let new_data = this.state.tabel_data_pelanggan
        new_data.map(function(row, j) {
            if (tabel_data_pelanggan_selected.includes(row.id)) {
                new_data.splice(j, 1)
            }
        })
        this.setState({tabel_data_pelanggan: new_data})
    }

    on_btn_save() {
        var data, filename, link;
        var csv = util.convert_array_of_objects_to_csv({data: this.state.tabel_data_pelanggan});
        if (csv == null) return;
        filename = 'daftar-pelanggan.csv';
        if (!csv.match(/^data:text\/csv/i)) {
            csv = 'data:text/csv;charset=utf-8,' + csv;
        }
        data = encodeURI(csv);
        link = document.createElement('a');
        link.setAttribute('href', data);
        link.setAttribute('download', filename);
        util.log(data, link)
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    scroll_tabel_collective = () => window.scrollTo(0, this.ref_tabel_collective.current.offsetTop)
    scroll_tabel_batch_pending = () => window.scrollTo(0, this.ref_tabel_batch_pending.current.offsetTop)

    on_btn_reset() {
        tabel_data_pelanggan_selected = []
        this.setState({tabel_data_pelanggan:[],batch_file_name:'',batch_file_obj:'',nomor_pelanggan:'',nama_pelanggan:'',telepon_pelanggan:'',selected_data_product:''})
    }

    on_input_change(e) {
        this.setState({[e.target.name]: e.target.value})
    }

    select_handle_change = (selected_option) => {
        this.setState({selected_data_product: selected_option});
    }

    on_row_select(row, is_selected) {
        let selected = tabel_data_pelanggan_selected
        if (is_selected) {
            if (!selected.includes(row.id)) {
                selected.push(row.id)
            }
        } else {
            if (selected.includes(row.id)) {
                let index = selected.indexOf(row.id)
                if (index !== -1) {
                    selected.splice(index, 1);
                }
            }
        }
        util.log(selected, row, is_selected)
        tabel_data_pelanggan_selected = selected
    }

    on_select_all(is_selected, current_display_and_selected_data) {
        let selected = [];
        if (is_selected) {
            current_display_and_selected_data.map(function(row, j) {
                selected.push(row.id);
            })
        }
        util.log(selected, is_selected);
        tabel_data_pelanggan_selected = selected;
    }

    on_row_select_coll(row, is_selected) {
        let selected = tabel_data_collective_selected;
        if (is_selected) {
            if (row.status === 'SIAP BAYAR') {
                if (!selected.includes(row.collective_id)) {
                    selected.push(row.collective_id);
                    let total = this.state.total_bayar_tabel_collective + row.amount;
                    this.setState({total_bayar_tabel_collective : total});
                }
            }
        } else {
            if (selected.includes(row.collective_id)) {
                let total = this.state.total_bayar_tabel_collective - row.amount;
                this.setState({total_bayar_tabel_collective : total});
                let index = selected.indexOf(row.collective_id);
                if (index !== -1) {
                    selected.splice(index, 1);
                }
            }
        }
        util.log(selected, row, is_selected);
        tabel_data_collective_selected = selected;
    }

    on_select_all_coll(is_selected, current_display_and_selected_data) {
        let selected = [];
        if (is_selected) {
            current_display_and_selected_data.map(function(row, j) {
                if (row.status === 'SIAP BAYAR') {
                    selected.push(row.collective_id);
                }
            })
        }
        util.log(selected, is_selected);
        tabel_data_collective_selected = selected;
    }

    on_btn_detail_collective_tx(row) {
        util.log(row.next_collective);
        current_batch_id = row.batch_id;
        this.load_collective_details(util.date_time_local(row.timestamp_utc), row.next_collective.url);
    }

    load_collective_details(tanggal, url) {
        let self = this
        let body = {
            "session_id" : self.props.session
        }
        // tabel_collective_detail: []
        self.setState({show_collective_detail: true, 
            tanggal_collective_detail: tanggal, 
            flag_loading_collective_detail: true}, 
            function() {
                self.scroll_tabel_collective();
            })
        request.post(url)
            .timeout(120000)
            .set("Content-Type", "application/json")
            .send(body)
            .end(function(err, res) {
                let message_error = "";
                if (err || !res.ok) {
                    util.log(err.message, err);
                } else {
                    util.log(res);
                    if (res.statusCode === 200) {
                        let payload = res.body;
                        util.log(payload);
                        if (payload.status === "OK") {
                            if (payload.results != undefined && payload.results.list.length > 0) {
                                let list = [];
                                tabel_data_collective_selected = [];
                                let tot = 0;
                                let there_is_sudah_bayar = false;
                                payload.results.list.map(function(item, i) {
                                    let it = item;
                                    let status_display = it.status;
                                    let status_message = '';
                                    if (status_display === 'SUCCESS') {
                                        if (it.allow_pay) {
                                            if (item.amount && item.amount > 0) {
                                                tot = tot + item.amount;
                                            }
                                            tabel_data_collective_selected.push(item.collective_id);
                                            status_display = 'SIAP BAYAR';
                                        } else {
                                            status_display = 'PROCESSING';
                                            if (it.trx_pay) {
                                                if (it.trx_pay.status === 'SUCCESS') {
                                                    status_display = 'SUDAH BAYAR';
                                                    there_is_sudah_bayar = true;
                                                } else {
                                                    status_display = 'GAGAL BAYAR';
                                                    status_message = it.trx_pay.message;
                                                }
                                            }
                                        }
                                    } else {
                                        if (it.message) {
                                            status_message = it.message;
                                        }
                                    }
                                    it.amount = item.amount;
                                    it.nama_tagihan = util.get_name_from_printable(it.info_printable);
                                    it.status = status_display;
                                    it.status_message = status_message;
                                    list.push(it);
                                })
                                let show_more_btn = false;
                                if (payload.results.next_page !== null) {
                                    coll_next_load_url = payload.results.next_page.url;
                                    show_more_btn = true;
                                } else {
                                    coll_next_load_url = "";
                                    show_more_btn = false;
                                }
                                self.setState({tabel_collective_detail : list, 
                                    selected_tabel_collective : tabel_data_collective_selected, 
                                    total_bayar_tabel_collective : tot,
                                    show_more_coll_btn : show_more_btn, 
                                    tx_show_btn_print_all_struk : there_is_sudah_bayar
                                },
                                function() {
                                    self.refresh_schedule_detail(true);
                                });
                            }
                        } else {
                            util.on_error(payload.error);
                            message_error = {"body": res.body.error.message, "title": "Transaksi Gagal"};
                            if (res.body.display_message !== undefined) {
                                message_error.title = res.body.error.message;
                                message_error.body = res.body.display_message;
                            }
                            if (res.body.error.code === 2007) {    // list kosong
                                // no-op
                            }
                        }
                    } else {
                        message_error = {"body": "HTTP error " + res.statusCode, "title": "Transaksi Gagal"}
                    }
                }
                if (message_error.length != 0) {
                    util.log("[error] pending tx", message_error.title, message_error.body);
                }
                self.setState({flag_loading_collective_detail: false});
            });
    }

    on_refresh_pending_batch() {
        this.load_pending_transaction();
    }

    on_refresh_collective_batch() {
        let url = apis.collective_list_tx_batch.replace("{batch_id}", current_batch_id);
        this.load_collective_details(this.state.tanggal_collective_detail, url);
    }

    on_btn_coll_load_more() {
        if (this.state.flag_loading_collective_detail) return;
        if (coll_next_load_url.length !== 0) {
            let self = this;
            let body = {
                "session_id" : self.props.session
            };
            self.setState({flag_loading_collective_detail: true});
            request.post(coll_next_load_url).timeout(120000)
                .set("Content-Type", "application/json")
                .send(body)
                .end(function(err, res) {
                    let message_error = ""
                    if (err || !res.ok) {
                        util.log(err.message, err)
                    } else {
                        util.log(res);
                        if (res.statusCode === 200) {
                            let payload = res.body
                            util.log(payload)
                            if (payload.status === "OK") {
                                if (payload.results != undefined && payload.results.list.length > 0) {
                                    let list = []
                                    let tot = 0
                                    payload.results.list.map(function(item, i) {
                                        let it = item
                                        let status_display = it.status
                                        let status_message = '';
                                        if (status_display === 'SUCCESS') {
                                            if (it.allow_pay) {
                                                if (item.amount && item.amount > 0) {
                                                    tot = tot + item.amount;
                                                }
                                                tabel_data_collective_selected.push(item.collective_id);
                                                status_display = 'SIAP BAYAR';
                                            } else {
                                                status_display = 'PROCESSING';
                                                if (it.trx_pay) {
                                                    if (it.trx_pay.status === 'SUCCESS') {
                                                        status_display = 'SUDAH BAYAR';
                                                    } else {
                                                        status_display = 'GAGAL BAYAR';
                                                        status_message = it.trx_pay.message;
                                                    }
                                                }
                                            }
                                        } else {
                                            if (it.message) {
                                                status_message = it.message;
                                            }
                                        }
                                        it.nama_tagihan = util.get_name_from_printable(it.info_printable)
                                        it.status = status_display
                                        it.status_message = status_message;
                                        list.push(it)
                                    })
                                    let show_more_btn = false
                                    if (payload.results.next_page !== null) {
                                        coll_next_load_url = payload.results.next_page.url
                                        show_more_btn = true
                                    } else {
                                        coll_next_load_url = ""
                                        show_more_btn = false
                                    }
                                    let tab = self.state.tabel_collective_detail.concat(list)
                                    let sel = self.state.selected_tabel_collective.concat(tabel_data_collective_selected)
                                    let t = self.state.total_bayar_tabel_collective + tot
                                    self.setState({tabel_collective_detail: tab, 
                                        selected_tabel_collective: sel, 
                                        total_bayar_tabel_collective: t,
                                        show_more_coll_btn:show_more_btn})
                                }
                            } else {
                                util.on_error(payload.error)
                                message_error = {"body": res.body.error.message, "title": "Transaksi Gagal"}
                                if (res.body.display_message !== undefined) {
                                    message_error.title = res.body.error.message
                                    message_error.body = res.body.display_message
                                }
                                if (res.body.error.code === 2007) {    // list kosong
                                    // no-op
                                }
                            }
                        } else {
                            message_error = {"body": "HTTP error " + res.statusCode, "title": "Transaksi Gagal"}
                        }
                    }
                    if (message_error.length != 0) {
                        util.log("[error] pending tx", message_error.title, message_error.body)
                    }
                    self.setState({flag_loading_collective_detail: false})
                });
        }
    }

    setRef = (ref) => {
        this.componentRef = ref
    }

    on_btn_cetak_struk(el, data) {
        util.log("on_btn_cetak_struk: ", data);
        let data_receipt = {
            trx_code: 'KOL' + data.collective_id,
            date_time: data.datetime,
            product_id: data.product_id,
            product_name: data.product_name,
            customer_name: data.customer_name ? data.customer_name : '',
            customer_phone_number: data.customer_phone ? data.customer_phone : '',
            info: data.info_printable,
            nominal: data.trx_pay.nominal,
            fee: data.end_user_fee,
            total: data.amount
        };
        if (util.is_electron()) {
            const btn = el.target;
            btn.classList.add("m-loader", "m-loader--light", "m-loader--right");
            setTimeout(function() {
                btn.classList.remove("m-loader", "m-loader--light", "m-loader--right");
            }, 3000);
            const agent_info = this.props.profile;
            util.electron_print_receipt(agent_info, data_receipt);
        } else {
            this.setState({tx_show_struk: true, tx_result_success:{"data" : data_receipt}});
        }
    }

    sleep (time) {
        return new Promise((resolve) => setTimeout(resolve, time));
    }

    async on_cetak_struk_all(el) {
        let self = this;
        let data_receipt = [];
        this.state.tabel_collective_detail.map(function(data, i) {
            if (data.status === 'SUDAH BAYAR') {
                let t = {
                    trx_code: 'KOL' + data.collective_id,
                    date_time: data.datetime,
                    product_id: data.product_id,
                    product_name: data.product_name,
                    customer_name: data.customer_name ? data.customer_name : '',
                    customer_phone_number: data.customer_phone ? data.customer_phone : '',
                    info: data.info_printable,
                    nominal: data.trx_pay.nominal,
                    fee: data.end_user_fee,
                    total: data.amount
                };
                data_receipt.push(t);
            }
        });
        if (data_receipt.length != 0) {
            if (util.is_electron()) {
                let bn = util.get_electron_build_number();
                console.log("bn", bn);
                if (bn !== null && bn >= 5) {
                    swal("INFORMASI", "Print " + data_receipt.length + " struk transaksi. Mohon tunggu sampai struk selesai tercetak semua.", "success");
                    const agent_info = this.props.profile;
                    let i = 0;
                    for (var item of data_receipt) {
                        i++;
                        util.log("cetak ke-" + i);
                        util.electron_print_receipt(agent_info, item);
                        await self.sleep(5000);
                    }
                } else {
                    this.setState({tx_show_struk: true, tx_result_success:{"data" : data_receipt}});
                }
            } else {
                this.setState({tx_show_struk: true, tx_result_success:{"data" : data_receipt}});
            }
        }
    }

    refresh_schedule_detail(start) {
        // let self = this;
        // if (start) {
        //     if (timer_refresh_detail == null) {
        //         timer_refresh_detail = setTimeout(function() {
        //             let needed = false;
        //             self.state.tabel_collective_detail.map(function(data, i) {
        //                 console.log("[refresh_schedule_detail] item: " + data.status);
        //                 if (data.status === 'PROCESSING' || data.status === 'WAITING') { needed = true; }
        //             });
        //             console.log("[refresh_schedule_detail] auto refresh: " + needed);
        //             if (needed) { self.on_refresh_collective_batch(); }
        //             timer_refresh_detail = null;
        //         }, delay);
        //     }
        // } else {
        //     if (timer_refresh_detail != null) {
        //         clearTimeout(timer_refresh_detail);
        //     }
        // }
    }

    refresh_schedule_history(start) {
        // let self = this;
        // if (start) {
        //     if (timer_refresh_history == null) {
        //         timer_refresh_history = setTimeout(function() {
        //             let needed = false;
        //             self.state.tabel_pending_tx.map(function(data, i) {
        //                 console.log("[refresh_schedule_history] item: " + data.status);
        //                 if (data.status === 'PROCESSING' || data.status === 'WAITING' || data.status === 'RUNNING') { needed = true; }
        //             });
        //             console.log("[refresh_schedule_history] auto refresh: " + needed);
        //             if (needed) { self.on_refresh_pending_batch(); }
        //             timer_refresh_history = null;
        //         }, delay);
        //     }
        // } else {
        //     if (timer_refresh_history != null) {
        //         clearTimeout(timer_refresh_history);
        //     }
        // }
    }

    render() {
        let self = this;
        let uid = this.props.profile ? this.props.profile.username : "";
        let ug = navigator.userAgent;
        let val_style = {fontWeight: 500,margin: 'auto 0'};
        let product_style = {fontWeight: 600,margin: 'auto 0'};
        let tx_result_success = this.state.tx_result_success.data;

        const RowNumber = (cell, row, i) =>
            <div>{i + 1}</div>

        const RowProduk = (cell, row, i) =>
            <div>{row.produk_nama + " (" + row.produk_id + ")" }</div>

        const RowStatus = (cell, row, i) =>
            <div>
                {row.status != undefined ? <span className="m-badge m-badge--brand m-badge--wide">{row.status}</span> : '-'}
            </div>

        const RowDatetime = (cell, row, i) =>
            <div>
                {row.datetime !== undefined ? <span className="">{util.date_time_local(row.timestamp_utc)}</span> : '-'}
            </div>

        const RowAksi = (cell, row, i) =>
            <div>
                <button type="button" 
                    className="btn btn-success m-btn m-btn--custom" 
                    onClick={() => this.on_btn_detail_collective_tx(row)} 
                    style={{paddingTop:'5px',paddingBottom:'5px',paddingLeft:'10px',paddingRight:'10px',marginTop:'-1px',fontSize: '1rem'}}>
                    Daftar Tagihan
                </button>
            </div>

        const RowProdukCollectiveDetail = (cell, row, i) =>
            <div>{row.product_name + " (" + (row.product_code === null ? row.product_id : row.product_id + "~" + row.product_code) + ")"}</div>

        const RowNamaPelangganCollectiveDetail = (cell, row, i) =>
            <div>
                {row.nama_tagihan.length !== 0 ? row.nama_tagihan + (row.customer_name !== null ? ' (' + row.customer_name + ')' : '')  : row.customer_name}
            </div>

        const RowTotalBayar = (cell, row, i) =>
            <div>{"Rp. " + util.rupiah_format(row.amount)}</div>

        const RowStatusBayar = (cell, row, i) =>
            <div style={{fontWeight: 600,fontSize:'0.9rem'}}>{row.status_message.length > 0 ? row.status + " (" + row.status_message + ")" : row.status}</div>

        const selectRowProp = {
            mode: "checkbox",
            clickToSelect: true,
            onSelect: this.on_row_select,
            onSelectAll: this.on_select_all,
            bgColor: "rgb(238, 193, 213)" 
        };

        const selectRowPropCollectiveDetail = {
            mode: "checkbox",
            clickToSelect: true,
            onSelect: this.on_row_select_coll,
            onSelectAll: this.on_select_all_coll,
            selected: this.state.selected_tabel_collective,
            style: {background:'rgba(128,128,128, 0.4)'}
        };

        const pending_columns = [{
            dataField: 'id',
            text: 'No.',
            formatter: RowNumber,
            headerAlign: 'center',
            align: 'center',
            style: {width: '3%'}
        },
        {
            dataField: 'description',
            text: 'Deskripsi',
            headerAlign: 'center',
            align: 'center',
            style: {width: '30%'}
        },
        {
            dataField: 'timestamp_utc',
            formatter: RowDatetime,
            text: 'Tanggal',
            headerAlign: 'center',
            align: 'center',
            style: {width: '30%'}
        },
        {
            dataField: 'status',
            formatter: RowStatus,
            text: 'Status',
            headerAlign: 'center',
            align: 'center',
            style: {width: '10%'}
        },
        {
            dataField: 'aksi',
            text: 'Aksi',
            formatter: RowAksi,
            headerAlign: 'center',
            align: 'center',
            style: {width: '20%'}
        }]

        const collective_detail_columns = [{
            dataField: 'id',
            text: 'No.',
            formatter: RowNumber,
            headerAlign: 'center',
            align: 'center',
            style: {width: '3%'}
        },
        {
            dataField: 'produk',
            formatter: RowProdukCollectiveDetail,
            text: 'Produk',
            headerAlign: 'left',
            align: 'left',
            style: {width: '20%'}
        },
        {
            dataField: 'bill_number',
            text: 'Nomor Pelanggan',
            headerAlign: 'center',
            align: 'center',
            style: {width: '15%'}
        },
        {
            dataField: 'customer_name',
            text: 'Nama Pelanggan',
            formatter: RowNamaPelangganCollectiveDetail,
            headerAlign: 'center',
            align: 'center',
            style: {width: '17%'}
        },
        {
            dataField: 'total_bayar',
            text: 'Total Bayar',
            formatter: RowTotalBayar,
            headerAlign: 'center',
            align: 'center',
            style: {width: '17%'}
        },
        {
            dataField: 'status_bayar',
            formatter: RowStatusBayar,
            text: 'Status Bayar',
            headerAlign: 'center',
            align: 'center',
            style: {width: '25%'}
        }]

        const columns = [{
            dataField: 'id',
            text: 'No.',
            formatter: RowNumber,
            headerAlign: 'center',
            align: 'center',
            style: {width: '3%'}
        },
        {
            dataField: 'produk',
            formatter: RowProduk,
            text: 'Produk',
            headerAlign: 'left',
            align: 'left',
            style: {width: '35%'}
        },
        {
            dataField: 'id_pelanggan',
            text: 'Nomor Tagihan',
            headerAlign: 'center',
            align: 'center',
            style: {width: '25%'}
        },
        {
            dataField: 'nama_pelanggan',
            text: 'Nama Pembeli',
            headerAlign: 'center',
            align: 'center',
            style: {width: '20%'}
        },
        {
            dataField: 'telepon_pelanggan',
            text: 'Telepon Pembeli',
            headerAlign: 'center',
            align: 'center',
            style: {width: '20%'}
        }]

        const collective_detail_expand_row = {
            renderer: (row) => (
                <div className="row">
                    <div className="col-lg-8" style={{textAlign:'center'}}>
                        {row.info_printable.split('|').map(function(item, key) {
                            if (item.length !== 0) {
                                return (
                                    <span key={key}>
                                        {item}
                                        <br/>
                                    </span>
                                )
                            }
                            return ''
                        })}
                    </div>
                    {row.status === 'SUDAH BAYAR' ?
                        <div className="col-lg-4" style={{textAlign: 'center',margin: 'auto'}}>
                            <button type="button" className="btn btn-primary btn-sm m-btn m-btn--custom"  
                            onClick={(el) => this.on_btn_cetak_struk(el, row)}>
                                Cetak Struk
                            </button>
                            &nbsp;&nbsp;
                            <a href={'mailto:' + config.helpdesk_email + '?cc=' + config.admin_email + '&subject=Komplain Transaksi Kolektif ' + row.collective_id.toUpperCase() + '&body=' + '%0A%0A%0Atxid: KOL' + row.collective_id + '%0Auid: ' + uid + '%0Aug: ' + ug} target="_top" type="button" className="btn btn-secondary btn-sm m-btn m-btn--custom">
                                Komplain
                            </a>
                        </div>
                    : ''}
                </div>
            ),
            showExpandColumn: true,
            expanded: this.state.expanded,
            onExpand: this.handleOnExpand,
            expandHeaderColumnRenderer: ({isAnyExpands}) => {
                if (isAnyExpands) {
                    return <b>-</b>;
                }
                return <b>+</b>;
            },
            expandColumnRenderer: ({expanded}) => {
                if (expanded) {
                    return (<b>-</b>)
                }
                return (<b>+</b>)
            }
        }

        const PendingTransactionView = (props) =>
            <div style={{marginTop: '30px'}} ref={this.ref_tabel_batch_pending}>
                <div className="form-group m-form__group" style={{display:'inline-flex',width:'100%',marginBottom:'10px'}}>
                    <h6 className="m--font-danger" style={{width:'100%',margin:'auto',fontWeight: 600}}>History Batch Inquiry Transaksi 3 Minggu Terakhir</h6>
                        {!this.state.flag_loading_pending_transaction ? 
                        <div style={{textAlign:'right',display:'table-cell',width:'100%'}}>
                            <button className='btn btn-brand m-btn m-btn--icon m-btn--icon-only' 
                                onClick={() => this.on_refresh_pending_batch()} 
                                style={{width:'40px'}}>
                                <i className="la la-refresh"></i>
                            </button>
                        </div>
                        : 
                        <div style={{textAlign:'right',display:'table-cell',width:'100%'}}>
                            <button className='btn btn-brand m-btn m-btn--icon m-btn--icon-only' style={{width:'40px'}}>
                                <div className="m-loader m-loader--secondary"></div>
                            </button>
                        </div>}
                </div>
                <BootstrapTable keyField='item_id'
                    data={this.state.tabel_pending_tx} striped hover bordered={true} 
                    options={this.options} 
                    noDataIndication="Tidak ada pending batch transaksi" 
                    columns={pending_columns}/>
            </div>

        const CollectiveDetailView = (props) =>
            <div style={{marginTop: '30px'}} ref={this.ref_tabel_collective}>
                <div className="form-group m-form__group" style={{display:'inline-flex',width:'100%'}}>
                    <div style={{textAlign:'right',display:'table-cell',width:'100%'}}>
                        {this.state.flag_loading_collective_detail ? 
                        <button className='btn btn-brand m-btn m-btn--icon m-btn--icon-only' 
                            style={{width:'40px',marginRight:'5px'}}>
                            <div className="m-loader m-loader--secondary"></div> 
                        </button>
                        : 
                        <button className='btn btn-brand m-btn m-btn--icon m-btn--icon-only' 
                            onClick={() => this.on_refresh_collective_batch()} 
                            style={{width:'40px',marginRight:'5px'}}>
                            <i className="la la-refresh"></i>
                        </button>
                        }
                        <button className={this.state.flag_submit_processing ? 'btn btn-primary m-btn m-btn--icon m-loader m-loader--secondary m-loader--right' : 'btn btn-primary m-btn m-btn--icon'} onClick={() => this.on_submit_payment()} style={{height:'35px', marginRight:'5px'}}>
                            <span>
                                <i className="la la-archive"></i>
                                <span>Bayar Batch Transaksi</span>
                            </span>
                        </button>
                        <BatchExportExcel data={this.state.tabel_collective_detail} 
                            date_batch={this.state.tanggal_collective_detail} 
                            total_batch={this.state.total_bayar_tabel_collective} 
                            agent_name={this.props.profile.name}/>
                        <ReactToPrint trigger= {() => { 
                            return <button className='btn btn-secondary m-btn m-btn--icon' style={{height:'35px', marginLeft:'5px'}}>
                                        <span>
                                            <i className="la la-print"></i>
                                            <span>Cetak Tabel</span>
                                        </span>
                                    </button>
                            }}
                            content={() => {
                                return this.componentRef
                            }}
                            pageStyle="@page{size: auto A4 landscape;}"
                        />
                        {this.state.tx_show_btn_print_all_struk ?
                            <button className='btn btn-secondary m-btn m-btn--icon' onClick={() => this.on_cetak_struk_all()} style={{height:'35px', marginLeft:'5px'}}>
                                <span>
                                    <i className="la la-print"></i>
                                    <span>Cetak Struk Sukses Bayar</span>
                                </span>
                            </button>
                        : ''}
                    </div>
                </div>
                <div ref={this.setRef}>
                    <div className="row">
                        <h6 className="m--font-danger" style={{marginLeft:'15px',fontWeight: 600}}>Daftar Tagihan Kolektif Transaksi Batch Inquiry Tanggal {this.state.tanggal_collective_detail}</h6>
                        <label style={{marginLeft:'auto',marginRight:'20px',marginTop:'-3px',fontWeight:600}}>Total Transaksi Bayar Rp.{util.rupiah_format(this.state.total_bayar_tabel_collective)}</label>
                    </div>
                    <BootstrapTable keyField='collective_id'
                        data={this.state.tabel_collective_detail} striped hover bordered={true} 
                        options={this.options} 
                        noDataIndication="Tidak ada daftar tagihan" 
                        columns={collective_detail_columns}
                        expandRow={collective_detail_expand_row}
                        selectRow={selectRowPropCollectiveDetail}/>
                    <div className="row">
                        <label style={{marginLeft:'auto',marginRight:'20px',marginTop:'-3px',fontWeight:600}}>Total Transaksi Bayar Rp.{util.rupiah_format(this.state.total_bayar_tabel_collective)}</label>
                    </div>
                    {this.state.show_more_coll_btn ? <button type="button" style={{width: '-webkit-fill-available'}} onClick={this.on_btn_coll_load_more} className={this.state.flag_loading_collective_detail ? 'btn btn-secondary m-loader m-loader--primary m-loader--right' : 'btn btn-secondary'}>
                        Load More
                    </button> : ''}
                </div>
            </div>

        const DaftarPelangganView = (props) =>
            <div style={{marginTop: '0px'}}>
                <BootstrapTable keyField='id'
                    data={this.state.tabel_data_pelanggan} striped hover bordered={true} 
                    options={this.options} 
                    noDataIndication="Tidak ada daftar pelanggan" 
                    selectRow={selectRowProp}
                    columns={columns}/>
            </div>

        const FileInputView = (props) =>
        	<div style={{marginBottom:'10px'}}>
        		<label style={{fontWeight: 600}}>
                    Load Data Daftar Pelanggan dari File
                </label>
        		<div className="row">
                    <span htmlFor="example-text-input" className="col-2" style={val_style}>
                    	Nama File 
                    </span>
                    <div className="col-10" style={val_style}>
                        :&nbsp;&nbsp;&nbsp;{this.state.batch_file_obj.name}
                    </div>
                </div>
                <div className="row">
                    <span htmlFor="example-text-input" className="col-2" style={val_style}>
                    	Terakhir Update
                    </span>
                    <div className="col-10" style={val_style}>
                        :&nbsp;&nbsp;&nbsp;{this.state.batch_file_obj.lastModifiedDate ? util.date_time_local_2(this.state.batch_file_obj.lastModifiedDate.getTime()) : util.date_time_local_2(this.state.batch_file_obj.lastModified)}
                    </div>
                </div>
        	</div>

        return (
                <div className="col-md-12" style={{padding: 0,marginTop:'-15px'}}>
                    <div className="m-alert m-alert--outline alert alert-success alert-dismissible fade show" role="alert">
                        <button type="button" className="close" data-dismiss="alert" aria-label="Close"></button>
                        <strong>Migrasi batch tool baru.</strong>
                        &nbsp;Jika Anda masih ingin menggunakan tool lama bisa akses <Link to="/agen/batch-transaction2">disini</Link>
                    </div>
                    <div className="m-portlet m-portlet--tab" style={{minHeight:'600px'}}>
                        <PortletHead headTxt={"Batch Transaksi"} />
                        <div className="m-portlet__body" style={{margin: '0px 0px',paddingTop:'17px'}}>
                            <div className="m-form m-form--fit m-form--label-align-right" style={{borderBottom: '1px dashed #ebedf2',paddingBottom:'17px',marginBottom:'15px'}}>

                                <div className="form-group m-form__group row col-md-12" style={{paddingLeft:0,paddingRight:0,paddingBottom:0,margin:0}}>
                                    <label className="col-2 col-form-label" style={{textAlign:'left',paddingLeft:0,paddingRight:0}}>
                                        Produk
                                    </label>
                                    <div className="col-4" style={{padding:'0 0 0 3px'}}>
                                        <Select
                                            value={this.state.selected_data_product}
                                            onChange={this.select_handle_change}
                                            options={this.state.select_data_product}
                                            placeholder="Pilih Produk"
                                            isClearable={true}/>
                                    </div>

                                    <label className="col-2" style={{textAlign:'right', lineHeight:'18px',margin:'auto'}}>
                                        Nomor Tagihan
                                    </label>
                                    <div className="col-4" style={{padding:'0 3px 0 7px'}}>
                                        <input className="form-control m-input" type="text" value={this.state.nomor_pelanggan} name="nomor_pelanggan" onChange={(e) => this.on_input_change(e)}/>
                                    </div>
                                </div>

                                <div className="form-group m-form__group row col-md-12" style={{paddingLeft:0,paddingRight:0,margin:0}}>
                                    <label className="col-2 col-form-label" style={{textAlign: 'left',paddingLeft:0,paddingRight:0}}>
                                        Nama Pembeli
                                    </label>
                                    <div className="col-4" style={{padding:'0 0 0 3px'}}>
                                        <input className="form-control m-input" placeholder="optional" type="text" value={this.state.nama_pelanggan} name="nama_pelanggan" onChange={(e) => this.on_input_change(e)}/>
                                    </div>

                                    <label className="col-2" style={{textAlign: 'right', lineHeight: '18px',margin:'auto'}}>
                                        Telepon Pembeli
                                    </label>
                                    <div className="col-4" style={{padding:'0 0 0 7px'}}>
                                        <div style={{display:'inline-flex'}}>
                                            <input className="form-control m-input" placeholder="optional" type="text" value={this.state.telepon_pelanggan} name="telepon_pelanggan" onChange={(e) => this.on_input_change(e)}/>
                                            <button className="btn btn-brand m-btn m-btn--icon m-btn--icon-only" style={{marginTop:'2px',marginLeft:'10px',width:'50px'}} onClick={() => this.on_btn_add()}>
                                                <i className="la la-plus"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group m-form__group" style={{display:'inline-flex',width:'100%'}}>

                                <div className="btn-group" role="group">
                                    <button type="button" className="m-btn btn btn-primary" onClick={() => this.on_btn_remove()} style={{padding:'0px 10px',height:'35px'}} title="Hapus daftar baris terseleksi">
                                        <i className="la la-trash"></i>
                                    </button>
                                    <button type="button" className="m-btn btn btn-success m-btn--icon" onClick={(e) => document.getElementById("batch_file_input").click()} style={{padding:'0px 10px',height:'35px'}} title="Load daftar pelanggan dari sebuah file">
                                        <span>
                                            <i className="la la-upload"></i>
                                            <span>Import</span>
                                        </span>
                                    </button>
                                    <button type="button" className="m-btn btn btn-warning m-btn--icon" onClick={() => this.on_btn_save()} style={{padding:'0px 10px',height:'35px'}} title="Simpan daftar pelanggan ke sebuah file">
                                        <span>
                                            <i className="la la-save"></i>
                                            <span>Simpan</span>
                                        </span>
                                    </button>
                                </div>

                                <input type="file" accept=".csv,text/plain" id="batch_file_input" name="batch_file_input" 
                                        onChange={this.batch_file_input_changed} 
                                        style={{display:'none'}} 
                                        ref={ref=> this.file_input = ref}/>

                                <div style={{textAlign:'right',display:'table-cell',width:'100%'}}>
                                    <button className={this.state.flag_cek_processing ? 'btn btn-primary m-btn m-btn--icon m-loader m-loader--secondary m-loader--right' : 'btn btn-primary m-btn m-btn--icon'}   
                                        onClick={() => this.on_inquiry_batch_file()} style={{height:'35px',marginRight:'5px'}}>
                                        <span>
                                            <i className="la la-archive"></i>
                                            <span>Inquiry Daftar Pelanggan</span>
                                        </span>
                                    </button>
                                    <button type="button" className="m-btn btn btn-secondary" onClick={() => this.on_btn_reset()} style={{padding:'0px 10px',height:'35px'}} title="Reset data daftar pelanggan">
                                        <span>Reset</span>
                                    </button>
                                </div>

                            </div>

                            {this.state.batch_file_obj.length !== 0 ? <FileInputView/> : ''}
                            {this.state.show_daftar_pelanggan ? <DaftarPelangganView/> : ''}
                            {this.state.show_pending_transaction ? <PendingTransactionView/> : ''}
                            {this.state.show_collective_detail ? <CollectiveDetailView/> : ''}

                        </div>
                    </div>
                    {this.state.tx_show_struk ? <CetakStruk data={tx_result_success} on_close={()=>{
                        self.setState({tx_show_struk: false})
                    }} /> : ''}
                </div>
                )
    }
}

const mapDispatchToProps = (dispatch) => ({
    update_current_page:  val => dispatch(do_action(action.ACTION_UPDATE_CURRENT_PAGE, val))
})

const mapStateToProps = (state) => ({
    session : state.Session,
    profile: state.Profile
})

export default connect(mapStateToProps, mapDispatchToProps)(AgentBatchTransaction2)