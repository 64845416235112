import React, {Component} from 'react';
import {connect} from 'react-redux';
import Modal from 'react-responsive-modal';
import moment from 'moment';
import ReactToPrint from "react-to-print";
import util from '../../helper/util';
import Barcode from 'react-barcode';
import QRCode from 'qrcode.react';

class CetakResiPaket extends Component {

    constructor(props) {
        super(props);
        this.cetak_btn_ref = React.createRef();
        util.log(this.props.data);
    }

    componentDidMount() {
        let self = this;
    }

    handleAfterPrint = () => {
        this.props.on_close()
    }

    handleBeforePrint = () => {
    }

    renderContent = () => {
        return this.componentRef;
    }

    renderTrigger = () => {
        return <button type="button" onClick={this.on_cetak} className='btn btn-primary'>Cetak</button>
    }

    setRef = (ref) => {
        this.componentRef = ref;
    }

    render() {
        let data = this.props.data;
        let detail = this.props.data_detail;
        let is_history = this.props.is_history;
        let connote = detail.connote[0] || {};
        let koli = detail.koli[0] || {};
        util.log('data', {data, connote});

        let agentName = "";
        let zoneCode = "";
        let receiverKota = "";
        if (detail.b2bpos_agen) {
            agentName = detail.b2bpos_agen.location_name;
        }
        if (detail.origin) {
            zoneCode = detail.origin.zone_code + " - " + detail.destination.zone_code;
        }
        if (detail.destination) {
            let kotaKelurahan = detail.destination.detail.split(':');
            receiverKota = ', ' + kotaKelurahan[0];
        }
        let image_style = {marginLeft:'auto',marginRight:'auto',maxWidth: '50px',display: 'table-cell'};
        let postingDate = "";
        let postingTime = "";
        if (detail.state_updated_at) {
            let t = moment(new Date(detail.state_updated_at));
            postingDate = t.format("DD-MM-YYYY");
            postingTime = t.format("HH:MM");
        }
        let barCodeValue = is_history ? detail.connote_code + '(DUPLICATE)' : detail.connote_code;

        return (
            <Modal className="modal" 
                open={true} 
                onClose={this.props.on_close} center 
                showCloseIcon={true} 
                closeOnOverlayClick={false}>
                <div className='modal-dialog modal-lg' role='document' 
                    style={{fontFamily: 'verdana', marginTop: '0px',marginBottom: '0px',width:'600px'}}>
                    <div className="modal-content" 
                        style={{border:'white',backgroundColor:'transparent'}}>
                        <div className="modal-header" style={{borderBottom:'none', padding:'0 15px'}}>
                            <h5 className="modal-title">
                                Resi Paket
                            </h5>
                        </div>
                        <div className="modal-body" ref={this.setRef}>
                            <div>
                                <form className="m-form m-form--fit m-form--label-align-right">
                                    <div className="m-portlet__body">
                                        <table className='resi-table'>
                                            <tr>
                                                <td colspan="8" style={{padding:0}}>
                                                    <table className='resi-header' style={{width: '100%'}}>
                                                        <tr>
                                                            <td colspan="2" rowSpan="2" style={{verticalAlign: 'middle'}}>
                                                            <table style={{border:'none'}}>
                                                            <tr><td style={{border:'none'}}>
                                                                <img src="../swipe/img/logo_pos_indonesia.png" 
                                                                style={image_style}/>  
                                                            </td></tr>
                                                            </table>
                                                            </td>
                                                            <td colspan="4">{detail.service_name}</td>
                                                            <td colspan="2" rowSpan="2" style={{verticalAlign: 'middle'}}>
                                                            <table style={{border:'none'}}>
                                                            <tr><td style={{border:'none'}}>
                                                                <img src="../swipe/img/pospay-agen.png" 
                                                                style={image_style}/> 
                                                            </td></tr>
                                                            </table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4" style={{padding:0}}>
                                                                <Barcode 
                                                                    value={barCodeValue}
                                                                    height={40}
                                                                    width={1.5}
                                                                    fontSize={10}
                                                                    margin={5}
                                                                />
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="8">Ref Pengiriman Paket</td>
                                            </tr>
                                            <tr>
                                                <td colspan="8" style={{textAlign: 'center'}}>{agentName}</td>
                                            </tr>
                                            <tr>
                                                <td colspan="4">Dari:
                                                    <div>{connote.sender_name}</div>
                                                    <div>{connote.sender_address}</div>
                                                    <div>{connote.sender_zipcode}</div>
                                                    <div>Telp: {connote.sender_phone}</div>
                                                </td>
                                                <td colspan="4" rowspan="2">Kepada:
                                                    <div style={{fontWeight: 600}}>{connote.receiver_name}</div>
                                                    <div>{connote.receiver_address}<span style={{fontWeight:600}}>{receiverKota}</span></div>
                                                    <div>{connote.receiver_zipcode}</div>
                                                    <div>Telp: {connote.receiver_phone}</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="4">Berat: {connote.actual_weight} kg
                                                <br/>Volume: {koli.width}cm x {koli.height}cm x {koli.length}cm
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="4" style={{textAlign: 'center'}}>{zoneCode}</td>
                                                <td colspan="2" rowspan="3" style={{padding:0,height:'100px',verticalAlign: 'middle'}}>
                                                    <table style={{border:'none',marginLeft:'30px',marginTop:'3px'}}>
                                                        <tr><td style={{border:'none'}}>
                                                        <QRCode value={barCodeValue}
                                                            size={64}
                                                            renderAs={'svg'}/>
                                                        </td></tr>
                                                    </table>
                                                </td>
                                                <td colspan="2">NON COD</td>
                                            </tr>
                                            <tr>
                                                <td colspan="4" rowspan="2">Tanggal Transaksi:
                                                <br/>{connote.sla_date}</td>
                                                <td colspan="2" style={{verticalAlign: 'middle'}}>-</td>
                                            </tr>
                                            <tr>
                                                <td colspan="2">1/1</td>
                                            </tr>
                                        </table>
                                        <div style={{border: 'dotted 0.5px lightgray',margin:'5px 0'}}></div>
                                        <table className='resi-table'>
                                            <tr>
                                                <td colspan="4" rowspan="2">Bukti Pengiriman
                                                    <div>Kantor Kirim: {agentName}</div>
                                                    <div>Tanggal Posting: {postingDate}</div>
                                                    <div>Waktu Posting: {postingTime}</div>
                                                </td>
                                                <td colspan="2">Pengirim:
                                                    <div>{connote.sender_name}</div>
                                                    <div>{connote.sender_address}</div>
                                                    <div>{connote.sender_zipcode}</div>
                                                    <div>Telp: {connote.sender_phone}</div>
                                                </td>
                                                <td colspan="2">Penerima:
                                                    <div style={{fontWeight:600}}>{connote.receiver_name}</div>
                                                    <div>{connote.receiver_address}<span style={{fontWeight:600}}>{receiverKota}</span></div>
                                                    <div>{connote.receiver_zipcode}</div>
                                                    <div>Telp: {connote.receiver_phone}</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="4">Berat: {connote.actual_weight} kg
                                                    <br/>Volume: {koli.width}cm x {koli.height}cm x {koli.length}cm
                                                    <br/>Bea Kirim: Rp. {util.rupiah_format(connote.amount)}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="4">Jenis Kiriman: Pos Jumbo Ekonomi</td>
                                                <td colspan="4" rowspan="2">Pernyataan Pengirim:
                                                    <div>1. Setuju dengan ketentuan dan syarat pengiriman yang ditetapkan PT. Pos Indonesia (Persero)</div>
                                                    <div>2. Isi kiriman: {koli.description}</div>
                                                    <div>3. Nilai pertanggungan isi kiriman: Rp. {util.rupiah_format(koli.harga_barang)}</div>
                                                    <div>4. Asuransi: Rp. {util.rupiah_format(connote.surcharge_amount)}</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="4" style={{textAlign: 'center'}}>
                                                    <Barcode 
                                                        value={barCodeValue}
                                                        height={30}
                                                        width={is_history ? 1 : 1.5}
                                                        fontSize={10}
                                                        margin={5}
                                                        textAlign={'center'}
                                                    />
                                                <div style={{fontSize:'0.8em',textAlign:'left'}}>Silakan membuka https://www.posindonesia.co.id/id/tracking untuk mengetahui status kiriman anda</div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="modal-footer" style={{borderTop: 'none', padding:'0 15px'}}>
                            <ReactToPrint ref={this.cetak_btn_ref}
                                trigger={this.renderTrigger}
                                content={this.renderContent}
                                onBeforePrint={this.handleBeforePrint}
                                onAfterPrint={this.handleAfterPrint}
                                pageStyle="@page{size: auto A4 portrait;}"
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => ({
    profile: state.Profile
})

export default connect(mapStateToProps, null)(CetakResiPaket)
