import React, {Component} from 'react';
import { connect } from 'react-redux';
import {Form, FormGroup} from '../component/form/form';
import {Portlet, PortletHead, PortletBody, PortletFooter} from '../component/widget/portlet';
import api from '../helper/api';
import localdata from '../helper/localdata';
import config from '../helper/config';
import swal from 'sweetalert';
import action from '../helper/action';
import do_action from '../action';

class AgentMLOHandover extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.scroll_to_top();
        this.props.update_current_page('mlo-posting');
    }

    scroll_step() {
        if (window.pageYOffset === 0) {
            clearInterval(this.state.scroll_interval_id);
        }
        window.scroll(0, window.pageYOffset - 50);
    }

    scroll_to_top() {
        let scroll_interval_id = setInterval(this.scroll_step.bind(this), 16.66);
        this.setState({scroll_interval_id: scroll_interval_id});
    }


    render() {
        const content_style = {fontWeight: 500};
        return (
            <div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="m-portlet">
                            <PortletHead headTxt={"Syarat & Ketentuan Layanan Kurir Pospay Agen"} />
                            <div className="m-portlet__body">
                                
                            </div>
                        </div>
                        <div className=" m-portlet__foot m-portlet__foot--fit">
                            <div className="m-form__actions ">
                                <div className="row">
                                    <div className="col-10">
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    update_current_page:  val => dispatch(do_action(action.ACTION_UPDATE_CURRENT_PAGE, val))
});

const mapStateToProps = (state) => ({
    profile: state.Profile
});

export default connect(mapStateToProps, mapDispatchToProps)(AgentMLOHandover);