import {connect} from 'react-redux'
import React, {Component} from 'react'
import {history} from '../../helper/history'
import Header from './header'
import Sidebar from './sidebar'
import Footer from './footer'
import apis from '../../helper/api'
import {withRouter} from "react-router-dom"
import localdata from '../../helper/localdata'
import request from 'superagent'
import swal from 'sweetalert'
import util from '../../helper/util'
import config from '../../helper/config'

let running_text_once = false;
class Index extends Component {

    constructor(props) {
        super(props)
        this.state = {
            title : "Dashboard",
            breadcrumb : [{"title" : "Agen"}],
            info_posfin : config.info_posfin
        };
        this.on_page_changed = this.on_page_changed.bind(this);
        this.on_logout = this.on_logout.bind(this);
    }

    get_running_text() {
        let self = this;
        let body = {
            "session_id" : this.props.session
        }
        request.post(apis.get_running_text)
            .set("Content-Type", "application/json")
            .send(body)
            .end(function(err, res) {
                if (err || !res.ok) {
                    util.log(err)
                } else {
                    util.log(res);
                    if (res.statusCode === 200) {
                        let payload = res.body
                        if (payload.status === "OK" && payload.results) {
                            if (payload.results.text_message && payload.results.text_message.length !== 0) {
                                self.setState({info_posfin: payload.results.text_message});
                            }
                        }
                    }
                }
            })
    }

    on_page_changed(e, val) {
        let lock = false
        if (lock) {
            e.preventDefault()
        } else {
            this.setState({title : val.title});
            this.setState({breadcrumb : val.nav});
        }
    }

    on_logout() {
        let body = {
            "session_id": this.props.session
        }
        request.post(apis.logout)
            .set("Content-Type", "application/json")
            .send(body)
            .end(function(err, res) {
                if (err || !res.ok) {
                    swal("Ups!", "Error code " + res.statusCode, "warning")
                } else {
                    if (res.statusCode === 200) {
                        let payload = res.body
                        if (payload.status === "OK" || payload.error.code === 2006) {
                            localdata.clear_data().then((res) => {
                                history.replace('/login');
                            }).catch((err) => {
                                util.log("error: ", err)
                            })
                        } else {
                            swal("Ups!", payload.error.message, "warning")
                        }
                    }
                }
            })
    }

    render() {
        if (this.props.session !== null && !running_text_once) {
            running_text_once = true;
            this.get_running_text();
        }
        const listItems = this.state.breadcrumb.map((d, index) =>
            <React.Fragment key={index} >
                <li className="m-nav__item">
                    <a className="m-nav__link">
                        <span className="m-nav__link-text">{d.title}</span>
                    </a>
                </li>
                <li className="m-nav__separator">
                    &nbsp;/&nbsp;
                </li>
            </React.Fragment>);

        const renderHTML = (escapedHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: escapedHTML } });
        const info = renderHTML(this.state.info_posfin);

        return (
            <div className="m-grid m-grid--hor m-grid--root m-page">
                
                <Header on_page_changed={this.on_page_changed} on_logout={this.on_logout} />
                <div className="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
                    <button className="m-aside-left-close m-aside-left-close--skin-dark " id="m_aside_left_close_btn">
                        <i className="la la-close"></i>
                    </button>
                    <Sidebar on_page_changed={this.on_page_changed} on_logout={this.on_logout} />
                    <div className="m-grid__item m-grid__item--fluid m-wrapper">
                        <div className="m-subheader ">
                            <div className="d-flex align-items-center">
                                <div className="mr-auto" style={{marginRight:'0 !important',width:'100%'}}>
                                    <h3 className="m-subheader__title m-subheader__title--separator">
                                        {this.state.title}
                                    </h3>
                                    <ul className="m-subheader__breadcrumbs m-nav m-nav--inline">
                                        <li className="m-nav__item m-nav__item--home">
                                            <a className="m-nav__link m-nav__link--icon" style={{marginRight: '5px'}}>
                                                <i className="m-nav__link-icon fa fa-home"></i>
                                            </a>
                                        </li>
                                        <li className="m-nav__separator">
                                            &nbsp;/&nbsp;
                                        </li>
                                        {listItems}
                                    </ul>
                                    <div style={{marginRight:'0px',marginTop:'7px',marginLeft:'15px',display:'inline-flex',position:'absolute'}}>
                                        <i className="m-nav__link-icon fa fa-envelope-open" style={{display:'inline',fontSize:'1.1rem',color:'#898b96',marginTop:'3px',marginRight:'5px',color:'#f76b1c'}}></i>
                                        <p className="marquee-separator marquee" style={{fontWeight:600}}>{info}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="m-content">
                            {this.props.children}
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    session : state.Session
})

export default withRouter(connect(mapStateToProps, null)(Index))