import { connect } from 'react-redux'
import React, { Component } from 'react'
import moment from 'moment'
import request from 'superagent'
import MetronicIndex from '../layout/metronic/index'
import { Form, FormGroup } from '../component/form/form'
import { Portlet, PortletHead, PortletBody, PortletFooter, PortletTab, PortletTabHeader, PortletTabHeaderList, PortletTabBody, PortletTabBodyList, PortletAlert, PortletStruk } from '../component/widget/portlet'
import apis from '../helper/api'
import localdata from '../helper/localdata'
import action from '../helper/action'
import do_action from '../action'
import config from '../helper/config'
import swal from 'sweetalert'
import util from '../helper/util'
import Modal from 'react-responsive-modal'
import CetakTagihan from '../component/form/cetak_inquiry'
import CetakStruk from '../component/form/cetak_struk'
import ReactGA from 'react-ga'

let tx_product = ""
let start_time_process_seconds = 0

class AgentEcommerce extends Component {
    constructor(props) {
        super(props)
        this.state = {
            no_pelanggan: "",
            products: [],
            provider: "",
            tx_show_modal: false,
            tx_flag_processing: false,
            tx_show_struk: false,
            tx_tagihan_pelanggan: "",
            tx_result_message: "",
            tx_result_success: "",
            scroll_interval_id: 0,
            show_info_pelanggan: false, 
            nama_pelanggan: "", 
            nomor_pelanggan: "",
            show_btn_bayar: true
        }
        this.on_btn_tagihan = this.on_btn_tagihan.bind(this)
        this.on_btn_reset = this.on_btn_reset.bind(this)
        this.on_btn_confirm_cancel = this.on_btn_confirm_cancel.bind(this)
        this.on_btn_confirm_ok = this.on_btn_confirm_ok.bind(this)
        this.on_btn_struk_cancel = this.on_btn_struk_cancel.bind(this)
        this.on_btn_struk_ok = this.on_btn_struk_ok.bind(this)
        this.on_btn_cetak_struk = this.on_btn_cetak_struk.bind(this)
        this.on_key_press = this.on_key_press.bind(this)
    }

    componentDidMount() {
        this.scroll_to_top()
        this.props.update_current_page('ecommerce')
        let self = this
        tx_product = ""
        localdata.get_product("SERVICE_ECOMMERCE").then((payload) => {
            util.log("SERVICE_ECOMMERCE: ", payload)
            if (payload.length !== 0) {
                self.setState({products : payload})
            }
        }).catch((err) => {
            util.log("error : ", err)
        })
    }

    scroll_step() {
        if (window.pageYOffset === 0) {
            clearInterval(this.state.scroll_interval_id);
        }
        window.scroll(0, window.pageYOffset - 50);
    }

    scroll_to_top() {
        let scroll_interval_id = setInterval(this.scroll_step.bind(this), 16.66);
        this.setState({scroll_interval_id: scroll_interval_id});
    }

    on_input_change(e) {
        if (e.target.name == "no_pelanggan") {
            this.setState({
                no_pelanggan: e.target.value
            })
        } else {
            this.setState({[e.target.name]: e.target.value})
        }
    }

    on_key_press(e) {
        util.log(e)
        if (e.keyCode == 13) {
            if (e.target.name == "no_pelanggan") {
                this.on_btn_tagihan()
            }
        }
    }

    on_select_change(e) {
        if (e.target.value.length !== 0) {
            let product_name = e.target[e.target.selectedIndex].getAttribute('data-product_name')
            tx_product = {
                "product_id" : e.target.value,
                "product_name" : product_name
            }
        } else {
            tx_product = ""
        }
        util.log("on_select_change: ", e.target.value)
        this.setState({provider: e.target.value})
        this.nomor_pelanggan_input.focus()
    }

    on_btn_cetak_struk(el) {
        if (this.state.tx_result_success.length !== 0) {
            util.log(this.state.tx_result_success.data);
            if (util.is_electron()) {
                const btn = el.target;
                btn.classList.add("m-loader", "m-loader--primary", "m-loader--right");
                setTimeout(function() {
                    btn.classList.remove("m-loader", "m-loader--primary", "m-loader--right");
                }, 3000);
                const agent_info = this.props.profile;
                util.electron_print_receipt(agent_info, this.state.tx_result_success.data);
            } else {
                this.setState({tx_show_struk: true});
            }
        }
    }

    on_btn_struk_ok() {
        this.setState({tx_show_struk: false})
    }

    on_btn_struk_cancel() {
        this.setState({tx_show_struk: false})
    }

    on_btn_tagihan() {
        if (this.state.tx_flag_inquiry_processing) return
        let self = this
        if (self.state.no_pelanggan.length !== 0) {
            if (tx_product.length === 0) {
                swal("Ups!", "Silakan pilih jenis payment terlebih dulu!", "warning")
                return
            }
            let biller_number = this.state.no_pelanggan
            let body = {
                "session_id" : self.props.session,
                "product_id" : tx_product.product_id,
                "biller_number": biller_number
            }
            self.setState({tx_flag_inquiry_processing: true, tx_tagihan_pelanggan: ""})
            start_time_process_seconds =  Math.floor(Date.now() / 1000)
            request.post(apis.inquiry)
                .set("Content-Type", "application/json")
                .timeout(120*1000)
                .send(body)
                .end(function(err, res) {
                    let message_error = ""
                    if (err || !res.ok) {
                        util.log(err)
                        message_error = {"body": err.message, "title": "Transaksi Gagal"}
                        if (res !== undefined) {
                            message_error.body = "Error code " + res.statusCode
                        }
                    } else {
                        util.log(res);
                        if (res.statusCode == 200) {
                            let payload = res.body
                            util.log(payload)
                            if (payload.status == "OK") {
                                self.setState({tx_tagihan_pelanggan: payload.results, show_btn_bayar: true})
                                if (start_time_process_seconds !== 0) {
                                    let end_time_process = Math.floor(Date.now() / 1000)
                                    let action = config.ga.event.inquiry + '-' + util.time_range_ga(end_time_process-start_time_process_seconds)
                                    ReactGA.event({
                                        category: 'transaction',
                                        action: action,
                                        label: payload.results.product_name
                                    })
                                }
                            } else {
                                util.on_error(payload.error)
                                message_error = {"body": res.body.error.message, "title": "Transaksi Gagal"}
                                if (res.body.display_message !== undefined) {
                                    message_error.title = res.body.error.message
                                    message_error.body = res.body.display_message
                                }
                            }
                        } else {
                            message_error = {"body": "HTTP error " + res.statusCode, "title": "Transaksi Gagal"}
                        }
                    }
                    if (message_error.length != 0) {
                        swal(message_error.title, message_error.body, "error")
                    }
                    self.setState({tx_flag_inquiry_processing: false})
                    start_time_process_seconds = 0
                });
        }
    }

    on_btn_reset() {
        this.setState({no_pelanggan: "", tx_result_message: "", tx_result_success: "", tx_tagihan_pelanggan: "", provider: ""})
        tx_product = ""
    }

    on_btn_bayar() {
        this.setState({tx_show_modal: true, show_info_pelanggan: false, nama_pelanggan: "", nomor_pelanggan: ""})
    }

    on_btn_confirm_ok() {
        if (this.state.tx_flag_processing) return
        if (tx_product !== "") {
            let self = this
            let whitelabel_36 = config.whitelabel.toString(36).toUpperCase()
            let user_id = this.props.profile.userid.toString(36).toUpperCase()
            let prefix = whitelabel_36.substring(whitelabel_36.length - 3)
            let suffix = user_id.substring(user_id.length - 3)
            let token = util.gen_token(prefix, this.props.session, suffix)
            let biller_number = this.state.no_pelanggan
            let body = {
                "session_id" : this.props.session,
                "product_id" : tx_product.product_id,
                "product_code" : tx_product.product_denom,
                "biller_number" : biller_number,
                "sign_token" : token
            }
            if (this.state.nama_pelanggan !== undefined && this.state.nama_pelanggan.length !== 0) {
                body.customer_name = this.state.nama_pelanggan
            }
            if (this.state.nomor_pelanggan !== undefined && this.state.nomor_pelanggan.length !== 0) {
                body.customer_phone_number = this.state.nomor_pelanggan
            }
            self.setState({tx_flag_processing: true})
            start_time_process_seconds =  Math.floor(Date.now() / 1000)
            request.post(apis.payment)
                .set("Content-Type", "application/json")
                .timeout(120*1000)
                .send(body)
                .end(function(err, res) {
                    let message_result = ""
                    let success_result = ""
                    if (err || !res.ok) {
                        util.log(err)
                        message_result = {title: "Error", body: err.message}
                        if (res != undefined) {
                            message_result.body = "Error code " + res.statusCode
                        }
                        if (err.code === 504) {
                            message_result.body = config.timeout_message
                        }
                    } else {
                        util.log(res);
                        if (res.statusCode === 200) {
                            let payload = res.body
                            util.log(payload)
                            if (payload.status == "OK") {
                                let results = payload.results
                                let success_info = "Biller No: " + results.biller_number + "|Produk: " + results.product_id + " " + results.product_name + "|Harga Produk: Rp. " + util.rupiah_format(results.nominal) + "|Biaya Admin: Rp. " + util.rupiah_format(results.fee) + "|Total Bayar: Rp. " + util.rupiah_format(results.total) 
                                success_result = {title: "Transaksi Sukses", body: success_info, data: results}
                                self.props.update_balance(results.balance_after)
                                swal(success_result.title, payload.display_message, "success")
                                self.setState({show_btn_bayar:false})
                                if (start_time_process_seconds !== 0) {
                                    let end_time_process = Math.floor(Date.now() / 1000)
                                    let action = config.ga.event.payment + '-' + util.time_range_ga(end_time_process-start_time_process_seconds)
                                    ReactGA.event({
                                        category: 'transaction',
                                        action: action,
                                        label: results.product_name
                                    })
                                }
                            } else {
                                util.on_error(payload.error)
                                message_result = {title: "Error", body: res.body.error.message}
                                if (res.body.display_message != undefined) {
                                    message_result.body = message_result.body + ". " + res.body.display_message
                                }
                            }
                        } else {
                            message_result = {title: "Error", body: res.statusCode}
                        }
                    }
                    if (message_result.length !== 0) {
                        swal(message_result.title, message_result.body, "error")
                        message_result.title = "[" + moment().format("DD-MM-YYYY hh:mm") + "] " + message_result.title
                    }
                    self.setState({tx_flag_processing: false, tx_result_message: message_result, tx_result_success: success_result, tx_show_modal: false})
                    start_time_process_seconds = 0
                });
        }
    }

    on_btn_confirm_cancel() {
        this.setState({tx_show_modal: false})
    }

    on_btn_cetak_tagihan() {
        this.setState({show_cetak_tagihan: true})
    }

    render() {
        let self = this
        let tx_result_success = this.state.tx_result_success.data
        const padding_field = {paddingTop: 6+'px',paddingBottom: 6+'px'}
        const label_tagihan_style = {textAlign:'left',padding:0,marginTop:'auto',marginBottom:'auto',fontWeight:600}
        const value_tagihan_style = {fontWeight:600,width:'100%',textAlign:'right',padding:0}
        const TagihanView = (props) => 
        <div className="col-lg-6">
            <div className="m-portlet m-portlet--success m-portlet--head-solid-bg m-portlet--rounded" style={{minHeight:'500px',background:'#fff'}}>
                <div className="m-portlet__head">
                    <div className="m-portlet__head-caption">
                        <div className="m-portlet__head-title">
                            <span className="m-portlet__head-icon">
                                <i className="flaticon-interface-5"></i>
                            </span>
                            <h3 className="m-portlet__head-text">
                                Tagihan {this.state.no_pelanggan}
                            </h3>
                        </div>
                    </div>
                    <div className="m-portlet__head-tools">
                        <ul className="m-portlet__nav">
                            {this.state.show_btn_bayar ? 
                                <li className="m-portlet__nav-item">
                                    <button onClick={() => this.on_btn_bayar()} className="m-portlet__nav-link btn btn-primary m-btn m-btn--pill m-btn--air" style={{padding:"7px 15px"}}>
                                        Bayar
                                    </button>
                                </li>
                            : ''}
                            <li className="m-portlet__nav-item" style={{display:'none'}}>
                                <button onClick={() => this.on_btn_cetak_tagihan()} className="m-portlet__nav-link btn btn-light m-btn m-btn--pill m-btn--air" style={{padding:"7px 15px"}}>
                                    Cetak
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="m-portlet__body" style={{padding:15+'px'}}>
                    <div className="row">
                        <div className="col-lg-12">
                            <form className="m-form m-form--fit m-form--label-align-right m-form--group-seperator-dashed">
                                <div className="form-group m-form__group row" style={padding_field}>
                                    <label className="col-lg-2 col-form-label" style={label_tagihan_style}>
                                        Produk:
                                    </label>
                                    <div className="col-lg-10" style={{paddingLeft:0,paddingRight:0}}>
                                        <label className="col-form-label" style={{padding:0}}>
                                            {tx_product.length !== 0 ? tx_product.product_id + " " + tx_product.product_name : this.state.provider}
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group m-form__group row" style={padding_field}>
                                    <label className="col-lg-2 col-form-label" style={label_tagihan_style}>
                                        Info:
                                    </label>
                                    <div className="col-lg-10" style={{paddingLeft:0,paddingRight:0}}>
                                        <label className="col-form-label" style={{lineHeight:'20px',padding:0}}>
                                            {this.state.tx_tagihan_pelanggan.info.split('|').map(function(item, key) {
                                                return (
                                                    <span key={key}>
                                                        {item}
                                                        <br/>
                                                    </span>
                                                )
                                            })}
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group m-form__group row" style={padding_field}>
                                    <label className="col-lg-4 col-form-label" style={label_tagihan_style}>
                                        Tagihan:
                                    </label>
                                    <div className="col-lg-8">
                                        <label className="col-form-label" style={value_tagihan_style}>
                                            Rp. {this.state.tx_tagihan_pelanggan?util.rupiah_format(this.state.tx_tagihan_pelanggan.nominal):'0'}
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group m-form__group row" style={padding_field}>
                                    <label className="col-lg-4 col-form-label" style={label_tagihan_style}>
                                        Biaya Admin:
                                    </label>
                                    <div className="col-lg-8">
                                        <label className="col-form-label" style={value_tagihan_style}>
                                            Rp. {this.state.tx_tagihan_pelanggan?util.rupiah_format(this.state.tx_tagihan_pelanggan.fee):'0'}
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group m-form__group row" style={padding_field}>
                                    <label className="col-lg-4 col-form-label" style={label_tagihan_style}>
                                        Total Bayar:
                                    </label>
                                    <div className="col-lg-8">
                                        <label className="col-form-label" style={value_tagihan_style}>
                                            Rp. {this.state.tx_tagihan_pelanggan?util.rupiah_format(this.state.tx_tagihan_pelanggan.total):''}
                                        </label>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-12">
                        {this.state.tx_result_message.length != 0 ? <PortletAlert icon="warning" title={this.state.tx_result_message.title} message={this.state.tx_result_message.body} /> : ""}
                        {this.state.tx_result_success.length != 0 ? <PortletStruk icon="warning" title={this.state.tx_result_success.title} message={this.state.tx_result_success.body} on_btn_cetak_struk={this.on_btn_cetak_struk}/> : ""}
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="m-portlet m-portlet--tab" style={{minHeight:'500px'}}>
                            <PortletHead headTxt={"Bayar Belanja E-Commerce"} />
                            <Form>
                                <PortletBody>
                                    <FormGroup>
                                        <label htmlFor="provider">Jenis E-Commerce</label>
                                        <select className="form-control m-input" id="product-sel" onChange={(e) => this.on_select_change(e)} value={this.state.provider}>
                                        <option key={999} value=""> -- Pilih -- </option>
                                        {
                                             self.state.products.length != 0 ? self.state.products.map((product, j) =>
                                                <option key={j} value={product.product_id} data-product_name={product.product_name}>
                                                    {product.product_name}
                                                </option>
                                            ) : ''
                                        }
                                        </select>
                                    </FormGroup>
                                    <FormGroup>
                                        <label htmlFor="nomor-pelanggan">Nomor Tagihan</label>
                                        <input
                                            ref={input => {this.nomor_pelanggan_input = input}}
                                            onChange={(e) => this.on_input_change(e)}
                                            onKeyDown={this.on_key_press}
                                            type="text"
                                            className="form-control m-input"
                                            name="no_pelanggan"
                                            value={this.state.no_pelanggan}
                                            placeholder="Nomor Bayar"/>
                                    </FormGroup>
                                </PortletBody>
                                <div className=" m-portlet__foot m-portlet__foot--fit" style={{position:'absolute',width:'100%',bottom:0,marginBottom:'31px'}}>
                                    <div className="m-form__actions ">
                                        <div className="row">
                                            <div className="col-10">
                                                <button onClick={() => this.on_btn_tagihan()} type="button" className={this.state.tx_flag_inquiry_processing ? 'btn btn-success m-loader m-loader--light m-loader--right' : 'btn btn-success'}>
                                                    Cek Tagihan
                                                </button>
                                                &nbsp;&nbsp;
                                                <button onClick={() => this.on_btn_reset()} type="reset" className="btn btn-secondary">
                                                    Reset
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>

                    {this.state.tx_tagihan_pelanggan ? <TagihanView/> : ''}
                    
                    <Modal className="modal" open={this.state.tx_show_modal} onClose={this.on_btn_confirm_cancel} center showCloseIcon={false} closeOnOverlayClick={false}>
                        <div className="modal-dialog modal-lg" role="document" style={{marginTop: 0+'px',marginBottom: 0+'px'}}>
                            <div className="modal-content" style={{border:"white"}}>
                                <div className="modal-header">
                                    <h5 className="modal-title" id="">
                                        Konfirmasi Bayar E-Commerce
                                    </h5>
                                </div>
                                <div className="modal-body">
                                    <form>
                                        <div className="form-group m-form__group">
                                            <span className="m--font-boldest">
                                                <b>Nomor Pelanggan:</b>
                                            </span>
                                            <p className="form-control-static">{this.state.no_pelanggan}</p>
                                        </div>
                                        <div className="form-group">
                                            <span className="m--font-boldest">
                                                Produk:
                                            </span>
                                            <p className="form-control-static">{tx_product.length !== 0 ? tx_product.product_id + " " + tx_product.product_name : this.state.tx_product}</p>
                                        </div>
                                        <div className="form-group">
                                            <span className="m--font-boldest">
                                                Total Bayar:
                                            </span>
                                            <p className="form-control-static">Rp. {this.state.tx_tagihan_pelanggan?this.state.tx_tagihan_pelanggan.total:''}</p>
                                        </div>
                                        <div className="" style={{cursor: 'pointer', textAlign: 'right', marginBottom: '7px'}} onClick={()=>{self.setState({show_info_pelanggan: !self.state.show_info_pelanggan})}}>
                                            <i className={this.state.show_info_pelanggan ? 'la la-minus' : 'la la-plus'} style={{fontSize: '15px', marginRight: '5px'}}></i>
                                            <span className="m--font-boldest">
                                                Informasi Pembeli
                                            </span>
                                            <span style={{backgroundColor:'#666', height: '8px', marginLeft: '5px'}}/>
                                        </div>
                                        <div className="m-form__section m-form__section--first" style={this.state.show_info_pelanggan ? {display: 'block'} : {display: 'none'}}>
                                            <div className="m-form__group row" style={{marginBottom: '10px'}}>
                                                <label className="col-lg-3" style={{margin: 'auto', fontWeight: 600, textAlign: 'left'}}>
                                                    Nama:
                                                </label>
                                                <div className="col-lg-9">
                                                    <input onChange={(e) => this.on_input_change(e)}
                                                        type="text"
                                                        className="form-control m-input"
                                                        name="nama_pelanggan"
                                                        value={this.state.nama_pelanggan}
                                                        placeholder=""/>
                                                </div>
                                            </div>
                                            <div className="m-form__group row" style={{}}>
                                                <label className="col-lg-3" style={{margin: 'auto', fontWeight: 600, textAlign: 'left'}}>
                                                    Nomor HP:
                                                </label>
                                                <div className="col-lg-9">
                                                    <input onChange={(e) => this.on_input_change(e)}
                                                        type="text"
                                                        className="form-control m-input"
                                                        name="nomor_pelanggan"
                                                        value={this.state.nomor_pelanggan}
                                                        placeholder=""/>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="modal-footer">
                                    <p className="m--font-boldest" style={{marginLeft: 10+'px',marginRight: 10+'px',marginTop: 10+'px',textAlign: 'center',fontWeight: 600,fontSize: 'larger'}}>
                                        Apakah Anda yakin untuk melanjutkan transaksi ini?
                                    </p>
                                    <button type="button" onClick={this.on_btn_confirm_cancel} className="btn btn-secondary" data-dismiss="modal">
                                        Batal
                                    </button>
                                    <button type="button" onClick={this.on_btn_confirm_ok} className={this.state.tx_flag_processing ? 'btn btn-primary m-loader m-loader--light m-loader--right' : 'btn btn-primary'}>
                                        OK
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    {this.state.tx_show_struk ? <CetakStruk data={tx_result_success} on_close={()=>{
                        self.setState({tx_show_struk: false})
                    }} /> : ''}
                    {this.state.show_cetak_tagihan ? <CetakTagihan data={{product_id: this.state.tx_tagihan_pelanggan.product_id, product_name: this.state.tx_tagihan_pelanggan.product_name, biller_number: this.state.no_pelanggan, info:this.state.tx_tagihan_pelanggan.info, nominal: util.rupiah_format(this.state.tx_tagihan_pelanggan.nominal), fee: util.rupiah_format(this.state.tx_tagihan_pelanggan.fee), total: util.rupiah_format(this.state.tx_tagihan_pelanggan.total), on_cetak_close: ()=>{
                        self.setState({show_cetak_tagihan: false})
                    }}}/> : ''}
                </div>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    update_current_page:  val => dispatch(do_action(action.ACTION_UPDATE_CURRENT_PAGE, val)),
    update_balance: val => dispatch(do_action(action.ACTION_UPDATE_USER_BALANCE, val))
})

const mapStateToProps = (state) => ({
    session : state.Session,
    profile: state.Profile
})

export default connect(mapStateToProps, mapDispatchToProps)(AgentEcommerce)