import {connect} from 'react-redux'
import React from 'react'
import {FormGroup} from '../component/form/form'
import {Portlet, PortletHead, PortletBody} from '../component/widget/portlet'
import localdata from '../helper/localdata'
import util from '../helper/util'
import BootstrapTable from 'react-bootstrap-table-next'
import action from '../helper/action'
import do_action from '../action'

class TabelKodeProduct extends React.PureComponent {
    constructor(props) {
        super(props)
        this.options = {
            onPageChange: this.onPageChange.bind(this),
            hideSizePerPage: true
        }
    }

    componentDidMount() {
    }

    onPageChange(page, sizePerPage) {
        // no-op
    }

    render() {
        const RowNumber = (cell, row, i) =>
            <div>{i + 1}</div>
        
        const ProdukColumn = (cell, row) =>
            <div>
                <span>{row.service_name}</span>
            </div>

        const NamaColumn = (cell, row) =>
            <div>
                <span>{row.product_name}</span>
            </div>

        const KodeColumn = (cell, row) =>
            <div>
                <span>{row.product_code}</span>
            </div>

        const columns = [{
            dataField: 'id',
            text: 'No.',
            formatter: RowNumber,
            headerAlign: 'center',
            align: 'center',
            style: {width: '3%'}
        },
        {
            dataField: 'service_col',
            text: 'Service',
            formatter: ProdukColumn,
            headerAlign: 'center',
            align: 'center',
            style: {width: '24%'}
        },
        {
            dataField: 'produk_col',
            text: 'Produk',
            formatter: NamaColumn,
            headerAlign: 'center',
            align: 'center',
            style: {width: '24%'}
        },
        {
            dataField: 'kode_col',
            text: 'Kode',
            formatter: KodeColumn,
            headerAlign: 'center',
            align: 'center',
            style: {width: '24%'}
        }]

        const TableTx = (props) =>
            <BootstrapTable 
                keyField='id'
                data={this.props.data_products} striped hover bordered={true} 
                options={this.options} 
                noDataIndication="Tidak ada data produk" 
                columns={columns}/>

        let self = this
        return (<TableTx/>)
    }
}

class KodeProduct extends React.PureComponent {
    constructor(props) {
        super(props)
        this.data_all = []
        this.state = {search_input: "", data_products: []}
    }

    componentDidMount() {
        this.scroll_to_top()
        let self = this
        let data = []
        this.props.update_current_page('kode_produk');
        localdata.get_list_services().then((payload) => {
            payload.map(function(service, i) {
                let service_name = service.title
                let products = service.list_products
                products.map(function(product, j) {
                    if (product.list_denoms != undefined) {
                        product.list_denoms.map(function(denom, k) {
                            data.push({service_name: product.product_name, product_name: denom.product_name, product_code: denom.product_code})
                        })
                    } else {
                        data.push({service_name: service_name, product_name: product.product_name, product_code: product.product_id})
                    }
                })
            })
            self.data_all = data
            self.setState({data_products: data})
        }).catch((err) => {
            util.log("task_load_product", err)
        })
    }

    scroll_step() {
        if (window.pageYOffset === 0) {
            clearInterval(this.state.scroll_interval_id);
        }
        window.scroll(0, window.pageYOffset - 50);
    }

    scroll_to_top() {
        let scroll_interval_id = setInterval(this.scroll_step.bind(this), 16.66);
        this.setState({scroll_interval_id: scroll_interval_id});
    }

    on_search_btn_click(e) {
        if (this.state.search_input.length > 3) {
            let search_result = []
            let self = this
            self.data_all.map(function(item, i) {
                if (item.product_name.toLowerCase().indexOf(self.state.search_input.toLowerCase()) !== -1) {
                    search_result.push(item)
                }
                self.setState({data_products: search_result})
            })
        } else {
            this.setState({data_products : this.data_all})
        }
    }

    on_input_change(e) {
        this.setState({[e.target.name]: e.target.value})
    }

    render() {
        let self = this
        return (
            <div className="row">
                <div className="col-md-12">
                    <Portlet>
                        <PortletHead headTxt={"Daftar Service & Produk"} />
                        <PortletBody>
                            <FormGroup>
                                <div className="input-group">
                                    <label style={{fontWeight:600,margin:'auto',marginRight:'7px'}}>
                                        Cari:
                                    </label>
                                    <input onChange={(e) => this.on_input_change(e)} placeholder="Nama Produk" className="form-control m-input" type="text" name="search_input" value={this.state.search_input} />
                                    <span className="input-group-btn" onClick={(e) => this.on_search_btn_click(e)}>
                                        <button className="btn btn-primary" type="button">
                                            Submit
                                        </button>
                                    </span>
                                </div>
                            </FormGroup>
                            <br/>
                            <TabelKodeProduct data_products={this.state.data_products}/>
                        </PortletBody>
                    </Portlet>
                </div>
            </div>
        )
    }
}
const mapDispatchToProps = (dispatch) => ({
    update_current_page:  val => dispatch(do_action(action.ACTION_UPDATE_CURRENT_PAGE, val))
})

const mapStateToProps = (state) => ({
    session : state.Session,
    profile: state.Profile
})

export default connect(mapStateToProps, mapDispatchToProps)(KodeProduct)