import config from '../helper/config';

const domain_dev = "https://apiweb.numtek.id/";
const domain_stg = "https://apiweb.numtek.id/";
const domain_prod = "https://apiweb.numtek.id/";

let domain = domain_dev;
if (config.env === 'development') {
    domain = domain_dev; 

} else if (config.env === 'staging') {
    domain = domain_stg; 
} else {
    domain = domain_prod;
}


    const version = "v2";
    const base_url = domain + "" + version + "/";
let whitelabel_36 = config.whitelabel.toString(36).toUpperCase();

const apis = {
    version: version,
    check_session : base_url + "agent/check-session",
    login : base_url + "agent/auth/login",
    logout : base_url + "agent/auth/logout",
    register_agent : base_url + "agent/reg/signup",
    get_captha : base_url + "agent/auth/captcha/" + whitelabel_36 + "/image.png",
    refresh_captha : base_url + "agent/auth/captcha/" + whitelabel_36 + "/refresh",
    get_profile : base_url + "agent/profile/info",
    update_profile : base_url + "agent/profile/edit",
    update_account : base_url + "agent/update-account",
    verify_identity : base_url + "agent/verify-identity",
    services : base_url + "agent/service/list",
    purchase : base_url + "agent/trx/purchase",
    payment : base_url + "agent/trx/payment",
    inquiry : base_url + "agent/trx/inquiry",
    balance_main : base_url + "agent/balance/deposit",
    list_history_tx : base_url + "agent/trx/history/cashout",
    list_history_bal : base_url + "agent/trx/history/cashin",
    list_history_fee : base_url + "agent/report/commission/list",
    list_history_bonus : base_url + "agent/report/bonus/list",
    change_password : base_url + "agent/password/change",
    forgot_password : base_url + "agent/password/forgot",
    list_propinsi : domain + "api/helper/geoname/province/list",
    list_kota: domain + "api/helper/geoname/{provinsi_id}/city/list",
    list_district: domain + "api/helper/geoname/{provinsi_id}/{kota_id}/district/list",
    list_village: domain + "api/helper/geoname/{provinsi_id}/{kota_id}/{district_id}/village/list",
    upload_photo: base_url + "agent/profile/upload/photo",
    upload_kartu_identitas: base_url + "agent/profile/upload/id-card",
    verification_code: base_url + "agent/reg/verify",
    request_approval: base_url + "agent/reg/request-approval",
    reg_get_profile: base_url + "agent/reg/profile/info",
    reg_exist_username: base_url + "agent/check-exist",
    reg_update_profile: base_url + "agent/reg/profile/edit",
    reg_upload_photo: base_url + "agent/reg/profile/upload/photo",
    reg_upload_kartu_identitas: base_url + "agent/reg/profile/upload/id-card",
    report_monthly: base_url + "agent/report/commission/monthly/",
    report_monthly_bonus: base_url + "agent/report/bonus/monthly/",
    report_daily: base_url + "agent/report/trx/daily/detail/1",
    report_daily_grouped: base_url + "agent/report/trx/daily/group/product/1",
    report_daily_summary: base_url + "agent/report/trx/daily/summary",
    sms_token_submit: base_url + "agent/auth/submit-token-sms",
    sms_token_resend: base_url + "agent/auth/resend-token-sms",
    sms_token_resend_verification: base_url + "agent/reg/resend-token-verification",
    get_public_profile_master: base_url + "master-agent/profile/public/{whitelabel_36}/{master_id}",
    batch_tx_inquiry: base_url + "agent/trx/batch/inquiry",
    batch_tx_payment: base_url + "agent/trx/batch/payment",
    batch_tx_purchase: base_url + "agent/trx/batch/purchase",
    collective_tx_inquiry: base_url + "agent/trx/batch/inquiry-by-data",
    collective_list_history: base_url + "agent/trx/batch/history/list",
    collective_list_tx_batch: base_url + "agent/trx/batch/history/collective/list/{batch_id}",
    collective_tx_payment: base_url + "agent/trx/batch/history/collective/pay/{batch_id}",
    get_settings: base_url + "agent/setting/detail",
    modify_settings: base_url + "agent/setting/change",
    get_workstation: base_url + "agent/work-station/list",
    search_workstation: base_url + "agent/work-station/search",
    change_status_workstation: base_url + "agent/work-station/change-status",
    change_password_workstation: base_url + "agent/work-station/change-password",
    add_new_workstation: base_url + "agent/work-station/new",
    get_running_text: base_url + "agent/running-text/show",
    get_upline: base_url + "agent/profile/upline",
    download_list_transaction: base_url + "agent/report/history/data-trx",
    download_list_fee: base_url + "agent/report/commission/data-trx",
    download_list_bonus: base_url + "agent/report/bonus/data-trx",
    kurir_get_profile: base_url + "agent/profile/kurir",
    kurir_submit_register: base_url + "agent/profile/upload/datakurir",
    kurir_get_draft_paket: base_url + "agent/courier/nipos/packet/draft",
    kurir_edit_draft_paket: base_url + "agent/courier/nipos/packet/draft/edit",
    kurir_set_service_code_draft_paket: base_url + "agent/courier/nipos/packet/draft/set-service-code",
    kurir_add_koli: base_url + "agent/courier/nipos/packet/draft/koli/add",
    kurir_edit_koli: base_url + "agent/courier/nipos/packet/draft/koli/edit",
    kurir_delete_koli: base_url + "agent/courier/nipos/packet/draft/koli/delete",
    kurir_save_draft_paket: base_url + "agent/courier/nipos/packet/draft/save-to-trx",
    kurir_list_transaksi: base_url + "agent/courier/nipos/trx/list",
    kurir_detail_transaksi: base_url + "agent/courier/nipos/trx/detail",
    kurir_cancel_transaksi: base_url + "agent/courier/nipos/trx/cancel",
    kurir_cross_check_transaksi: base_url + "agent/courier/nipos/trx/crosscheck",
    kurir_find_postal_code: base_url + "agent/courier/util/find-postal-code",
    inquiry_cashout: base_url + "agent/remittance/giro/cashout/inquiry",
    payment_cashout: base_url + "agent/remittance/giro/cashout/payment",
    inquiry_cashin: base_url + "agent/remittance/giro/cashin/inquiry",
    payment_cashin: base_url + "agent/remittance/giro/cashin/payment",
    service_platinum: base_url + "agent/service/platinum",
    req_email_verification: base_url + "agent/reg/profile/request-pin-email",
    send_email_verification: base_url + "agent/reg/profile/verify-pin-email",

 };

export default apis;