import React, {Component} from 'react';
import { connect } from 'react-redux';
import {Form, FormGroup} from '../component/form/form';
import {Portlet, PortletHead, PortletBody, PortletFooter} from '../component/widget/portlet';
import api from '../helper/api';
import localdata from '../helper/localdata';
import config from '../helper/config';
import swal from 'sweetalert';
import action from '../helper/action';
import do_action from '../action';

class AgentKurirKetentuan extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.scroll_to_top();
        this.props.update_current_page('kurir_register');
    }

    scroll_step() {
        if (window.pageYOffset === 0) {
            clearInterval(this.state.scroll_interval_id);
        }
        window.scroll(0, window.pageYOffset - 50);
    }

    scroll_to_top() {
        let scroll_interval_id = setInterval(this.scroll_step.bind(this), 16.66);
        this.setState({scroll_interval_id: scroll_interval_id});
    }

    on_btn_setuju() {
        this.props.history.push({
            pathname: '/agen/kurir-register',
            search: '',
            state: {}
        });
    }

    on_btn_batal() {
        this.props.history.push({
            pathname: '/agen/home',
            search: '',
            state: {}
        });
    }

    render() {
        const content_style = {fontWeight: 500};
        return (
            <div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="m-portlet">
                            <PortletHead headTxt={"Syarat & Ketentuan Layanan Kurir Pospay Agen"} />
                            <div className="m-portlet__body">
                                <article>
                                    <ol style={{paddingLeft: '20px'}}>
                                        <li style={content_style}><p>Bersedia mengikuti peraturan yang ditetapkan dari PT Pos Indonesia</p></li>
                                        <li style={content_style}><p>Melayani pelanggan sesuai dengan Juklak dan Juknis yang sudah ditetapkan oleh Posfin</p></li>
                                        <li style={content_style}><p>Tidak diperkenankan mengalihkan pengelolaan kepada pihak lain</p></li>
                                        <li style={content_style}><p>Tidak diperkenankan memindahkan lokasi ke tempat lain kecuali atas persetujuan dari Posfin/Pos Indonesia</p></li>
                                        <li style={content_style}><p>Pengelola Agen Pos harus menyediakan ruangan sebagai tempat penampungan/collecting kiriman</p></li>
                                        <li style={content_style}><p>Pengelola Agen Pos harus menjaga kerahasiaan, keselamatan dan keamanan atas barang kiriman yang masih berada di bawah naungan Agen Pos</p></li>
                                        <li style={content_style}><p>Bersedian memberikan ganti rugi atas kehilangan atau kerusakan kiriman pos yang belum diserahterimakan ke Kantor Pos</p></li>
                                        <li style={content_style}><p>Alat yang diperlukan untuk beroperasi sebagai Agen Pos:</p>
                                            <ol>
                                                <li style={content_style}><p>Komputer atau laptop yang dilengkapi dengan koneksi internet</p></li>
                                                <li style={content_style}><p>Timbangan digital</p></li>
                                                <li style={content_style}><p>Printer Thermal Sticker</p></li>
                                            </ol>
                                        </li>
                                        <li style={content_style}><p>Tidak diperkenankan menjalankan jasa kurir atau layanan sejenis</p></li>
                                    </ol>
                                </article>
                            </div>
                        </div>
                        <div className=" m-portlet__foot m-portlet__foot--fit">
                            <div className="m-form__actions ">
                                <div className="row">
                                    <div className="col-10">
                                        <button onClick={() => this.on_btn_setuju()} type="button" 
                                        className='btn btn-success'>
                                            Setuju
                                        </button>
                                        &nbsp;&nbsp;
                                        <button onClick={() => this.on_btn_batal()} type="reset" className="btn btn-secondary">
                                            Batal
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    update_current_page:  val => dispatch(do_action(action.ACTION_UPDATE_CURRENT_PAGE, val))
});

const mapStateToProps = (state) => ({
    profile: state.Profile
});

export default connect(mapStateToProps, mapDispatchToProps)(AgentKurirKetentuan);