import request from 'superagent'
import apis from '../helper/api'
import config from '../helper/config'
import service from '../helper/action'
import util from '../helper/util'

const get_http_generator = next => (url, name) =>
	request.get(url).end((err, res) => {
		if (err) {
		  return next({
		    type: '${name}_ERROR',
		    err
		  })
		}
		const data = JSON.parse(res.text)
		next({
		  type: '${name}_RECEIVED',
		  data
		})
	})

const post_http_generator = next => (url, name, payload) =>
	request
	   .post(url)
	   .set("Content-Type", "application/json")
	   .send(payload)
	   .set('Accept', 'application/json')
	   .on('error', function(err) {
            return next({
				type: '${name}_ERROR',
				err
			})
        })
	   .then(res => {
			const data = JSON.parse(res.body)
			next({
				type: '${name}_RECEIVED',
				data
			})
	   });

const logger = store => next => action => {
	util.log('prev state', store.getState())
	util.log('dispatching', action)
	let result = next(action)
	util.log('next state', store.getState())
	return result
}

const api_service = store => next => action => {
	next(action)
	switch (action.type) {
		case service.SERVICE_LOGIN_BY_SESSION:
			let payload = action.payload;
			post_http_generator(apis.login, action.type, payload)
			break
		default:
			break
	}

};

export {logger, api_service}