import {connect} from 'react-redux';
import React, {Component} from 'react'
import moment from 'moment'
import request from 'superagent'

import MetronicIndex from '../layout/metronic/index'
import {Form, FormGroup} from '../component/form/form'
import {Portlet, PortletHead, PortletBody, PortletFooter} from '../component/widget/portlet'

import apis from '../helper/api'
import localdata from '../helper/localdata'
import config from '../helper/config'
import swal from 'sweetalert'
import action from '../helper/action'
import do_action from '../action'
import util from '../helper/util'
import Select from 'react-select'

class ElectronPrinterSettings extends Component {
    constructor(props) {
        super(props)
        this.state = {
            message: '',
            flag_processing: true,
            printer: '',
            printer_list: [],
            page_size: '',
            page_sizes: [],
            resolution: '',
            resolution_opts: [],
            page_size_width: '',
            page_size_height: '',
            show_custom_page_size: false,
            saved_printer: "Pilih Printer",
            saved_page_size: "Pilih Ukuran Kertas",
            saved_resolution: 'Default',
            landscape: '',
            landscape_opts: [{value:'Ya', label:'Ya'}, {value:'Tidak', label:'Tidak'}],
            saved_landscape: 'Pilih Landscape atau Tidak',
            margin: '',
            saved_margin: 'Margin Atas Kanan Bawah Kiri (px)',
            flag_processing_test: false,
            font_size: '',
            saved_font_size: '0.8',
            font: '',
            font_opts: [{value:'courier', label:'Courier'}, {value:'saxmono', label:'Saxmono'}, {value:'consolas', label:'Consolas'}, {value:'draft', label:'Draft'}, {value:'tahoma', label:'Tahoma'}],
            saved_font: 'Courier',
        }
        this.on_btn_update = this.on_btn_update.bind(this);
        this.on_btn_reset = this.on_btn_reset.bind(this);
        this.get_settings = this.get_settings.bind(this);
        this.on_btn_test = this.on_btn_test.bind(this);
    }

    componentDidMount() {
        let self = this;
        this.props.update_current_page('electron_printer_settings');
        window.ipcRenderer.on('rpc-response', (event, res) => {
            util.log('rpc-response', res);
            if (res.method === 'printer_list') {
                if (res.return.length !== 0) {
                    let options = [];
                    res.return.map(function(item, i) {
                        let opt = {value : item, label : item};
                        options.push(opt);
                    })
                    self.setState({printer_list : options});
                }
            } else if (res.method === 'printer_info') {
                if (res.return) {
                    let options_page_sizes = [];
                    let ret = res.return;
                    if (ret.PaperSheets && ret.PaperSheets.length !== 0) {
                        ret.PaperSheets.map(function(item, i) {
                            let opt = {value : item, label : item};
                            options_page_sizes.push(opt);
                        });
                        options_page_sizes.push({label:"Kustom (Lebar x Tinggi)", value:"Kustom (Lebar x Tinggi)"});
                        self.setState({page_sizes : options_page_sizes});
                    }
                    let options_resolution = [];
                    if (ret.Resolutions && ret.Resolutions.length !== 0) {
                        ret.Resolutions.map(function(item, i) {
                            if (!item.startsWith('-')) {
                                let opt = {value : item, label : item};
                                options_resolution.push(opt);
                            }
                        });
                        self.setState({resolution_opts : options_resolution});
                    }
                }
            } else if (res.method === 'get_db') {
                if (res.return) {
                    const saved = res.return;
                    let saved_state = {};
                    for (let i = 0; i < saved.length; i++) {
                        let item = saved[i];
                        if (item._id === 'default_printer') {
                            saved_state.saved_printer = item.val;
                            self.get_printer_info(item.val);
                        } else if (item._id === 'page_size') {
                            saved_state.saved_page_size = item.val;
                        } else if (item._id === 'landscape') {
                            saved_state.saved_landscape = item.val;
                        } else if (item._id === 'margin') {
                            saved_state.saved_margin = item.val;
                        } else if (item._id === 'font_size') {
                            saved_state.saved_font_size = item.val;
                        } else if (item._id === 'font_family') {
                            saved_state.saved_font = item.val;
                        } else if (item._id === 'resolution') {
                            saved_state.saved_resolution = item.val;
                        }
                    }
                    if (saved_state.saved_page_size && saved_state.saved_page_size.startsWith('Custom.')) {
                        const a = saved_state.saved_page_size.substring(7);
                        const b = a.split('x');
                        console.log(a, b);
                        saved_state.saved_page_size = 'Kustom (Lebar x Tinggi)';
                        saved_state.show_custom_page_size = true;
                        saved_state.page_size_width = util.inch_2_cm(b[0] / 100);
                        saved_state.page_size_height = util.inch_2_cm(b[1] / 100);
                    }
                    if (!saved_state.saved_landscape) {
                        saved_state.saved_landscape = 'Tidak';
                    }
                    if (!saved_state.saved_page_size) {
                        saved_state.saved_page_size = 'Kustom (12.10cm x 13.90cm)';
                    }
                    if (!saved_state.saved_margin) {
                        saved_state.saved_margin = '14 35 0 23';
                    }
                    if (!saved_state.saved_font_size) {
                        saved_state.saved_font_size = '0.8'
                    }
                    if (!saved_state.saved_font) {
                        saved_state.saved_font = 'Courier'
                    }
                    if (!saved_state.saved_resolution) {
                        saved_state.saved_resolution = 'Default'
                    }
                    self.setState(saved_state);
                }
            } else if (res.method === 'delete_db') {
                if (res.return) {
                    if (res.return.status === 'OK') {
                        self.setState({printer: '', page_size: '', saved_printer: 'Pilih Printer', saved_page_size: 'Pilih Ukuran Kertas', show_custom_page_size: false, resolution: 'Default', saved_resolution: 'Default'});
                    }
                }
            }
        });
        this.get_printer_list();
        setTimeout(function() {
            self.get_settings();
            self.setState({flag_processing: false});
        }, 1000);
    }

    select_handle_change = (selected_option) => {
        const self = this;
        console.log(selected_option);
        if (this.state.printer.value !== selected_option.value) {
            this.setState({printer: selected_option});
            setTimeout(function() {
                self.get_printer_info(selected_option.value);
            }, 500);
        }
    };

    select_handle_change_page_size = (selected_option) => {
        console.log(selected_option);
        let new_state = {page_size: selected_option};
        if (selected_option.value === 'Kustom (Lebar x Tinggi)') {
            new_state.show_custom_page_size = true;
        } else {
            new_state.show_custom_page_size = false;
        }
        this.setState(new_state);
    };

    select_handle_change_resolution = (selected_option) => {
        console.log(selected_option);
        if (this.state.resolution.value !== selected_option.value) {
            this.setState({resolution: selected_option});
        }
    };

    select_handle_change_font = (selected_option) => {
        console.log(selected_option);
        if (this.state.font.value !== selected_option.value) {
            this.setState({font: selected_option});
        }
    };

    select_handle_change_landscape = (selected_option) => {
        const self = this;
        console.log(selected_option);
        if (this.state.landscape.value !== selected_option.value) {
            this.setState({landscape: selected_option});
        }
    };

    on_input_change(e) {
        this.setState({[e.target.name]: e.target.value})
    }

    on_select_change(e) {
        this.setState({[e.currentTarget.id] : e.currentTarget.value})
    }

    get_settings() {
        if (util.is_electron()) {
            let request = {method: "get_db", params: {}};
            util.log('rpc-request', request);
            window.ipcRenderer.send('rpc-request', request);
        }
    }

    save_settings() {
        if (util.is_electron()) {
            util.log(this.state.printer, this.state.page_size, this.state.landscape, this.state.margin);
            let saved = {};
            if (this.state.printer.length !== 0) {
                saved.default_printer = this.state.printer.value;
            }
            if (this.state.page_size.length !== 0) {
                let size = this.state.page_size.value;
                if (size === 'Kustom (Lebar x Tinggi)') {
                    const width = this.state.page_size_width.length !== 0 ? this.state.page_size_width : '0';
                    const height = this.state.page_size_height.length !== 0 ? this.state.page_size_height : '0';
                    if (width <= 0 || height <= 0) {
                        swal('Error', 'Ukuran kustom lebar dan tinggi harus lebih besar dari 0', 'error');
                        return;
                    }
                    size = 'Custom.' + (util.cm_2_inch(width) * 100) + 'x' + (util.cm_2_inch(height) * 100);
                }
                saved.page_size = size;
            }
            if (this.state.landscape.length !== 0) {
                saved.landscape = this.state.landscape.value;
            }
            if (this.state.margin.length !== 0) {
                let margin_split = this.state.margin.split(' ');
                if (margin_split.length !== 4) {
                    swal('Error', 'Margin harus mengikuti format yang sudah ditentukan (satuan pixel dan mulai dari bagian atas margin, kanan, bawah dan kiri margin dan dipisahkan dengan spasi)', 'error');
                    return;
                }
                saved.margin = this.state.margin;
            }
            if (this.state.font_size.length !== 0) {
                saved.font_size = this.state.font_size;
            }
            if (this.state.resolution.length !== 0) {
                saved.resolution = this.state.resolution.value;
            }
            if (this.state.font.length !== 0) {
                saved.font_family = this.state.font.value;
            }
            let request = {method: "save_db", params: saved};
            util.log('rpc-request', request);
            window.ipcRenderer.send('rpc-request', request);
        }
    }

    get_printer_list() {
        if (util.is_electron()) {
            let request = {method: "printer_list", params: {}};
            util.log('rpc-request', request);
            window.ipcRenderer.send('rpc-request', request);
        }
    }

    get_printer_info(printer) {
        if (util.is_electron()) {
            let request = {method: "printer_info", params: {'printer': printer}};
            util.log('rpc-request', request);
            window.ipcRenderer.send('rpc-request', request);
        }
    }

    on_btn_update() {
        if (this.state.flag_processing) return;
        let self = this;
        self.setState({flag_processing: true});
        this.save_settings();
        setTimeout(function() {
            self.setState({flag_processing: false});
        }, 2000);
    }

    on_btn_reset() {
        if (util.is_electron()) {
            let request = {method: "delete_db", params: {}};
            util.log('rpc-request', request);
            window.ipcRenderer.send('rpc-request', request);
        }
    }

    on_btn_test() {
        if (util.is_electron()) {
            let self = this;
            self.setState({flag_processing_test: true});
            let request = {method: "test_print", params: {}};
            util.log('rpc-request', request);
            window.ipcRenderer.send('rpc-request', request);
            setTimeout(function() {
                self.setState({flag_processing_test: false});
            }, 2000);
        }
    }

    render() {
        let self = this
        const MessageView = (props) =>
            <div className="form-group m-form__group m--margin-top-10 ">
                <div className="alert m-alert m-alert--default" role="alert">
                    {this.state.message}
                </div>
            </div>

        return (
        	<div className="row">
                <div className="col-lg-12">
                    <div className="m-portlet m-portlet--full-height m-portlet--tabs  ">
                        <div className="tab-content">
                            <div className="tab-pane active" id="m_user_profile_tab_1">
                                <div className="m-form m-form--fit m-form--label-align-right">
                                    <div className="m-portlet__body">
                                        {this.state.message.length !== 0 ? <MessageView/> : ""}

                                        <div className="form-group m-form__group row">
                                            <label className="col-2 col-form-label" style={{textAlign: 'right'}}>
                                                Printer
                                            </label>
                                            <div className="col-10" style={{margin:'auto'}}>
                                                <Select value={this.state.printer}
                                                    onChange={this.select_handle_change}
                                                    options={this.state.printer_list}
                                                    placeholder={this.state.saved_printer}
                                                    isClearable={false}/>
                                            </div>
                                        </div>

                                        <div className="form-group m-form__group row">
                                            <label className="col-2 col-form-label" style={{textAlign: 'right'}}>
                                                Landscape
                                            </label>
                                            <div className="col-10" style={{margin:'auto'}}>
                                                <Select value={this.state.landscape}
                                                    onChange={this.select_handle_change_landscape}
                                                    options={this.state.landscape_opts}
                                                    placeholder={this.state.saved_landscape}
                                                    isClearable={false}/>
                                            </div>
                                        </div>

                                        <div className="form-group m-form__group row">
                                            <label className="col-2 col-form-label" style={{textAlign: 'right'}}>
                                                Margin
                                            </label>
                                            <div className="col-10" style={{margin:'auto'}}>
                                                <input onChange={(e) => this.on_input_change(e)}
                                                    type="text"
                                                    className="form-control m-input"
                                                    name="margin"
                                                    value={this.state.margin}
                                                    placeholder={this.state.saved_margin}/>
                                                <label style={{marginTop:'10px', fontStyle:'italic'}}>Margin atas, kanan, bawah, dan kiri dipisahkan dengan spasi dengan ukuran pixel (default 14 35 0 23)</label>
                                            </div>
                                        </div>

                                        <div className="form-group m-form__group row">
                                            <label className="col-2 col-form-label" style={{textAlign: 'right'}}>
                                                Font Family
                                            </label>
                                            <div className="col-10" style={{margin:'auto'}}>
                                                <Select value={this.state.font}
                                                    onChange={this.select_handle_change_font}
                                                    options={this.state.font_opts}
                                                    placeholder={this.state.saved_font}
                                                    isClearable={false}/>
                                                <label style={{marginTop:'10px', fontStyle:'italic'}}>Pilih jenis font (hanya bekerja pada versi 1.2.3 keatas)</label>
                                            </div>
                                        </div>

                                        <div className="form-group m-form__group row">
                                            <label className="col-2 col-form-label" style={{textAlign: 'right'}}>
                                                Font Size
                                            </label>
                                            <div className="col-10" style={{margin:'auto'}}>
                                                <input onChange={(e) => this.on_input_change(e)}
                                                    type="text"
                                                    className="form-control m-input"
                                                    name="font_size"
                                                    value={this.state.font_size}
                                                    placeholder={this.state.saved_font_size}/>
                                                <label style={{marginTop:'10px', fontStyle:'italic'}}>Ukuran font dalam satuan Ems (default 0.8em)</label>
                                            </div>
                                        </div>

                                        <div className="form-group m-form__group row">
                                            <label className="col-2 col-form-label" style={{textAlign: 'right'}}>
                                                Kualitas
                                            </label>
                                            <div className="col-10" style={{margin:'auto'}}>
                                                <Select value={this.state.resolution}
                                                    onChange={this.select_handle_change_resolution}
                                                    options={this.state.resolution_opts}
                                                    placeholder={this.state.saved_resolution}
                                                    isClearable={false}/>
                                                <label style={{marginTop:'10px', fontStyle:'italic'}}>Resolusi (dalam dpi) yang didukung oleh masing-masing printer</label>
                                            </div>
                                        </div>

                                        <div className="form-group m-form__group row">
                                            <label className="col-2 col-form-label" style={{textAlign: 'right'}}>
                                                Page Size
                                            </label>
                                            <div className="col-10" style={{margin:'auto'}}>
                                                <Select value={this.state.page_size}
                                                    onChange={this.select_handle_change_page_size}
                                                    options={this.state.page_sizes}
                                                    placeholder={this.state.saved_page_size}
                                                    isClearable={false}/>
                                                <label style={{marginTop:'10px', fontStyle:'italic'}}>Ukuran yang didukung oleh masing-masing printer</label>
                                            </div>
                                        </div>

                                    {this.state.show_custom_page_size ? 
                                        <div className="form-group m-form__group row">
                                            <label className="col-2 col-form-label" style={{textAlign: 'right'}}>
                                            </label>
                                            <div className="col-3" style={{}}>
                                                <input onChange={(e) => this.on_input_change(e)}
                                                    type="text"
                                                    className="form-control m-input"
                                                    name="page_size_width"
                                                    value={this.state.page_size_width}
                                                    placeholder="Lebar (cm)"/>
                                            </div>
                                            <div className="col-3" style={{}}>
                                                <input onChange={(e) => this.on_input_change(e)}
                                                    type="text"
                                                    className="form-control m-input"
                                                    name="page_size_height"
                                                    value={this.state.page_size_height}
                                                    placeholder="Tinggi (cm)"/>
                                            </div>
                                        </div>
                                    : ''}

                                    </div>
                                    <div className="m-portlet__foot m-portlet__foot--fit">
                                        <div className="m-form__actions">
                                            <div className="row">
                                                <div className="col-7">
                                                    <button type="button" 
                                                        onClick={this.on_btn_update} 
                                                        className={this.state.flag_processing ? 'btn btn-accent m-btn m-btn--air m-btn--custom m-loader m-loader--light m-loader--right' : 'btn btn-accent m-btn m-btn--air m-btn--custom'}>
                                                        Edit Pengaturan
                                                    </button>
                                                    &nbsp;&nbsp;
                                                    <button onClick={() => this.on_btn_reset()} type="reset" className="btn btn-secondary">
                                                        Reset Pengaturan
                                                    </button>
                                                </div>
                                                <div className="col-5" style={{textAlign:'right'}}>
                                                    <button type="button" 
                                                        onClick={this.on_btn_test} 
                                                        className={this.state.flag_processing_test ? 'btn btn-secondary m-loader m-loader--primary m-loader--right' : 'btn btn-secondary'}>
                                                        Test Print
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    update_current_page:  val => dispatch(do_action(action.ACTION_UPDATE_CURRENT_PAGE, val))
});

const mapStateToProps = (state) => ({
    session : state.Session,
    profile: state.Profile
});

export default connect(mapStateToProps, mapDispatchToProps)(ElectronPrinterSettings);
