import {connect} from 'react-redux'
import React, {Component} from 'react'
import moment from 'moment'
import request from 'superagent'

import MetronicIndex from '../layout/metronic/index'
import {Form, FormGroup} from '../component/form/form'
import {Portlet, PortletHead, PortletBody, PortletFooter} from '../component/widget/portlet'

import apis from '../helper/api'
import localdata from '../helper/localdata'
import config from '../helper/config'
import swal from 'sweetalert'
import action from '../helper/action'
import do_action from '../action'
import util from '../helper/util'

let photo_default = "../../swipe/assets/app/media/img/users/default.jpg"

class AgentSettings extends Component {
    constructor(props) {
        super(props)
        this.state = {
            flag_processing: false,
            message : "",
            setting_level: "",
            setting_status : "",
            setting_paymode: "",
            setting_max_terminal: "",
            setting_notif_login: "",
            setting_notif_trx: "",
            setting_notif_balance: ""
            
        }
        this.on_btn_update = this.on_btn_update.bind(this)
        this.get_settings = this.get_settings.bind(this)
    }

    componentDidMount() {
        this.get_settings()
    }

    on_input_change(e) {
        this.setState({[e.target.name]: e.target.value})
    }

    on_select_change(e) {
        this.setState({[e.currentTarget.id] : e.currentTarget.value})
    }

    get_settings() {
        let self = this
        let body = {
            "session_id" : this.props.session
        }
        this.setState({flag_processing: true})
        request.post(apis.get_settings)
            .set("Content-Type", "application/json")
            .send(body)
            .end(function(err, res) {
                if (err || !res.ok) {
                    util.log("get_settings", err)
                } else {
                    util.log("get_settings", res);
                    if (res.statusCode == 200) {
                        let payload = res.body
                        if (payload.status == "OK") {
                            if (payload.results) {
                                let results = payload.results
                                let data = {}
                                data.setting_level = results.level
                                data.setting_status = results.status
                                data.setting_paymode = results.paymode
                                data.setting_max_terminal = results.max_terminal
                                data.setting_notif_login = results.notif_login ? '1' : '0'
                                data.setting_notif_trx = results.notif_trx ? '1' : '0'
                                data.setting_notif_balance = results.notif_balance ? '1' : '0'
                                self.setState(data)
                            }
                        } else {
                            self.on_error(payload.error)
                        }
                    }
                }
                self.setState({flag_processing: false})
            })
    }

    on_btn_update() {
        if (this.state.flag_processing) return
        let self = this
        self.setState({flag_processing: true})
        let body = {
            "session_id" : self.props.session
        }
        body.notif_login = this.state.setting_notif_login === '1' ? true : false
        body.notif_trx = this.state.setting_notif_trx === '1' ? true : false
        body.notif_balance = this.state.setting_notif_balance === '1' ? true : false
        
        request.post(apis.modify_settings)
            .set("Content-Type", "application/json")
            .send(body)
            .end(function(err, res) {
                let message_result = ""
                let success_result = ""
                if (err || !res.ok) {
                    util.log(err)
                    message_result = {title: "Error", body: err.message}
                    if (res != undefined) {
                        message_result.body = "Error code " + res.statusCode
                    }
                } else {
                    util.log(res);
                    if (res.statusCode === 200) {
                        let payload = res.body
                        util.log(payload)
                        if (payload.status === "OK") {
                            message_result = {title: "Update Sukses", body: "Berhasil Ubah Setting"}
                        } else {
                            message_result = {title: "Error", body: payload.error.message}
                            if (payload.display_message !== undefined) {
                                message_result = {title: payload.error.message, body: payload.display_message}
                            }
                        }
                    } else {
                        message_result = {title: "Error", body: res.statusCode}
                    }
                }
                if (message_result.length !== 0) {
                    swal(message_result.title, message_result.body, "warning")
                }
                self.setState({flag_processing: false})
            });
    }

    render() {
        let self = this
        const BankAccountView = (props) =>
            <div className="m-stack m-stack--ver m-stack--mobile m-stack--demo" style={{marginBottom: 20+'px',marginTop: 10+'px'}}>
                {this.props.profile.bank_accounts.map((item, i) =>
                    <div key={i} className="m-stack__item m-stack__item--center m-stack__item--top" 
                        style={{textAlign: 'center',marginBottom:'5px'}}>
                        {item.bank_name}: {item.account_number}
                    </div>
                )}
            </div>

        const MessageView = (props) =>
            <div className="form-group m-form__group m--margin-top-10 ">
                <div className="alert m-alert m-alert--default" role="alert">
                    {this.state.message}
                </div>
            </div>

        return (
        	<div className="row">
                <div className="col-lg-3">
                    <div className="m-portlet">
                        <div className="m-portlet__body">
                            <div className="m-card-profile">
                                <div className="m-card-profile__title m--hide">
                                    Your Profile
                                </div>
                                <div className="m-card-profile__pic">
                                    <div className="m-card-profile__pic-wrapper">
                                        <img style={{width:100+'px',height:100+'px'}} src={this.props.profile ? (this.props.profile.photo ? this.props.profile.photo : photo_default) : photo_default}/>
                                    </div>
                                </div>
                                <div className="m-card-profile__details">
                                    <span className="m-card-profile__name" style={{marginTop:-15+'px'}}>
                                        {this.props.profile ? (this.props.profile.name ? this.props.profile.name : this.props.profile.usertype) : ""}
                                    </span>
                                    {this.state.setting_level.length !== 0 ? 
                                    <a className="m-card-profile__email m-link" style={{marginTop:-10+'px'}}>
                                        LEVEL {this.state.setting_level}
                                    </a> : ''}
                                </div>

                                {this.props.profile.bank_accounts && this.props.profile.bank_accounts.length !== 0 ? <BankAccountView/> : ""}

                            </div>
                            
                        </div>
                    </div>
                </div>
                <div className="col-lg-9">
                    <div className="m-portlet m-portlet--full-height m-portlet--tabs  ">
                        <div className="tab-content">
                            <div className="tab-pane active" id="m_user_profile_tab_1">
                                <div className="m-form m-form--fit m-form--label-align-right">
                                    <div className="m-portlet__body">
                                        {this.state.message.length !== 0 ? <MessageView/> : ""}
                                        <div className="form-group m-form__group row">
                                           
                                        </div>
                                        <div className="form-group m-form__group row">
                                            <label className="col-3 col-form-label" style={{textAlign: 'right'}}>
                                                Status
                                            </label>
                                            <div className="col-9" style={{margin:'auto'}}>
                                                <select disabled className="form-control m-input" id="setting_status" value={this.state.setting_status} onChange={(e) => this.on_select_change(e)} style={{MozAppearance: 'none',WebkitAppearance: 'none'}}>
                                                    <option key={0} value="ACTIVE">Aktif</option>
                                                    <option key={1} value="INACTIVE">Tidak Aktif</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group m-form__group row">
                                            <label className="col-3 col-form-label" style={{textAlign: 'right'}}>
                                                Paymode
                                            </label>
                                            <div className="col-9" style={{margin:'auto'}}>
                                                <select disabled className="form-control m-input" id="setting_paymode" value={this.state.setting_paymode} onChange={(e) => this.on_select_change(e)} style={{MozAppearance: 'none',WebkitAppearance: 'none'}}>
                                                    <option key={0} value="PREPAID">PREPAID</option>
                                                    <option key={1} value="POSTPAID">POSTPAID</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group m-form__group row">
                                            <label className="col-3 col-form-label" style={{textAlign: 'right'}}>
                                                Max Terminal
                                            </label>
                                            <div className="col-9" style={{margin:'auto'}}>
                                                <input disabled className="form-control m-input" 
                                                name="setting_max_terminal"
                                                type="text" 
                                                onChange={(e) => this.on_input_change(e)}
                                                value={this.state.setting_max_terminal}/>
                                            </div>
                                        </div>
                                        <div className="form-group m-form__group row">
                                            <label className="col-3 col-form-label" style={{textAlign: 'right'}}>
                                                Notif Login
                                            </label>
                                            <div className="col-9" style={{margin:'auto'}}>
                                                <select className="form-control m-input" id="setting_notif_login" value={this.state.setting_notif_login} onChange={(e) => this.on_select_change(e)} style={{MozAppearance: 'none',WebkitAppearance: 'none'}}>
                                                    <option key={0} value="1">Aktif</option>
                                                    <option key={1} value="0">Tidak Aktif</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group m-form__group row">
                                            <label className="col-3 col-form-label" style={{textAlign: 'right'}}>
                                                Notif Transaksi
                                            </label>
                                            <div className="col-9" style={{margin:'auto'}}>
                                                <select className="form-control m-input" id="setting_notif_trx" value={this.state.setting_notif_trx} onChange={(e) => this.on_select_change(e)} style={{MozAppearance: 'none',WebkitAppearance: 'none'}}>
                                                    <option key={0} value="1">Aktif</option>
                                                    <option key={1} value="0">Tidak Aktif</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group m-form__group row">
                                            <label className="col-3 col-form-label" style={{textAlign: 'right'}}>
                                                Notif Balance
                                            </label>
                                            <div className="col-9" style={{margin:'auto'}}>
                                                <select className="form-control m-input" id="setting_notif_balance" value={this.state.setting_notif_balance} onChange={(e) => this.on_select_change(e)} style={{MozAppearance: 'none',WebkitAppearance: 'none'}}>
                                                    <option key={0} value="1">Aktif</option>
                                                    <option key={1} value="0">Tidak Aktif</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="m-portlet__foot m-portlet__foot--fit">
                                        <div className="m-form__actions">
                                            <div className="row">
                                                <div className="col-7">
                                                    <button type="reset" 
                                                        onClick={this.on_btn_update} 
                                                        className={this.state.flag_processing ? 'btn btn-accent m-btn m-btn--air m-btn--custom m-loader m-loader--light m-loader--right' : 'btn btn-accent m-btn m-btn--air m-btn--custom'}>
                                                        Edit Pengaturan
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    
})

const mapStateToProps = (state) => ({
    session : state.Session,
    profile: state.Profile
})

export default connect(mapStateToProps, mapDispatchToProps)(AgentSettings)
