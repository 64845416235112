import React, { Component } from 'react'
import { connect } from 'react-redux'
import Modal from 'react-responsive-modal'
import moment from 'moment'
import ReactToPrint from "react-to-print"
import util from '../../helper/util'

class CetakTagihan extends Component {

    constructor(props) {
        super(props)
    }

    handleAfterPrint = () => {
        this.props.data.on_cetak_close()
    }

    handleBeforePrint = () => {
        util.log('before print!')
    }

    renderContent = () => {
        return this.componentRef
    }

    renderTrigger = () => {
        return <button type="button" type="button" onClick={this.on_cetak} className='btn btn-primary'>Cetak</button>
    }

    setRef = (ref) => {
        this.componentRef = ref
    }

    render() {
        let data = this.props.data
        let item_style = {padding: 3+'px 0'}
        let fontValue = 'courier12'
        let fontSizeValue = '14px'
        let spacing = 'normal'
        let spaceLineHeight = '17px'
        let fontWeightValue = 'normal'
        let table_cell_style = {marginBottom: 0+'px',fontFamily:fontValue,fontSize:fontSizeValue,display:'table-cell', width:'23%',color:'black',fontWeight: fontWeightValue}
        let table_row_style = {padding: 5+'px 0', display:'table-row',letterSpacing: spacing}
        return (
            <Modal className="modal" open={true} center onClose={data.on_cetak_close} showCloseIcon={true} closeOnOverlayClick={false}>
                <div className="modal-dialog modal-lg" role='document' style={{marginTop: 0+'px',marginBottom: 0+'px',width: '600px'}}>
                    <div className="modal-content" style={{border:'white',backgroundColor:'transparent'}}>
                        <div className="modal-header">
                            <h5 className="modal-title">
                                CETAK TAGIHAN
                            </h5>
                        </div>
                        <div className="modal-body" ref={this.setRef}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <form className="m-form m-form--fit m-form--label-align-right">
                                        <div className="m-portlet__body">
                                            <div className="row">
                                                <h3 style={{marginLeft:'15px',marginBottom:'0',fontWeight: fontWeightValue}}>PosFin</h3>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12" style={{}}>
                                                    <p style={{fontFamily:fontValue,fontSize: fontSizeValue,marginBottom:'13px',marginTop: '5px',    lineHeight:spaceLineHeight,letterSpacing: spacing,fontWeight: fontWeightValue}}><span style={{fontWeight:600}}>Authorized by PT. POS INDONESIA (PERSERO)</span><br/>TGL {moment().format("DD-MM-YYYY hh:mm")}<br/>{this.props.profile ? (this.props.profile.name ? this.props.profile.username + " (" + this.props.profile.name + ")" : this.props.profile.username) : ""}</p>
                                                </div>
                                            </div>
                                            <div className="m-form__section m-form__section--first" style={{lineHeight: spaceLineHeight}}>
                                                <div style={{display:'table',width:'100%',tableLayout:'fixed'}}>
                                                    <div className="form-group m-form__group row" style={table_row_style}>
                                                        <span style={table_cell_style}>
                                                            Produk
                                                        </span>
                                                        <span style={{fontFamily:fontValue,fontSize:fontSizeValue,padding:'0 0',display: 'table-cell',color:'black',letterSpacing: spacing,fontWeight: fontWeightValue}}>
                                                            : {data !== undefined ? data.product_id : ''}
                                                            &nbsp;
                                                            {data !== undefined ? data.product_name : ''}
                                                        </span>
                                                    </div>
                                                    <div className="form-group m-form__group row" style={table_row_style}>
                                                        <span style={table_cell_style}>
                                                            Pelanggan
                                                        </span>
                                                        <span style={{fontFamily:fontValue,fontSize:fontSizeValue,padding:'0 0',display: 'table-cell',color:'black',letterSpacing: spacing,fontWeight: fontWeightValue}}>
                                                            : {data !== undefined ? data.biller_number : ''}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="form-group m-form__group row" style={{padding: '0 0 3px 0'}}>
                                                    <div>
                                                        <span style={{fontFamily:fontValue,fontSize:fontSizeValue,color:'black',letterSpacing: spacing,fontWeight: fontWeightValue}}>
                                                            {data !== undefined ? data.info.split('|').map(function(item, key) {
                                                                return (
                                                                    item.length !== 0 ?
                                                                    <span key={key} style={{padding:'0 15px'}}>
                                                                        {item}
                                                                        <br/>
                                                                    </span>
                                                                    : ''
                                                                )
                                                            }) : ''}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div style={{display:'table',width:'100%',tableLayout:'fixed'}}>
                                                    <div className="form-group m-form__group row" style={table_row_style}>
                                                        <span style={table_cell_style}>
                                                            Type
                                                        </span>
                                                        <div style={{}}>
                                                            <span style={{fontFamily:'15px',fontSize:fontSizeValue,color:'black',letterSpacing: spacing,fontWeight: 600,paddingRight:'30px',paddingLeft:'20px'}}>
                                                                :&nbsp;&nbsp;INVOICE
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="form-group m-form__group row" style={table_row_style}>
                                                        <span style={table_cell_style}>
                                                            Harga Produk
                                                        </span>
                                                        <div style={{}}>
                                                            <span style={{fontFamily:'15px',fontSize:fontSizeValue,color:'black',letterSpacing: spacing,fontWeight: 600,paddingRight:'30px',paddingLeft:'20px'}}>
                                                                :&nbsp;&nbsp;Rp. {data !== undefined ? data.nominal : '0'}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="form-group m-form__group row" style={table_row_style}>
                                                        <span style={table_cell_style}>
                                                            Biaya Admin
                                                        </span>
                                                        <div style={{}}>
                                                            <span style={{fontFamily:'15px',fontSize:fontSizeValue,color:'black',letterSpacing: spacing,fontWeight: 600,paddingRight:'30px',paddingLeft:'20px'}}>
                                                                :&nbsp;&nbsp;Rp. {data !== undefined ? data.fee : '0'}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="form-group m-form__group row" style={table_row_style}>
                                                        <span style={table_cell_style}>
                                                            Total
                                                        </span>
                                                        <div style={{}}>
                                                            <span style={{fontFamily:'15px',fontSize:fontSizeValue,color:'black',letterSpacing: spacing,fontWeight: 600,paddingRight:'30px',paddingLeft:'20px'}}>
                                                                :&nbsp;&nbsp;Rp. {data !== undefined ? data.total : '0'}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <p style={{marginLeft:0+'px',marginRight:0+'px',marginTop:15+'px',textAlign:'left',fontFamily:fontValue,fontSize:fontSizeValue,fontWeight: fontWeightValue,marginBottom:5+'px',lineHeight:spaceLineHeight,letterSpacing: spacing,color:'black'}}>Struk ini adalah Tagihan, bukan Bukti Pembayaran</p>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <ReactToPrint
                                trigger={this.renderTrigger}
                                content={this.renderContent}
                                onBeforePrint={this.handleBeforePrint}
                                onAfterPrint={this.handleAfterPrint}
                                pageStyle="@page{size: auto A4 portrait;}"
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => ({
    profile: state.Profile
})

export default connect(mapStateToProps, null)(CetakTagihan)
