import { connect } from 'react-redux'
import React, { Component } from 'react'
import moment from 'moment'
import request from 'superagent'
import { history } from '../helper/history'
import {Link} from "react-router-dom"

import MetronicIndex from '../layout/metronic/index'
import { Form, FormGroup } from '../component/form/form'
import { Portlet, PortletHead, PortletBody, PortletFooter, PortletTab } from '../component/widget/portlet'
import BootstrapTable from 'react-bootstrap-table-next'
import { DatePicker, DatePickerInput } from 'rc-datepicker'
import 'rc-datepicker/lib/style.css';

import apis from '../helper/api'
import localdata from '../helper/localdata'
import action from '../helper/action'
import do_action from '../action'
import config from '../helper/config'
import swal from 'sweetalert'
import Modal from 'react-responsive-modal'
import util from '../helper/util'
import ReactToPrint from "react-to-print"

var LineChart = require("react-chartjs").Line
var chartOptions = {
    scaleLabel : "<%= Number(value).toLocaleString('id') %>",
    scaleShowGridLines : true,
    scaleGridLineColor : "rgba(0,0,0,.05)",
    scaleGridLineWidth : 1,
    scaleShowHorizontalLines: true,
    scaleShowVerticalLines: true,
    bezierCurve : true,
    bezierCurveTension : 0.4,
    pointDot : true,
    pointDotRadius : 4,
    pointDotStrokeWidth : 1,
    pointHitDetectionRadius : 20,
    datasetStroke : true,
    datasetStrokeWidth : 2,
    datasetFill : true,
    legendTemplate : "<ul class=\"<%=name.toLowerCase()%>-legend\"><% for (var i=0; i<datasets.length; i++){%><li><span style=\"background-color:<%=datasets[i].strokeColor%>\"><%if(datasets[i].label){%><%=datasets[i].label%><%}%></span></li><%}%></ul>",
    offsetGridLines : false
}

class ReportMonthly extends Component {
    constructor(props) {
        super(props)
        this.now = moment().toDate()
        this.state = {
            flag_processing: false,
            data_report: [],
            data_report_bonus: [],
            report_year: '',
            data_report_jumlah_tx: [0,0,0,0,0,0,0,0,0,0,0,0],
            data_report_total_tx: [0,0,0,0,0,0,0,0,0,0,0,0],
            data_report_total_fee: [0,0,0,0,0,0,0,0,0,0,0,0],
            data_report_total_bonus: [0,0,0,0,0,0,0,0,0,0,0,0]
        }
        this.options = {
            hideSizePerPage: true
        }
    }

    componentDidMount() {
        this.scroll_to_top()
        let self = this
        this.props.update_current_page('laporan')
        self.setState({report_year: this.now.getFullYear()})
        self.get_summary(this.now.getFullYear())
    }

    scroll_step() {
        if (window.pageYOffset === 0) {
            clearInterval(this.state.scroll_interval_id);
        }
        window.scroll(0, window.pageYOffset - 50);
    }

    scroll_to_top() {
        let scroll_interval_id = setInterval(this.scroll_step.bind(this), 16.66);
        this.setState({scroll_interval_id: scroll_interval_id});
    }

    on_submit_summary(e) {
        this.setState({report_year: this.state.year_sel})
        this.get_summary(this.state.year_sel)
    }

    get_summary(year) {
        let url_komisi = apis.report_monthly + year + "/0"
        let url_bonus = apis.report_monthly_bonus + year + "/0"
        if (this.state.flag_processing) return
        let session_id = this.props.session
        if (session_id === null) return
        let self = this
        let body = {
            "session_id" : session_id
        }
        self.setState({flag_processing: true, data_report: [], data_report_bonus: [], data_report_jumlah_tx: [0,0,0,0,0,0,0,0,0,0,0,0], data_report_total_tx: [0,0,0,0,0,0,0,0,0,0,0,0], data_report_total_fee: [0,0,0,0,0,0,0,0,0,0,0,0]})
        request.post(url_komisi)
            .set("Content-Type", "application/json")
            .send(body)
            .end(function(err, res) {
                if (err || !res.ok) {
                    util.log("get_summary_komisi", err)
                } else {
                    util.log("get_summary_komisi", res)
                    if (res.statusCode == 200) {
                        let payload = res.body
                        if (payload.status == "OK") {
                            if (payload.results) {
                                let results = payload.results
                                if (payload.results.list && payload.results.list.length !== 0) {
                                    let items = []
                                    let items_jumlah_tx = [0,0,0,0,0,0,0,0,0,0,0,0]
                                    let items_total_tx = [0,0,0,0,0,0,0,0,0,0,0,0]
                                    let items_total_fee = [0,0,0,0,0,0,0,0,0,0,0,0]
                                    results.list.map(function(item, i) {
                                        let it = {
                                            "id" : item.id,
                                            "report_month" : item.report_month,
                                            "total_trx_event" : item.total_trx_event,
                                            "total_trx_price" : "Rp. " + util.rupiah_format(item.total_trx_price),
                                            "total_commission_received" : "Rp. " + util.rupiah_format(item.total_commission_received),
                                            "status" : item.status
                                        }
                                        items.push(it)
                                        items_jumlah_tx[(item.report_month-1)] = item.total_trx_event
                                        items_total_tx[(item.report_month-1)] = item.total_trx_price
                                        items_total_fee[(item.report_month-1)] = item.total_commission_received
                                    })
                                    
                                    self.setState({data_report: items, data_report_jumlah_tx: items_jumlah_tx, data_report_total_tx: items_total_tx, data_report_total_fee: items_total_fee})
                                }
                            }
                        } else {
                            self.on_error(payload.error)
                        }
                    }
                }
                self.setState({flag_processing: false})
            })

        request.post(url_bonus)
            .set("Content-Type", "application/json")
            .send(body)
            .end(function(err, res) {
                if (err || !res.ok) {
                    util.log("get_summary_bonus", err)
                } else {
                    util.log("get_summary_bonus", res)
                    if (res.statusCode == 200) {
                        let payload = res.body
                        if (payload.status == "OK") {
                            if (payload.results) {
                                let results = payload.results
                                if (payload.results.list && payload.results.list.length !== 0) {
                                    let items = []
                                    let items_total_bonus = [0,0,0,0,0,0,0,0,0,0,0,0]
                                    results.list.map(function(item, i) {
                                        let it = {
                                            "id" : item.id,
                                            "report_month" : item.report_month,
                                            "total_trx_event" : item.total_trx_event,
                                            "total_trx_price" : "Rp. " + util.rupiah_format(item.total_trx_price),
                                            "total_bonus_received" : "Rp. " + util.rupiah_format(item.total_bonus_received),
                                            "status" : item.status
                                        }
                                        items.push(it)
                                        items_total_bonus[(item.report_month-1)] = item.total_bonus_received
                                    })
                                    self.setState({data_report_total_bonus: items_total_bonus, data_report_bonus: items})
                                }
                            }
                        } else {
                            self.on_error(payload.error)
                        }
                    }
                }
                self.setState({flag_processing: false})
            })
    }

    on_error(err) {
        if (err.code === 2006) {
            localdata.clear_data().then((res) => {
                history.replace('/login')
            }).catch((err) => {
                util.log("error: ", err)
            })
        } else if (err.code === 2007) {
            // no-op
        } else {
            let msg = "Error: " + err.code
            if (err.message !== undefined) {
                msg = err.message
            }
            swal("Ups!", msg, "warning")
        }
    }

    on_select_change(e) {
        this.setState({[e.currentTarget.id] : e.currentTarget.value})
    }

    handleAfterPrint = () => {
        util.log('after print!')
    }

    handleBeforePrint = () => {
        util.log('before print!')
    }

    renderContent = () => {
        return this.componentRef
    }

    renderTrigger = () => {
        return <button className="m-portlet__nav-link btn btn-light m-btn m-btn--pill m-btn--air">Cetak</button>
    }

    setRef = (ref) => {
        this.componentRef = ref
    }

    render() {
        let uid = this.props.profile ? this.props.profile.username : ""
        let ug = navigator.userAgent

        const RowNumber = (cell, row, i) =>
            <div>{i + 1}</div>
        
        const BulanColumn = (cell, row) =>
            <div>
                {util.get_month_name(row.report_month)}
            </div>

        const RiwayatColumn = (cell, row) =>
            <div>
                <Link to={{pathname:'/agen/riwayat-fee', state:{month_year: this.now.getFullYear() + "-" + row.report_month}}} className="m-menu__link m-menu__toggle">
                    <i className="m-menu__link-icon flaticon-list-1" title="Riwayat Komisi"></i>
                </Link>
                <Link to={{pathname:'/agen/riwayat-transaksi', state:{month_year: this.now.getFullYear() + "-" + row.report_month}}} className="m-menu__link m-menu__toggle" style={{marginLeft: '10px'}}>
                    <i className="m-menu__link-icon flaticon-layers" title="Riwayat Transaksi"></i>
                </Link>
            </div>

        const RiwayatBonusColumn = (cell, row) =>
            <div>
                <Link to={{pathname:'/agen/riwayat-bonus', state:{month_year: this.now.getFullYear() + "-" + row.report_month}}} className="m-menu__link m-menu__toggle">
                    <i className="m-menu__link-icon flaticon-list-1" title="Riwayat Bonus"></i>
                </Link>
            </div>

        const columns = [{
            dataField: 'id',
            text: 'No.',
            formatter: RowNumber,
            headerAlign: 'center',
            align: 'center',
            style: {width: '3%'}
        },
        {
            dataField: 'bulan_col',
            text: 'Bulan',
            formatter: BulanColumn,
            headerAlign: 'left',
            align: 'left',
            style: {width: '15%'}
        },
        {
            dataField: 'total_trx_event',
            text: 'Jumlah Transaksi',
            headerAlign: 'center',
            align: 'center',
            style: {width: '25%'}
        },
        {
            dataField: 'total_trx_price',
            text: 'Jumlah Nilai Transaksi',
            headerAlign: 'right',
            align: 'right',
            style: {width: '25%'}
        },
        {
            dataField: 'total_commission_received',
            text: 'Total Komisi',
            headerAlign: 'right',
            align: 'right',
            style: {width: '25%'}
        },
        {
            dataField: 'status',
            text: 'Status',
            headerAlign: 'center',
            align: 'center',
            style: {width: '15%'}
        },
        {
            dataField: 'riwayat',
            text: 'Riwayat',
            headerAlign: 'center',
            align: 'center',
            formatter: RiwayatColumn,
            style: {width: '15%'}
        }]

        const columnsBonus = [{
            dataField: 'id',
            text: 'No.',
            formatter: RowNumber,
            headerAlign: 'center',
            align: 'center',
            style: {width: '3%'}
        },
        {
            dataField: 'bulan_col',
            text: 'Bulan',
            formatter: BulanColumn,
            headerAlign: 'left',
            align: 'left',
            style: {width: '15%'}
        },
        {
            dataField: 'total_trx_event',
            text: 'Jumlah Transaksi',
            headerAlign: 'center',
            align: 'center',
            style: {width: '25%'}
        },
        {
            dataField: 'total_trx_price',
            text: 'Jumlah Nilai Transaksi',
            headerAlign: 'right',
            align: 'right',
            style: {width: '25%'}
        },
        {
            dataField: 'total_bonus_received',
            text: 'Total Bonus',
            headerAlign: 'right',
            align: 'right',
            style: {width: '25%'}
        },
        {
            dataField: 'status',
            text: 'Status',
            headerAlign: 'center',
            align: 'center',
            style: {width: '15%'}
        },
        {
            dataField: 'riwayat',
            text: 'Riwayat',
            headerAlign: 'center',
            align: 'center',
            formatter: RiwayatBonusColumn,
            style: {width: '15%'}
        }]

        const TableKomisi = (props) =>
            <BootstrapTable 
                keyField='id'
                data={this.state.data_report} striped hover bordered={true} 
                options={this.options} 
                noDataIndication="Tidak ada data" 
                columns={columns}/>

        const TableBonus = (props) =>
            <BootstrapTable 
                keyField='id'
                data={this.state.data_report_bonus} striped hover bordered={true} 
                options={this.options} 
                noDataIndication="Tidak ada data" 
                columns={columnsBonus}/>

        const chart_data_jumlah_tx = {
            labels: ['Januari','Februari','Maret','April','Mei','Juni','Juli','Agustus','September','Oktober','November','Desember'],
            datasets: [
                {
                    label: "Jumlah Transaksi",
                    fillColor: "rgba(220,220,220,0.2)",
                    strokeColor: "rgba(220,220,220,1)",
                    pointColor: "rgba(220,220,220,1)",
                    pointStrokeColor: "#fff",
                    pointHighlightFill: "#fff",
                    pointHighlightStroke: "rgba(220,220,220,1)",
                    data: this.state.data_report_jumlah_tx
                }
            ]
        }

        const chart_data_total_tx = {
            labels: ['Januari','Februari','Maret','April','Mei','Juni','Juli','Agustus','September','Oktober','November','Desember'],
            datasets: [
                {
                    label: "Total Transaksi",
                    fillColor: "rgba(220,220,220,0.2)",
                    strokeColor: "rgba(220,220,220,1)",
                    pointColor: "rgba(220,220,220,1)",
                    pointStrokeColor: "#fff",
                    pointHighlightFill: "#fff",
                    pointHighlightStroke: "rgba(220,220,220,1)",
                    data: this.state.data_report_total_tx
                }
            ]
        }

        const chart_data_total_fee = {
            labels: ['Januari','Februari','Maret','April','Mei','Juni','Juli','Agustus','September','Oktober','November','Desember'],
            datasets: [
                {
                    label: "Komisi",
                    fillColor: "rgba(220,220,220,0.2)",
                    strokeColor: "rgba(220,220,220,1)",
                    pointColor: "rgba(220,220,220,1)",
                    pointStrokeColor: "#fff",
                    pointHighlightFill: "#fff",
                    pointHighlightStroke: "rgba(220,220,220,1)",
                    data: this.state.data_report_total_fee
                }
            ]
        }

        const chart_data_total_bonus = {
            labels: ['Januari','Februari','Maret','April','Mei','Juni','Juli','Agustus','September','Oktober','November','Desember'],
            datasets: [
                {
                    label: "Bonus",
                    fillColor: "rgba(220,220,220,0.2)",
                    strokeColor: "rgba(220,220,220,1)",
                    pointColor: "rgba(220,220,220,1)",
                    pointStrokeColor: "#fff",
                    pointHighlightFill: "#fff",
                    pointHighlightStroke: "rgba(220,220,220,1)",
                    data: this.state.data_report_total_bonus
                }
            ]
        }

        return (
            <div className="row">
                <div className="col-md-12">
                    <Portlet>
                        <div className="m-portlet__head">
                            <div className="m-portlet__head-caption">
                                <div className="m-portlet__head-title">
                                    <h3 className="m-portlet__head-text">
                                        Tahun 
                                        <select className="m-input" id="year_sel" value={this.state.year_sel} onChange={(e) => this.on_select_change(e)} style={{height: '2.2rem', fontSize: '15px', marginLeft: '5px', borderColor: '#ebedf2', color: '#575962', background: '#fff', WebkitAppearance: 'none', padding: '0 10px'}}>
                                            <option value="2024">2024</option>
                                            <option value="2023">2023</option>
                                            <option value="2022">2022</option>
                                            <option value="2021">2021</option>
                                            <option value="2020">2020</option>
                                            <option value="2019">2019</option>
                                            <option value="2018">2018</option>
                                            <option value="2017">2017</option>
                                        </select>
                                        <button onClick={(e) => this.on_submit_summary(e)} className={this.state.flag_processing ? 'btn m-btn--pill btn-secondary m-btn m-btn--custom  m-btn--bolder m-loader m-loader--primary m-loader--right' : 'btn m-btn--pill btn-secondary m-btn m-btn--custom  m-btn--bolder'} style={{marginLeft: '10px',fontSize: 'small',paddingTop:'7px',paddingBottom:'7px'}}>Submit</button>   
                                    </h3>
                                </div>
                            </div>
                            <div className="m-portlet__head-tools">
                                <ul className="m-portlet__nav">
                                    <li className="m-portlet__nav-item">
                                        <ReactToPrint
                                            trigger={this.renderTrigger}
                                            content={this.renderContent}
                                            onBeforePrint={this.handleBeforePrint}
                                            onAfterPrint={this.handleAfterPrint}
                                            pageStyle="@page{size: auto A4 portrait;}"
                                        />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Portlet>
                    <div ref={this.setRef}>
                        <Portlet>
                            <PortletBody>
                                <FormGroup>
                                    <label style={{fontWeight:600}}>Komisi Tahun {this.state.report_year}</label>
                                    <TableKomisi/>
                                </FormGroup>
                                <br/>
                                <FormGroup>
                                    <label style={{fontWeight:600}}>Bonus Tahun {this.state.report_year}</label>
                                    <TableBonus/>
                                </FormGroup>
                            </PortletBody>
                        </Portlet>
                        <Portlet>
                            <PortletHead headTxt={"Grafik Perolehan Komisi"} />
                            <PortletBody>
                                <LineChart data={chart_data_total_fee} options={chartOptions}  width={900} height={350}/>
                            </PortletBody>
                        </Portlet>
                        <Portlet>
                            <PortletHead headTxt={"Grafik Perolehan Bonus"} />
                            <PortletBody>
                                <LineChart data={chart_data_total_bonus} options={chartOptions}  width={900} height={350}/>
                            </PortletBody>
                        </Portlet>
                        <Portlet>
                            <PortletHead headTxt={"Grafik Jumlah Transaksi"} />
                            <PortletBody>
                                <LineChart data={chart_data_jumlah_tx} options={chartOptions}  width={900} height={350}/>
                            </PortletBody>
                        </Portlet>
                        <Portlet>
                            <PortletHead headTxt={"Grafik Jumlah Nilai Melakukan Transaksi"} />
                            <PortletBody>
                                <LineChart data={chart_data_total_tx} options={chartOptions}  width={900} height={350}/>
                            </PortletBody>
                        </Portlet>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    update_current_page:  val => dispatch(do_action(action.ACTION_UPDATE_CURRENT_PAGE, val))
})

const mapStateToProps = (state) => ({
    session : state.Session,
    profile: state.Profile
})

export default connect(mapStateToProps, mapDispatchToProps)(ReportMonthly)
