import {connect} from 'react-redux';
import React, {Component} from 'react';
import moment from 'moment';
import request from 'superagent';
import {PortletHead, PortletBody} from '../component/widget/portlet';
import BootstrapTable from 'react-bootstrap-table-next';
import {DatePickerInput} from 'rc-datepicker';
import 'rc-datepicker/lib/style.css';
import {FormGroup} from '../component/form/form';
import apis from '../helper/api';
import action from '../helper/action';
import do_action from '../action';
import config from '../helper/config';
import swal from 'sweetalert';
import util from '../helper/util';
import CetakResiPaket from '../component/form/cetak_resi_paket';

let next_load_url = "";
let data_resi_paket = null;
let data_resi_paket_detail = null;

class AgentMLORiwayat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data_history_tx: [],
            start_date: "",
            end_date: "",
            tx_flag_processing: false,
            tx_flag_cari_processing: false,
            show_more_btn: false,
            scroll_interval_id: 0,
            expanded: [],
            donwload_flag: false,
            show_resi_paket: false
        };
        this.options = {
            onPageChange: this.onPageChange.bind(this),
            hideSizePerPage: true
        };
        this.start_date_change = this.start_date_change.bind(this);
        this.end_date_change = this.end_date_change.bind(this);
        this.on_btn_load_more = this.on_btn_load_more.bind(this);
        this.on_btn_cari = this.on_btn_cari.bind(this);
        this.on_btn_reset = this.on_btn_reset.bind(this);
        this.on_btn_cetak_struk = this.on_btn_cetak_struk.bind(this);
    }

    componentDidMount() {
        this.scroll_to_top();
        this.props.update_current_page('mlo-posting');
        let startOnlyDate = '';
        let date = moment().subtract(7, 'days').toDate();
        if (this.props.location.state) {
            if (this.props.location.state.month_year) {
                date = moment(this.props.location.state.month_year).startOf('month').toDate();
            }
        }
        if (date.length !== 0) {
            let bulan = (date.getMonth() + 1).toString();
            let hari = date.getDate().toString();
            let m = bulan.length === 1 ? '0' + bulan : bulan;
            let h = hari.length === 1 ? '0' + hari : hari;
            startOnlyDate = date.getFullYear() + "-" + m + "-" + h + " " + "00:00:00";
        }

        date = moment().toDate();
        if (this.props.location.state) {
            if (this.props.location.state.month_year) {
                date = moment(this.props.location.state.month_year).endOf('month').toDate();
            }
        }
        let endOnlyDate = '';
        if (date.length !== 0) {
            let bulan = (date.getMonth() + 1).toString();
            let hari = date.getDate().toString();
            let m = bulan.length === 1 ? '0' + bulan : bulan;
            let h = hari.length === 1 ? '0' + hari : hari;
            endOnlyDate = date.getFullYear() + "-" + m + "-" + h + " " + "23:59:59";
        }
        let self = this;
        this.setState({start_date: startOnlyDate, end_date: endOnlyDate}, () => {
            self.load_data(apis.kurir_list_transaksi + "/1", false);
        });
    }

    load_data(url, is_append) {
        let self = this
        let body = {
            "session_id" : this.props.session,
            "date_begin" : self.state.start_date,
            "date_end": self.state.end_date
        }
        if (this.props.profile.workstation_code) {
            body.workstation_code = this.props.profile.workstation_code
        }
        if (is_append) {
            this.setState({tx_flag_processing: true})
        } else {
            self.setState({tx_flag_cari_processing: true})
        }
        request.post(url)
            .set("Content-Type", "application/json")
            .send(body)
            .end(function(err, res) {
                if (err || !res.ok) {
                    util.log("get_tx", err)
                } else {
                    util.log("get_tx", res);
                    if (res.statusCode == 200) {
                        let payload = res.body
                        if (payload.status == "OK") {
                            if (payload.results) {
                                let results = payload.results
                                if (results.list !== undefined) {
                                    let items = []
                                    results.list.map(function(item, i) {
                                        items.push(item)
                                    })
                                    if (items.length === 0 && !is_append) {
                                        swal("Info", "Data transaksi belum ada", "warning")
                                    } else {
                                        if (!is_append) {
                                            self.setState({data_history_tx: items})
                                        } else {
                                            var joined = self.state.data_history_tx.concat(items);
                                            self.setState({data_history_tx: joined})
                                        }
                                    }
                                }
                                if (results.next_page !== null) {
                                    next_load_url = results.next_page.url
                                    self.setState({show_more_btn: true})
                                } else {
                                    next_load_url = ""
                                    self.setState({show_more_btn: false})
                                }
                            }
                        } else {
                            self.on_error(payload.error)
                        }
                    }
                }
                self.setState({tx_flag_processing: false, tx_flag_cari_processing: false})
            })
    }

    on_error(err) {
        util.on_error(err)
        if (err.code === 2007) {
            this.setState({show_more_btn: false})
        } else {
            let msg = "Error: " + err.code
            if (err.message !== undefined) {
                msg = err.message
            }
            swal("Ups!", msg, "warning")
        }
    }

    scroll_step() {
        if (window.pageYOffset === 0) {
            clearInterval(this.state.scroll_interval_id);
        }
        window.scroll(0, window.pageYOffset - 50);
    }

    scroll_to_top() {
        let scroll_interval_id = setInterval(this.scroll_step.bind(this), 16.66);
        this.setState({scroll_interval_id: scroll_interval_id});
    }

    on_btn_load_more() {
        if (this.state.tx_flag_processing) return
        if (next_load_url.length !== 0) {
            this.load_data(next_load_url, true)
        }
    }

    on_btn_reset() {
        this.setState({start_date : "", end_date : "", show_more_btn: false})
        this.load_data(apis.kurir_list_transaksi + "/1", false)
    }

    on_btn_cari() {
        if (this.state.start_date.length === 0) {
            swal("Info", "Pilih tanggal awal lebih dulu", "warning")
            return
        }
        if (this.state.end_date.length === 0) {
            swal("Info", "Pilih tanggal akhir lebih dulu", "warning")
            return
        }
        let d_start = new Date(this.state.start_date)
        let d_end = new Date(this.state.end_date)
        if (d_start > d_end) {
            swal("Info", "Tidak dapat diproses, tanggal awal lebih besar dari tanggal akhir", "warning")
            return
        }

        let self = this
        let body = {
            "session_id" : self.props.session,
            "date_begin" : self.state.start_date,
            "date_end": self.state.end_date
        }
        if (this.props.profile.workstation_code) {
            body.workstation_code = this.props.profile.workstation_code
        }
        self.setState({tx_flag_cari_processing: true, data_history_tx: []})
        request.post(apis.kurir_list_transaksi)
            .set("Content-Type", "application/json")
            .send(body)
            .end(function(err, res) {
                let message_error = ""
                if (err || !res.ok) {
                    util.log(err)
                    message_error = {"body": err.message, "title": "Transaksi Gagal"}
                    if (res !== undefined) {
                        message_error.body = "Error code " + res.statusCode
                    }
                } else {
                    util.log(res);
                    if (res.statusCode == 200) {
                        let payload = res.body
                        util.log(payload)
                        if (payload.status == "OK") {
                            let results = payload.results
                            if (results.list !== undefined) {
                                let items = []
                                results.list.map(function(item, i) {
                                    items.push(item)
                                })
                                self.setState({data_history_tx: items})
                            }
                            if (results.next_page !== null) {
                                next_load_url = results.next_page.url
                                self.setState({show_more_btn: true})
                            } else {
                                next_load_url = ""
                                self.setState({show_more_btn: false})
                            }
                        } else {
                            message_error = {"body": res.body.error.message, "title": "Transaksi Gagal"}
                            if (res.body.display_message !== undefined) {
                                message_error.title = res.body.error.message
                                message_error.body = res.body.display_message
                            }
                            self.setState({show_more_btn: false})
                        }
                    } else {
                        message_error = {"body": "HTTP error " + res.statusCode, "title": "Transaksi Gagal"}
                    }
                }
                if (message_error.length != 0) {
                    swal(message_error.title, message_error.body, "warning")
                }
                self.setState({tx_flag_cari_processing: false})
            });
    }

    onPageChange(page, sizePerPage) {
        alert(`page: ${page}, sizePerPage: ${sizePerPage}`);
    }

    start_date_change(date) {
        try {
            let onlyDate = ''
            if (date.length !== 0) {
                let bulan = (date.getMonth() + 1).toString()
                let hari = date.getDate().toString()
                let m = bulan.length === 1 ? '0' + bulan : bulan
                let h = hari.length === 1 ? '0' + hari : hari
                onlyDate = date.getFullYear() + "-" + m + "-" + h + " " + "00:00:00"
            }
            this.setState({start_date : onlyDate})
        } catch(err) {
            util.log("[start_date_change] error", err)
        }
    }

    end_date_change(date) {
        try {
            let onlyDate = ''
            if (date.length !== 0) {
                let bulan = (date.getMonth() + 1).toString()
                let hari = date.getDate().toString()
                let m = bulan.length === 1 ? '0' + bulan : bulan
                let h = hari.length === 1 ? '0' + hari : hari
                onlyDate = date.getFullYear() + "-" + m + "-" + h + " " + "23:59:59"
            }
            this.setState({end_date : onlyDate})
        } catch(err) {
            util.log("[end_date_change] error", err)
        }
    }

    on_filter_label(i) {
        if (i === 1) {
            this.start_date_change(moment().toDate())
            this.end_date_change(moment().toDate())
        } else if (i === 2) {
            this.start_date_change(moment().subtract(1, 'days').toDate())
            this.end_date_change(moment().toDate())
        } else if (i === 3) {
            this.start_date_change(moment().subtract(7, 'days').toDate())
            this.end_date_change(moment().toDate())
        } else if (i === 4) {
            this.start_date_change(moment().subtract(30, 'days').toDate())
            this.end_date_change(moment().toDate())
        }
    }

    handleOnExpand = (row, isExpand, rowIndex, e) => {
        if (isExpand) {
            this.setState(() => ({expanded: [...this.state.expanded, row.unique_id]}));
        } else {
            this.setState(() => ({expanded: this.state.expanded.filter(x => x !== row.unique_id)}));
        }
    }

    setRef = (ref) => {
        this.componentRef = ref
    }

    on_input_change(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    on_select_change(e) {
        this.setState({[e.target.id]: e.target.value})
    }

    on_click_sub_menu(e, tag) {
        if (tag == 'riwayat') {
            this.props.history.push({
                pathname: '/agen/mlo-riwayat',
                search: '',
                state: {}
            });
        } else if (tag == 'posting') {
            this.props.history.push({
                pathname: '/agen/mlo-posting',
                search: '',
                state: {}
            });
        }
    }

    on_btn_cetak_struk(el, data) {
        let self = this;
        util.log("on_btn_cetak_struk: ", data);
        if (util.is_electron()) {
            // TODO
        } else {
            data_resi_paket = data;
            let body = {
                session_id : this.props.session,
                item_id: data.item_id,
                item_code: data.item_code
            };
            const btn = el.target;
            btn.classList.add("m-loader", "m-loader--light", "m-loader--right");
            request.post(apis.kurir_detail_transaksi)
            .set("Content-Type", "application/json")
            .send(body)
            .end(function(err, res) {
                let message_error = ""
                if (err || !res.ok) {
                    util.log(err);
                    message_error = {"body": err.message, "title": "Generate Resi Gagal"}
                    if (res !== undefined) {
                        message_error.body = "Error code " + res.statusCode;
                    }
                } else {
                    util.log(res);
                    if (res.statusCode == 200) {
                        let payload = res.body;
                        util.log(payload);
                        if (payload.status == "OK") {
                            data_resi_paket_detail = payload.results;
                            self.setState({show_resi_paket: true});
                        } else {
                            message_error = {"body": res.body.error.message, "title": "Generate Resi Gagal"}
                            if (res.body.display_message !== undefined) {
                                message_error.title = res.body.error.message;
                                message_error.body = res.body.display_message;
                            }
                        }
                    } else {
                        message_error = {"body": "HTTP error " + res.statusCode, "title": "Generate Resi Gagal"}
                    }
                }
                if (message_error.length != 0) {
                    swal(message_error.title, message_error.body, "warning");
                }
                btn.classList.remove("m-loader", "m-loader--light", "m-loader--right");
            });
        }
    }

    render() {
        let uid = this.props.profile ? this.props.profile.username : "";
        let ug = navigator.userAgent;

        const NumberCol = (cell, row, i) =>
            <div>{i + 1}</div>

        const KodeCol = (cell, row, i) =>
            <div>{row.connote_code}</div>

        const NamaPelangganCol = (cell, row, i) =>
            <div>{row.connote[0].sender_name}</div>

        const WaktuCol = (cell, row, i) =>
            <div>{row.connote[0].sla_date}</div>

        const JumlahConnoteCol = (cell, row, i) =>
            <div>{row.connote.length}</div>

        const ServiceCol = (cell, row, i) =>
            <div>{row.service_name}</div>

        const JumlahBayarCol = (cell, row, i) =>
            <div>{row.trx_total}</div>

        const KantorCol = (cell, row, i) =>
            <div>{row.connote[0].last_location_name}</div>

        const StatusBayarCol = (cell, row, i) =>
            <div>{row.connote[0].last_state}</div>

        const expandRow = {
            renderer: (row) => (
                <div className="row">
                    <div className="col-lg-12" style={{textAlign: 'center',margin: 'auto'}}>
                        <button type="button" className="btn btn-primary btn-sm m-btn m-btn--custom"  
                        onClick={(el) => this.on_btn_cetak_struk(el, row)}>
                            Cetak Resi
                        </button>
                        &nbsp;&nbsp;
                        <a href={'mailto:' + config.helpdesk_email + '?cc=' + config.admin_email + '&subject=Komplain Transaksi Paket ' + row.connote_code + '&body=' + '%0A%0A%0Atxid: ' + row.connote_code + '%0Auid: ' + uid + '%0Aug: ' + ug} target="_top" type="button" className="btn btn-secondary btn-sm m-btn m-btn--custom">
                            Komplain
                        </a>
                    </div>
                </div>
            ),
            showExpandColumn: true,
            expanded: this.state.expanded,
            onExpand: this.handleOnExpand,
            expandHeaderColumnRenderer: ({isAnyExpands}) => {
                if (isAnyExpands) {
                    return <b>-</b>;
                }
                return <b>+</b>;
            },
            expandColumnRenderer: ({expanded}) => {
                if (expanded) {
                    return (<b>-</b>)
                }
                return (<b>+</b>)
            }
        }

        const columns = [{
            dataField: 'unique_id',
            text: 'No.',
            formatter: NumberCol,
            headerAlign: 'center',
            align: 'center',
            style: {width: '3%'}
        },
        {
            dataField: 'kode_col',
            text: 'Kode',
            formatter: KodeCol,
            headerAlign: 'left',
            align: 'left',
            style: {width: '10%'}
        },
        {
            dataField: 'nama_pelanggan_col',
            text: 'Pengirim',
            formatter: NamaPelangganCol,
            headerAlign: 'left',
            align: 'left',
            style: {width: '15%'}
        },
        {
            dataField: 'waktu_col',
            text: 'Waktu',
            formatter: WaktuCol,
            headerAlign: 'center',
            align: 'center',
            style: {width: '15%'}
        },
        {
            dataField: 'harga_col',
            text: 'Service',
            formatter: ServiceCol,
            headerAlign: 'right',
            align: 'right',
            style: {width: '15%'}
        },
        {
            dataField: 'jumlah_bayar_col',
            text: 'Total',
            formatter: JumlahBayarCol,
            headerAlign: 'center',
            align: 'center',
            style: {width: '10%'}
        },
        {
            dataField: 'kantor_col',
            text: 'Lokasi',
            formatter: KantorCol,
            headerAlign: 'center',
            align: 'center',
            style: {width: '35%'}
        },
        {
            dataField: 'status_bayar_col',
            text: 'Status',
            formatter: StatusBayarCol,
            headerAlign: 'center',
            align: 'center',
            style: {width: '15%'}
        }]

        const TableTx = (props) =>
            <BootstrapTable 
                keyField='unique_id'
                data={this.state.data_history_tx} striped hover bordered={true} 
                options={this.options} 
                noDataIndication="Tidak ada data" 
                columns={columns}
                expandRow={expandRow}
                ref={this.setRef}/>

        let self = this;
        const input_text_style = {padding: '7px'};

        return (
            <div>
                <div className="row" style={{marginBottom: '10px'}}>
                    <div className="btn-group" role="group" style={{marginLeft: '1rem'}}>
                        <button type="button" className="m-btn btn btn-success m-btn--icon" onClick={(e) => this.on_click_sub_menu(e, 'posting')} style={{padding:'0px 10px',height:'35px'}} title="">
                            <span>Pengiriman Baru</span>
                        </button>
                        <button type="button" className="m-btn btn btn-warning m-btn--icon" onClick={(e) => this.on_click_sub_menu(e, 'riwayat')} style={{padding:'0px 10px',height:'35px'}} title="">
                            <span>Riwayat Transaksi</span>
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="m-portlet m-portlet--tab" style={{minHeight: '-webkit-fill-available'}}>
                            <PortletHead headTxt={"Riwayat Pengiriman"} />
                            <PortletBody>
                                <FormGroup>
                                    <label style={{fontWeight: 600}}>
                                        Filter Data:
                                    </label>
                                    <div style={{display:'inline'}}>
                                        <span onClick={() => this.on_filter_label(1)} style={{marginLeft: 10+'px',marginRight: 10+'px', cursor: 'pointer'}}>Hari Ini</span>
                                        /
                                        <span onClick={() => this.on_filter_label(2)} style={{marginLeft: 10+'px',marginRight: 10+'px', cursor: 'pointer'}}>Kemaren</span>
                                        /
                                        <span onClick={() => this.on_filter_label(3)} style={{marginLeft: 10+'px',marginRight: 10+'px', cursor: 'pointer'}}>7 Hari Terakhir</span>
                                        /
                                        <span onClick={() => this.on_filter_label(4)} style={{marginLeft: 10+'px',marginRight: 10+'px',    cursor: 'pointer'}}>30 Hari Terakhir</span>
                                    </div>
                                    <div className="input-group">
                                        <div className="input-group">
                                            <DatePickerInput
                                                onChange={this.start_date_change}
                                                value={this.state.start_date}/>
                                            <span style={{padding: '7px'}}>-</span>
                                            <DatePickerInput
                                                onChange={this.end_date_change}
                                                value={this.state.end_date}/>
                                            &nbsp;&nbsp;&nbsp;
                                            <input placeholder="Pencarian" value={this.state.input_service} onChange={(e) => this.on_input_change(e)} className="form-control m-input" type="text" name="input_nama_pengirim" style={{...input_text_style, width: '200px'}}/>
                                            &nbsp;&nbsp;&nbsp;
                                            <select className="form-control m-input" id="gender-sel" value={this.state.sel_gender} onChange={(e) => this.on_select_change(e)} style={{...input_text_style, 
                                                padding: '3px',
                                                width: '220px',
                                                marginLeft: 'auto',
                                                marginRight: '-5px'}}>
                                                <option key={999} value="">Pilih Status</option>
                                                <option key={0} value="pending">Pending</option>
                                                <option key={1} value="completed">Completed</option>
                                            </select>
                                            &nbsp;&nbsp;&nbsp;
                                            <button type="button" className={this.state.tx_flag_cari_processing ? 'btn btn-secondary btn-sm m-btn m-btn--custom m-loader m-loader--primary m-loader--right' : 'btn btn-secondary btn-sm m-btn m-btn--custom'}  
                                            onClick={() => this.on_btn_cari()}>
                                                Cari
                                            </button>
                                            &nbsp;&nbsp;&nbsp;
                                            <button type="button" className="btn btn-secondary btn-sm m-btn m-btn--custom" 
                                            onClick={() => this.on_btn_reset()}>
                                                Reset
                                            </button>
                                            &nbsp;&nbsp;
                                        </div>
                                    </div>
                                </FormGroup>
                                <br/>
                                <TableTx/>
                                {this.state.show_more_btn ? <button type="button" style={{width: '-webkit-fill-available'}} onClick={this.on_btn_load_more} className={this.state.tx_flag_processing ? 'btn btn-secondary m-loader m-loader--primary m-loader--right' : 'btn btn-secondary'}>
                                    Load More
                                </button> : ''}
                                
                            </PortletBody>
                        </div>
                    </div>
                </div>
                {this.state.show_resi_paket ? <CetakResiPaket 
                    data={data_resi_paket} 
                    data_detail={data_resi_paket_detail}
                    is_history={true}
                    on_close={()=>{
                        self.setState({show_resi_paket: false})
                    }} /> : ''}
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    update_current_page:  val => dispatch(do_action(action.ACTION_UPDATE_CURRENT_PAGE, val))
})

const mapStateToProps = (state) => ({
    session : state.Session,
    profile: state.Profile
})

export default connect(mapStateToProps, mapDispatchToProps)(AgentMLORiwayat)