import {connect} from 'react-redux'
import React, {Component} from 'react'
import {history} from '../helper/history'
import moment from 'moment'
import request from 'superagent'

import MetronicIndex from '../layout/metronic/index'
import {Form, FormGroup} from '../component/form/form'
import {Portlet, PortletHead, PortletBody, PortletFooter} from '../component/widget/portlet'
import BootstrapTable from 'react-bootstrap-table-next'

import apis from '../helper/api'
import localdata from '../helper/localdata'
import config from '../helper/config'
import swal from 'sweetalert'
import action from '../helper/action'
import do_action from '../action'
import util from '../helper/util'

class AgentBatchTransaction extends Component {
    constructor(props) {
        super(props)
        this.state = {
            search_product_key: '',
        	batch_file_name: '',
        	batch_file_obj: '',
            tagihan_obj: [],
            command: '',
        	flag_cek_processing: false,
        	flag_submit_processing: false
        }
        this.options = {
            onPageChange: this.onPageChange.bind(this),
            hideSizePerPage: true
        }
        this.batch_file_input_changed = this.batch_file_input_changed.bind(this)
        this.on_btn_reset = this.on_btn_reset.bind(this)
    }

    componentDidMount() {
    	this.scroll_to_top()
    }

    onPageChange(page, sizePerPage) {
        alert(`page: ${page}, sizePerPage: ${sizePerPage}`);
    }

    on_error(err) {
        if (err.code == 2006) {
            localdata.clear_data().then((res) => {
                history.replace('/login')
            }).catch((err) => {
                util.log("error: ", err)
            })
        } else {
            swal("Ups!", "Error: " + err.code, "warning")
        }
    }

    on_select_change(e) {
        let self = this
    }

    scroll_step() {
        if (window.pageYOffset === 0) {
            clearInterval(this.state.scroll_interval_id);
        }
        window.scroll(0, window.pageYOffset - 50);
    }

    scroll_to_top() {
        let scroll_interval_id = setInterval(this.scroll_step.bind(this), 16.66);
        this.setState({scroll_interval_id: scroll_interval_id});
    }

    batch_file_input_changed = (event) => {
    	let b_file = event.target.files[0]
        util.log(b_file)
        if (b_file === undefined) {return}
        this.on_cek_batch_file(b_file)
    }

    on_cek_batch_file (b_file) {
        let self = this
        let reader = new FileReader()
        this.setState({batch_file_name: b_file.name})

        reader.onload = function() {
            let tagihan_all = []
            let text = reader.result
            let lines = text.split(/[\r\n]+/g)

            let product_key = null
            let product_name = null
            let list_tagihan = []
            let command = ''
            lines.forEach(function(line) {
                util.log(line)
                if (line.trim().toLowerCase().startsWith('#selesai#')) {
                    if (product_key !== null) {
                        let o = {product_id: product_key, product_name: product_name, list: list_tagihan, command: command}
                        tagihan_all.push(o)
                    }
                    product_key = null
                    list_tagihan = []
                } else {
                    if (line.trim().toLowerCase().startsWith("#tagihan#")) {
                        if ((command.length === 0 || command === 'TAGIHAN')) {
                            const [p1, p2, p3, p4, ...others] = line.split('#')
                            product_key = p3 != undefined ? p3.trim() : 'Tidak Ada Kode Produk'
                            product_name = p4 != undefined ? p4.trim() : 'Tidak Ada Nama Produk'
                            command = 'TAGIHAN'
                        }
                    } else if (line.trim().toLowerCase().startsWith("#bayar#")) {
                        if ((command.length === 0 || command === 'PEMBAYARAN')) {
                            const [p1, p2, p3, p4, ...others] = line.split('#')
                            product_key = p3 != undefined ? p3.trim() : 'Tidak Ada Kode Produk'
                            product_name = p4 != undefined ? p4.trim() : 'Tidak Ada Nama Produk'
                            command = 'PEMBAYARAN'
                        }
                    } else if (line.trim().toLowerCase().startsWith("#beli#")) {
                        if ((command.length === 0 || command === 'PEMBELIAN')) {
                            const [p1, p2, p3, p4, ...others] = line.split('#')
                            product_key = p3 != undefined ? p3.trim() : 'Tidak Ada Kode Produk'
                            product_name = p4 != undefined ? p4.trim() : 'Tidak Ada Nama Produk'
                            command = 'PEMBELIAN'
                        }
                    } else if (line.trim().toLowerCase().startsWith("#cekharga#")) {
                        if ((command.length === 0 || command === 'CEK HARGA')) {
                            const [p1, p2, p3, p4, ...others] = line.split('#')
                            product_key = p3 != undefined ? p3.trim() : 'Tidak Ada Kode Produk'
                            product_name = p4 != undefined ? p4.trim() : 'Tidak Ada Nama Produk'
                            command = 'CEK HARGA'
                        }
                    } else {
                        if (line.length !== 0) {
                            const [p1, p2, ...others] = line.split('#')
                            if (p1 !== null && p1.length !== 0) {
                                let tagihan = {bill_no: p1, name: 'Anonim'}
                                if (p2 !== undefined && p2.length !== 0) {
                                    tagihan.name = p2
                                }
                                list_tagihan.push(tagihan)
                            }
                        }
                    }
                }
            })
            util.log("all tagihan", tagihan_all)
            self.setState({tagihan_obj:tagihan_all, batch_file_obj: b_file, command: command})
        }
        reader.readAsText(b_file)
    }

    on_submit_batch_file() {
        if (this.state.batch_file_obj === undefined) return
        let self = this
        swal({
            title: "Konfirmasi",
            text: "Apakah Anda yakin untuk melanjutkan transaksi ini?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((sure) => {
            if (sure) {
                let url = ''
                let sign_token = ''
                if (self.state.command.toLowerCase() === 'tagihan') {
                    url = apis.batch_tx_inquiry
                } else if (self.state.command.toLowerCase() === 'pembayaran') {
                    url = apis.batch_tx_payment
                    let whitelabel_36 = config.whitelabel.toString(36).toUpperCase()
                    let user_id = this.props.profile.userid.toString(36).toUpperCase()
                    let prefix = whitelabel_36.substring(whitelabel_36.length - 3)
                    let suffix = user_id.substring(user_id.length - 3)
                    sign_token = util.gen_token(prefix, this.props.session, suffix)
                } else if (self.state.command.toLowerCase() === 'cek harga') {
                    url = apis.batch_tx_inquiry
                } else if (self.state.command.toLowerCase() === 'pembelian') {
                    url = apis.batch_tx_purchase
                    let whitelabel_36 = config.whitelabel.toString(36).toUpperCase()
                    let user_id = this.props.profile.userid.toString(36).toUpperCase()
                    let prefix = whitelabel_36.substring(whitelabel_36.length - 3)
                    let suffix = user_id.substring(user_id.length - 3)
                    sign_token = util.gen_token(prefix, this.props.session, suffix)
                }
                if (url.length !== 0) {
                    self.setState({tx_flag_cari_processing: true})
                    request.post(url)
                        .attach('fileupload', this.state.batch_file_obj)
                        .field('session_id', this.props.session)
                        .field('sign_token', sign_token)
                        .then(res => {
                            util.log(res)
                            if (res.ok) {
                                let failed = ""
                                if (res.statusCode === 200) {
                                    let payload = res.body
                                    if (payload.status === "OK") {
                                        swal(payload.error.message, payload.display_message, "success")
                                        self.scroll_to_top()
                                        self.on_btn_reset()
                                    } else {
                                        failed = payload.error.message
                                    }
                                } else {
                                    failed = res.statusCode
                                }
                                if (failed.length !== 0) {
                                    swal("Gagal", failed, "error")
                                }
                            }
                            self.setState({tx_flag_cari_processing: false})
                        })
                } else {
                    swal("Format File Salah", "Periksa kembali format file input, pastikan sesuai dengan aturan yang telah kita buat.", "warning")
                }
            } else {
                // no-op
            }
        })
    }

    on_btn_reset() {
        this.file_input.value = ""
        this.setState({tagihan_obj:[],batch_file_name:'',batch_file_obj:'',command:''})
    }

    render() {
        let self = this
        let val_style = {fontWeight: 500,margin: 'auto 0'}
        let product_style = {fontWeight: 600,margin: 'auto 0'}
        const RowNumber = (cell, row, i) =>
            <div>{i + 1}</div>

        const columns = [{
            dataField: 'id',
            text: 'No.',
            formatter: RowNumber,
            headerAlign: 'center',
            align: 'center',
            style: {width: '3%'}
        },
        {
            dataField: 'bill_no',
            text: 'Nomor Tagihan',
            headerAlign: 'center',
            align: 'center',
            style: {width: '48%'}
        },
        {
            dataField: 'name',
            text: 'Nama',
            headerAlign: 'center',
            align: 'center',
            style: {width: '48%'}
        }]

        const TableTagihanView = (props) =>
            <div style={{marginTop:'20px'}}>
                <label style={{fontWeight: 600}}>
                    Produk #{props.pos + 1}
                </label>
                <div className="row">
                    <span className="col-3" style={val_style}>
                        Tipe:
                    </span>
                    <div className="col-9" style={product_style}>
                        {props.data.command}
                    </div>
                </div>
                <div className="row">
                    <span className="col-3" style={val_style}>
                        Kode Produk:
                    </span>
                    <div className="col-9" style={product_style}>
                        {props.data.product_id}
                    </div>
                </div>
                <div className="row">
                    <span className="col-3" style={val_style}>
                        Nama Produk:
                    </span>
                    <div className="col-9" style={product_style}>
                        {props.data.product_name ? props.data.product_name : 'PRODUK ANONIM'}
                    </div>
                </div>
                <div className="row">
                    <span className="col-3" style={val_style}>
                        Jumlah:
                    </span>
                    <div className="col-9" style={product_style}>
                        {props.data.list.length}
                    </div>
                </div>
                <div style={{marginTop: '10px'}}>
                    <TableTagihan data={props.data.list} pos={props.pos}/>
                </div>
            </div>

        const TableTagihan = (props) =>
            <BootstrapTable key={props.pos} keyField='id'
                data={props.data} striped hover bordered={true} 
                options={this.options} 
                noDataIndication="Tidak ada data tagihan" 
                columns={columns}/>

        const FileInputView = (props) =>
        	<div style={{marginTop:'20px'}}>
        		<label style={{fontWeight: 600}}>
                    File Input
                </label>
        		<div className="row">
                    <span htmlFor="example-text-input" className="col-3" style={val_style}>
                    	Nama File: 
                    </span>
                    <div className="col-9" style={val_style}>
                        {this.state.batch_file_obj.name}
                    </div>
                </div>
                <div className="row">
                    <span htmlFor="example-text-input" className="col-3" style={val_style}>
                    	Terakhir Update:
                    </span>
                    <div className="col-9" style={val_style}>
                        {util.date_time_local_2(this.state.batch_file_obj.lastModified)}
                    </div>
                </div>
                
                {this.state.tagihan_obj.length !== 0 ? this.state.tagihan_obj.map((item, i) =>
                    <TableTagihanView data={item} key={i} pos={i} />
                ) : ''}

                <div className="row" style={{margin:'20px 0 20px 0'}}>
                    <button disabled={props.product === null ? true : false} type="button" className={this.state.tx_flag_cari_processing ? 'btn btn-primary btn-sm m-btn m-btn--primary m-loader m-loader--secondary m-loader--right' : 'btn btn-primary btn-sm m-btn m-btn--primary'}  
                        onClick={() => this.on_submit_batch_file()}>
                        Submit Batch Transaksi
                    </button>
                </div>
        	</div>

        const content_style = {display:'block',padding:'7px 0'}
        const content_style_2 = {fontWeight: 500}
        const content_style_3 = {display: 'block',marginTop: '1rem',marginBottom: '1rem',fontSize: '90%',color: '#212529'}
        const DocView = (props) =>
            <div>
                <label style={{fontWeight: 600,marginBottom: 0,marginTop:'8px'}}>
                    Dokumentasi File Batch Format
                </label>
                <span style={content_style}>Contoh file batch untuk pembayaran PLN Pascabayar adalah sebagai berikut:</span>

                <div style={content_style_3}>
                    #BAYAR#3008#PLN PASCABAYAR<br/>
                    122100575010#Pak Amin<br/>
                    141101138295#Bu Saras#0857829390<br/>
                    189001198783#Pak Soleh#0823047484<br/>
                    #SELESAI#
                </div>

                <span style={content_style}>Baris pertama, kata "BAYAR" diikuti dengan kode dan nama produk, dipisahkan dengan karakter "#" (Wajib). Baris-baris berikutnya adalah daftar nomor tagihan dan nama pelanggan, juga dipisahkan dengan karakter "#". Bisa juga ditambahkan info nomor telepon pelanggan tsb (jika ada). Terakhir, ditutup dengan kata "SELESAI".</span>
                <span style={content_style}>Didalam satu file batch ini juga boleh ditambahkan lebih dari satu daftar tagihan pembayaran, misalnya saja seperti melakukan pembayaran PLN PASCABAYAR dan PDAM. Contoh file nya akan menjadi seperti berikut:</span>

                <div style={content_style_3}>
                #BAYAR#3008#PLN PASCABAYAR<br/>
                122100575010#Pak Amin<br/>
                141101138295#Bu Saras<br/>
                189001198783#Pak Soleh<br/>
                #SELESAI#<br/>
                <br/>
                #BAYAR#4046#PDAM Kota Bekasi<br/>
                078950013078#Pak Sudi<br/>
                031150010052#Pak Sandi<br/>
                012850016090#Pak Joko<br/>
                #SELESAI#
                </div>

                <span style={content_style}>Sedangkan untuk melihat dan mencetak beberapa tagihan pelanggan, ganti kata "BAYAR" dengan kata "TAGIHAN":</span>

                <div style={content_style_3}>
                #TAGIHAN#4046#PDAM Kota Bekasi<br/>
                078950013078#Pak Sudi<br/>
                031150010052#Pak Sandi<br/>
                012850016090#Pak Joko<br/>
                #SELESAI#
                </div>

                <span style={content_style}>Beberapa keyword permintaan untuk melakukan batch transaksi adalah:</span>
                <ol style={{paddingLeft: '20px'}}>
                    <li style={content_style_2}><p>#BAYAR : untuk melakukan pembayaran tagihan pelanggan</p></li>
                    <li style={content_style_2}><p>#TAGIHAN : untuk melihat dan cetak tagihan pelanggan</p></li>
                    <li style={content_style_2}><p>#BELI : untuk membeli pulsa operator, paket data atau token PLN</p></li>
                    <li style={content_style_2}><p>#CEKHARGA : untuk melihat dan cetak harga pulsa, paket data atau token PLN</p></li>
                </ol>
            </div>

        return (<div className="row">
                <div className="col-md-12">
                    <Portlet>
                        <PortletHead headTxt={"Batch Transaksi"} />
                        <div className="m-portlet__body" style={{margin: '0px 10px'}}>
                            <FormGroup>
                                <label style={{fontWeight: 600}}>
                                    File Input (.txt atau .csv):
                                </label>
                                <div className="input-group">
                                    <div className="input-group">
                                    	<input disabled placeholder="Taruh file CSV disini" className="form-control m-input" type="text" name="batch_file_name" value={this.state.batch_file_name} />
                                    	<span className="input-group-btn" onClick={(e) => document.getElementById("batch_file_input").click()}>
    										<button className="btn btn-primary" type="button">
    											Browse
    										</button>
    									</span>
                                	</div>
                                    <input type="file" accept=".csv,text/plain" id="batch_file_input" name="batch_file_input" 
                                            onChange={this.batch_file_input_changed} 
                                            style={{display:'none'}} 
                                            ref={ref=> this.file_input = ref}/>
                                    &nbsp;&nbsp;&nbsp;
                                    <button type="button" className="btn btn-secondary btn-sm m-btn m-btn--custom" 
                                    onClick={() => this.on_btn_reset()}>
                                        Reset
                                    </button>
                                </div>
                            </FormGroup>
                            {this.state.batch_file_obj.length !== 0 ? <FileInputView/> : <DocView/>}
                        </div>
                    </Portlet>
                </div>
                
            </div>)
    }
}

const mapDispatchToProps = (dispatch) => ({
})

const mapStateToProps = (state) => ({
    session : state.Session,
    profile: state.Profile
})

export default connect(mapStateToProps, mapDispatchToProps)(AgentBatchTransaction)