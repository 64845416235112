import React from 'react';
import ReactDOM from 'react-dom';
import {createStore, applyMiddleware, compose} from 'redux'
import {Provider} from 'react-redux'
import App from './App';
import './index.css';
import reducerApp from './reducer'
import registerServiceWorker from './registerServiceWorker';
import {logger, api_service} from './service/api_service'

const middlewares = [logger, api_service];
const store = createStore(
	reducerApp,
	applyMiddleware(...middlewares)
);

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>, document.getElementById('root'));
registerServiceWorker();
