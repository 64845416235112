import {connect} from 'react-redux'
import React, {Component} from 'react'
import moment from 'moment'
import request from 'superagent'
import {history} from '../helper/history'
import {Link} from "react-router-dom"
import MetronicIndex from '../layout/metronic/index'
import {Form, FormGroup} from '../component/form/form'
import {Portlet, PortletHead, PortletBody, PortletFooter, PortletTab} from '../component/widget/portlet'
import BootstrapTable from 'react-bootstrap-table-next'
import {DatePicker, DatePickerInput} from 'rc-datepicker'
import 'rc-datepicker/lib/style.css';
import apis from '../helper/api'
import localdata from '../helper/localdata'
import action from '../helper/action'
import do_action from '../action'
import config from '../helper/config'
import swal from 'sweetalert'
import Modal from 'react-responsive-modal'
import ModalChangePassword from '../component/form/dialog_change_password'
import util from '../helper/util'

let next_load_url = ""
let next_load_search_input = ""
let is_next_load_search = false
let row_selected = {}
class AgentWorkstation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            flag_processing: false,
            data_workstation: [],
            show_more_btn: false,
            search_input: "",
            show_add_form: false,
            nama: "",
            kode: "",
            password: "",
            konfirm_password: "",
            show_dialog_change_password: false,
            type_input_password: 'password'
        }
        this.options = {
            hideSizePerPage: true
        }
        this.on_btn_load_more = this.on_btn_load_more.bind(this)
        this.on_btn_search = this.on_btn_search.bind(this)
        this.on_btn_add = this.on_btn_add.bind(this)
        this.on_btn_show_register = this.on_btn_show_register.bind(this)
        this.on_btn_reset = this.on_btn_reset.bind(this)
    }

    componentDidMount() {
        let self = this
        const get_url = apis.get_workstation
        self.get_workstation(get_url, false)
    }

    search_workstation(url, is_append) {
    	if (this.state.search_input.length !== 0) {
    		const self = this
    		let get_url = apis.search_workstation
    		if (url.length !== 0) {
    			get_url = url
    		}
    		let session_id = this.props.session
	    	let body = {
	            "session_id" : session_id
	        }
	        if (!is_append) {
	        	body.search = this.state.search_input
	        } else {
	        	body.search = next_load_search_input
	        }
	        let new_state = {flag_processing: true}
	        if (!is_append) {
	        	new_state.data_workstation = []
	        }
	        self.setState(new_state)
	        request.post(get_url)
	            .set("Content-Type", "application/json")
	            .send(body)
	            .end(function(err, res) {
	                if (err || !res.ok) {
	                    util.log("search_workstation", err)
	                } else {
	                    util.log("search_workstation", res);
	                    if (res.statusCode == 200) {
	                        let payload = res.body
	                        if (payload.status == "OK") {
	                            if (payload.results) {
	                                let results = payload.results
	                                if (results.list !== undefined) {
	                                    if (!is_append) {
	                                        self.setState({data_workstation: results.list})
	                                    } else {
	                                        var joined = self.state.data_workstation.concat(results.list);
	                                        self.setState({data_workstation: joined})
	                                    }
	                                }
	                                if (results.next_page !== null) {
	                                    next_load_url = results.next_page.url
	                                    self.setState({show_more_btn: true})
	                                } else {
	                                    next_load_url = ""
	                                    self.setState({show_more_btn: false})
	                                }
	                            }
	                        } else {
	                            self.on_error(payload.error)
	                        }
	                    }
	                }
	                self.setState({flag_processing: false})
	            })
    	}
    }

    get_workstation(url, is_append) {
    	let self = this
    	let session_id = this.props.session
    	let body = {
            "session_id" : session_id
        }
        let new_state = {flag_processing: true}
        if (!is_append) {
        	new_state.data_workstation = []
        }
        self.setState(new_state)
        request.post(url)
            .set("Content-Type", "application/json")
            .send(body)
            .end(function(err, res) {
                if (err || !res.ok) {
                    util.log("get_workstation", err)
                } else {
                    util.log("get_workstation", res);
                    if (res.statusCode == 200) {
                        let payload = res.body
                        if (payload.status == "OK") {
                            if (payload.results) {
                                let results = payload.results
                                if (results.list !== undefined) {
                                    if (!is_append) {
                                        self.setState({data_workstation: results.list})
                                    } else {
                                        var joined = self.state.data_workstation.concat(results.list);
                                        self.setState({data_workstation: joined})
                                    }
                                }
                                if (results.next_page !== null) {
                                    next_load_url = results.next_page.url
                                    self.setState({show_more_btn: true})
                                } else {
                                    next_load_url = ""
                                    self.setState({show_more_btn: false})
                                }
                            }
                        } else {
                            self.on_error(payload.error)
                        }
                    }
                }
                self.setState({flag_processing: false})
            })
    }

    on_btn_search() {
    	if (this.state.flag_processing) return
    	next_load_search_input = this.state.search_input
    	is_next_load_search = true
    	this.search_workstation("", false)
    }

    on_btn_reset() {
    	is_next_load_search = false
    	next_load_url = ""
    	const get_url = apis.get_workstation
    	this.get_workstation(get_url, false)
    }

    on_btn_load_more() {
        if (this.state.flag_processing) return
        if (next_load_url.length !== 0) {
        	if (is_next_load_search) {
        		this.search_workstation(next_load_url, true)
        	} else {
            	this.get_workstation(next_load_url, true)
        	}
        }
    }

    on_btn_show_register() {
    	this.setState({show_add_form: !this.state.show_add_form})
    }

    on_btn_add() {
    	if (this.state.flag_processing_add) return
    	if (this.state.kode.length === 0 || this.state.nama.length === 0 || this.state.password.length === 0 || this.state.konfirm_password.length === 0) {
    		swal("Error", "Periksa kembali form register workstation Anda", "error")
    		return
    	}
    	if (this.state.password !== this.state.konfirm_password) {
    		swal("Error", "Password dan konfirm password Anda tidak sama", "error")
    		return
    	}
    	let self = this
    	let session_id = this.props.session
    	let body = {
            "session_id" : session_id,
            "code" : this.state.kode,
            "name": this.state.nama,
            "password" : this.state.password
        }
        self.setState({flag_processing_add: true})
        request.post(apis.add_new_workstation)
            .set("Content-Type", "application/json")
            .send(body)
            .end(function(err, res) {
                if (err || !res.ok) {
                    util.log("add_workstation", err)
                } else {
                    util.log("add_workstation", res);
                    if (res.statusCode == 200) {
                        let payload = res.body
                        if (payload.status == "OK") {
                            if (payload.results) {
                                let results = payload.results
                                swal('Sukses', payload.display_message, 'success')
                                var joined = self.state.data_workstation
                                var new_data = [payload.results].concat(joined)
                                self.setState({kode:"", nama:"", password:"", konfirm_password:"", data_workstation: new_data})
                            }
                        } else {
                            self.on_error(payload.error)
                        }
                    }
                }
                self.setState({flag_processing_add: false})
            })
    }

    on_btn_ubah_status(e, row) {
    	let self = this
    	const target = e.target
        swal({
            title: "Konfirmasi",
            text: "Apakah Anda yakin untuk mengubah status operator ini?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((sure) => {
            if (sure) {
                let new_status = row.active ? false : true
                let body = {
                    "session_id" : this.props.session,
                    "code" : row.workstation_code,
                    "active" : new_status
                }
                let url = apis.change_status_workstation
		        target.classList.add('m-loader', 'm-loader--light', 'm-loader--right')
                request.post(url)
                    .set("Content-Type", "application/json")
                    .send(body)
                    .end(function(err, res) {
                        if (err || !res.ok) {
                            util.log("change_status", err)
                        } else {
                            util.log("change_status", res);
                            if (res.statusCode == 200) {
                                let payload = res.body
                                if (payload.status == "OK") {
                                    if (payload.results) {
                                        if (payload.results.active === new_status) {
                                            let data = self.state.data_workstation
                                            let new_data = []
                                            data.map(function(item, i) {
                                            	if (item.workstation_code === row.workstation_code) {
                                            		item.active = new_status
                                            	}
                                            	new_data.push(item)
                                            })
                                            self.setState({data_workstation: new_data})
                                        } else {
                                            swal('Error', 'Gagal ubah status', 'error')
                                        }
                                    }
                                } else {
                                    swal("Ups!", payload.error.message, "warning")
                                }
                            } else {
                                swal("Error!", res.statusCode, "warning")
                            }
                        }
                        target.classList.remove('m-loader', 'm-loader--light', 'm-loader--right')
                    })
            } else {
                // no-op
            }
        })
    }

    on_btn_ubah_password(e, row) {
    	row_selected = row
    	this.setState({show_dialog_change_password: true})
    }

    on_error(err) {
        util.on_error(err)
        if (err.code === 2007) {
            this.setState({show_more_btn: false})
        } else {
            let msg = "Error: " + err.code
            if (err.message !== undefined) {
                msg = err.message
            }
            swal("Ups!", msg, "warning")
        }
    }

    on_show_hide_password(e) {
        util.log("on_show_hide_password", e)
        if (this.state.type_input_password === 'password') {
            this.setState({type_input_password: 'text'})
        } else {
            this.setState({type_input_password: 'password'})
        }
    }

    render() {
    	let self = this

    	const RowNumber = (cell, row, i) =>
            <div>{i + 1}</div>

        const RowStatus = (cell, row, i) =>
            <div>{row.active ? "AKTIF" : "TIDAK AKTIF"}</div>

        const RowAksi = (cell, row, i) =>
            <div>
            	<button type="button" className="btn btn-success" onClick={(e) => this.on_btn_ubah_status(e, row)} style={{paddingTop:'5px',paddingBottom:'5px',marginTop:'-1px',fontSize: '0.9rem'}}>
                    Ubah Status
                </button>
                &nbsp;&nbsp;
                <button type="button" className="btn btn-secondary" onClick={(e) => this.on_btn_ubah_password(e, row)} style={{paddingTop:'5px',paddingBottom:'5px',marginTop:'-1px',fontSize: '0.9rem'}}>
                    Ubah Password
                </button>
            </div>

    	const columns = [{
            dataField: 'id',
            text: 'No.',
            formatter: RowNumber,
            headerAlign: 'center',
            align: 'center',
            style: {width: '3%'}
        },
        {
            dataField: 'workstation_code',
            text: 'Kode',
            headerAlign: 'left',
            align: 'left',
            style: {width: '15%'}
        },
        {
            dataField: 'workstation_name',
            text: 'Nama',
            headerAlign: 'left',
            align: 'left',
            style: {width: '20%'}
        },
        {
            dataField: 'status',
            text: 'Status',
            formatter: RowStatus,
            headerAlign: 'left',
            align: 'left',
            style: {width: '15%'}
        },
        {
            dataField: 'action',
            text: 'Aksi',
            formatter: RowAksi,
            headerAlign: 'center',
            align: 'center',
            style: {width: '35%'}
        }]

    	const TableWorkstation = (props) =>
            <BootstrapTable 
                keyField='item_id'
                data={this.state.data_workstation} striped hover bordered={true} 
                options={this.options} 
                noDataIndication="Tidak ada operator" 
                columns={columns}/>

    	return (
            <div className="row">
                <div className="col-md-12">
                    <Portlet>
                        <div className="m-portlet__head">
                            <div className="m-portlet__head-caption">
                                <div className="m-portlet__head-title">
                                	<h3 className="m-portlet__head-text">
		                                Operator
		                                <button onClick={(e) => this.on_btn_show_register(e)} className='btn btn-secondary m-btn m-btn--icon' style={{marginLeft: '10px',fontSize: 'small',paddingTop:'7px',paddingBottom:'7px'}}>
                                            <span>
                                                <i className={this.state.show_add_form ? "la la-minus" : "la la-plus"}></i>
                                                <span>Register</span>
                                            </span>
                                        </button>
		                            </h3>
                                </div>
                            </div>
                        </div>
                        
                    	{this.state.show_add_form ?
                    		<div className="m-portlet__body" style={{paddingBottom:0}}>
	                            <div style={{borderBottom:'1px dashed rgb(235, 237, 242)',paddingBottom:'5px'}}>
	                                <div className="m-form__group row" style={{marginBottom: '10px'}}>
	                                    <label className="col-lg-2" style={{fontWeight: 600, textAlign: 'right',color: '#575962',margin: 'auto'}}>
	                                        Kode:
	                                    </label>
	                                    <div className="col-lg-10">
	                                        <input onChange={(e) => this.setState({kode: e.target.value})}
	                                            type="text"
	                                            className="form-control m-input"
	                                            name="kode"
	                                            value={this.state.kode}
	                                            style={{padding: '5px'}}/>
	                                    </div>
	                                </div>
	                                <div className="m-form__group row" style={{marginBottom: '10px'}}>
	                                    <label className="col-lg-2" style={{fontWeight: 600, textAlign: 'right',color: '#575962',margin: 'auto'}}>
	                                        Nama:
	                                    </label>
	                                    <div className="col-lg-10">
	                                        <input onChange={(e) => this.setState({nama: e.target.value})}
	                                            type="text"
	                                            className="form-control m-input"
	                                            name="nama"
	                                            value={this.state.nama}
	                                            style={{padding: '5px'}}/>
	                                    </div>
	                                </div>
	                                <div className="m-form__group row" style={{marginBottom: '10px'}}>
	                                    <label className="col-lg-2" style={{fontWeight: 600, textAlign: 'right',color: '#575962',margin: 'auto'}}>
	                                        Password:
	                                    </label>
	                                    <div className="col-lg-10">
	                                        <div className="m-input-icon m-input-icon--right">
                                                <input className="form-control m-input" 
                                                name="password"
                                                type={this.state.type_input_password} 
                                                onChange={(e) => this.setState({password: e.target.value})}
                                                value={this.state.password}/>
                                                <span className="m-input-icon__icon m-input-icon__icon--right" style={{width: '4rem'}}>
                                                    <span onClick={(e) => this.on_show_hide_password(e)}>
                                                        <i className={this.state.type_input_password === 'password' ? 'la la-eye' : 'la la-eye-slash'}></i>
                                                    </span>
                                                </span>
                                            </div>
	                                    </div>
	                                </div>
	                                <div className="m-form__group row" style={{marginBottom: '10px'}}>
	                                    <label className="col-lg-2" style={{fontWeight: 600, textAlign: 'right',color: '#575962',margin: 'auto'}}>
	                                        Konfirm Password:
	                                    </label>
	                                    <div className="col-lg-10">
	                                        <div className="m-input-icon m-input-icon--right">
                                                <input className="form-control m-input" 
                                                name="konfirm_password"
                                                type={this.state.type_input_password} 
                                                onChange={(e) => this.setState({konfirm_password: e.target.value})}
                                                value={this.state.konfirm_password}/>
                                                <span className="m-input-icon__icon m-input-icon__icon--right" style={{width: '4rem'}}>
                                                    <span onClick={(e) => this.on_show_hide_password(e)}>
                                                        <i className={this.state.type_input_password === 'password' ? 'la la-eye' : 'la la-eye-slash'}></i>
                                                    </span>
                                                </span>
                                            </div>
	                                    </div>
	                                </div>
	                                <button onClick={(e) => this.on_btn_add(e)} className={this.state.flag_processing_add ? 'btn btn-primary m-btn--bolder m-loader m-loader--secondary m-loader--right' : 'btn btn-primary m-btn  m-btn--bolder'} style={{fontSize: 'small',paddingTop:'7px',paddingBottom:'7px',display:'flex',marginLeft:'auto',marginBottom:'10px',marginTop:'15px'}}>Submit Registrasi</button>  
	                            </div>
                            </div>
                        : ''}

                        <div className="m-portlet__body" style={{}}>
                        	<div className="m-form__group row" style={{paddingBottom:'17px',marginLeft:'0'}}>
	                            <div className="col-lg-5" style={{padding: 0}}>
	                                <input onChange={(e) => this.setState({search_input: e.target.value})}
	                                    type="text"
	                                    className="form-control m-input"
	                                    name="search_input"
	                                    value={this.state.search_input}
	                                    style={{padding: '7px'}}/>
	                            </div>
	                            <div className="col-lg-7" style={{paddingRight: 0}}>
	                                <button onClick={(e) => this.on_btn_search(e)} className={this.state.flag_processing ? 'btn btn-primary m-btn m-btn--bolder m-loader m-loader--secondary m-loader--right' : 'btn btn-primary m-btn  m-btn--bolder'} style={{fontSize:'small',paddingTop:'7px',paddingBottom:'7px'}}>Cari</button>
	                                &nbsp;&nbsp;
	                                <button onClick={(e) => this.on_btn_reset(e)} className='btn btn-secondary m-btn  m-btn--bolder' style={{fontSize:'small',paddingTop:'7px',paddingBottom:'7px'}}>Reset</button>
	                            </div>
	                        </div>
                            <FormGroup>
                                <label style={{fontWeight:600}}>Daftar Operator</label>
                                <TableWorkstation/>
                                {this.state.show_more_btn ? <button type="button" style={{width: '-webkit-fill-available'}} onClick={this.on_btn_load_more} className={this.state.flag_processing ? 'btn btn-secondary m-loader m-loader--primary m-loader--right' : 'btn btn-secondary'}>
                                    Load More
                                </button> : ''}
                            </FormGroup>
                        </div>
                    </Portlet>
                </div>
                {this.state.show_dialog_change_password ? <ModalChangePassword data={row_selected} on_close={()=>{
                    self.setState({show_dialog_change_password: false})
                }} /> : ''}
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    update_current_page:  val => dispatch(do_action(action.ACTION_UPDATE_CURRENT_PAGE, val))
})

const mapStateToProps = (state) => ({
    session : state.Session,
    profile: state.Profile
})

export default connect(mapStateToProps, mapDispatchToProps)(AgentWorkstation)