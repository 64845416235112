import { connect } from 'react-redux'
import React, { Component } from 'react'
import moment from 'moment'
import request from 'superagent'
import { history } from '../helper/history'

// Component
import MetronicIndex from '../layout/metronic/index'
import { Form, FormGroup } from '../component/form/form'
import { Portlet, PortletHead, PortletBody, PortletFooter, PortletTab } from '../component/widget/portlet'
import BootstrapTable from 'react-bootstrap-table-next'
import { DatePicker, DatePickerInput } from 'rc-datepicker';
import 'rc-datepicker/lib/style.css';

// Helper
import apis from '../helper/api'
import localdata from '../helper/localdata'
import action from '../helper/action'
import do_action from '../action'
import config from '../helper/config'
import swal from 'sweetalert'
import Modal from 'react-responsive-modal'
import util from '../helper/util'
import ReactToPrint from "react-to-print"
import GeneralExportExcel from '../component/widget/GeneralExportExcel';

let next_load_url = ""

class AgentHistoryDeposit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data_history_tx: [],
            start_date: "",
            end_date: "",
            tx_flag_processing: false,
            tx_flag_cari_processing: false,
            show_more_btn: false,
            scroll_interval_id: 0,
            expanded: [],
            is_excel_generated: false
        }
        this.options = {
            onPageChange: this.onPageChange.bind(this),
            hideSizePerPage: true
        };
        this.start_date_change = this.start_date_change.bind(this)
        this.end_date_change = this.end_date_change.bind(this)
        this.on_btn_load_more = this.on_btn_load_more.bind(this) 
    }

    componentDidMount() {
        this.scroll_to_top()
        this.props.update_current_page('riwayat-deposit')
        let url = apis.list_history_bal + "/1"
        this.load_data(url, false)
    }

    load_data(url, is_append) {
        let self = this
        let body = {
            "session_id" : this.props.session,
            "date_begin" : self.state.start_date,
            "date_end": self.state.end_date
        }
        if (is_append) {
            this.setState({tx_flag_processing: true})
        } else {
            self.setState({tx_flag_cari_processing: true})
        }
        request.post(url)
            .set("Content-Type", "application/json")
            .send(body)
            .end(function(err, res) {
                if (err || !res.ok) {
                    util.log("get_tx", err)
                } else {
                    util.log("get_tx", res);
                    if (res.statusCode == 200) {
                        let payload = res.body
                        if (payload.status == "OK") {
                            if (payload.results) {
                                let results = payload.results
                                if (results.list !== undefined) {
                                    let items = []
                                    results.list.map(function(item, i) {
                                        let it = {
                                            "id" : item.id,
                                            "tx_type" : item.trx_type,
                                            "tx_code" : item.trx_code,
                                            "no_pelanggan" : item.trx_bill_number,
                                            "amount" : "Rp. " + util.rupiah_format(item.amount),
                                            "description" : item.description,
                                            "bal_before" : "Rp. " + util.rupiah_format(item.balance_value_before),
                                            "bal_after" : "Rp. " + util.rupiah_format(item.balance_value_after),
                                            "tx_date" : util.date_time_local(item.trx_datetime_utc),
                                            "note" : item.info_printable !== null ? item.info_printable : '-'
                                        }
                                        items.push(it)
                                    })
                                    if (items.length === 0 && !is_append) {
                                        swal("Info", "Data riwayat belum ada", "warning")
                                    } else {
                                        if (!is_append) {
                                            self.setState({data_history_tx: items})
                                        } else {
                                            var joined = self.state.data_history_tx.concat(items);
                                            self.setState({data_history_tx: joined})
                                        }
                                    }
                                }
                                if (results.next_page !== null) {
                                    next_load_url = results.next_page.url
                                    self.setState({show_more_btn: true})
                                } else {
                                    next_load_url = ""
                                    self.setState({show_more_btn: false})
                                }
                            }
                        } else {
                            self.on_error(payload.error)
                        }
                    }
                }
                self.setState({tx_flag_processing: false, tx_flag_cari_processing: false})
            })
    }

    on_error(err) {
        util.on_error(err)
        if (err.code === 2007) {
            this.setState({show_more_btn: false})
        } else {
            let msg = "Error: " + err.code
            if (err.message !== undefined) {
                msg = err.message
            }
            swal("Ups!", msg, "warning")
        }
    }

    scroll_step() {
        if (window.pageYOffset === 0) {
            clearInterval(this.state.scroll_interval_id);
        }
        window.scroll(0, window.pageYOffset - 50);
    }

    scroll_to_top() {
        let scroll_interval_id = setInterval(this.scroll_step.bind(this), 16.66);
        this.setState({scroll_interval_id: scroll_interval_id});
    }

    on_btn_load_more() {
        if (this.state.tx_flag_processing) return
        if (next_load_url.length !== 0) {
            this.load_data(next_load_url, true)
        }
    }

    on_btn_reset() {
        this.setState({start_date : "", end_date : "", show_more_btn: false})
        this.load_data(apis.list_history_bal + "/1", false)
    }

    on_btn_cari() {
        if (this.state.start_date.length === 0) {
            swal("Info", "Pilih tanggal awal lebih dulu", "warning")
            return
        }
        if (this.state.end_date.length === 0) {
            swal("Info", "Pilih tanggal akhir lebih dulu", "warning")
            return
        }
        let d_start = new Date(this.state.start_date)
        let d_end = new Date(this.state.end_date)
        if (d_start > d_end) {
            swal("Info", "Tidak dapat diproses, tanggal awal lebih besar dari tanggal akhir", "warning")
            return
        }
        
        let self = this
        let body = {
            "session_id" : self.props.session,
            "date_begin" : self.state.start_date,
            "date_end": self.state.end_date
        }
        self.setState({tx_flag_cari_processing: true, data_history_tx: []})
        request.post(apis.list_history_bal)
            .set("Content-Type", "application/json")
            .send(body)
            .end(function(err, res) {
                let message_error = ""
                if (err || !res.ok) {
                    util.log(err)
                    message_error = {"body": err.message, "title": "Transaksi Gagal"}
                    if (res !== undefined) {
                        message_error.body = "Error code " + res.statusCode
                    }
                } else {
                    util.log(res);
                    if (res.statusCode == 200) {
                        let payload = res.body
                        util.log(payload)
                        if (payload.status == "OK") {
                            let results = payload.results
                            if (results.list !== undefined) {
                                let items = []
                                results.list.map(function(item, i) {
                                    let it = {
                                        "id" : item.id,
                                        "tx_type" : item.trx_type,
                                        "tx_code" : item.trx_code,
                                        "no_pelanggan" : item.trx_bill_number,
                                        "amount" : "Rp. " + util.rupiah_format(item.amount),
                                        "description" : item.description,
                                        "bal_before" : "Rp. " + util.rupiah_format(item.balance_value_before),
                                        "bal_after" : "Rp. " + util.rupiah_format(item.balance_value_after),
                                        "tx_date" : util.date_time_local(item.trx_datetime_utc),
                                        "note" : item.info_printable !== null ? item.info_printable : '-'
                                    }
                                    items.push(it)
                                })
                                self.setState({data_history_tx: items})
                            }
                            if (results.next_page !== null) {
                                next_load_url = results.next_page.url
                                self.setState({show_more_btn: true})
                            } else {
                                next_load_url = ""
                                self.setState({show_more_btn: false})
                            }
                        } else {
                            message_error = {"body": res.body.error.message, "title": "Transaksi Gagal"}
                            if (res.body.display_message !== undefined) {
                                message_error.title = res.body.error.message
                                message_error.body = res.body.display_message
                            }
                            self.setState({show_more_btn: false})
                        }
                    } else {
                        message_error = {"body": "HTTP error " + res.statusCode, "title": "Transaksi Gagal"}
                    }
                }
                if (message_error.length != 0) {
                    swal(message_error.title, message_error.body, "warning")
                }
                self.setState({tx_flag_cari_processing: false})
            });
    }

    onPageChange(page, sizePerPage) {
        alert(`page: ${page}, sizePerPage: ${sizePerPage}`);
    }

    start_date_change(date) {
        try {
            let onlyDate = ''
            if (date.length !== 0) {
                let bulan = (date.getMonth() + 1).toString()
                let hari = date.getDate().toString()
                let m = bulan.length === 1 ? '0' + bulan : bulan
                let h = hari.length === 1 ? '0' + hari : hari
                onlyDate = date.getFullYear() + "-" + m + "-" + h + " " + "00:00:00"
            }
            this.setState({start_date : onlyDate})
        } catch(err) {
            util.log("[start_date_change] error", err)
        }
    }

    end_date_change(date) {
        try {
            let onlyDate = ''
            if (date.length !== 0) {
                let bulan = (date.getMonth() + 1).toString()
                let hari = date.getDate().toString()
                let m = bulan.length === 1 ? '0' + bulan : bulan
                let h = hari.length === 1 ? '0' + hari : hari
                onlyDate = date.getFullYear() + "-" + m + "-" + h + " " + "23:59:59"
            }
            this.setState({end_date : onlyDate})
        } catch(err) {
            util.log("[end_date_change] error", err)
        }
    }

    on_filter_label(i) {
        if (i === 1) {
            this.start_date_change(moment().toDate())
            this.end_date_change(moment().toDate())
        } else if (i === 2) {
            this.start_date_change(moment().subtract(1, 'days').toDate())
            this.end_date_change(moment().toDate())
        } else if (i === 3) {
            this.start_date_change(moment().subtract(7, 'days').toDate())
            this.end_date_change(moment().toDate())
        } else if (i === 4) {
            this.start_date_change(moment().subtract(30, 'days').toDate())
            this.end_date_change(moment().toDate())
        }
    }

    handleOnExpand = (row, isExpand, rowIndex, e) => {
        if (isExpand) {
            this.setState(() => ({expanded: [...this.state.expanded, row.id]}));
        } else {
            this.setState(() => ({expanded: this.state.expanded.filter(x => x !== row.id)}));
        }
    }

    handleAfterPrint = () => {
    }

    handleBeforePrint = () => {
    }

    renderContent = () => {
        return this.componentRef
    }

    renderTrigger = () => {
        return <button className="m-portlet__nav-link btn btn-light m-btn m-btn--pill m-btn--air" style={{marginLeft:'auto', fontSize:'small'}}>Cetak</button>
    }

    setRef = (ref) => {
        this.componentRef = ref
    }

    gen_export_excel() {
        this.setState({is_excel_generated: true});
    }

    reset_export_excel() {
        this.setState({is_excel_generated: false});
    }

    render() {
        const RowNumber = (cell, row, i) =>
            <div>{i + 1}</div>
        
        const TxColumn = (cell, row) =>
            <div style={{overflow: 'hidden',width: 250+'px',textOverflow: 'ellipsis',whiteSpace: 'nowrap'}}>
                <span className="m-badge m-badge--metal m-badge--wide m-badge--rounded">{row.tx_type}</span>
                &nbsp;&nbsp;{row.tx_code.toUpperCase()}
            </div>

        const PelangganColumn = (cell, row) =>
            <div>
                <span>{row.description} {row.amount}</span>
            </div>

        const expandRow = {
            renderer: (row) => (
                <div className="row">
                    <div className="col-lg-8" style={{textAlign:'center'}}>
                        <span>TX {row.tx_code.toUpperCase()}</span><br/>
                        {row.note.split('|').map(function(item, key) {
                            return (
                                <span key={key}>
                                    {item}
                                    <br/>
                                </span>
                            )
                        })}
                    </div>
                </div>
            ),
            showExpandColumn: true,
            expanded: this.state.expanded,
            onExpand: this.handleOnExpand,
            expandHeaderColumnRenderer: ({isAnyExpands}) => {
                if (isAnyExpands) {
                    return <b>-</b>;
                }
                return <b>+</b>;
            },
            expandColumnRenderer: ({expanded}) => {
                if (expanded) {
                    return (<b>-</b>)
                }
                return (<b>+</b>)
            }
        }

        const columns = [{
            dataField: 'id',
            text: 'No.',
            formatter: RowNumber,
            headerAlign: 'center',
            align: 'center',
            style: {width: '2%'}
        },
        {
            dataField: 'tx_col',
            text: 'Transaksi',
            formatter: TxColumn,
            headerAlign: 'center',
            align: 'left',
            style: {width: '15%'}
        },
        {
            dataField: 'pelanggan_col',
            text: 'Deskripsi',
            formatter: PelangganColumn,
            headerAlign: 'center',
            align: 'left',
            style: {width: '25%'}
        },
        {
            dataField: 'bal_before',
            text: 'Saldo Sebelum',
            headerAlign: 'center',
            align: 'right',
            style: {width: '15%'}
        },
        {
            dataField: 'bal_after',
            text: 'Saldo Sesudah',
            headerAlign: 'center',
            align: 'right',
            style: {width: '15%'}
        },
        {
            dataField: 'tx_date',
            text: 'Waktu',
            headerAlign: 'center',
            align: 'center',
            style: {width: '15%'}
        }]

        const TableTx = (props) =>
            <BootstrapTable 
                keyField='id'
                data={this.state.data_history_tx} striped hover bordered={true} 
                options={this.options} 
                noDataIndication="Tidak ada data riwayat" 
                columns={columns}
                expandRow={expandRow}
                ref={this.setRef}/>

        return (
            <div className="row">
                <div className="col-md-12">
                    <div className="m-portlet m-portlet--tab" style={{minHeight: '-webkit-fill-available'}}>
                        <PortletHead headTxt={"Deposit"} />
                        <PortletBody>
                            <FormGroup>
                                <label style={{fontWeight: 600}}>
                                    Filter Data:
                                </label>
                                <div style={{display:'inline'}}>
                                    <span onClick={() => this.on_filter_label(1)} style={{marginLeft: 10+'px',marginRight: 10+'px', cursor: 'pointer'}}>Hari Ini</span>
                                    /
                                    <span onClick={() => this.on_filter_label(2)} style={{marginLeft: 10+'px',marginRight: 10+'px', cursor: 'pointer'}}>Kemaren</span>
                                    /
                                    <span onClick={() => this.on_filter_label(3)} style={{marginLeft: 10+'px',marginRight: 10+'px', cursor: 'pointer'}}>7 Hari Terakhir</span>
                                    /
                                    <span onClick={() => this.on_filter_label(4)} style={{marginLeft: 10+'px',marginRight: 10+'px',    cursor: 'pointer'}}>30 Hari Terakhir</span>
                                </div>
                                <div className="input-group">
                                    <DatePickerInput
                                        onChange={this.start_date_change}
                                        value={this.state.start_date}/>
                                    <span style={{padding: 7+'px'}}>-</span>
                                    <DatePickerInput
                                        onChange={this.end_date_change}
                                        value={this.state.end_date}/>
                                    &nbsp;&nbsp;&nbsp;
                                    <button type="button" className={this.state.tx_flag_cari_processing ? 'btn btn-secondary btn-sm m-btn m-btn--custom m-loader m-loader--primary m-loader--right' : 'btn btn-secondary btn-sm m-btn m-btn--custom'}  
                                    onClick={() => this.on_btn_cari()}>
                                        Cari
                                    </button>
                                    &nbsp;&nbsp;&nbsp;
                                    <button type="button" className="btn btn-secondary btn-sm m-btn m-btn--custom" 
                                    onClick={() => this.on_btn_reset()}>
                                        Reset
                                    </button>
                                    &nbsp;&nbsp;
                                    <ReactToPrint
                                        trigger={this.renderTrigger}
                                        content={this.renderContent}
                                        onBeforePrint={this.handleBeforePrint}
                                        onAfterPrint={this.handleAfterPrint}
                                        pageStyle="@page{size: auto A4 portrait;}"/>
                                    &nbsp;
                                    {this.state.is_excel_generated ? 
                                        <React.Fragment>
                                            <GeneralExportExcel data={this.state.data_history_tx} 
                                                type="history_deposit" 
                                                agent_name={this.props.profile.name}
                                                filename="history-deposit" 
                                                title="Riwayat Deposit"/>
                                            &nbsp;&nbsp;
                                            <button className='m-portlet__nav-link btn btn-light m-btn m-btn--pill m-btn--air' 
                                                onClick={() => this.reset_export_excel()} style={{fontSize:'small'}}>
                                                Reset Excel
                                            </button>
                                        </React.Fragment>
                                    : <button className='m-portlet__nav-link btn btn-light m-btn m-btn--pill m-btn--air' 
                                        onClick={() => this.gen_export_excel()} style={{fontSize:'small'}}>
                                        Export ke Excel
                                    </button>}
                                </div>
                            </FormGroup>
                            <br/>
                            <TableTx/>
                            {this.state.show_more_btn ? <button type="button" style={{width: '-webkit-fill-available'}} onClick={this.on_btn_load_more} className={this.state.tx_flag_processing ? 'btn btn-secondary m-loader m-loader--primary m-loader--right' : 'btn btn-secondary'}>
                                Load More
                            </button> : ''}
                        </PortletBody>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    update_current_page:  val => dispatch(do_action(action.ACTION_UPDATE_CURRENT_PAGE, val))
})

const mapStateToProps = (state) => ({
    session : state.Session,
    profile: state.Profile
})

export default connect(mapStateToProps, mapDispatchToProps)(AgentHistoryDeposit)