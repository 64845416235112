import React, {Component, Suspense} from 'react'
import './App.css';
import {Router, Route, Switch} from 'react-router-dom'

// import LoginRegister from './page/login_register'
import HomeAgent from './page/home_agent'
// import Page404 from './page/page_404'

import {history} from './helper/history'
// import keymap from './helper/keymap'
// import { ShortcutManager } from 'react-shortcuts'
// import PropTypes from 'prop-types'

const LoginRegister = React.lazy(() => {return import("./page/login_register");});
// const HomeAgent = React.lazy(() => {return import("./page/home_agent");});
const Page404 = React.lazy(() => {return import("./page/page_404");});

// class LazyLoading extends Component {
//   render() {
//     return (<img style={{margin:'auto'}} src="../swipe/img/loading.gif" alt="Loading page ..."/>);
//   }
// }

// const shortcutManager = new ShortcutManager(keymap)

class App extends Component {

  // getChildContext() {
  //   return {shortcuts: shortcutManager}
  // }

  render() {
    return (
      <Suspense fallback={<div id="posfin-loading" style={{margin:'auto'}}><img src="../swipe/img/loading.gif" alt="Loading page"/></div>}>
        <Router history={history}>
          <Switch>
            <Route exact path="/login" render={props => <LoginRegister {...props} />} />
            <Route exact path="/agen" render={props => <HomeAgent {...props} />} />
            <Route path="*" render={props => <Page404 {...props} />} />
          </Switch>
        </Router>
      </Suspense>
    );
  }
}

// App.childContextTypes = {
//   shortcuts: PropTypes.object.isRequired
// }


export default App;