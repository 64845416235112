import {connect} from 'react-redux'
import React, {Component} from 'react'
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  withRouter
} from "react-router-dom"
import config from '../../helper/config'
import util from '../../helper/util';
import DonasiIcon from '../../component/donasi-white.svg';
import PosAsuransiIcon from '../../component/pos-asuransi-white.svg';
import localdata from '../../helper/localdata';

const nav = {
    "home" : {title:"Dashboard", nav:[{"title" : "Beranda"}]},
    "pulsa": {title:"Pembelian", nav:[{"title" : "Beranda"}, {"title" : "Pulsa Prabayar"}]},
    "data": {title:"Pembelian", nav:[{"title" : "Beranda"}, {"title" : "Beli Data"}]},
    "kredit": {title:"Pembayaran", nav:[{"title" : "Beranda"}, {"title" : "Cicilan"}]},
    "pln": {title:"Pembayaran", nav:[{"title" : "Beranda"}, {"title" : "PLN"}]},
    "pdam": {title:"Pembayaran", nav:[{"title" : "Beranda"}, {"title" : "PDAM"}]},
    "bpjs": {title:"Pembayaran", nav:[{"title" : "Beranda"}, {"title" : "BPJS"}]},
    "hp_pascabayar": {title:"Pembayaran", nav:[{"title" : "Beranda"}, {"title" : "Pascabayar"}]},
    "tv": {title:"Pembayaran", nav:[{"title" : "Beranda"}, {"title" : "TV"}]},
    "pajak": {title:"Pembayaran", nav:[{"title" : "Beranda"}, {"title" : "Pajak"}]},
    "history_tx": {title:"Riwayat / History", nav:[{"title" : "Beranda"}, {"title" : "Riwayat Transaksi"}]},
    "history_fee": {title:"Riwayat / History", nav:[{"title" : "Beranda"}, {"title" : "Riwayat Komisi"}]},
    "history_bonus": {title:"Riwayat / History", nav:[{"title" : "Beranda"}, {"title" : "Riwayat Bonus"}]},
    "history_deposit": {title:"Riwayat / History", nav:[{"title" : "Beranda"}, {"title" : "Riwayat Deposit"}]},
    "ecommerce": {title:"Pembayaran", nav:[{"title" : "Beranda"}, {"title" : "E-Commerce"}]},
    "telkom": {title:"Pembayaran", nav:[{"title" : "Beranda"}, {"title" : "Telkom Pay"}]},
    "asuransi": {title:"Pembayaran", nav:[{"title" : "Beranda"}, {"title" : "Asuransi"}]},
    "tiket": {title:"Pembayaran", nav:[{"title" : "Beranda"}, {"title" : "Bayar Tiket"}]},
    "voucher": {title:"Pembelian", nav:[{"title" : "Beranda"}, {"title" : "E-Money"}]},
    "gas": {title:"Pembayaran", nav:[{"title" : "Beranda"}, {"title" : "Bayar Gas"}]},
    "batch_transaction": {title:"Batch Transaksi", nav:[{"title" : "Beranda"}, {"title" : "Batch Transaksi"}]},
    "batch_transaction2": {title:"Batch Transaksi 2", nav:[{"title" : "Beranda"}, {"title" : "Batch Transaksi 2"}]},
    "donasi": {title:"Pembayaran", nav:[{"title" : "Beranda"}, {"title" : "Donasi"}]},
    "posting": {title:"MLO", nav:[{"title" : "Beranda"}, {"title" : "MLO"}, {"title" : "Posting"}]},
    "handover": {title:"MLO", nav:[{"title" : "Beranda"}, {"title" : "MLO"}, {"title" : "Handover"}]},
    "kurir": {title:"Kurir", nav:[{"title" : "Beranda"}, {"title" : "MLO"}, {"title" : "Handover"}]},
    "cashin": {title:"Tarik Tunai & Transfer", nav:[{"title" : "Beranda"}, {"title" : "Platinum Agen"}, {"title" : "Tarik Tunai & Transfer"}]},
    "remitansi": {title:"Remitansi", nav:[{"title" : "Beranda"}, {"title" : "MLO"}, {"title" : "Handover"}]},
    "mpn": {title:"MPN G3", nav:[{"title" : "Beranda"}, {"title" : "MLO"}, {"title" : "Handover"}]}
};

class Sidebar extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            is_premium: false,
            is_taxmpn: false
        };
        this.is_premium_account();
        this.is_taxmpn();
    }

    open_heksa() {
        window.open(config.heksa_url);
    }
    open_ematerai() {
        window.open(config.emeterai_url);
    }
    open_edc() {
        window.open(config.edc_url);
    }

    is_premium_account() {
        let that = this;
        localdata.get_login_result().then((payload) => {
            util.log("is_premium_account", payload.results.profile.platinum);
            that.setState({is_premium: payload.results.profile.platinum});
        });
    }

    is_taxmpn() {
        let that = this;
        localdata.get_login_result().then((payload) => {
            util.log("allow_tax_mpn", payload.results.profile.allow_tax_mpn);
            that.setState({is_taxmpn: payload.results.profile.allow_tax_mpn});
        });
    }

    render() {
        let uid = this.props.profile ? (this.props.profile.masterid ? this.props.profile.username + " " + this.props.profile.masterid : this.props.profile.username) : ""
        let body_contact_admin = '%0A%0A%0Auid: ' + uid + '%0Aug: ' + navigator.userAgent
        let is_indomarco_agent = this.props.profile && util.is_indomarco_agent(this.props.profile.masterid)
        
        return (
            <div id="m_aside_left" className="m-grid__item	m-aside-left  m-aside-left--skin-dark ">
                <div id="m_ver_menu" className="m-aside-menu  m-aside-menu--skin-dark m-aside-menu--submenu-skin-dark " data-menu-vertical="true"
                    data-menu-scrollable="false" data-menu-dropdown-timeout="500">
                    <ul className="m-menu__nav m-menu__nav--dropdown-submenu-arrow ">
                        
                        <li className={this.props.current_page==='dashboard'||this.props.current_page===null?'m-menu__item  m-menu__item--active':'m-menu__item'} aria-haspopup="true" >
                            <Link to="/agen/home" className="m-menu__link"
                                onClick={(e) => this.props.on_page_changed(e, nav.home)}>
                                <i className="m-menu__link-icon flaticon-squares-1"></i>
                                <span className="m-menu__link-title">
                                    <span className="m-menu__link-wrap">
                                        <span className="m-menu__link-text">
                                            Beranda
                                        </span>
                                    </span>
                                </span>
                            </Link>
                        </li>

                        {this.state.is_premium ?
                        <React.Fragment>
                            <li className="m-menu__section">
                                <h4 className="m-menu__section-text">
                                    Layanan Agen Platinum
                                </h4>
                                <i className="m-menu__section-icon flaticon-more-v3"></i>
                            </li>

                             <li className={this.props.current_page==='kurir'?'m-menu__item m-menu__item--active':'m-menu__item'} aria-haspopup="true" data-menu-submenu-toggle="hover">
                                <Link to="/agen/mlo-posting" 
                                    className="m-menu__link m-menu__toggle" 
                                    onClick={(e) => this.props.on_page_changed(e, nav.kurir)}>
                                    <i className="m-menu__link-icon">
                                        <img src={PosAsuransiIcon} style={{height:'22px'}} />
                                    </i>
                                    <span className="m-menu__link-text">
                                        Kurir & Logistik
                                    </span>
                                </Link>
                            </li>

                             <li className={this.props.current_page==='cashin'?'m-menu__item m-menu__item--active':'m-menu__item'} aria-haspopup="true" data-menu-submenu-toggle="hover">
                                <Link to="/agen/tunai-transfer" 
                                    className="m-menu__link m-menu__toggle" 
                                    onClick={(e) => this.props.on_page_changed(e, nav.cashin)}>
                                    <i className="m-menu__link-icon">
                                        <img src={PosAsuransiIcon} style={{height:'22px'}} />
                                    </i>
                                    <span className="m-menu__link-text">
                                    Tarik Tunai & Transfer
                                    </span>
                                </Link>
                            </li>

                             <li style={{display: 'none'}} className={this.props.current_page==='remitansi'?'m-menu__item m-menu__item--active':'m-menu__item'} aria-haspopup="true" data-menu-submenu-toggle="hover">
                                <Link to="/agen/remitansi" 
                                    className="m-menu__link m-menu__toggle" 
                                    onClick={(e) => this.props.on_page_changed(e, nav.remitansi)}>
                                    <i className="m-menu__link-icon">
                                        <img src={PosAsuransiIcon} style={{height:'22px'}} />
                                    </i>
                                    <span className="m-menu__link-text">
                                        Remitansi
                                    </span>
                                </Link>
                            </li>
                        </React.Fragment>
                        : ''}

                        <li className="m-menu__section">
                            <h4 className="m-menu__section-text">
                                Agen Menu
                            </h4>
                            <i className="m-menu__section-icon flaticon-more-v3"></i>
                        </li>

                        <li className={this.props.current_page==='pulsa-prepaid'?'m-menu__item  m-menu__item--active':'m-menu__item'} aria-haspopup="true">
                            <Link to="/agen/pulsa" 
                                className="m-menu__link m-menu__toggle" 
                                onClick={(e) => this.props.on_page_changed(e, nav.pulsa)}>
                                <i className="m-menu__link-icon flaticon-interface-4"></i>
                                <span className="m-menu__link-text">
                                    Pulsa Prabayar
                                </span>
                            </Link>
                        </li>

                        <li className={this.props.current_page==='pulsa-pascabayar'?'m-menu__item m-menu__item--active':'m-menu__item'} aria-haspopup="true">
                            <Link to="/agen/hp-pascabayar" 
                                className="m-menu__link m-menu__toggle" 
                                onClick={(e) => this.props.on_page_changed(e, nav.hp_pascabayar)}>
                                <i className="m-menu__link-icon flaticon-time-3"></i>
                                <span className="m-menu__link-text">
                                    Pulsa Pascabayar
                                </span>
                            </Link>
                        </li>

                        <li className={this.props.current_page==='paket-data'?'m-menu__item m-menu__item--active':'m-menu__item'} aria-haspopup="true" data-menu-submenu-toggle="hover">
                            <Link to="/agen/paket-data" 
                                className="m-menu__link m-menu__toggle" 
                                onClick={(e) => this.props.on_page_changed(e, nav.data)}>
                                <i className="m-menu__link-icon flaticon-interface-7"></i>
                                <span className="m-menu__link-text">
                                    Paket Data
                                </span>
                            </Link>
                        </li>

                        <li className={this.props.current_page==='pln'?'m-menu__item m-menu__item--active':'m-menu__item'} aria-haspopup="true" data-menu-submenu-toggle="hover">
                            <Link to="/agen/pln" 
                                className="m-menu__link m-menu__toggle" 
                                onClick={(e) => this.props.on_page_changed(e, nav.pln)}>
                                <i className="m-menu__link-icon flaticon-light"></i>
                                <span className="m-menu__link-text">
                                    Bayar Listrik
                                </span>
                            </Link>
                        </li>

                        <li className={this.props.current_page==='pdam'?'m-menu__item m-menu__item--active':'m-menu__item'} aria-haspopup="true" data-menu-submenu-toggle="hover">
                            <Link to="/agen/pdam" 
                                className="m-menu__link m-menu__toggle" 
                                onClick={(e) => this.props.on_page_changed(e, nav.pdam)}>
                                <i className="m-menu__link-icon flaticon-placeholder-2"></i>
                                <span className="m-menu__link-text">
                                    Bayar Air
                                </span>
                            </Link>
                        </li>

                        <li className={this.props.current_page==='tv'?'m-menu__item m-menu__item--active':'m-menu__item'} aria-haspopup="true" data-menu-submenu-toggle="hover">
                            <Link to="/agen/tv" 
                                className="m-menu__link m-menu__toggle" 
                                onClick={(e) => this.props.on_page_changed(e, nav.tv)}>
                                <i className="m-menu__link-icon flaticon-imac"></i>
                                <span className="m-menu__link-text">
                                    Bayar TV
                                </span>
                            </Link>
                        </li>

                        <li className={this.props.current_page==='kredit'?'m-menu__item m-menu__item--active':'m-menu__item'} aria-haspopup="true" data-menu-submenu-toggle="hover">
                            <Link to="/agen/kredit" 
                                className="m-menu__link m-menu__toggle" 
                                onClick={(e) => this.props.on_page_changed(e, nav.kredit)}>
                                <i className="m-menu__link-icon flaticon-list"></i>
                                <span className="m-menu__link-text">
                                    Cicilan / Kredit
                                </span>
                            </Link>
                        </li>

                        <li className={this.props.current_page==='bpjs'?'m-menu__item m-menu__item--active':'m-menu__item'} aria-haspopup="true" data-menu-submenu-toggle="hover">
                            <Link to="/agen/bpjs" 
                                className="m-menu__link m-menu__toggle" 
                                onClick={(e) => this.props.on_page_changed(e, nav.bpjs)}>
                                <i className="m-menu__link-icon flaticon-lifebuoy"></i>
                                <span className="m-menu__link-text">
                                    BPJS Kesehatan
                                </span>
                            </Link>
                        </li>

                        <li className={this.props.current_page==='pajak'?'m-menu__item m-menu__item--active':'m-menu__item'} aria-haspopup="true" data-menu-submenu-toggle="hover">
                            <Link to="/agen/pajak" 
                                className="m-menu__link m-menu__toggle" 
                                onClick={(e) => this.props.on_page_changed(e, nav.pajak)}>
                                <i className="m-menu__link-icon flaticon-time"></i>
                                <span className="m-menu__link-text">
                                    Pajak
                                </span>
                            </Link>
                        </li>

                        <li className={this.props.current_page==='ecommerce'?'m-menu__item m-menu__item--active':'m-menu__item'} aria-haspopup="true" data-menu-submenu-toggle="hover">
                            <Link to="/agen/ecommerce" 
                                className="m-menu__link m-menu__toggle" 
                                onClick={(e) => this.props.on_page_changed(e, nav.ecommerce)}>
                                <i className="m-menu__link-icon flaticon-business"></i>
                                <span className="m-menu__link-text">
                                    E-Commerce
                                </span>
                            </Link>
                        </li>

                        <li className={this.props.current_page==='telkom'?'m-menu__item m-menu__item--active':'m-menu__item'} aria-haspopup="true" data-menu-submenu-toggle="hover">
                            <Link to="/agen/telkom" 
                                className="m-menu__link m-menu__toggle" 
                                onClick={(e) => this.props.on_page_changed(e, nav.telkom)}>
                                <i className="m-menu__link-icon flaticon-technology"></i>
                                <span className="m-menu__link-text">
                                    Telkom Pay
                                </span>
                            </Link>
                        </li>

                        <li className={this.props.current_page==='asuransi'?'m-menu__item m-menu__item--active':'m-menu__item'} aria-haspopup="true" data-menu-submenu-toggle="hover">
                            <Link to="/agen/asuransi" 
                                className="m-menu__link m-menu__toggle" 
                                onClick={(e) => this.props.on_page_changed(e, nav.asuransi)}>
                                <i className="m-menu__link-icon flaticon-technology-1"></i>
                                <span className="m-menu__link-text">
                                    Bayar Asuransi
                                </span>
                            </Link>
                        </li>

                        <li className={this.props.current_page==='tiket'?'m-menu__item m-menu__item--active':'m-menu__item'} aria-haspopup="true" data-menu-submenu-toggle="hover">
                            <Link to="/agen/tiket" 
                                className="m-menu__link m-menu__toggle" 
                                onClick={(e) => this.props.on_page_changed(e, nav.tiket)}>
                                <i className="m-menu__link-icon flaticon-transport"></i>
                                <span className="m-menu__link-text">
                                    Bayar Tiket
                                </span>
                            </Link>
                        </li>

                        <li className={this.props.current_page==='voucher'?'m-menu__item m-menu__item--active':'m-menu__item'} aria-haspopup="true" data-menu-submenu-toggle="hover">
                            <Link to="/agen/voucher" 
                                className="m-menu__link m-menu__toggle" 
                                onClick={(e) => this.props.on_page_changed(e, nav.voucher)}>
                                <i className="m-menu__link-icon flaticon-car"></i>
                                <span className="m-menu__link-text">
                                    E-Money
                                </span>
                            </Link>
                        </li>

                        <li className={this.props.current_page==='gas'?'m-menu__item m-menu__item--active':'m-menu__item'} aria-haspopup="true" data-menu-submenu-toggle="hover">
                            <Link to="/agen/gas" 
                                className="m-menu__link m-menu__toggle" 
                                onClick={(e) => this.props.on_page_changed(e, nav.gas)}>
                                <i className="m-menu__link-icon flaticon-calendar-1"></i>
                                <span className="m-menu__link-text">
                                    Bayar Gas
                                </span>
                            </Link>
                        </li>

                        {/* <li className='m-menu__item' aria-haspopup="true" data-menu-submenu-toggle="hover">
                            <a className="m-menu__link m-menu__toggle" 
                                onClick={() => this.open_heksa()}>
                                <i className="m-menu__link-icon">
                                    <img src={PosAsuransiIcon} style={{height:'22px'}} />
                                </i>
                                <span className="m-menu__link-text">
                                    POS Assurance
                                </span>
                            </a>
                        </li>

                        <li className={this.props.current_page==='donasi'?'m-menu__item m-menu__item--active':'m-menu__item'} aria-haspopup="true" data-menu-submenu-toggle="hover">
                            <Link to="/agen/donasi" 
                                className="m-menu__link m-menu__toggle" 
                                onClick={(e) => this.props.on_page_changed(e, nav.donasi)}>
                                <i className="m-menu__link-icon">
                                    <img src={DonasiIcon} style={{height:'22px'}} />
                                </i>
                                <span className="m-menu__link-text">
                                    Donasi
                                </span>
                            </Link>
                        </li> */}
                        {/* {this.state.is_taxmpn ? 

                        <li className={this.props.current_page==='mpn'?'m-menu__item m-menu__item--active':'m-menu__item'} aria-haspopup="true" data-menu-submenu-toggle="hover">
                            <Link to="/agen/mpn" 
                                className="m-menu__link m-menu__toggle" 
                                onClick={(e) => this.props.on_page_changed(e, nav.mpn)}>
                                <i className="m-menu__link-icon flaticon-calendar-1"></i>
                                <span className="m-menu__link-text">
                                    MPN G3
                                </span>
                            </Link>
                        </li>
                        : ''}

                        <li className='m-menu__item' aria-haspopup="true" data-menu-submenu-toggle="hover">
                            <a className="m-menu__link m-menu__toggle" 
                                onClick={() => this.open_ematerai()}>
                                <i className="m-menu__link-icon  flaticon-notes">                                 
                                </i>
                                <span className="m-menu__link-text">
                                EMeterai
                                </span>
 
                            </a>
                        </li>
                        <li className='m-menu__item' aria-haspopup="true" data-menu-submenu-toggle="hover">
                            <a className="m-menu__link m-menu__toggle" 
                                onClick={() => this.open_edc()}>
                                <i className="m-menu__link-icon  flaticon-analytics"> 
                                </i>
                                <span className="m-menu__link-text">
                                Pendaftaran EDC
                                </span>
                            </a>
                        </li>  */}

                        <li className="m-menu__section">
                            <h4 className="m-menu__section-text">
                                Riwayat / History
                            </h4>
                            <i className="m-menu__section-icon flaticon-more-v3"></i>
                        </li>

                        {!is_indomarco_agent ?
                            <React.Fragment>
                            <li className={this.props.current_page==='riwayat-komisi'?'m-menu__item m-menu__item--active':'m-menu__item'} aria-haspopup="true" data-menu-submenu-toggle="hover">
                                <Link to="/agen/riwayat-fee" className="m-menu__link"
                                                onClick={(e) => this.props.on_page_changed(e, nav.history_fee)}>
                                    <i className="m-menu__link-icon flaticon-list-1"></i>
                                    <span className="m-menu__link-text">
                                        Komisi
                                    </span>
                                </Link>
                            </li>
                            <li className={this.props.current_page==='riwayat-bonus'?'m-menu__item m-menu__item--active':'m-menu__item'} aria-haspopup="true" data-menu-submenu-toggle="hover">
                                <Link to="/agen/riwayat-bonus" className="m-menu__link"
                                                onClick={(e) => this.props.on_page_changed(e, nav.history_bonus)}>
                                    <i className="m-menu__link-icon flaticon-calendar-1"></i>
                                    <span className="m-menu__link-text">
                                        Bonus
                                    </span>
                                </Link>
                            </li>
                            </React.Fragment>
                        : ''}
                        
                        <li className={this.props.current_page==='riwayat-transaksi'?'m-menu__item m-menu__item--active':'m-menu__item'} aria-haspopup="true" data-menu-submenu-toggle="hover">
                            <Link to="/agen/riwayat-transaksi" className="m-menu__link"
                                            onClick={(e) => this.props.on_page_changed(e, nav.history_tx)}>
                                <i className="m-menu__link-icon flaticon-layers"></i>
                                <span className="m-menu__link-text">
                                    Transaksi
                                </span>
                            </Link>
                        </li>

                        <li className={this.props.current_page==='riwayat-deposit'?'m-menu__item m-menu__item--active':'m-menu__item'} aria-haspopup="true" data-menu-submenu-toggle="hover">
                            <Link to="/agen/riwayat-deposit" className="m-menu__link"
                                            onClick={(e) => this.props.on_page_changed(e, nav.history_deposit)}>
                                <i className="m-menu__link-icon flaticon-web"></i>
                                <span className="m-menu__link-text">
                                    Deposit
                                </span>
                            </Link>
                        </li>

                        <li className="m-menu__section">
                            <h4 className="m-menu__section-text">
                                Tools
                            </h4>
                            <i className="m-menu__section-icon flaticon-more-v3"></i>
                        </li>

                        <li className={this.props.current_page==='kode_produk'?'m-menu__item m-menu__item--active':'m-menu__item'}  aria-haspopup="true" data-menu-submenu-toggle="hover">
                            <Link to="/agen/kode-produk" className="m-menu__link m-menu__toggle">
                                <i className="m-menu__link-icon flaticon-menu-button"></i>
                                <span className="m-menu__link-text">
                                    Kode Produk
                                </span>
                            </Link>
                        </li>

                        <li className={this.props.current_page==='batch_tx'?'m-menu__item m-menu__item--active':'m-menu__item'} aria-haspopup="true" data-menu-submenu-toggle="hover" style={{}}>
                            <Link to="/agen/batch-transaction" className="m-menu__link m-menu__toggle">
                                <i className="m-menu__link-icon flaticon-list-2"></i>
                                <span className="m-menu__link-text">
                                    Batch Transaksi
                                </span>
                            </Link>
                        </li>

                        <li className="m-menu__item" aria-haspopup="true" data-menu-submenu-toggle="hover" style={{display:'none'}}>
                            <Link to="/agen/batch-transaction2" className="m-menu__link m-menu__toggle">
                                <i className="m-menu__link-icon flaticon-list-2"></i>
                                <span className="m-menu__link-text">
                                    Batch Transaksi 2
                                </span>
                            </Link>
                        </li>

                        <li className="m-menu__section">
                            <h4 className="m-menu__section-text">
                                Support
                            </h4>
                            <i className="m-menu__section-icon flaticon-more-v3"></i>
                        </li>

                        {!is_indomarco_agent ?
                            <React.Fragment>
                            <li className="m-menu__item" aria-haspopup="true" data-menu-submenu-toggle="hover">
                                <a href={'mailto:' + config.helpdesk_email + '?body=' + body_contact_admin} target="_top" className="m-menu__link m-menu__toggle">
                                    <i className="m-menu__link-icon flaticon-computer"></i>
                                    <span className="m-menu__link-text">
                                        Hubungi Admin
                                    </span>
                                </a>
                            </li>
                            <li className="m-menu__item" aria-haspopup="true" data-menu-submenu-toggle="hover">
                                <a href="https://drive.google.com/drive/folders/1nJDvDnjkERhO8XKm0Fdhnf7POIk9-A7w?usp=sharing" target="_blank" className="m-menu__link m-menu__toggle">
                                    <i className="m-menu__link-icon flaticon-questions-circular-button"></i>
                                    <span className="m-menu__link-text">
                                        Dokumen Support
                                    </span>
                                </a>
                            </li>
                            </React.Fragment>
                        : ''}

                        <li className="m-menu__item" aria-haspopup="true">
                            <p className="m-menu__link" onClick={() => this.props.on_logout()}>
                                <i className="m-menu__link-icon flaticon-logout"></i>
                                <span className="m-menu__link-text">
                                    Logout
                                </span>
                            </p>
                        </li>

                    </ul>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    profile: state.Profile,
    current_page: state.CurrentPage
})

export default connect(mapStateToProps, null)(Sidebar)
