import actions from '../helper/action'
const balance = (state = null, action = null) => {
	if (action.type == actions.ACTION_UPDATE_USER_BALANCE) {
		if (state == null) {
			state = 0;
		}
		return action.payload
	}
	return state
}

export default balance