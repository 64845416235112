import {connect} from 'react-redux';
import React, {Component} from 'react';
import {history} from '../helper/history';
import moment from 'moment';
import request from 'superagent';

import MetronicIndex from '../layout/metronic/index';
import {Form, FormGroup} from '../component/form/form';
import {Portlet, PortletHead, PortletBody, PortletFooter} from '../component/widget/portlet';

import apis from '../helper/api';
import localdata from '../helper/localdata';
import config from '../helper/config';
import swal from 'sweetalert';
import action from '../helper/action';
import do_action from '../action';
import util from '../helper/util';
import {DatePicker, DatePickerInput} from 'rc-datepicker';
import cookie from 'react-cookies';

let photo_default = "../../swipe/assets/app/media/img/users/default.jpg";

class AgentProfile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            identitas: [{name:"KTP"},{name:"SIM"},{name:"PASSPORT"},{name:"OTHER"}],
            sel_identitas: "", identitas_nomer: "", nomor_npwp: "",
            sel_gender: "", i_tanggal_lahir: "", phone_number: "",
            name_full: "", email: "", fax: "", tempat_lahir: "", tanggal_lahir: "", pekerjaan: "",
            propinsi: "", kota: "", kecamatan: "", kelurahan: "", alamat: "", kode_pos: "", device_name: "",
            kota_nama: "", kecamatan_nama: "", kelurahan_nama: "",
            tx_flag_processing: false, photo_url: photo_default, identitas_filename: "", foto_identitas: "",
            tx_alert : "",
            message : "",
            list_propinsi : [],
            list_kota : [],
            list_kecamatan : [],
            list_kelurahan : [],
            identity_type: '',
            show_device_name: false
        }
        this.on_btn_update = this.on_btn_update.bind(this)
        this.birth_date_change = this.birth_date_change.bind(this)
        this.on_btn_change_device_name = this.on_btn_change_device_name.bind(this)
    }

    birth_date_change(date) {
        try {
            util.log(date)
            let onlyDate = ''
            if (date.length !== 0) {
                let bulan = (date.getMonth() + 1).toString()
                let hari = date.getDate().toString()
                let m = bulan.length === 1 ? '0' + bulan : bulan
                let h = hari.length === 1 ? '0' + hari : hari
                onlyDate = date.getFullYear() + "-" + m + "-" + h
            }
            util.log(onlyDate)
            this.setState({tanggal_lahir : onlyDate, i_tanggal_lahir : date});
        } catch(err) {
            util.log("[end_date_change] error", err)
        }
    }

    on_error(err) {
        if (err.code == 2006) {
            localdata.clear_data().then((res) => {
                history.replace('/login');
            }).catch((err) => {
                util.log("error: ", err)
            })
        } else {
            swal("Ups!", "Error: " + err.code, "warning")
        }
    }

    on_select_change(e) {
        let self = this
        util.log(e.target.id + " . " + e.target.value)
        if (e.target.id === 'propinsi-sel') {
            self.setState({list_kota: [], list_kecamatan: [], list_kelurahan: [], propinsi: e.target.value})
            if (e.target.value.length !== 0) {
                let url = "https://fintech-stg.bedigital.co.id/api/helper/geoname/" + e.target.value + "/city/list"
                request.get(url).end((err, res) => {
                    if (err) {
                        util.log("error: " + err);
                    } else {
                        util.log(res);
                        if (res.status == 200) {
                            const data = JSON.parse(res.text)
                            if (data.status == "OK") {
                                let list = data.results.list
                                let t = []
                                list.map(item =>
                                    t.push({'city_id' : item.city_id, 'city_name' : item.city_name})
                                )
                                self.setState({list_kota: t})
                            }
                        }
                    }
                })
            }
        } else if (e.target.id === 'kota-sel') {
            self.setState({list_kecamatan: [], list_kelurahan: [], kota: e.target.value})
            if (e.target.value.length !== 0) {
                let url = "https://fintech-stg.bedigital.co.id/api/helper/geoname/" + self.state.propinsi + "/" + e.target.value + "/district/list"
                request.get(url).end((err, res) => {
                    if (err) {
                        util.log("error: " + err);
                    } else {
                        util.log(res);
                        if (res.status == 200) {
                            const data = JSON.parse(res.text)
                            if (data.status == "OK") {
                                let list = data.results.list
                                let t = []
                                list.map(item =>
                                    t.push({'district_id' : item.district_id, 'district_name' : item.district_name})
                                )
                                self.setState({list_kecamatan: t})
                            }
                        }
                    }
                })
            }
        } else if (e.target.id === 'kecamatan-sel') {
            self.setState({list_kelurahan: [], kecamatan: e.target.value})
            if (e.target.value.length !== 0) {
                let url = "https://fintech-stg.bedigital.co.id/api/helper/geoname/" + self.state.propinsi + "/" + self.state.kota + "/" + e.target.value + "/village/list"
                request.get(url).end((err, res) => {
                    if (err) {
                        util.log("error: " + err);
                    } else {
                        util.log(res);
                        if (res.status == 200) {
                            const data = JSON.parse(res.text)
                            if (data.status == "OK") {
                                let list = data.results.list
                                let t = []
                                list.map(item =>
                                    t.push({'village_id' : item.village_id, 'village_name' : item.village_name})
                                )
                                self.setState({list_kelurahan: t})
                            }
                        }
                    }
                })
            }
        } else if (e.target.id === 'kelurahan-sel') {
            self.setState({kelurahan: e.target.value})
        } else if (e.target.id === 'gender-sel') {
            self.setState({sel_gender: e.target.value})
        } else if (e.target.id === 'identitas-sel') {
            self.setState({sel_identitas: e.target.value})
        }
    }

    componentDidMount() {
        let self = this
        this.props.update_current_page('profile')
        let body = {
            "session_id" : this.props.session
        }
        request.post(apis.get_profile)
            .set("Content-Type", "application/json")
            .send(body)
            .end(function(err, res) {
                if (err || !res.ok) {
                    util.log("get_profile", err)
                } else {
                    util.log("get_profile", res);
                    if (res.statusCode == 200) {
                        let payload = res.body
                        if (payload.status == "OK") {
                            if (payload.results) {
                                let results = payload.results
                                let res = {
                                    "device_name" : results.device_name !== null ? results.device_name : "",
                                    "name_full" : results.fullname !== null ? results.fullname : "",
                                    "phone_number" : results.phone_number !== null ? results.phone_number : "",
                                    "email" : results.email !== null ? results.email : "",
                                    "fax" : results.fax_number !== null ? results.fax_number : "",
                                    "tempat_lahir" : results.place_birth !== null ? results.place_birth : "",
                                    "tanggal_lahir" : results.birth_date !== null ? results.birth_date : "",
                                    "i_tanggal_lahir" : results.birth_date !== null ? results.birth_date : "",
                                    "pekerjaan" : results.job_title !== null ? results.job_title : "",
                                    "sel_gender" : results.gender !== null ? results.gender : "",
                                    "sel_identitas" : results.id_card_type !== null ? results.id_card_type : "",
                                    "identitas_nomer" : results.id_card_number !== null ? results.id_card_number : "",
                                    "identitas_filename" : results.id_card_filename !== null ? results.id_card_filename : "",
                                    "foto_identitas" : results.id_card_originalname !== null ? results.id_card_originalname : "",
                                    "nomor_npwp" : results.npwp_number !== null ? results.npwp_number : "",
                                    "propinsi" : results.province_id !== null ? results.province_id : "",
                                    "kota" : results.city_id !== null ? results.city_id : "",
                                    "kota_nama" : results.city_name !== null ? results.city_name : "",
                                    "kecamatan" : results.district_id !== null ? results.district_id : "",
                                    "kecamatan_nama" : results.district_name !== null ? results.district_name : "",
                                    "kelurahan" : results.village_id !== null ? results.village_id : "",
                                    "kelurahan_nama" : results.village_name !== null ? results.village_name : "",
                                    "alamat" : results.address !== null ? results.address : "",
                                    "kode_pos" : results.postal_code !== null ? results.postal_code : "",
                                    "photo_url" : results.photo_filename !== null && results.photo_filename.length !== 0 ? results.photo_filename : photo_default,
                                    "identity_type" : results.identity_type
                                }
                                self.load_propinsi()
                                self.setState(res)
                            }
                        } else {
                            self.on_error(payload.error)
                        }
                    }
                }
            })
    }

    load_propinsi() {
        let self = this
        request.get(apis.list_propinsi).end((err, res) => {
            if (err) {
                util.log("error: " + err);
            } else {
                util.log(res);
                if (res.status == 200) {
                    const data = JSON.parse(res.text)
                    if (data.status == "OK") {
                        let list = data.results.list
                        let t = []
                        list.map(item =>
                            t.push({'province_id' : item.province_id, 'province_name' : item.province_name})
                        )
                        self.setState({list_propinsi: t})
                    }
                }
            }
        })
    }

    on_input_change(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    on_btn_update() {
        if (this.state.tx_flag_processing) return
        let self = this
        self.setState({tx_flag_processing: true})
        let body = {
            'session_id' : self.props.session,
            'fullname' : self.state.name_full,
            'fax_number' : self.state.fax,
            'place_birth' : self.state.tempat_lahir,
            'birth_date' : self.state.tanggal_lahir,
            'gender' : self.state.sel_gender,
            'npwp_number' : self.state.nomor_npwp,
            'province_id' : self.state.propinsi,
            'city_id' : self.state.kota,
            'district_id' : self.state.kecamatan,
            'village_id' : self.state.kelurahan,
            'address' : self.state.alamat,
            'postal_code' : self.state.kode_pos,
            'job_title' : self.state.pekerjaan,
            'id_card_type' : self.state.sel_identitas,
            'id_card_number' : self.state.identitas_nomer
        }
        if (this.state.identity_type !== 'EMAIL') {
            body.email = self.state.email
        }
        if (this.state.identity_type !== 'PHONENUMBER') {
            body.phone_number = self.state.phone_number
        }
        request.post(apis.update_profile)
            .set("Content-Type", "application/json")
            .send(body)
            .end(function(err, res) {
                let message_result = ""
                let success_result = ""
                if (err || !res.ok) {
                    util.log(err)
                    message_result = {title: "Error", body: err.message}
                    if (res != undefined) {
                        message_result.body = "Error code " + res.statusCode
                    }
                } else {
                    util.log(res);
                    if (res.statusCode === 200) {
                        let payload = res.body
                        util.log(payload)
                        if (payload.status === "OK") {
                            message_result = {title: "Berhasil Update Profil", body: payload.display_message}
                        } else {
                            message_result = {title: "Error", body: res.body.error.message}
                            if (res.body.display_message !== undefined) {
                                message_result = {title: res.body.error.message, body: res.body.display_message}
                            }
                        }
                    } else {
                        message_result = {title: "Error", body: res.statusCode}
                    }
                }
                if (message_result.length !== 0) {
                    swal(message_result.title, message_result.body, "warning")
                }
                self.setState({tx_flag_processing: false})
            });
    }

    photo_input_changed = (event) => {
        let upload_img = event.target.files[0]
        util.log(upload_img)
        if (upload_img === undefined) { return }
        let self = this
        if (upload_img.type === "image/jpeg" || upload_img.type === "image/png") {
            request.post(apis.upload_photo)
                .attach('fileupload', upload_img)
                .field('session_id', self.props.session)
                .then(res => {
                    util.log(res)
                    if (res.ok) {
                        let failed = ""
                        if (res.statusCode === 200) {
                            let payload = res.body
                            if (payload.status === "OK") {
                                swal("Sukses", "Anda berhasil mengganti foto", "warning")
                                let new_photo = payload.results.photo_filename
                                let profile_val = self.props.profile
                                let p = Object.assign({}, profile_val, {photo: new_photo})
                                util.log("update photo:", p)
                                self.setState({photo_url: new_photo})
                                self.props.update_profile(p)
                            } else {
                                failed = payload.error.message
                            }
                        } else {
                            failed = res.statusCode
                        }
                        if (failed.length !== 0) {
                            swal("Gagal", "Anda gagal mengganti foto", "warning")
                        }
                    }
                })
        } else {
            return swal("Ups !", "Hanya boleh file gambar", "warning")
        }
    }

    photo_clicked() {
        document.getElementById("photo_input").click()
    }

    kartu_identitas_input_changed = (event) => {
        let upload_img = event.target.files[0]
        util.log(upload_img)
        if (upload_img === undefined) { return }
        let self = this
        if (upload_img.type === "image/jpeg" || upload_img.type === "image/png" || upload_img.type === "image/jpg") {
            this.setState({foto_identitas: upload_img.name})
            request.post(apis.upload_kartu_identitas)
                .attach('fileupload', upload_img)
                .field('session_id', self.props.session)
                .then(res => {
                    util.log(res)
                    if (res.ok) {
                        let failed = ""
                        if (res.statusCode === 200) {
                            let payload = res.body
                            if (payload.status === "OK") {
                                swal("Sukses", "Berhasil upload kartu identitas", "warning")
                                self.setState({uploaded_identitas: true})
                            } else {
                                failed = payload.error.message
                            }
                        } else {
                            failed = res.statusCode
                        }
                        if (failed.length !== 0) {
                            swal("Gagal Upload", failed, "warning")
                        }
                    }
                })
        } else {
            return swal("Ups!", "Hanya boleh file gambar", "warning")
        }
    }

    on_btn_change_device_name() {
        if (util.is_electron()) {
            return;
        }
        swal({
            title: "Konfirmasi",
            text: "Mengubah nama terminal ini akan mengantarkan Anda ke halaman login lagi / force logout.\nApakah Anda setuju untuk mengganti nama terminal ini?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((sure) => {
            if (sure) {
                let body = {
                    "session_id": this.props.session
                }
                request.post(apis.logout)
                    .set("Content-Type", "application/json")
                    .send(body)
                    .end(function(err, res){
                        if (err || !res.ok) {
                            swal("Ups!", "Error code " + res.statusCode, "warning")
                        } else {
                            if (res.statusCode == 200) {
                                let payload = res.body
                                if (payload.status == "OK" || payload.error.code == 2006) {
                                    localdata.clear_data().then((res) => {
                                        cookie.remove('name_agent', {path: '/'});
                                        cookie.remove('name_agent', {path: '/', domain: 'fintechagent-stg.bedigital.co.id'});
                                        cookie.remove('name_agent', {path: '/', domain: 'agen.posfin.id'});
                                        history.replace('/login');
                                    }).catch((err) => {
                                        util.log("error: ", err);
                                    })
                                } else {
                                    swal("Ups!", payload.error.message, "warning");
                                }
                            }
                        }
                    })
            }
        })
    }

    render() {
        let self = this
        const BankAccountView = (props) =>
            <div className="m-stack m-stack--ver m-stack--mobile m-stack--demo" style={{marginBottom: 20+'px',marginTop: 10+'px'}}>
                {this.props.profile.bank_accounts.map((item, i) =>
                    <div key={i} className="m-stack__item m-stack__item--center m-stack__item--top" 
                        style={{textAlign:'center',marginBottom:'5px'}}>
                        {item.bank_name}: {item.account_number}
                    </div>
                )}
            </div>

        const MessageView = (props) =>
            <div className="form-group m-form__group m--margin-top-10 ">
                <div className="alert m-alert m-alert--default" role="alert">
                    {this.state.message}
                </div>
            </div>

        // let phone_read_only = this.state.identity_type === 'PHONENUMBER' ? {'disabled' : 'disabled'} : {}
        // let email_read_only = this.state.identity_type === 'EMAIL' ? {'disabled' : 'disabled'} : {}
        let phone_read_only = {'disabled' : 'disabled'}
        let email_read_only = {'disabled' : 'disabled'}
        let is_workstation = false
        if (this.props.profile) {
            if (this.props.profile.workstation_code) {
                is_workstation = true
            }
        }
        return (
            <div className="row">
                <div className="col-lg-3">
                    <div className="m-portlet">
                        <div className="m-portlet__body">
                            <div className="m-card-profile">
                                <div className="m-card-profile__title m--hide">
                                    Your Profile
                                </div>
                                <div className="m-card-profile__pic">
                                    <div className="m-card-profile__pic-wrapper">
                                        <img style={{cursor:'pointer',width:100+'px',height:100+'px'}} onClick={this.photo_clicked} src={this.state.photo_url}/>
                                    </div>
                                </div>
                                <div className="m-card-profile__details">
                                    <span className="m-card-profile__name" style={{marginTop:-15+'px'}}>
                                        {this.props.profile ? (this.props.profile.name ? this.props.profile.name : this.props.profile.usertype) : ""}
                                    </span>
                                    <input type="file" id="photo_input" onChange={this.photo_input_changed} style={{display:'none'}}/>
                                    <a className="m-card-profile__email m-link" style={{marginTop:-10+'px'}}>
                                        {this.props.profile.username}
                                    </a>
                                    <span style={{display:'block',fontWeight: 500}}>UID: {parseInt(this.props.profile.userid, 36)}</span>
                                </div>
                                {this.props.profile.bank_accounts && this.props.profile.bank_accounts.length !== 0 ? <BankAccountView/> : ""}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-9">
                    <div className="m-portlet m-portlet--full-height m-portlet--tabs  ">
                        <div className="tab-content">
                            <div className="tab-pane active" id="m_user_profile_tab_1">
                                <form className="m-form m-form--fit m-form--label-align-right">
                                    <div className="m-portlet__body">
                                        {this.state.message.length !== 0 ? <MessageView/> : ""}
                                        <div className="form-group m-form__group row">
                                            <div className="col-8">
                                                <h3 className="m-form__section">
                                                    1. Kartu Identitas
                                                </h3>
                                            </div>
                                            {this.state.device_name.length !== 0 && this.state.show_device_name ? 
                                                <div className="col-4" style={{textAlign:'right'}}>
                                                    <label className="label">
                                                        Nama Terminal : <span style={{cursor:'pointer',textDecoration:'underline',fontWeight:600}} onClick={this.on_btn_change_device_name}>{this.state.device_name}</span>
                                                    </label>
                                                </div>
                                            : ''}
                                        </div>
                                        <div className="form-group m-form__group row">
                                            <label htmlFor="example-text-input" className="col-2 col-form-label">
                                                Tipe Kartu Identitas
                                            </label>
                                            <div className="col-10" style={{margin: 'auto 0'}}>
                                                <select className="form-control m-input" id="identitas-sel" value={this.state.sel_identitas} onChange={(e) => this.on_select_change(e)}>
                                                    <option key={999} value=""> -- Pilih -- </option>
                                                    {
                                                        self.state.identitas.length != 0 ? self.state.identitas.map((p, i) =>
                                                            <option key={i} value={p.name}>
                                                                {p.name}
                                                            </option>
                                                        ) : ''
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group m-form__group row">
                                            <label htmlFor="example-text-input" className="col-2 col-form-label">
                                                Upload Foto (Sesuai Kartu Identitas)
                                            </label>
                                            <div className="col-7" style={{margin:'auto 0'}}>
                                                <input disabled className="form-control m-input" type="text" name="foto_identitas" value={this.state.foto_identitas} />
                                                <input type="file" id="upload_identitas" name="upload_identitas" onChange={this.kartu_identitas_input_changed} style={{display:'none'}}/>
                                            </div>
                                            <div className="col-2" style={{margin:'auto 0',paddingLeft: 0}}>
                                                {this.state.foto_identitas.length === 0 ? (!is_workstation ? <span className="" style={{width:'8rem',height:'unset',cursor:'pointer',backgroundColor:'cadetblue',padding:'10px 15px',borderRadius:'5px'}} onClick={(e) => document.getElementById("upload_identitas").click()} >
                                                    <span style={{color: 'white', fontWeight: '600'}}>Upload</span>
                                                </span> : '') : 
                                                <span className="" style={{width:'8rem',height:'unset',cursor:'pointer',backgroundColor:'cadetblue',padding:'10px 15px',borderRadius:'5px'}} onClick={(e) => window.open(this.state.identitas_filename,'_blank').focus()
                                                    } >
                                                    <span style={{color: 'white', fontWeight: '600'}}>Open</span>
                                                </span>}
                                                
                                            </div>
                                        </div>
                                        <div className="form-group m-form__group row">
                                            <label htmlFor="example-text-input" className="col-2 col-form-label">
                                                Nomor (Sesuai Kartu Identitas)
                                            </label>
                                            <div className="col-10" style={{margin: 'auto 0'}}>
                                                <input className="form-control m-input" type="text" 
                                                name="identitas_nomer"
                                                onChange={(e) => this.on_input_change(e)}
                                                value={this.state.identitas_nomer}/>
                                            </div>
                                        </div>
                                        <div className="form-group m-form__group row">
                                            <label htmlFor="example-text-input" className="col-2 col-form-label">
                                                Nama (Sesuai Kartu Identitas)
                                            </label>
                                            <div className="col-10" style={{margin: 'auto 0'}}>
                                                <input className="form-control m-input" 
                                                disabled
                                                name="name_full"
                                                type="text" 
                                                onChange={(e) => this.on_input_change(e)}
                                                value={this.state.name_full}/>
                                            </div>
                                        </div>
                                        <div className="m-form__seperator m-form__seperator--dashed m-form__seperator--space-2x"></div>
                                        <div className="form-group m-form__group row">
                                            <div className="col-10">
                                                <h3 className="m-form__section">
                                                    2. Personal Info
                                                </h3>
                                            </div>
                                        </div>
                                        <div className="form-group m-form__group row">
                                            <label htmlFor="example-text-input" className="col-2 col-form-label">
                                                No. Telepon
                                            </label>
                                            <div className="col-10">
                                                <input {...phone_read_only} className="form-control m-input" type="text" 
                                                name="phone_number"
                                                onChange={(e) => this.on_input_change(e)}
                                                value={this.state.phone_number}/>
                                            </div>
                                        </div>
                                        <div className="form-group m-form__group row">
                                            <label htmlFor="example-text-input" className="col-2 col-form-label">
                                                Email
                                            </label>
                                            <div className="col-10">
                                                <input {...email_read_only} className="form-control m-input" type="text" 
                                                name="email"
                                                onChange={(e) => this.on_input_change(e)}
                                                value={this.state.email}/>
                                            </div>
                                        </div>
                                        <div className="form-group m-form__group row">
                                            <label htmlFor="example-text-input" className="col-2 col-form-label">
                                                NPWP
                                            </label>
                                            <div className="col-10">
                                                <input className="form-control m-input" type="text" 
                                                name="nomor_npwp"
                                                onChange={(e) => this.on_input_change(e)}
                                                value={this.state.nomor_npwp}/>
                                            </div>
                                        </div>
                                        <div className="form-group m-form__group row">
                                            <label htmlFor="example-text-input" className="col-2 col-form-label">
                                                Jenis Kelamin
                                            </label>
                                            <div className="col-10">
                                                <select className="form-control m-input" id="gender-sel" value={this.state.sel_gender} onChange={(e) => this.on_select_change(e)}>
                                                    <option key={999} value=""> -- Pilih -- </option>
                                                    <option key={0} value="M">Laki-Laki</option>
                                                    <option key={1} value="F">Perempuan</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group m-form__group row">
                                            <label htmlFor="example-text-input" className="col-2 col-form-label">
                                                Fax
                                            </label>
                                            <div className="col-10">
                                                <input className="form-control m-input" type="text" 
                                                name="fax"
                                                onChange={(e) => this.on_input_change(e)}
                                                value={this.state.fax}/>
                                            </div>
                                        </div>
                                        <div className="form-group m-form__group row">
                                            <label htmlFor="example-text-input" className="col-2 col-form-label">
                                                Tempat Lahir
                                            </label>
                                            <div className="col-10">
                                                <input className="form-control m-input" type="text" 
                                                name="tempat_lahir"
                                                onChange={(e) => this.on_input_change(e)}
                                                value={this.state.tempat_lahir}/>
                                            </div>
                                        </div>
                                        <div className="form-group m-form__group row">
                                            <label htmlFor="example-text-input" className="col-2 col-form-label">
                                                Tanggal Lahir
                                            </label>
                                            <div className="col-10">
                                                <DatePickerInput
                                                    onChange={this.birth_date_change}
                                                    value={this.state.i_tanggal_lahir}/>
                                            </div>
                                        </div>
                                        <div className="form-group m-form__group row">
                                            <label htmlFor="example-text-input" className="col-2 col-form-label">
                                                Pekerjaan
                                            </label>
                                            <div className="col-10">
                                                <input className="form-control m-input" type="text" 
                                                name="pekerjaan"
                                                onChange={(e) => this.on_input_change(e)}
                                                value={this.state.pekerjaan}/>
                                            </div>
                                        </div>
                                        <div className="m-form__seperator m-form__seperator--dashed m-form__seperator--space-2x"></div>
                                        <div className="form-group m-form__group row">
                                            <div className="col-10">
                                                <h3 className="m-form__section">
                                                    3. Alamat Domisili
                                                </h3>
                                            </div>
                                        </div>
                                        <div className="form-group m-form__group row">
                                            <label htmlFor="example-text-input" className="col-2 col-form-label">
                                                Provinsi
                                            </label>
                                            <div className="col-10">
                                                <select className="form-control m-input" id="propinsi-sel" value={this.state.propinsi} onChange={(e) => this.on_select_change(e)}>
                                                    <option key={999} value=""> -- Pilih Propinsi -- </option>
                                                    {
                                                        self.state.list_propinsi.length != 0 ? self.state.list_propinsi.map((item, i) =>
                                                            <option key={i} value={item.province_id}>
                                                                {item.province_name}
                                                            </option>
                                                        ) : ''
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group m-form__group row">
                                            <label htmlFor="example-text-input" className="col-2 col-form-label">
                                                Kota
                                            </label>
                                            <div className="col-10">
                                                <select className="form-control m-input" id="kota-sel" value={this.state.kota} onChange={(e) => this.on_select_change(e)}>
                                                    <option key={999} value="">{self.state.kota_nama.length !== 0 ? self.state.kota_nama : '-- Pilih Kota --'}</option>
                                                    {
                                                        self.state.list_kota.length != 0 ? self.state.list_kota.map((item, i) =>
                                                            <option key={i} value={item.city_id}>
                                                                {item.city_name}
                                                            </option>
                                                        ) : ''
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group m-form__group row">
                                            <label htmlFor="example-text-input" className="col-2 col-form-label">
                                                Kecamatan
                                            </label>
                                            <div className="col-10">
                                                <select className="form-control m-input" id="kecamatan-sel" value={this.state.kecamatan} onChange={(e) => this.on_select_change(e)}>
                                                    <option key={999} value="">{self.state.kecamatan_nama.length !== 0 ? self.state.kecamatan_nama : '-- Pilih Kecamatan --'}</option>
                                                    {
                                                        self.state.list_kecamatan.length != 0 ? self.state.list_kecamatan.map((item, i) =>
                                                            <option key={i} value={item.district_id}>
                                                                {item.district_name}
                                                            </option>
                                                        ) : ''
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group m-form__group row">
                                            <label htmlFor="example-text-input" className="col-2 col-form-label">
                                                Kelurahan
                                            </label>
                                            <div className="col-10">
                                                <select className="form-control m-input" id="kelurahan-sel" value={this.state.kelurahan} onChange={(e) => this.on_select_change(e)}>
                                                    <option key={999} value="">{self.state.kelurahan_nama.length !== 0 ? self.state.kelurahan_nama : '-- Pilih Kelurahan --'}</option>
                                                    {
                                                        self.state.list_kelurahan.length != 0 ? self.state.list_kelurahan.map((item, i) =>
                                                            <option key={i} value={item.village_id}>
                                                                {item.village_name}
                                                            </option>
                                                        ) : ''
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group m-form__group row">
                                            <label htmlFor="example-text-input" className="col-2 col-form-label">
                                                Alamat Lengkap
                                            </label>
                                            <div className="col-10">
                                                <input className="form-control m-input" type="text" 
                                                name="alamat"
                                                onChange={(e) => this.on_input_change(e)}
                                                value={this.state.alamat}/>
                                            </div>
                                        </div>
                                        <div className="form-group m-form__group row">
                                            <label htmlFor="example-text-input" className="col-2 col-form-label">
                                                Kode Pos
                                            </label>
                                            <div className="col-10">
                                                <input className="form-control m-input" type="text" 
                                                name="kode_pos"
                                                onChange={(e) => this.on_input_change(e)}
                                                value={this.state.kode_pos}/>
                                            </div>
                                        </div>
                                    </div>
                                    {!is_workstation ?
                                    <div className="m-portlet__foot m-portlet__foot--fit">
                                        <div className="m-form__actions">
                                            <div className="row">
                                                <div className="col-7">
                                                    <button type="reset" 
                                                        onClick={this.on_btn_update} 
                                                        className={this.state.tx_flag_processing ? 'btn btn-accent m-btn m-btn--air m-btn--custom m-loader m-loader--light m-loader--right' : 'btn btn-accent m-btn m-btn--air m-btn--custom'}>
                                                        Simpan Perubahan
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : ''} 
                                </form>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    update_current_page:  val => dispatch(do_action(action.ACTION_UPDATE_CURRENT_PAGE, val)),
    update_profile: payload => dispatch(do_action(action.ACTION_UPDATE_USER_INFO, payload))
})

const mapStateToProps = (state) => ({
    session : state.Session,
    profile: state.Profile
})

export default connect(mapStateToProps, mapDispatchToProps)(AgentProfile)