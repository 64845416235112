import { connect } from 'react-redux'
import React, { Component } from 'react'
import moment from 'moment'
import request from 'superagent'

import MetronicIndex from '../layout/metronic/index'
import { Form, FormGroup } from '../component/form/form'
import { Portlet, PortletHead, PortletBody, PortletFooter } from '../component/widget/portlet'

import apis from '../helper/api'
import localdata from '../helper/localdata'
import config from '../helper/config'
import swal from 'sweetalert'
import action from '../helper/action'
import do_action from '../action'
import util from '../helper/util'

let photo_default = "../../swipe/assets/app/media/img/users/default.jpg"

class ChangePassword extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tx_flag_processing: false,
            message : "",
            new_password: "",
            confirm_new_password: "",
            type_input_password: 'password'
        }
        this.on_btn_update = this.on_btn_update.bind(this)
    }

    componentDidMount() {
        this.props.update_current_page('topup')
    }

    on_input_change(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    on_btn_update() {
        if (this.state.tx_flag_processing) return
        let self = this
        self.setState({tx_flag_processing: true})

        let body = {
            "session_id" : self.props.session,
            "password_new" : this.state.new_password,
            "password_confirm" : this.state.confirm_new_password
        }
        request.post(apis.change_password)
            .set("Content-Type", "application/json")
            .send(body)
            .end(function(err, res) {
                let message_result = ""
                let success_result = ""
                if (err || !res.ok) {
                    util.log(err)
                    message_result = {title: "Error", body: err.message}
                    if (res != undefined) {
                        message_result.body = "Error code " + res.statusCode
                    }
                } else {
                    util.log(res);
                    if (res.statusCode === 200) {
                        let payload = res.body
                        util.log(payload)
                        if (payload.status === "OK") {
                            message_result = {title: "Update Sukses", body: "Berhasil Ubah Password"}
                        } else {
                            message_result = {title: "Error", body: payload.error.message}
                            if (payload.display_message !== undefined) {
                                message_result = {title: payload.error.message, body: payload.display_message}
                            }
                        }
                    } else {
                        message_result = {title: "Error", body: res.statusCode}
                    }
                }
                if (message_result.length !== 0) {
                    swal(message_result.title, message_result.body, "warning")
                }
                self.setState({tx_flag_processing: false})
            });
    }

    on_show_hide_password(e) {
        util.log("on_show_hide_password", e)
        if (this.state.type_input_password === 'password') {
            this.setState({type_input_password: 'text'})
        } else {
            this.setState({type_input_password: 'password'})
        }
    }

    render() {
        let self = this
        const BankAccountView = (props) =>
            <div className="m-stack m-stack--ver m-stack--mobile m-stack--demo" style={{marginBottom: 20+'px',marginTop: 10+'px'}}>
                {this.props.profile.bank_accounts.map((item, i) =>
                    <div key={i} className="m-stack__item m-stack__item--center m-stack__item--top" 
                        style={{textAlign:'center',marginBottom:'5px'}}>
                        {item.bank_name}: {item.account_number}
                    </div>
                )}
            </div>

        const MessageView = (props) =>
            <div className="form-group m-form__group m--margin-top-10 ">
                <div className="alert m-alert m-alert--default" role="alert">
                    {this.state.message}
                </div>
            </div>

        return (
        	<div className="row">
                <div className="col-lg-3">
                    <div className="m-portlet">
                        <div className="m-portlet__body">
                            <div className="m-card-profile">
                                <div className="m-card-profile__title m--hide">
                                    Your Profile
                                </div>
                                <div className="m-card-profile__pic">
                                    <div className="m-card-profile__pic-wrapper">
                                        <img style={{width:100+'px',height:100+'px'}} src={this.props.profile ? (this.props.profile.photo ? this.props.profile.photo : photo_default) : photo_default}/>
                                    </div>
                                </div>
                                <div className="m-card-profile__details">
                                    <span className="m-card-profile__name" style={{marginTop:-15+'px'}}>
                                        {this.props.profile ? (this.props.profile.name ? this.props.profile.name : this.props.profile.usertype) : ""}
                                    </span>
                                    <a href="#" className="m-card-profile__email m-link" style={{marginTop:-10+'px'}}>
                                        {this.props.profile.username}
                                    </a>
                                </div>

                                {this.props.profile.bank_accounts && this.props.profile.bank_accounts.length !== 0 ? <BankAccountView/> : ""}

                            </div>
                            
                        </div>
                    </div>
                </div>
                <div className="col-lg-9">
                    <div className="m-portlet m-portlet--full-height m-portlet--tabs  ">
                        <div className="tab-content">
                            <div className="tab-pane active" id="m_user_profile_tab_1">
                                <form className="m-form m-form--fit m-form--label-align-right">
                                    <div className="m-portlet__body">
                                        {this.state.message.length !== 0 ? <MessageView/> : ""}
                                        <div className="form-group m-form__group row">
                                            <div className="col-10">
                                                <h3 className="m-form__section">
                                                    Ubah Password
                                                </h3>
                                            </div>
                                        </div>
                                        <div className="form-group m-form__group row">
                                            <label htmlFor="example-text-input" className="col-3 col-form-label">
                                                Password Baru
                                            </label>
                                            <div className="col-9 m-input-icon m-input-icon--right">
                                                <input className="form-control m-input" 
                                                name="new_password"
                                                type={this.state.type_input_password} 
                                                onChange={(e) => this.on_input_change(e)}
                                                value={this.state.new_password}/>
                                                <span className="m-input-icon__icon m-input-icon__icon--right" style={{width: '4rem'}}>
                                                    <span onClick={(e) => this.on_show_hide_password(e)}>
                                                        <i className={this.state.type_input_password === 'password' ? 'la la-eye' : 'la la-eye-slash'}></i>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="form-group m-form__group row">
                                            <label htmlFor="example-text-input" className="col-3 col-form-label">
                                                Konfirm Password Baru
                                            </label>
                                            <div className="col-9 m-input-icon m-input-icon--right">
                                                <input className="form-control m-input" 
                                                type={this.state.type_input_password} 
                                                name="confirm_new_password"
                                                onChange={(e) => this.on_input_change(e)}
                                                value={this.state.confirm_new_password}/>
                                                <span className="m-input-icon__icon m-input-icon__icon--right" style={{width: '4rem'}}>
                                                    <span onClick={(e) => this.on_show_hide_password(e)}>
                                                        <i className={this.state.type_input_password === 'password' ? 'la la-eye' : 'la la-eye-slash'}></i>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div className="m-portlet__foot m-portlet__foot--fit">
                                        <div className="m-form__actions">
                                            <div className="row">
                                                <div className="col-7">
                                                    <button type="reset" 
                                                        onClick={this.on_btn_update} 
                                                        className={this.state.tx_flag_processing ? 'btn btn-accent m-btn m-btn--air m-btn--custom m-loader m-loader--light m-loader--right' : 'btn btn-accent m-btn m-btn--air m-btn--custom'}>
                                                        Simpan
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    update_current_page:  val => dispatch(do_action(action.ACTION_UPDATE_CURRENT_PAGE, val))
})

const mapStateToProps = (state) => ({
    session : state.Session,
    profile: state.Profile
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword)