import {connect} from 'react-redux'
import React, {Component} from 'react'
import moment from 'moment'
import request from 'superagent'
import {history} from '../helper/history'
import MetronicIndex from '../layout/metronic/index'
import {Form, FormGroup} from '../component/form/form'
import {Portlet, PortletHead, PortletBody, PortletFooter, PortletTab} from '../component/widget/portlet'
import BootstrapTable from 'react-bootstrap-table-next'
import {DatePicker, DatePickerInput} from 'rc-datepicker'
import 'rc-datepicker/lib/style.css';
import apis from '../helper/api'
import localdata from '../helper/localdata'
import action from '../helper/action'
import do_action from '../action'
import config from '../helper/config'
import swal from 'sweetalert'
import Modal from 'react-responsive-modal'
import util from '../helper/util'
import ReactToPrint from "react-to-print"
import GeneralExportExcel from '../component/widget/GeneralExportExcel';

let next_load_url = ""

class AgentHistoryBonus extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data_history_fee: [],
            start_date: "",
            end_date: "",
            tx_flag_processing: false,
            tx_flag_cari_processing: false,
            show_more_btn: false,
            tx_show_breakdown: false,
            tx_result_success: "",
            scroll_interval_id: 0,
            expanded: [],
            is_excel_generated: false
        }
        this.options = {
            hideSizePerPage: true
        };
        this.start_date_change = this.start_date_change.bind(this)
        this.end_date_change = this.end_date_change.bind(this)
        this.on_btn_load_more = this.on_btn_load_more.bind(this)
        this.on_btn_cari = this.on_btn_cari.bind(this)
        this.on_btn_reset = this.on_btn_reset.bind(this)
        this.on_btn_breakdown_close = this.on_btn_breakdown_close.bind(this)
        this.on_btn_breakdown = this.on_btn_breakdown.bind(this)
    }

    componentDidMount() {
        this.scroll_to_top()
        this.props.update_current_page('riwayat-bonus')
        this.first_load()
    }

    first_load() {
        let startOnlyDate = ''
        let date = moment().subtract(7, 'days').toDate()
        if (this.props.location.state) {
            if (this.props.location.state.month_year) {
                date = moment(this.props.location.state.month_year).startOf('month').toDate()
            }
        }
        if (date.length !== 0) {
            let bulan = (date.getMonth() + 1).toString()
            let hari = date.getDate().toString()
            let m = bulan.length === 1 ? '0' + bulan : bulan
            let h = hari.length === 1 ? '0' + hari : hari
            startOnlyDate = date.getFullYear() + "-" + m + "-" + h
        }

        date = moment().toDate()
        if (this.props.location.state) {
            if (this.props.location.state.month_year) {
                date = moment(this.props.location.state.month_year).endOf('month').toDate()
            }
        }
        let endOnlyDate = ''
        if (date.length !== 0) {
            let bulan = (date.getMonth() + 1).toString()
            let hari = date.getDate().toString()
            let m = bulan.length === 1 ? '0' + bulan : bulan
            let h = hari.length === 1 ? '0' + hari : hari
            endOnlyDate = date.getFullYear() + "-" + m + "-" + h
        }

        let url = apis.list_history_bonus + "/" + startOnlyDate + "/" + endOnlyDate
        this.setState({start_date: startOnlyDate, end_date: endOnlyDate})
        this.load_data(url, false)
    }

    load_data(url, is_append) {
        let self = this
        let body = {
            "session_id" : this.props.session,
            "date_begin" : self.state.start_date,
            "date_end": self.state.end_date
        }
        if (is_append) {
            this.setState({tx_flag_processing: true})
        } else {
            self.setState({tx_flag_cari_processing: true})
        }
        request.post(url)
            .set("Content-Type", "application/json")
            .send(body)
            .end(function(err, res) {
                if (err || !res.ok) {
                    util.log("get_tx", err)
                } else {
                    util.log("get_tx", res);
                    if (res.statusCode == 200) {
                        let payload = res.body
                        if (payload.status == "OK") {
                            if (payload.results) {
                                let results = payload.results
                                if (results.list !== undefined) {
                                    let items = []
                                    results.list.map(function(item, i) {
                                        let it = {
                                            "id" : item.id,
                                            "product_id" : item.product_id,
                                            "product_code" : item.product_code,
                                            "product_name" : item.product_name,
                                            "description" : item.description !== null ? item.description : '-',
                                            "trx_datetime" : moment(item.trx_datetime).format('DD/MM/YYYY HH:mm'),
                                            "trx_price_total" : "Rp. " + util.rupiah_format(item.trx_price_total),
                                            "trx_price_fee_end_user" : "Rp. " + util.rupiah_format(item.trx_price_fee_end_user),
                                            "trx_bonus_received" : "Rp. " + util.rupiah_format(item.trx_bonus_received),
                                            "next_breakdown" : item.next_breakdown !== null ? item.next_breakdown.url : "",
                                            "status" : "ONGOING"
                                        }
                                        items.push(it)
                                    })
                                    if (items.length === 0 && !is_append) {
                                        swal("Info", "Data komisi belum ada", "warning")
                                    } else {
                                        if (!is_append) {
                                            self.setState({data_history_fee: items})
                                        } else {
                                            var joined = self.state.data_history_fee.concat(items);
                                            self.setState({data_history_fee: joined})
                                        }
                                    }
                                }
                                if (results.next_page !== null) {
                                    next_load_url = results.next_page.url
                                    self.setState({show_more_btn: true})
                                } else {
                                    next_load_url = ""
                                    self.setState({show_more_btn: false})
                                }
                            }
                        } else {
                            self.on_error(payload.error)
                        }
                    }
                }
                self.setState({tx_flag_processing: false, tx_flag_cari_processing: false})
            })
    }

    on_error(err) {
        util.on_error(err)
        if (err.code === 2007) {
            this.setState({show_more_btn: false})
        } else {
            let msg = "Error: " + err.code
            if (err.message !== undefined) {
                msg = err.message
            }
            swal("Ups!", msg, "warning")
        }
    }

    scroll_step() {
        if (window.pageYOffset === 0) {
            clearInterval(this.state.scroll_interval_id);
        }
        window.scroll(0, window.pageYOffset - 50);
    }

    scroll_to_top() {
        let scroll_interval_id = setInterval(this.scroll_step.bind(this), 16.66);
        this.setState({scroll_interval_id: scroll_interval_id});
    }

    on_btn_load_more() {
        if (this.state.tx_flag_processing) return
        if (next_load_url.length !== 0) {
            this.load_data(next_load_url, true)
        }
    }

    on_btn_reset() {
        this.setState({start_date : "", end_date : "", show_more_btn: false, data_history_fee: []})
        this.first_load()
    }

    on_btn_cari() {
        if (this.state.start_date.length === 0) {
            swal("Info", "Pilih tanggal awal lebih dulu", "warning")
            return
        }
        if (this.state.end_date.length === 0) {
            swal("Info", "Pilih tanggal akhir lebih dulu", "warning")
            return
        }
        let d_start = new Date(this.state.start_date)
        let d_end = new Date(this.state.end_date)
        if (d_start > d_end) {
            swal("Info", "Tidak dapat diproses, tanggal awal lebih besar dari tanggal akhir", "warning")
            return
        }
        
        let self = this
        let url = apis.list_history_bonus + "/" + self.state.start_date + "/" + self.state.end_date
        let body = {
            "session_id" : self.props.session
        }
        self.setState({tx_flag_cari_processing: true, data_history_fee: [], show_more_btn: false})
        request.post(url)
            .set("Content-Type", "application/json")
            .send(body)
            .end(function(err, res) {
                let message_error = ""
                if (err || !res.ok) {
                    util.log(err)
                    message_error = {"body": err.message, "title": "Transaksi Gagal"}
                    if (res !== undefined) {
                        message_error.body = "Error code " + res.statusCode
                    }
                } else {
                    util.log(res);
                    if (res.statusCode == 200) {
                        let payload = res.body
                        util.log(payload)
                        if (payload.status == "OK") {
                            let results = payload.results
                            if (results.list !== undefined) {
                                let items = []
                                results.list.map(function(item, i) {
                                    let it = {
                                        "id" : item.id,
                                        "product_id" : item.product_id,
                                        "product_code" : item.product_code,
                                        "product_name" : item.product_name,
                                        "description" : item.description !== null ? item.description : '-',
                                        "trx_datetime" : moment(item.trx_datetime).format('DD/MM/YYYY HH:mm'),
                                        "trx_price_total" : "Rp. " + util.rupiah_format(item.trx_price_total),
                                        "trx_price_fee_end_user" : "Rp. " + util.rupiah_format(item.trx_price_fee_end_user),
                                        "trx_bonus_received" : "Rp. " + util.rupiah_format(item.trx_bonus_received),
                                        "next_breakdown" : item.next_breakdown !== null ? item.next_breakdown.url : "",
                                        "status" : "ONGOING"
                                    }
                                    items.push(it)
                                })
                                self.setState({data_history_fee: items})
                            }
                            if (results.next_page !== null) {
                                next_load_url = results.next_page.url
                                self.setState({show_more_btn: true})
                            } else {
                                next_load_url = ""
                                self.setState({show_more_btn: false})
                            }
                        } else {
                            message_error = {"body": res.body.error.message, "title": "Transaksi Gagal"}
                            if (res.body.display_message !== undefined) {
                                message_error.title = res.body.error.message
                                message_error.body = res.body.display_message
                            }
                            self.setState({show_more_btn: false})
                        }
                    } else {
                        message_error = {"body": "HTTP error " + res.statusCode, "title": "Transaksi Gagal"}
                    }
                }
                if (message_error.length != 0) {
                    swal(message_error.title, message_error.body, "warning")
                }
                self.setState({tx_flag_cari_processing: false})
            });
    }

    start_date_change(date) {
        try {
            let onlyDate = ''
            if (date.length !== 0) {
                let bulan = (date.getMonth() + 1).toString()
                let hari = date.getDate().toString()
                let m = bulan.length === 1 ? '0' + bulan : bulan
                let h = hari.length === 1 ? '0' + hari : hari
                onlyDate = date.getFullYear() + "-" + m + "-" + h
            }
            this.setState({start_date : onlyDate})
        } catch(err) {
            util.log("[start_date_change] error", err)
        }
    }

    end_date_change(date) {
        try {
            let onlyDate = ''
            if (date.length !== 0) {
                let bulan = (date.getMonth() + 1).toString()
                let hari = date.getDate().toString()
                let m = bulan.length === 1 ? '0' + bulan : bulan
                let h = hari.length === 1 ? '0' + hari : hari
                onlyDate = date.getFullYear() + "-" + m + "-" + h
            }
            this.setState({end_date : onlyDate})
        } catch(err) {
            util.log("[end_date_change] error", err)
        }
    }

    on_btn_breakdown(data) {
        util.log("on_btn_breakdown: ", data)
        let body = {
            "session_id" : this.props.session
        }
        let self = this
        request.post(data.next_breakdown)
            .set("Content-Type", "application/json")
            .send(body)
            .end(function(err, res) {
                let message_error = ""
                if (err || !res.ok) {
                    util.log(err)
                    message_error = {"body": err.message, "title": "Transaksi Gagal"}
                    if (res !== undefined) {
                        message_error.body = "Error code " + res.statusCode
                    }
                } else {
                    util.log(res);
                    if (res.statusCode == 200) {
                        let payload = res.body
                        util.log(payload)
                        if (payload.status == "OK") {
                            let data = payload.results.list
                            self.setState({tx_show_breakdown: true, tx_result_success:{"data":data}})
                        } else {
                            message_error = {"body": res.body.error.message, "title": "Transaksi Gagal"}
                            if (res.body.display_message !== undefined) {
                                message_error.title = res.body.error.message
                                message_error.body = res.body.display_message
                            }
                        }
                    } else {
                        message_error = {"body": "HTTP error " + res.statusCode, "title": "Transaksi Gagal"}
                    }
                }
                if (message_error.length != 0) {
                    swal(message_error.title, message_error.body, "warning")
                }
            })
    }

    on_btn_breakdown_close() {
        this.setState({tx_show_breakdown: false})
    }

    on_filter_label(i) {
        if (i === 1) {
            this.start_date_change(moment().toDate())
            this.end_date_change(moment().toDate())
        } else if (i === 2) {
            this.start_date_change(moment().subtract(1, 'days').toDate())
            this.end_date_change(moment().toDate())
        } else if (i === 3) {
            this.start_date_change(moment().subtract(7, 'days').toDate())
            this.end_date_change(moment().toDate())
        } else if (i === 4) {
            this.start_date_change(moment().subtract(30, 'days').toDate())
            this.end_date_change(moment().toDate())
        }
    }

    handleOnExpand = (row, isExpand, rowIndex, e) => {
        if (isExpand) {
            this.setState(() => ({expanded: [...this.state.expanded, row.id]}));
        } else {
            this.setState(() => ({expanded: this.state.expanded.filter(x => x !== row.id)}));
        }
    }

    handleAfterPrint = () => {
    }

    handleBeforePrint = () => {
    }

    renderContent = () => {
        return this.componentRef
    }

    renderTrigger = () => {
        return <button className="m-portlet__nav-link btn btn-light m-btn m-btn--pill m-btn--air" style={{marginLeft:'auto', fontSize:'small'}}>Cetak</button>
    }

    setRef = (ref) => {
        this.componentRef = ref
    }

    gen_export_excel() {
        this.setState({is_excel_generated: true});
    }

    reset_export_excel() {
        this.setState({is_excel_generated: false});
    }

    render() {
        let uid = this.props.profile ? (this.props.profile.name ? this.props.profile.username + " " + this.props.profile.name : this.props.profile.username) : ""
        let ug = navigator.userAgent
        const RowNumber = (cell, row, i) =>
            <div>{i + 1}</div>
        
        const TxColumn = (cell, row) =>
            <div>
                <span className="">{row.trx_datetime}</span>
            </div>

        const LayananColumn = (cell, row) =>
            <div>
                <span>{row.product_name}</span>
            </div>

        const StatusColumn = (cell, row) =>
            <div>
                <span className="m-badge m-badge--metal m-badge--wide m-badge--rounded">{row.status}</span>
            </div>

        const expandRow = {
            renderer: (row) => (
                <div className="row">
                    <div className="col-lg-12" style={{textAlign:'center'}}>
                        {row.description.split(',').map(function(item, key) {
                            return (
                                <span key={key}>
                                    {item}
                                    <br/>
                                </span>
                            )
                        })}
                    </div>
                    <div className="col-lg-4" style={{textAlign:'center',margin:'auto',display:'none'}}>
                        <button type="button" className="btn btn-primary btn-sm m-btn m-btn--custom"  
                        onClick={() => this.on_btn_breakdown(row)}>
                            Pembagian Komisi
                        </button>
                    </div>
                </div>
            ),
            showExpandColumn: true,
            expanded: this.state.expanded,
            onExpand: this.handleOnExpand,
            expandHeaderColumnRenderer: ({isAnyExpands}) => {
                if (isAnyExpands) {
                    return <b>-</b>;
                }
                return <b>+</b>;
            },
            expandColumnRenderer: ({expanded}) => {
                if (expanded) {
                    return (<b>-</b>)
                }
                return (<b>+</b>)
            }
        }

        const columns = [{
            dataField: 'id',
            text: 'No.',
            formatter: RowNumber,
            headerAlign: 'center',
            align: 'center',
            style: {width: '3%'}
        },
        {
            dataField: 'tx_col',
            text: 'Transaksi',
            formatter: TxColumn,
            headerAlign: 'left',
            align: 'left',
            style: {width: '15%'}
        },
        {
            dataField: 'pelanggan_col',
            text: 'Layanan',
            formatter: LayananColumn,
            headerAlign: 'left',
            align: 'left',
            style: {width: '25%'}
        },
        {
            dataField: 'trx_price_total',
            text: 'Jumlah Pembayaran',
            headerAlign: 'right',
            align: 'right',
            style: {width: '20%'}
        },
        {
            dataField: 'trx_price_fee_end_user',
            text: 'Biaya Admin',
            headerAlign: 'right',
            align: 'right',
            style: {width: '20%'}
        },
        {
            dataField: 'trx_bonus_received',
            text: 'Bonus',
            headerAlign: 'right',
            align: 'right',
            style: {width: '20%'}
        }]

        const TableTx = (props) =>
            <BootstrapTable 
                keyField='id'
                data={this.state.data_history_fee} striped hover bordered={true} 
                options={this.options} 
                noDataIndication="Tidak ada data riwayat" 
                columns={columns}
                expandRow={expandRow}
                ref={this.setRef}/>

        let tx_result_success = this.state.tx_result_success
        const DetailsCommision = (props) =>
            <Modal className="modal" open={this.state.tx_show_breakdown} onClose={this.on_btn_breakdown_close} center showCloseIcon={false} closeOnOverlayClick={false}>
                    <div className="modal-dialog modal-lg" role="document" style={{marginTop: 0+'px',marginBottom: 0+'px',width:350+'px'}}>
                        <div className="modal-content" style={{border:"white"}}>
                            <div className="modal-header">
                                <h5 className="modal-title">
                                    Pembagian Bonus
                                </h5>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="m-widget4">
                                            {tx_result_success.length !== 0 && tx_result_success.data !== undefined ?
                                                tx_result_success.data.map(function(item, key) {
                                                    return (<div key={key} className="m-widget4__item">
                                                        <div className="m-widget4__img m-widget4__img--pic">
                                                            <img src={item.photo_filename.length !== 0 ? item.photo_filename : '../swipe/assets/app/media/img/users/default.png'} style={{height: 55+'px',width: 55+'px'}}/>
                                                        </div>
                                                        <div className="m-widget4__info">
                                                            <span className="m-widget4__title">
                                                                {item.fullname}
                                                            </span>
                                                            <br/>
                                                            <span className="m-widget4__sub">
                                                                {item.user_type}
                                                            </span>
                                                        </div>
                                                        <div className="m-widget4__ext">
                                                            <a className="m-btn m-btn--pill m-btn--hover-brand btn btn-sm btn-secondary">
                                                                Rp. {item.trx_bonus_received}
                                                            </a>
                                                        </div>
                                                    </div>)
                                            })
                                            : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" onClick={this.on_btn_breakdown_close} className="btn btn-secondary" data-dismiss="modal">
                                    Tutup
                                </button>
                            </div>
                        </div>
                    </div>
            </Modal>

        return (
            <div className="row">
                <div className="col-md-12">
                    <div className="m-portlet m-portlet--tab" style={{minHeight: '-webkit-fill-available'}}>
                        <PortletHead headTxt={"Bonus"} />
                        <PortletBody>
                            <FormGroup>
                                <label style={{fontWeight: 600}}>
                                    Filter Data:
                                </label>
                                <div style={{display:'inline'}}>
                                    <span onClick={() => this.on_filter_label(1)} style={{marginLeft: 10+'px',marginRight: 10+'px', cursor: 'pointer'}}>Hari Ini</span>
                                    /
                                    <span onClick={() => this.on_filter_label(2)} style={{marginLeft: 10+'px',marginRight: 10+'px', cursor: 'pointer'}}>Kemaren</span>
                                    /
                                    <span onClick={() => this.on_filter_label(3)} style={{marginLeft: 10+'px',marginRight: 10+'px', cursor: 'pointer'}}>7 Hari Terakhir</span>
                                    /
                                    <span onClick={() => this.on_filter_label(4)} style={{marginLeft: 10+'px',marginRight: 10+'px',    cursor: 'pointer'}}>30 Hari Terakhir</span>
                                </div>
                                <div className="input-group">
                                    <div className="input-group">
                                        <DatePickerInput
                                            onChange={this.start_date_change}
                                            value={this.state.start_date}
                                        />
                                        <span style={{padding: 7+'px'}}>-</span>
                                        <DatePickerInput
                                            onChange={this.end_date_change}
                                            value={this.state.end_date}
                                        />
                                        &nbsp;&nbsp;&nbsp;
                                        <button type="button" className={this.state.tx_flag_cari_processing ? 'btn btn-secondary btn-sm m-btn m-btn--custom m-loader m-loader--primary m-loader--right' : 'btn btn-secondary btn-sm m-btn m-btn--custom'}  
                                        onClick={() => this.on_btn_cari()}>
                                            Cari
                                        </button>
                                        &nbsp;&nbsp;&nbsp;
                                        <button type="button" className="btn btn-secondary btn-sm m-btn m-btn--custom" 
                                        onClick={() => this.on_btn_reset()}>
                                            Reset
                                        </button>
                                        &nbsp;&nbsp;
                                        <ReactToPrint
                                            trigger={this.renderTrigger}
                                            content={this.renderContent}
                                            onBeforePrint={this.handleBeforePrint}
                                            onAfterPrint={this.handleAfterPrint}
                                            pageStyle="@page{size: auto A4 portrait;}"/>
                                        &nbsp;
                                        {this.state.is_excel_generated ? 
                                            <React.Fragment>
                                                <GeneralExportExcel data={this.state.data_history_fee} 
                                                    type="history_bonus" 
                                                    agent_name={this.props.profile.name}
                                                    filename="history-bonus" 
                                                    title="Riwayat Bonus"/>
                                                &nbsp;&nbsp;
                                                <button className='m-portlet__nav-link btn btn-light m-btn m-btn--pill m-btn--air' 
                                                    onClick={() => this.reset_export_excel()} style={{fontSize:'small'}}>
                                                    Reset Excel
                                                </button>
                                            </React.Fragment>
                                        : <button className='m-portlet__nav-link btn btn-light m-btn m-btn--pill m-btn--air' 
                                            onClick={() => this.gen_export_excel()} style={{fontSize:'small'}}>
                                            Export ke Excel
                                        </button>}
                                    </div>
                                </div>
                            </FormGroup>
                            <br/>
                            <TableTx/>
                            {this.state.show_more_btn ? <button type="button" style={{width: '-webkit-fill-available'}} onClick={this.on_btn_load_more} className={this.state.tx_flag_processing ? 'btn btn-secondary m-loader m-loader--primary m-loader--right' : 'btn btn-secondary'}>
                                Load More
                            </button> : ''}
                            
                        </PortletBody>
                    </div>
                </div>
                <DetailsCommision/>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    update_current_page:  val => dispatch(do_action(action.ACTION_UPDATE_CURRENT_PAGE, val))
})

const mapStateToProps = (state) => ({
    session : state.Session,
    profile: state.Profile
})

export default connect(mapStateToProps, mapDispatchToProps)(AgentHistoryBonus)
