import { combineReducers } from 'redux'
import Profile from './profile'
import Session from './session'
import Balance from './balance'
import CurrentPage from './current_page'

const reducer_app = combineReducers({
	CurrentPage, Session, Balance, Profile
})

export default reducer_app