import React, {Component} from 'react'
import {connect} from 'react-redux'
import Modal from 'react-responsive-modal'
import moment from 'moment'
import request from 'superagent'
import util from '../../helper/util'
import swal from 'sweetalert'
import apis from '../../helper/api'

let photo_default = "../../swipe/assets/app/media/img/users/default.jpg"

class ModalChangePassword extends Component {
	constructor(props) {
        super(props)
        this.state = {
        	flag_processing: false,
        	photo_url: photo_default,
        	konfirm_password: '',
        	password: '',
            type_input_password: 'password'
        }
        this.on_btn_ubah_password = this.on_btn_ubah_password.bind(this)
    }

    componentDidMount() {
    	util.log("change password", this.props.data)
    }

    on_btn_ubah_password(e) {
    	if (this.state.flag_processing) {return}
    	if (this.state.password.length === 0 || this.state.konfirm_password.length === 0) {
            swal('Error', 'Periksa kembali form password Anda', 'error')
            return
        }
        const self = this
        let body = {
            "session_id" : this.props.session,
            "code" : this.props.data.workstation_code,
            "password_new" : this.state.password,
            "password_confirm" : this.state.konfirm_password
        }
        let url = apis.change_password_workstation
        self.setState({flag_processing: true})
        request.post(url)
            .set("Content-Type", "application/json")
            .send(body)
            .end(function(err, res) {
                if (err || !res.ok) {
                    util.log("change_password", err)
                } else {
                    util.log("change_password", res);
                    if (res.statusCode == 200) {
                        let payload = res.body
                        if (payload.status == "OK") {
                            swal("Sukses", payload.error.message, 'success')
                            self.setState({password:'', konfirm_password: ''})
                        } else {
                            swal("Ups!", payload.error.message, "warning")
                        }
                    } else {
                        swal("Error!", res.statusCode, "warning")
                    }
                }
                self.setState({flag_processing: false})
            })
    }

    on_show_hide_password(e) {
        util.log("on_show_hide_password", e)
        if (this.state.type_input_password === 'password') {
            this.setState({type_input_password: 'text'})
        } else {
            this.setState({type_input_password: 'password'})
        }
    }

    render() {
    	return (<Modal className="modal" open={true} center onClose={this.props.on_close} showCloseIcon={true} closeOnOverlayClick={true}>
	            <div className="modal-dialog modal-lg" role="document" style={{marginTop: 0+'px',marginBottom: 0+'px',width: '700px'}}>
	                <div className="modal-content" style={{border:"white"}}>
	                    <div className="modal-header">
	                        <h5 className="modal-title">
	                            CHANGE PASSWORD OPERATOR
	                        </h5>
	                    </div>
	                    <div className="modal-body">
	                        <div className="row">
				            	<div className="col-lg-12">
				                    <div className="m-portlet__body">
				                    	<div className="m-card-profile__pic">
				                            <div className="m-card-profile__pic-wrapper" style={{textAlign: 'center'}}>
				                                <img src={this.state.photo_url} style={{width:'100px',height:'100px'}}/>
				                            </div>
				                        </div>
				                        <div className="m-card-profile__details" style={{textAlign: 'center', marginTop:'5px',    marginBottom:'10px'}}>
				                            <span className="m-card-profile__name">
				                                {this.props.data.workstation_code} {this.props.data.workstation_name}
				                            </span>
				                        </div>
				                        <div style={{margin: '0 10px'}}>
				                            <div className="form-group m-form__group row">
				                                <label htmlFor="example-text-input" className="col-3 col-form-label" style={{textAlign: 'right'}}>
				                                    Password Baru
				                                </label>
				                                <div className="col-9" style={{margin: 'auto 0'}}>
				                                    <div className="m-input-icon m-input-icon--right">
		                                                <input className="form-control m-input" 
		                                                name="password"
		                                                type={this.state.type_input_password} 
		                                                onChange={(e) => this.setState({password: e.target.value})}
		                                                value={this.state.password}/>
		                                                <span className="m-input-icon__icon m-input-icon__icon--right" style={{width: '4rem'}}>
		                                                    <span onClick={(e) => this.on_show_hide_password(e)}>
		                                                        <i className={this.state.type_input_password === 'password' ? 'la la-eye' : 'la la-eye-slash'}></i>
		                                                    </span>
		                                                </span>
		                                            </div>
				                                </div>
				                            </div>
				                            <div className="form-group m-form__group row">
				                                <label htmlFor="example-text-input" className="col-3 col-form-label" style={{textAlign: 'right'}}>
				                                    Konfirm Password
				                                </label>
				                                <div className="col-9" style={{margin: 'auto 0'}}>
			                                        <div className="m-input-icon m-input-icon--right">
		                                                <input className="form-control m-input" 
		                                                name="konfirm_password"
		                                                type={this.state.type_input_password} 
		                                                onChange={(e) => this.setState({konfirm_password: e.target.value})}
		                                                value={this.state.konfirm_password}/>
		                                                <span className="m-input-icon__icon m-input-icon__icon--right" style={{width: '4rem'}}>
		                                                    <span onClick={(e) => this.on_show_hide_password(e)}>
		                                                        <i className={this.state.type_input_password === 'password' ? 'la la-eye' : 'la la-eye-slash'}></i>
		                                                    </span>
		                                                </span>
		                                            </div>
				                                </div>
				                            </div>
				                            <button onClick={(e) => this.on_btn_ubah_password(e)} className={this.state.flag_processing ? 'btn btn-secondary m-btn--bolder m-loader m-loader--primary m-loader--right' : 'btn btn-primary m-btn  m-btn--bolder'} style={{fontSize: 'small',paddingTop:'7px',paddingBottom:'7px',display:'flex',marginLeft:'auto'}}>Submit Ubah Password</button>  
				                        </div>
				                        
				                    </div>
				                </div>
				            </div>
	                    </div>
	                    <div className="modal-footer">
	                    </div>
	                </div>
	            </div>
	        </Modal>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({})

const mapStateToProps = (state) => ({
	session : state.Session
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalChangePassword)