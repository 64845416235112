import {connect} from 'react-redux';
import React, {Component} from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch,
  withRouter
} from "react-router-dom";
import {history} from '../helper/history';
import Index from '../layout/metronic/index';
import AgentProfile from '../component/agent_profile';
import AgentProfileMaster from '../component/agent_profile_master';
import ChangePassword from '../component/change_password';
import AgentKredit from '../component/agent_kredit';
import AgentHome from '../component/agent_home';
import AgentIsiPulsa from '../component/agent_isi_pulsa';
import AgentPaketData from '../component/agent_paket_data';
import AgentPLN from '../component/agent_pln';
import AgentPDAM from '../component/agent_pdam';
import AgentBPJS from '../component/agent_bpjs';
import AgentHPPascabayar from '../component/agent_hp_pascabayar';
import AgentPajak from '../component/agent_pajak';
import AgentTV from '../component/agent_tv';
import AgentHistoryTx from '../component/agent_history_tx';
import AgentHistoryDeposit from '../component/agent_history_deposit';
import AgentHistoryCommision from '../component/agent_history_commision';
import AgentHistoryBonus from '../component/agent_history_bonus';
import AgentEcommerce from '../component/agent_ecommerce';
import AgentTelkom from '../component/agent_telkom';
import AgentAsuransi from '../component/agent_asuransi';
import AgentTiket from '../component/agent_tiket';
import AgentVoucher from '../component/agent_voucher';
import AgentGas from '../component/agent_gas';
import AgentSettings from '../component/agent_settings';
import ElectronPrinterSettings from '../component/electron_printer_settings';
import KodeProduk from '../component/kode_product';
import AgentDonasi from '../component/agent_donation';
import ReportMonthly from '../component/agent_report_monthly';
import ReportDaily from '../component/agent_report_daily';
import Inbox from '../component/inbox';
import TopupVA from '../component/topup';
import AgentBatchTransaction from '../component/agent_batch_transaction';
import AgentBatchTransaction2 from '../component/agent_batch_transaction2';
import AgentWorkstation from '../component/agent_workstation';
import AgentKurirKetentuan from '../component/agent_kurir_ketentuan';
import AgentKurirRegister from '../component/agent_kurir_register';
import AgentMLOPosting from '../component/agent_mlo_posting';
import AgentMLORiwayat from '../component/agent_mlo_riwayat';
import AgentMLOHandover from '../component/agent_mlo_handover';
import AgentCashinCashout from '../component/agent_cashin_cashout';
import AgentMpn from '../component/agent_mpn';
import apis from '../helper/api';
import request from 'superagent';
import localdata from '../helper/localdata';
import config from '../helper/config';
import action from '../helper/action';
import do_action from '../action';
import util from '../helper/util';
import ReactGA from 'react-ga';
import swal from 'sweetalert';

const routes = [
    {
        path: "/agen/settings_printer",
        component: ElectronPrinterSettings,
        exact : false
    },
    {
        path: "/agen/home",
        component: AgentHome,
        exact : false
    },
    {
        path: "/agen/profile",
        component: AgentProfile,
        exact : false
    },
    {
        path: "/agen/profile-master",
        component: AgentProfileMaster,
        exact : false
    },
    {
        path: "/agen/report-monthly",
        component: ReportMonthly,
        exact : false
    },
    {
        path: "/agen/report-daily",
        component: ReportDaily,
        exact : false
    },
    {
        path: "/agen/change-password",
        component: ChangePassword,
        exact : false
    },
    {
        path: "/agen/kredit",
        component: AgentKredit,
        exact : false
    },
    {
        path: "/agen/pulsa",
        component: AgentIsiPulsa,
        exact : false
    },
    {
        path: "/agen/paket-data",
        component: AgentPaketData,
        exact : false
    },
    {
        path: "/agen/pln",
        component: AgentPLN,
        exact : false
    },
    {
        path: "/agen/pdam",
        component: AgentPDAM,
        exact : false
    },
    {
        path: "/agen/bpjs",
        component: AgentBPJS,
        exact : false
    },
    {
        path: "/agen/hp-pascabayar",
        component: AgentHPPascabayar,
        exact : false
    },
    {
        path: "/agen/pajak",
        component: AgentPajak,
        exact : false
    },
    {
        path: "/agen/tv",
        component: AgentTV,
        exact : false
    },
    {
        path: "/agen/riwayat-transaksi",
        component: AgentHistoryTx,
        exact : false
    },
    {
        path: "/agen/riwayat-deposit",
        component: AgentHistoryDeposit,
        exact : false
    },
    {
        path: "/agen/topup-va",
        component: TopupVA,
        exact : false
    },
    {
        path: "/agen/inbox",
        component: Inbox,
        exact : false
    },
    {
        path: "/agen/ecommerce",
        component: AgentEcommerce,
        exact : false
    },
    {
        path: "/agen/telkom",
        component: AgentTelkom,
        exact : false
    },
    {
        path: "/agen/asuransi",
        component: AgentAsuransi,
        exact : false
    },
    {
        path: "/agen/tiket",
        component: AgentTiket,
        exact : false
    },
    {
        path: "/agen/voucher",
        component: AgentVoucher,
        exact : false
    },
    {
        path: "/agen/gas",
        component: AgentGas,
        exact : false
    },
    {
        path: "/agen/donasi",
        component: AgentDonasi,
        exact : false
    },
    {
        path: "/agen/riwayat-fee",
        component: AgentHistoryCommision,
        exact : false
    },
    {
        path: "/agen/batch-transaction",
        component: AgentBatchTransaction2,
        exact : false
    },
    {
        path: "/agen/batch-transaction2",
        component: AgentBatchTransaction,
        exact : false
    },
    {
        path: "/agen/kode-produk",
        component: KodeProduk,
        exact : false
    },
    {
        path: "/agen/riwayat-bonus",
        component: AgentHistoryBonus,
        exact : false
    },
    {
        path: "/agen/settings",
        component: AgentSettings,
        exact : false
    },
    {
        path: "/agen/manage-workstation",
        component: AgentWorkstation,
        exact : false
    },
    {
        path: "/agen/kurir-syarat",
        component: AgentKurirKetentuan,
        exact : false
    },
    {
        path: "/agen/kurir-register",
        component: AgentKurirRegister,
        exact : false
    },
    {
        path: "/agen/mlo-posting",
        component: AgentMLOPosting,
        exact : false
    },
    {
        path: "/agen/mlo-riwayat",
        component: AgentMLORiwayat,
        exact : false
    },
    {
        path: "/agen/mlo-handover",
        component: AgentMLOHandover,
        exact : false
    },
    {
        path: "/agen/tunai-transfer",
        component: AgentCashinCashout,
        exact : false
    },
    {
        path: "/agen/mpn",
        component: AgentMpn,
        exact : false
    }

];

const SubRoutes = route => (
    <Route
        exact={route.exact}
        path={route.path}
        render={props => (
            <route.component {...props} routes={route.routes}/>
        )}
    />
);

let task_schedule_counter = 0

class HomeAgent extends Component {

    constructor(props) {
        super(props)
        task_schedule_counter = 0
        ReactGA.initialize(config.ga.prop)
        localdata.get_login_result().then((payload) => {
            util.log("data", payload)
            let valid = true
            if (payload.status === "OK") {
                if (payload.results) {
                    if (payload.results.session_id) {
                        this.props.update_session(payload.results.session_id)
                    } else {
                        valid = false
                    }
                    let p = {
                        userid : payload.results.user_id,
                        masterid : payload.results.master_id,
                        usertype : payload.results.user_type,
                        username : payload.results.username,
                        paymode : payload.results.paymode
                    }
                    if (payload.results.workstation_code) {
                        p.workstation_code = payload.results.workstation_code
                    }
                    if (payload.results.profile) {
                        let profile = payload.results.profile
                        let p2 = {
                            name : profile.fullname, 
                            email : profile.email,
                            photo : profile.photo_filename
                        }
                        if (profile.workstation_name.length !== 0) {
                            p2.workstation_name = profile.workstation_name
                        }
                        p = Object.assign({}, p, p2);
                    }
                    if (payload.results.bank_accounts) {
                        let banks = []
                        payload.results.bank_accounts.map(function(item, i) {
                            banks.push({"bank_name" : item.bank_name, "account_name" : item.account_name, "account_number" : item.account_number})
                        })
                        p = Object.assign({}, p, {"bank_accounts" : banks});
                    }
                    this.props.update_profile(p)
                    if (payload.results.list_balances) {
                        let deposits = payload.results.list_balances.deposits
                        util.log("deposit: ", deposits[0])
                        this.props.update_balance(deposits[0].balance_value)
                    }
                } else {
                    valid = false
                }
            } else {
                valid = false
            }
            if (!valid) {
                setTimeout(() => {
                    history.replace('/login')
                }, 500);
            } else {
                this.task()
                this.task_schedule()
            }
        }).catch((err) => {
            util.log("error : ", err)
            setTimeout(() => {
                history.replace('/login')
            }, 500);
        })
    }

    componentDidMount() {
        util.log(this.props)
        if (util.is_electron()) {
            window.ipcRenderer.on('rpc-response', (event, res) => {
                util.log('rpc-response', res);
                if (res.method === 'test_print'
                    || res.method === 'print') {
                    if (res.return.result === 'NOK') {
                        swal('Error', res.return.message, 'error');
                        let emessage = res.return.message;
                        if (emessage instanceof Object) {
                            emessage = JSON.stringify(emessage);
                        }
                        swal('Error', emessage, 'error');
                    }
                } else if (res.method === 'get_uuid') {
                    localdata.set_property('device_id', res.return).then((val) => {
                        util.log('set property device_id: ', val);
                    }).catch((err) => {
                        util.log(err);
                    });
                }
            });
            let params = {method: "get_uuid"};
            util.log('rpc-request', params);
            window.ipcRenderer.send('rpc-request', params);
        }
    }

    task_schedule() {
        let delay = 1 * 60 * 1000
        let self = this
        setTimeout(function() {
            task_schedule_counter++;
            self.task()
            self.task_schedule()
        }, delay);
    }

    task() {
        if (task_schedule_counter == 0 || task_schedule_counter % 30 == 0) {
            this.task_load_product();
            // this.task_load_platinum();

        }
        if (task_schedule_counter == 0 || task_schedule_counter % 15 == 0) {
            this.task_update_balance()
        }
            
    }

    task_update_balance() {
        util.log("task_update_balance " + new Date())
        let body = {
            "session_id" : this.props.session
        }
        let self = this
        request.post(apis.balance_main)
            .set("Content-Type", "application/json")
            .send(body)
            .end(function(err, res) {
                if (err || !res.ok) {
                    util.log("task_update_balance", err)
                } else {
                    util.log("task_update_balance", res);
                    if (res.statusCode == 200) {
                        let payload = res.body
                        if (payload.status == "OK") {
                            if (payload.results.list) {
                                let deposits = payload.results.list
                                self.props.update_balance(deposits[0].balance_value)
                            }
                        } else {
                            util.on_error(payload.error)
                        }
                    }
                }
            });
    }

    task_load_product() {
        util.log("task_load_product")
        let body = {
            "session_id" : this.props.session,
            "include_platinum": true
        }
        let self = this
        request.post(apis.services)
            .set("Content-Type", "application/json")
            .send(body)
            .end(function(err, res) {
                if (err || !res.ok) {
                    util.log("task_load_product", err)
                } else {
                    util.log("task_load_product", res);
                    if (res.statusCode == 200) {
                        let payload = res.body
                        if (payload.status == "OK") {
                            if (payload.results.list !== null) {
                                localdata.set_list_services(payload.results.list).then((res) => {
                                    util.log("task_load_product", "products updated..")
                                }).catch((err) => {
                                    util.log("task_load_product", err)
                                })
                            }
                        } else {
                            util.on_error(payload.error)
                        }
                    }
                }
            });

        //feri adding platinum service
util.log("set_list_platinum");
        request.post(apis.service_platinum)
        .set("Content-Type", "application/json")
        .send(body)
        .end(function(err, res) {
            if (err || !res.ok) {
                util.log("set_list_platinum", err)
            } else {
                util.log("set_list_platinum", res);
                if (res.statusCode == 200) {
                    let payload = res.body
                    if (payload.status == "OK") {
                        if (payload.results.list !== null) {
                            localdata.set_list_platinum(payload.results.list).then((res) => {
                                util.log("set_list_platinum", "products updated..")
                            }).catch((err) => {
                                util.log("set_list_platinum", err)
                            })
                        }
                    } else {
                        util.on_error(payload.error)
                    }
                }
            }
        });


    }

    
    // task_load_platinum() {
    //     util.log("task_load_platinum")
    //     let body = {
    //         "session_id" : this.props.session
    //     }
    //     let self = this
    //     request.post(apis.service_platinum)
    //         .set("Content-Type", "application/json")
    //         .send(body)
    //         .end(function(err, res) {
    //             if (err || !res.ok) {
    //                 util.log("task_load_product_platinum", err)
    //             } else {
    //                 util.log("task_load_product_platinum", res);
    //                 if (res.statusCode == 200) {
    //                     let payload = res.body
    //                     if (payload.status == "OK") {
    //                         if (payload.results.list !== null) {
    //                             localdata.set_list_services_platinum(payload.results.list).then((res) => {
    //                                 util.log("task_load_product_platinum", "products updated..")
    //                             }).catch((err) => {
    //                                 util.log("task_load_product_platinum", err)
    //                             })
    //                         }
    //                     } else {
    //                         util.on_error(payload.error)
    //                     }
    //                 }
    //             }
    //         });
    // }

    render() {
        return (
            <Router>
            	<Index url={this.props.location.pathname}>
                    <Switch>
                        {routes.map((route, i) => <SubRoutes key={i} {...route} />)}
                        <Route exact path='/agen' render={props => <AgentHome {...props} />}/>
                        <Route path="*" render={props => <AgentHome {...props} />} />
                    </Switch>
                </Index>
            </Router>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    update_session: val => dispatch(do_action(action.ACTION_UPDATE_USER_SESSION, val)),
    update_balance: val => dispatch(do_action(action.ACTION_UPDATE_USER_BALANCE, val)),
    update_profile: payload => dispatch(do_action(action.ACTION_UPDATE_USER_INFO, payload))
})

const mapStateToProps = (state) => ({
    session : state.Session
})

export default connect(mapStateToProps, mapDispatchToProps)(HomeAgent)