import React, {Component} from 'react';
import ReactExport from 'react-export-excel';
import util from '../../helper/util';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const headerColStyle = {fill: {patternType: "solid", fgColor: {rgb: "ffd300"}}};

class GeneralExportExcel extends Component {

    constructor(props) {
        super(props)
        this.state = {data: []};
    }

    componentDidMount() {
        console.log("didMount", this.props);
        let dataArr = [];

        let i = 0;
        if (this.props.type === 'history_tx') {
            dataArr.push([{value: "History Transaksi Agen " + this.props.agent_name, style: {font: {sz: "10", bold: true}}}]);
            dataArr.push([{value: "No", style: headerColStyle}, {value: "TXID", style: headerColStyle}, {value: "Produk", style: headerColStyle}, {value: "Bill No.", style: headerColStyle}, {value: "Nominal", style: headerColStyle}, {value: "Saldo Sebelum (Rp)", style: headerColStyle}, {value: "Saldo Sesudah (Rp)", style: headerColStyle}, {value: "Terminal", style: headerColStyle}, {value: "Waktu", style: headerColStyle}]);
            this.props.data.forEach(function(row) {
                i++;
                let terminal = row.app_info ? row.app_info.toUpperCase() : (row.app_platform ? row.app_platform.toUpperCase() : '');
                dataArr.push([{value: i.toString()}, {value: row.trx_code.toUpperCase()}, {value: row.product_name.toString()}, {value: row.biller_number.toString()}, {value: row.total.toString()}, {value: row.bal_before.toString()}, {value: row.bal_after.toString()}, {value: terminal}, {value: row.date_time.toString()}]);
            });
        } else if (this.props.type === 'history_komisi') {
            dataArr.push([{value: "History Komisi Agen " + this.props.agent_name, style: {font: {sz: "10", bold: true}}}]);
            dataArr.push([{value: "No", style: headerColStyle}, {value: "Transaksi", style: headerColStyle}, {value: "Layanan", style: headerColStyle}, {value: "Jumlah Pembayaran", style: headerColStyle}, {value: "Biaya Admin", style: headerColStyle}, {value: "Komisi", style: headerColStyle}]);
            this.props.data.forEach(function(row) {
                i++;
                dataArr.push([{value: i.toString()}, {value: row.trx_datetime.toString()}, {value: row.product_name.toString()}, {value: row.trx_price_total.toString()}, {value: row.trx_price_fee_end_user.toString()}, {value: row.trx_commission_received.toString()}]);
            });
        } else if (this.props.type === 'history_bonus') {
            dataArr.push([{value: "History Bonus Agen " + this.props.agent_name, style: {font: {sz: "10", bold: true}}}]);
            dataArr.push([{value: "No", style: headerColStyle}, {value: "Transaksi", style: headerColStyle}, {value: "Layanan", style: headerColStyle}, {value: "Jumlah Pembayaran", style: headerColStyle}, {value: "Biaya Admin", style: headerColStyle}, {value: "Bonus", style: headerColStyle}]);
            this.props.data.forEach(function(row) {
                i++;
                dataArr.push([{value: i.toString()}, {value: row.trx_datetime.toString()}, {value: row.product_name.toString()}, {value: row.trx_price_total.toString()}, {value: row.trx_price_fee_end_user.toString()}, {value: row.trx_bonus_received.toString()}]);
            });
        } else if (this.props.type === 'history_deposit') {
            dataArr.push([{value: "History Deposit Agen " + this.props.agent_name, style: {font: {sz: "10", bold: true}}}]);
            dataArr.push([{value: "No", style: headerColStyle}, {value: "Tipe", style: headerColStyle}, {value: "Transaksi", style: headerColStyle}, {value: "Deskripsi", style: headerColStyle}, {value: "Saldo Sebelum", style: headerColStyle}, {value: "Saldo Sesudah", style: headerColStyle}, {value: "Waktu", style: headerColStyle}]);
            this.props.data.forEach(function(row) {
                i++;
                dataArr.push([{value: i.toString()}, {value: row.tx_type.toString()}, {value: row.tx_code.toUpperCase()}, {value: row.description.toString() + ' ' + row.amount.toString()}, {value: row.bal_before.toString()}, {value: row.bal_after.toString()}, {value: row.tx_date.toString()}]);
            });
        }

        let dataMultiSet = [{columns: [], data: dataArr}];
        console.log("dataMultiSet", dataMultiSet);
        this.setState({data: dataMultiSet});
    }

    render() {
        return (
            <React.Fragment>
                <ExcelFile filename={this.props.filename} element={<button className="m-portlet__nav-link btn btn-light m-btn m-btn--pill m-btn--air" style={{fontSize:'small'}}>Download</button>}>
                    <ExcelSheet dataSet={this.state.data} name={this.props.title}/>
                </ExcelFile>
            </React.Fragment>
        );
    }
}

export default GeneralExportExcel;