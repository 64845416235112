import {connect} from 'react-redux'
import React, {Component} from 'react'
import ReactDOM from 'react-dom'
import moment from 'moment'
import request from 'superagent'
import MetronicIndex from '../layout/metronic/index'
import {Form, FormGroup} from '../component/form/form'
import {Portlet, PortletHead, PortletBody, PortletFooter, PortletAlert, PortletStruk} from '../component/widget/portlet'
import apis from '../helper/api'
import localdata from '../helper/localdata'
import action from '../helper/action'
import do_action from '../action'
import config from '../helper/config'
import swal from 'sweetalert'
import util from '../helper/util'
import Modal from 'react-responsive-modal'
import CetakStruk from '../component/form/cetak_struk'
import ReactGA from 'react-ga'

let tx_product = ""
let start_time_process_seconds = 0

class AgentVoucher extends Component {
    constructor(props) {
        super(props)
        this.state = {
            no_pelanggan: "",
            product_prepaid: [],
            product_denom_prepaid: [],
            tx_product_denom_prepaid: "",
            tx_product_prepaid: "",
            tx_tagihan_prepaid: "",
            tx_show_modal: false,
            tx_show_struk: false,
            tx_flag_processing: false,
            tx_result_message: "",
            tx_result_success: "",
            show_info_pelanggan: false, nama_pelanggan: "", nomor_pelanggan: "",
            flag_inquiry_processing: false,
            not_support : ""
        }
        this.on_btn_submit = this.on_btn_submit.bind(this)
        this.on_btn_reset = this.on_btn_reset.bind(this)
        this.on_btn_confirm_cancel = this.on_btn_confirm_cancel.bind(this)
        this.on_btn_confirm_ok = this.on_btn_confirm_ok.bind(this)
        this.on_btn_struk_cancel = this.on_btn_struk_cancel.bind(this)
        this.on_btn_struk_ok = this.on_btn_struk_ok.bind(this)
        this.on_btn_cetak_struk = this.on_btn_cetak_struk.bind(this)
        this.total_bayar_prepaid = this.total_bayar_prepaid.bind(this)
    }

    componentDidMount() {
        let self = this
        this.scroll_to_top()
        this.props.update_current_page('voucher')
        localdata.get_product("SERVICE_VOUCHER").then((payload) => {
            util.log("SERVICE_VOUCHER: ", payload)
            if (payload.length !== 0) {
                self.setState({product_prepaid : payload})
            }
        }).catch((err) => {
            util.log("error : ", err)
        })
    }

    scroll_step() {
        if (window.pageYOffset === 0) {
            clearInterval(this.state.scroll_interval_id);
        }
        window.scroll(0, window.pageYOffset - 50);
    }

    scroll_to_top() {
        let scroll_interval_id = setInterval(this.scroll_step.bind(this), 16.66);
        this.setState({scroll_interval_id: scroll_interval_id});
    }

    on_input_change(e) {
        if (e.target.name === "no_pelanggan") {
            this.setState({no_pelanggan: e.target.value})
        } else {
            this.setState({[e.target.name]: e.target.value})
        }
    }

    on_btn_cetak_struk(el) {
        util.log("on_btn_cetak_struk");
        if (this.state.tx_result_success.length !== 0) {
            util.log(this.state.tx_result_success.data);
            if (util.is_electron()) {
                const btn = el.target;
                btn.classList.add("m-loader", "m-loader--primary", "m-loader--right");
                setTimeout(function() {
                    btn.classList.remove("m-loader", "m-loader--primary", "m-loader--right");
                }, 3000);
                const agent_info = this.props.profile;
                util.electron_print_receipt(agent_info, this.state.tx_result_success.data);
            } else {
                this.setState({tx_show_struk: true});
            }
        }
    }

    on_btn_submit() {
        if (this.state.no_pelanggan.length === 0) {
            swal("Ups!", "Nomor pelanggan tidak boleh kosong", "warning")
            return
        }
        if (tx_product.length === 0) {
            swal("Ups!", "Denom tidak boleh kosong", "warning")
            return
        }
        util.log("tx_product_denom:" + tx_product.product_denom, "tx_product_name: " + tx_product.tx_product_name)
        this.setState({tx_show_modal: true, show_info_pelanggan: false, nama_pelanggan: "", nomor_pelanggan: ""})
        this.total_bayar_prepaid()
    }

    on_btn_reset() {
        this.setState({no_pelanggan:"",product_denom_prepaid:[],tx_result_message:"",tx_result_success:"",tx_product_denom:"",not_support:""})
        tx_product = ""
    }

    on_btn_confirm_ok() {
        if (this.state.tx_flag_processing) return
        if (tx_product !== "") {
            let whitelabel_36 = config.whitelabel.toString(36).toUpperCase()
            let user_id = this.props.profile.userid.toString(36).toUpperCase()
            let prefix = whitelabel_36.substring(whitelabel_36.length - 3)
            let suffix = user_id.substring(user_id.length - 3)
            let token = util.gen_token(prefix, this.props.session, suffix)
            let biller_number = this.state.no_pelanggan
            if (biller_number.startsWith("62")) {
                biller_number = "0" + biller_number.substring(2, biller_number.length)
            }
            let body = {
                "session_id" : this.props.session,
                "product_id" : tx_product.product_id,
                "product_code" : tx_product.product_denom,
                "biller_number" : biller_number,
                "sign_token" : token
            }
            if (this.state.nama_pelanggan !== undefined && this.state.nama_pelanggan.length !== 0) {
                body.customer_name = this.state.nama_pelanggan
            }
            if (this.state.nomor_pelanggan !== undefined && this.state.nomor_pelanggan.length !== 0) {
                body.customer_phone_number = this.state.nomor_pelanggan
            }
            let self = this
            self.setState({tx_flag_processing: true})
            start_time_process_seconds =  Math.floor(Date.now() / 1000)
            request.post(apis.purchase)
                .set("Content-Type", "application/json")
                .timeout(120*1000)
                .send(body)
                .timeout(120*1000)
                .end(function(err, res) {
                    let message_result = "";
                    let success_result = "";
                    let show_modal = true;
                    if (err || !res.ok) {
                        util.log(err)
                        message_result = {title: "Error", body: err.message}
                        if (res !== undefined) {
                            message_result.body = "Error code " + res.statusCode
                        }
                        if (err.code === 504) {
                            message_result.body = config.timeout_message
                        }
                    } else {
                        util.log(res);
                        if (res.statusCode === 200) {
                            let payload = res.body
                            util.log(payload)
                            if (payload.status === "OK") {
                                let results = payload.results
                                let success_info = "Biller No: " + results.biller_number + "|Produk: " + results.product_id + " " + results.product_name + "|Harga Produk: Rp. " + util.rupiah_format(results.nominal) + "|Biaya Admin: Rp. " + util.rupiah_format(results.fee) + "|Total Bayar: Rp. " + util.rupiah_format(results.total) 
                                success_result = {title: "Transaksi Sukses", body: success_info, data: results}
                                self.props.update_balance(results.balance_after)
                                swal(success_result.title, payload.display_message, "success")
                                if (start_time_process_seconds !== 0) {
                                    let end_time_process = Math.floor(Date.now() / 1000)
                                    let action = config.ga.event.purchase + '-' + util.time_range_ga(end_time_process-start_time_process_seconds)
                                    ReactGA.event({
                                        category: 'transaction',
                                        action: action,
                                        label: results.product_name
                                    })
                                }
                                show_modal = false;
                            } else {
                                util.on_error(payload.error)
                                message_result = {title: "Error", body: res.body.error.message}
                                if (res.body.display_message !== undefined) {
                                    message_result.body = message_result.body + ". " + res.body.display_message
                                }
                            }
                        } else {
                            message_result = {title: "Error", body: res.statusCode}
                        }
                    }
                    if (message_result !== null && message_result.length !== 0) {
                        swal(message_result.title, message_result.body, "error")
                        message_result.title = "[" + moment().format("DD-MM-YYYY hh:mm") + "] " + message_result.title
                    }
                    self.setState({tx_flag_processing: false, tx_result_message: message_result, tx_result_success: success_result, tx_show_modal: show_modal})
                    start_time_process_seconds = 0
                });
        }
    }

    on_btn_confirm_cancel() {
        this.setState({tx_show_modal: false})
    }

    on_btn_struk_ok() {
        this.setState({tx_show_struk: false})
    }

    on_btn_struk_cancel() {
        this.setState({tx_show_struk: false})
    }

    on_denom_clicked(e) {
        let target = e.currentTarget
        tx_product = {
            "product_id" : target.dataset.product_id,
            "product_denom" : target.dataset.product,
            "product_name" : target.dataset.name
        }
        this.setState({tx_product_denom: target.dataset.product})
    }

    on_select_change_prepaid(e) {
        let self = this
        if (e.target.value.length !== 0) {
            let val = e.target.value
            localdata.get_denom_by_product_id("SERVICE_VOUCHER", val).then((payload) => {
                util.log("SERVICE_VOUCHER [" + val + "] : ", payload)
                if (payload.length !== 0) {
                    self.setState({product_denom_prepaid : payload})
                }
            }).catch((err) => {
                util.log("error : ", err)
            })
        } else {
            self.setState({product_denom_prepaid : []})
        }
        this.setState({tx_product_prepaid: e.target.value})
    }

    total_bayar_prepaid() {
        let biller_number = this.state.no_pelanggan;
        if (biller_number.startsWith("62")) {
            biller_number = "0" + biller_number.substring(2, biller_number.length);
        }
        let self = this;
        let body = {
            "session_id" : self.props.session,
            "product_id" : tx_product.product_id,
            "product_code" : tx_product.product_denom,
            "biller_number": biller_number
        };
        self.setState({tx_tagihan_prepaid: ""});
        start_time_process_seconds = Math.floor(Date.now() / 1000);
        request.post(apis.inquiry)
            .set("Content-Type", "application/json")
            .timeout(120*1000)
            .send(body)
            .end(function(err, res) {
                let tagihan = "";
                if (err || !res.ok) {
                    util.log(err);
                    tagihan = "-";
                } else {
                    util.log(res);
                    if (res.statusCode === 200) {
                        let payload = res.body;
                        if (payload.status === "OK") {
                            let results = payload.results;
                            tagihan = {
                                "nominal" : 'Rp. ' + util.rupiah_format(results.nominal),
                                "fee" : 'Rp. ' + util.rupiah_format(results.fee),
                                "total" : 'Rp. ' + util.rupiah_format(results.total),
                                "info" : results.info
                            };
                            if (start_time_process_seconds !== 0) {
                                let end_time_process = Math.floor(Date.now() / 1000);
                                let action = config.ga.event.inquiry + '-' + util.time_range_ga(end_time_process-start_time_process_seconds);
                                ReactGA.event({
                                    category: 'transaction',
                                    action: action,
                                    label: results.product_name
                                });
                            }
                        } else {
                            util.on_error(payload.error);
                            tagihan = "-";
                            if (payload.display_message.length !== 0) {
                                tagihan = payload.display_message;
                            } else {
                                tagihan = payload.error.message;
                            }
                            swal("Error", tagihan, "error");
                        }
                    } else {
                        tagihan = "-";
                    }
                }
                self.setState({tx_tagihan_prepaid: tagihan});
                start_time_process_seconds = 0;
            })
    }

    denoms_view() {
        const item_per_row = 3
        const rows = []
        let self = this
        let product_denom = self.state.product_denom_prepaid
        const items = product_denom.denoms.map((denom, i) => {
                    return (<a data-product={denom.product_code} data-name={denom.product_name} data-product_id={product_denom.product_id} key={i} 
                            id={"product-item-" + i}
                            className="m-nav-grid__item m-nav-grid__item product-item" 
                            onClick={self.on_denom_clicked.bind(this)} 
                            style={this.state.tx_product_denom ===  denom.product_code ? {backgroundColor : 'cadetblue',cursor:'pointer'} : {cursor:'pointer'}}>
                            <i className="m-nav-grid__icon flaticon-folder"></i>
                            <span className="m-nav-grid__text" style={this.state.tx_product_denom ===  denom.product_code ? {color:'white',fontWeight:600} : {color:'#575962',fontWeight:600}}>
                                {denom.product_name}
                            </span>
                        </a>
                    )
                })
        for(let row = 0; row < Math.ceil(product_denom.denoms.length / item_per_row); row += 1) {
            rows.push(<div key={ `row-${row}` } className="m-nav-grid__row">{items.slice(row*item_per_row, (row*item_per_row) + item_per_row)}</div>)
        }
        return rows
    }

    render() {
        let self = this
        let tx_result_success = this.state.tx_result_success.data

        const DenomView = (props) => 
            <div style={{background:'#fff',border:'4px solid #f7f7fa',marginBottom:'15px'}} data-code-preview="true" data-code-html="true" data-code-js="false">
                <div className="m-demo__preview">
                    <div className="m-nav-grid">
                        {self.denoms_view()}
                    </div>
                </div>
            </div>

        const TagihanView = (props) => 
            <div className="col-lg-6">
                <div className="m-portlet m-portlet--success m-portlet--head-solid-bg m-portlet--rounded" style={{minHeight:'500px',background:'#fff'}}>
                    <div className="m-portlet__head">
                        <div className="m-portlet__head-caption">
                            <div className="m-portlet__head-title">
                                <span className="m-portlet__head-icon">
                                    <i className="flaticon-interface-5"></i>
                                </span>
                                <h3 className="m-portlet__head-text">
                                    Tagihan Pelanggan 
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="m-portlet__body">
                        <div className="row">
                            <div className="col-lg-12">
                                <form>
                                    <div className="form-group m-form__group">
                                        <span className="m--font-boldest">
                                            No Pelanggan:
                                        </span>
                                        <p className="form-control-static">{this.state.no_pelanggan}</p>
                                    </div>
                                    <div className="form-group">
                                        <span className="m--font-boldest">
                                            Produk ID:
                                        </span>
                                        <p className="form-control-static">{tx_product.product_id}</p>
                                    </div>
                                    <div className="form-group">
                                        <span className="m--font-boldest">
                                            Produk:
                                        </span>
                                        <p className="form-control-static">{tx_product.product_name}</p>
                                    </div>
                                    <div className="form-group">
                                        <span className="m--font-boldest">
                                            Info:
                                        </span>
                                        <p className="form-control-static">{this.state.tx_tagihan_prepaid.info ? 
                                            this.state.tx_tagihan_prepaid.info.split('|').map(function(item, key) {
                                                return (
                                                    <span key={key}>
                                                        {item}
                                                        <br/>
                                                    </span>
                                                )
                                            }) : '-'}</p>
                                    </div>
                                    <div className="form-group row" style={{marginBottom: 0}}>
                                        <div className="col-lg-4">
                                            <span className="m--font-boldest">
                                                Harga:
                                            </span>
                                            <p className="form-control-static">
                                                {this.state.tx_tagihan_prepaid.length !== 0 ? this.state.tx_tagihan_prepaid.nominal !== undefined ? this.state.tx_tagihan_prepaid.nominal : '-' : '-'}
                                            </p>
                                        </div>
                                        <div className="col-lg-4">
                                            <span className="m--font-boldest">
                                                Biaya Admin:
                                            </span>
                                            <p className="form-control-static">
                                                {this.state.tx_tagihan_prepaid.length !== 0 ? this.state.tx_tagihan_prepaid.fee !== undefined ? this.state.tx_tagihan_prepaid.fee : '-' : '-'}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <span className="m--font-boldest">
                                            Total Bayar:
                                        </span>
                                        <div className="form-control-static">
                                            {this.state.tx_tagihan_prepaid.length !== 0 ? this.state.tx_tagihan_prepaid.total !== undefined ? this.state.tx_tagihan_prepaid.total : this.state.tx_tagihan_prepaid :
                                                <div className="m-loader m-loader--brand" 
                                                    style={{width: 30+'px', display: 'inline-block'}}>
                                                </div>}
                                        </div>
                                    </div>

                                    <div className="" style={{cursor: 'pointer', textAlign: 'right', marginBottom: '7px'}} onClick={()=>{self.setState({show_info_pelanggan: !self.state.show_info_pelanggan})}}>
                                        <i className={this.state.show_info_pelanggan ? 'la la-minus' : 'la la-plus'} style={{fontSize: '15px', marginRight: '5px'}}></i>
                                        <span className="m--font-boldest">
                                            Informasi Pembeli
                                        </span>
                                        <span style={{backgroundColor:'#666', height: '8px', marginLeft: '5px'}}/>
                                    </div>

                                    <div className="m-form__section m-form__section--first" style={this.state.show_info_pelanggan ? {display: 'block'} : {display: 'none'}}>
                                        <div className="m-form__group row" style={{marginBottom: '10px'}}>
                                            <label className="col-lg-3" style={{margin: 'auto', fontWeight: 600, textAlign: 'left'}}>
                                                Nama:
                                            </label>
                                            <div className="col-lg-9">
                                                <input
                                                    onChange={(e) => this.on_input_change(e)}
                                                    type="text"
                                                    className="form-control m-input"
                                                    name="nama_pelanggan"
                                                    value={this.state.nama_pelanggan}
                                                    placeholder=""/>
                                            </div>
                                        </div>
                                        <div className="m-form__group row" style={{}}>
                                            <label className="col-lg-3" style={{margin: 'auto', fontWeight: 600, textAlign: 'left'}}>
                                                Telepon:
                                            </label>
                                            <div className="col-lg-9">
                                                <input
                                                    onChange={(e) => this.on_input_change(e)}
                                                    type="text"
                                                    className="form-control m-input"
                                                    name="nomor_pelanggan"
                                                    value={this.state.nomor_pelanggan}
                                                    placeholder=""/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className=" m-portlet__foot m-portlet__foot--fit" style={{marginTop:'20px'}}>
                                        <div className="m-form__actions">
                                            <div className="row">
                                                <p className="m--font-boldest" style={{marginLeft: 'auto',marginRight: 'auto',marginTop: '10px',textAlign: 'center',fontWeight: 600,fontSize: 'larger'}}>
                                                    Apakah Anda yakin untuk melanjutkan transaksi ini?
                                                </p>
                                                <div style={{margin:'auto'}}>
                                                    <button type="button" onClick={this.on_btn_confirm_cancel} className="btn btn-secondary" data-dismiss="modal">
                                                        Cancel
                                                    </button>
                                                    &nbsp;&nbsp;
                                                    <button disabled={this.state.tx_tagihan_prepaid.total ? false : true} type="button" onClick={this.on_btn_confirm_ok} className={this.state.tx_flag_processing ? 'btn btn-primary m-loader m-loader--light m-loader--right' : 'btn btn-primary'}>
                                                        OK
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-12">
                        {this.state.tx_result_message.length !== 0 ? <PortletAlert icon="warning" title={this.state.tx_result_message.title} message={this.state.tx_result_message.body} /> : ""}
                        {this.state.tx_result_success.length !== 0 ? <PortletStruk icon="warning" title={this.state.tx_result_success.title} message={this.state.tx_result_success.body} on_btn_cetak_struk={this.on_btn_cetak_struk}/> : ""}
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <Portlet>
                            <PortletHead headTxt={"Beli E-Money"} />
                            <Form>
                                <PortletBody>
                                    <FormGroup>
                                        <label htmlFor="operator" style={{fontWeight: 400}}>Produk</label>
                                        <select className="form-control m-input" id="tv-prepaid-sel" value={this.state.tx_product_prepaid} onChange={(e) => this.on_select_change_prepaid(e)}>
                                            <option key={999} value=""> -- Pilih -- </option>
                                            {
                                                self.state.product_prepaid.length !== 0 ? self.state.product_prepaid.map((product, j) =>
                                                    <option key={j} value={product.product_id}>
                                                        {product.product_name}
                                                    </option>
                                                ) : ''
                                            }
                                        </select>

                                        <br/>
                                        {this.state.product_denom_prepaid.length !== 0 ? <DenomView/> : ''}
                                        
                                        <label htmlFor="title">Nomor Pelanggan</label>
                                        <input ref={input => {this.nomor_pelanggan_input = input}}
                                            onChange={(e) => this.on_input_change(e)}
                                            type="text"
                                            className="form-control m-input"
                                            name="no_pelanggan"
                                            value={this.state.no_pelanggan}
                                            placeholder="Nomor Pelanggan"/>
                                            {this.state.not_support.length !== 0 ? <span className="m-form__help m--font-danger">{this.state.not_support}</span> : ""}
                                    </FormGroup>
                                </PortletBody>

                                <PortletFooter>
                                    <div className="m-form__actions">
                                        <div className="row">
                                            <div className="col-10">
                                                <button onClick={() => this.on_btn_submit()} type="button" className="btn btn-success">
                                                    Beli
                                                </button>
                                                &nbsp;&nbsp;
                                                <button onClick={() => this.on_btn_reset()} type="reset" className="btn btn-secondary">
                                                    Reset
                                                </button>
                                                &nbsp;&nbsp;
                                                
                                            </div>
                                        </div>
                                    </div>
                                </PortletFooter>
                            </Form>
                        </Portlet>
                    </div>

                    {this.state.tx_show_modal ? <TagihanView/> : ''}
                    {this.state.tx_show_struk ? <CetakStruk data={tx_result_success} on_close={() => {
                        self.setState({tx_show_struk: false})
                    }} /> : ''}
                </div>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    update_current_page:  val => dispatch(do_action(action.ACTION_UPDATE_CURRENT_PAGE, val)),
    update_balance: val => dispatch(do_action(action.ACTION_UPDATE_USER_BALANCE, val))
})

const mapStateToProps = (state) => ({
    session : state.Session,
    profile: state.Profile
})

export default connect(mapStateToProps, mapDispatchToProps)(AgentVoucher)
