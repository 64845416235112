import React from "react";
import ReactExport from "react-export-excel";
import util from '../../helper/util'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const headerColStyle = {fill: {patternType: "solid", fgColor: {rgb: "ffd300"}}};

class BatchExportExcel extends React.Component {
    constructor(props) {
        super(props)
        this.state = {data: []};
    }

    componentDidMount() {
        // console.log("didMount", this.props);
        let dataArr = [];
        dataArr.push([{value: "Batch Transaksi " + this.props.agent_name, style: {font: {sz: "10", bold: true}}}]);
        dataArr.push([{value: "Tanggal " + this.props.date_batch, style: {font: {sz: "8"}}}]);
        dataArr.push([{value: "Total Bayar: Rp." + util.rupiah_format(this.props.total_batch), style: {font: {sz: "8"}}}]);
        dataArr.push([{value: "No", style: headerColStyle}, {value: "Produk", style: headerColStyle}, {value: "Nomor Pelanggan", style: headerColStyle}, {value: "Nama Pelanggan", style: headerColStyle}, {value: "Total Bayar", style: headerColStyle}, {value: "Status Bayar", style: headerColStyle}]);
        let i = 0;
        this.props.data.forEach(function(row) {
            i++;
            let produk = row.product_name + " (" + (row.product_code === null ? row.product_id : row.product_id + "~" + row.product_code) + ")";
            let nama_customer = row.nama_tagihan.length !== 0 ? row.nama_tagihan + (row.customer_name !== null ? ' (' + row.customer_name + ')' : '') : row.customer_name !== null ? row.customer_name : '';
            let total_bayar = "Rp. " + util.rupiah_format(row.amount);
            let status_bayar = row.status_message.length > 0 ? row.status + " (" + row.status_message + ")" : row.status;
            dataArr.push([{value: i.toString()}, {value: produk}, {value: row.bill_number.toString()}, {value: nama_customer}, {value: total_bayar}, {value: status_bayar}]);
        });
        let dataMultiSet = [{columns: [], data: dataArr}];
        // console.log("dataMultiSet", dataMultiSet);
        this.setState({data: dataMultiSet});
    }

    render() {
        return (
            <ExcelFile filename="batch-transaksi" element={<button className='btn btn-secondary m-btn m-btn--icon' style={{height:'35px'}}>Export ke Excel</button>}>
                <ExcelSheet dataSet={this.state.data} name="Batch Transaksi"/>
            </ExcelFile>
        );
    }
}

export default BatchExportExcel;