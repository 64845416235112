import { connect } from 'react-redux'
import React, { Component } from 'react'
import { history } from '../helper/history'
import moment from 'moment'
import request from 'superagent'

import MetronicIndex from '../layout/metronic/index'
import { Form, FormGroup } from '../component/form/form'
import { Portlet, PortletHead, PortletBody, PortletFooter } from '../component/widget/portlet'

import apis from '../helper/api'
import localdata from '../helper/localdata'
import config from '../helper/config'
import swal from 'sweetalert'
import action from '../helper/action'
import do_action from '../action'
import util from '../helper/util'
import { DatePicker, DatePickerInput } from 'rc-datepicker'

let photo_default = "../../swipe/assets/app/media/img/users/default.jpg"

class AgentProfileMaster extends Component {
    constructor(props) {
        super(props)
        this.state = {
        	master_agent_id: "",
        	photo_url: photo_default, 
        	photo_master_url: photo_default, 
        	name_full: "", 
        	email: "", 
        	fax: "", 
        	tempat_lahir: "", 
        	tanggal_lahir: "", 
        	pekerjaan: "", 
        	propinsi_nama: "", 
        	kota_name: "", 
        	kecamatan: "", 
        	kelurahan: "", 
        	alamat: "", 
        	kode_pos: "", 
        	kota_nama: "", 
        	kecamatan_nama: "", 
        	kelurahan_nama: "", 
        	nomor_npwp: "", 
        	branch_office_level: "", 
        	nomor_telepon: "", 
        	alias: [],
        	master_agent_id_2: "",
        	photo_url_2: photo_default, 
        	photo_master_url_2: photo_default, 
        	name_full_2: "", 
        	email_2: "", 
        	fax_2: "", 
        	tempat_lahir_2: "", 
        	tanggal_lahir_2: "", 
        	pekerjaan_2: "", 
        	propinsi_nama_2: "", 
        	kota_name_2: "", 
        	kecamatan_2: "", 
        	kelurahan_2: "", 
        	alamat_2: "", 
        	kode_pos_2: "", 
        	kota_nama_2: "", 
        	kecamatan_nama_2: "", 
        	kelurahan_nama_2: "", 
        	nomor_npwp_2: "", 
        	branch_office_level_2: "", 
        	nomor_telepon_2: "", 
        	alias_2: []
        }
    }

    componentDidMount() {
    	util.log(this.props.profile)
    	this.get_upline()
    }

    get_upline() {
    	let self = this
    	let body = {
            "session_id" : self.props.session
        }
    	request.post(apis.get_upline)
            .set("Content-Type", "application/json")
            .send(body)
            .end(function(err, res) {
                if (err || !res.ok) {
                    util.log("get_upline", err)
                } else {
                    util.log("get_upline", res)
                    if (res.statusCode == 200) {
                        let payload = res.body
                        if (payload.status == "OK") {
                        	if (payload.results && payload.results.list) {
                                let list = payload.results.list
                                const i = list.length
                                let master_id_1 = null
                                let master_id_2 = null
                                if (i !== 0) {
                                	master_id_1 = list[i-1].master_agent_id
                                	if (i > 1) {
                                		master_id_2 = list[0].master_agent_id
                                	}
                                }
                                self.get_public_profile_master(master_id_1, master_id_2)
                            }
                        }
                    }
                }
            })
    }

    get_public_profile_master(master_id_1, master_id_2) {
    	let self = this
    	// this.props.profile.masterid
    	let url_1 = apis.get_public_profile_master.replace("{whitelabel_36}", config.whitelabel.toString(36)).replace("{master_id}", master_id_1)
    	request.get(url_1)
            .set("Content-Type", "application/json")
            .end(function(err, res) {
                if (err || !res.ok) {
                    util.log("public_profile", err)
                } else {
                    util.log("public_profile", res)
                    if (res.statusCode == 200) {
                        let payload = res.body
                        if (payload.status == "OK") {
                        	if (payload.results) {
                                let profile = payload.results
                                let res = {
                                	"master_agent_id" : master_id_1,
						            "name_full" : profile.fullname !== undefined ? profile.fullname : "",
						            "email" : profile.email !== undefined ? profile.email : "",
						            "fax" : profile.fax_number !== undefined ? profile.fax_number : "",
						            "nomor_npwp" : profile.npwp_number !== undefined ? profile.npwp_number : "",
						            "propinsi_nama" : profile.province_name !== undefined ? profile.province_name : "",
						            "kota_nama" : profile.city_name !== undefined ? profile.city_name : "",
						            "kecamatan" : profile.district_id !== undefined ? profile.district_id : "",
						            "kecamatan_nama" : profile.district_name !== undefined ? profile.district_name : "",
						            "kelurahan" : profile.village_id !== undefined ? profile.village_id : "",
						            "kelurahan_nama" : profile.village_name !== undefined ? profile.village_name : "",
						            "alamat" : profile.address !== undefined ? profile.address : "",
						            "kode_pos" : profile.postal_code !== undefined ? profile.postal_code : "",
						            "photo_master_url" : profile.photo_filename !== undefined && profile.photo_filename.length !== 0 ? profile.photo_filename : photo_default,
						            "nomor_telepon" : profile.phone_number !== undefined ? profile.phone_number : "",
						            "company_name" : profile.company_name !== undefined ? profile.company_name : "",
						            "branch_office_level" : profile.branch_office_level !== undefined ? profile.branch_office_level : "",
						            "level" : profile.level,
						            "alias" : profile.alias,
						            "flag_load_data" : false
						        }
						        self.setState(res)
                            }
                        }
                    }
                }
            })

        if (master_id_2 !== null) {
        	let url_2 = apis.get_public_profile_master.replace("{whitelabel_36}", config.whitelabel.toString(36)).replace("{master_id}", master_id_2)
        	request.get(url_2)
            .set("Content-Type", "application/json")
            .end(function(err, res) {
                if (err || !res.ok) {
                    util.log("public_profile_2", err)
                } else {
                    util.log("public_profile_2", res)
                    if (res.statusCode == 200) {
                        let payload = res.body
                        if (payload.status == "OK") {
                        	if (payload.results) {
                                let profile = payload.results
                                let res = {
                                	"master_agent_id_2" : master_id_2,
						            "name_full_2" : profile.fullname !== undefined ? profile.fullname : "",
						            "email_2" : profile.email !== undefined ? profile.email : "",
						            "fax_2" : profile.fax_number !== undefined ? profile.fax_number : "",
						            "nomor_npwp_2" : profile.npwp_number !== undefined ? profile.npwp_number : "",
						            "propinsi_nama_2" : profile.province_name !== undefined ? profile.province_name : "",
						            "kota_nama_2" : profile.city_name !== undefined ? profile.city_name : "",
						            "kecamatan_2" : profile.district_id !== undefined ? profile.district_id : "",
						            "kecamatan_nama_2" : profile.district_name !== undefined ? profile.district_name : "",
						            "kelurahan_2" : profile.village_id !== undefined ? profile.village_id : "",
						            "kelurahan_nama_2" : profile.village_name !== undefined ? profile.village_name : "",
						            "alamat_2" : profile.address !== undefined ? profile.address : "",
						            "kode_pos_2" : profile.postal_code !== undefined ? profile.postal_code : "",
						            "photo_master_url_2" : profile.photo_filename !== undefined && profile.photo_filename.length !== 0 ? profile.photo_filename : photo_default,
						            "nomor_telepon_2" : profile.phone_number !== undefined ? profile.phone_number : "",
						            "company_name_2" : profile.company_name !== undefined ? profile.company_name : "",
						            "branch_office_level_2" : profile.branch_office_level !== undefined ? profile.branch_office_level : "",
						            "level_2" : profile.level,
						            "alias_2" : profile.alias
						        }
						        self.setState(res)
                            }
                        }
                    }
                }
            })
        }
    }

    render() {
        let self = this
        const BankAccountView = (props) =>
            <div className="m-stack m-stack--ver m-stack--mobile m-stack--demo" style={{marginBottom:20+'px',marginTop: 10+'px'}}>
                {this.props.profile.bank_accounts.map((item, i) =>
                    <div key={i} className="m-stack__item m-stack__item--center m-stack__item--top" 
                        style={{textAlign:'center',marginBottom:'5px'}}>
                        {item.bank_name}: {item.account_number}
                    </div>
                )}
            </div>
        return (
            <div className="row">
                <div className="col-lg-4">
                    <div className="m-portlet">
                        <div className="m-portlet__body">
                            <div className="m-card-profile">
                                <div className="m-card-profile__pic">
                                    <div className="m-card-profile__pic-wrapper" style={{marginTop: 0}}>
                                        <img style={{cursor:'pointer',width:100+'px',height:100+'px'}} onClick={this.photo_clicked} src={this.state.photo_url}/>
                                    </div>
                                </div>
                                <div className="m-card-profile__details">
                                    <span className="m-card-profile__name" style={{marginTop:-15+'px'}}>
                                        {this.props.profile ? (this.props.profile.name ? this.props.profile.name : this.props.profile.usertype) : ""}
                                    </span>
                                    <input type="file" id="photo_input" onChange={this.photo_input_changed} style={{display:'none'}}/>
                                    <a className="m-card-profile__email m-link" style={{marginTop:-10+'px'}}>
                                        {this.props.profile.username}
                                    </a>
                                </div>
                                {this.props.profile.bank_accounts && this.props.profile.bank_accounts.length !== 0 ? <BankAccountView/> : ""}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4">
                    <div className="m-portlet m-portlet--full-height m-portlet--tabs">
                        <div className="tab-content">
                            <div className="tab-pane active" id="m_user_profile_tab_1">
                                <form className="m-form m-form--fit m-form--label-align-right">
                                    <div className="m-portlet__body">
				                    	<div className="m-card-profile__pic">
				                            <div className="m-card-profile__pic-wrapper" style={{border: '2px solid #f4f5f8',     borderRadius: '100%', padding: '8px', width: '119px',margin: 'auto'}}>
				                                <img src={this.state.photo_master_url} style={{width:'100px',height:'100px', borderRadius: '100%'}}/>
				                            </div>
				                        </div>
				                        <div className="m-card-profile__details" style={{textAlign: 'center', marginTop:5+'px'}}>
				                            <span className="m-card-profile__name" style={{fontWeight:600,fontSize:'0.9rem'}}>
				                                {this.state.master_agent_id}
				                            </span>
				                        </div>
				                       
				                    	{this.state.alias.length > 0 ? 
				                    		<div className="m-card-profile__details" style={{textAlign: 'center', marginTop:0}}>
					                    		{this.state.alias.map(function(item, key) {
					                       			return (<span key={key} className="m-badge m-badge--brand m-badge--wide" style={{marginRight: '5px'}}>{item}</span>)
					                        	})}
				                        	</div>
				                        : ''}
				                        
				                        <div style={{margin: '20px 10px'}}>
				                            <div className="form-group m-form__group row" style={{paddingBottom: '5px'}}>
				                                <div className="col-12" style={{border: '1px solid #eee',background: '#fbfbfb',padding: '5px 10px',textAlign: 'center'}}>
				                                    <h3 className="m-form__section" style={{fontWeight:600}}>
				                                        Profil Master Agen
				                                    </h3>
				                                </div>
				                            </div>
				                            <div className="form-group m-form__group row" style={{padding: '5px 30px'}}>
				                                <label style={{textAlign: 'left'}} className="col-2 col-form-label">
				                                    Nama
				                                </label>
				                                <div className="col-10" style={{margin: 'auto 0'}}>
				                                    :&nbsp;&nbsp;&nbsp;<span>{this.state.name_full}</span>
				                                </div>
				                            </div>
				                            <div className="form-group m-form__group row" style={{padding: '5px 30px'}}>
				                                <label style={{textAlign: 'left'}} className="col-2 col-form-label">
				                                    Cabang
				                                </label>
				                                <div className="col-10" style={{margin: 'auto 0'}}>
				                                	<span>:&nbsp;&nbsp;&nbsp;{this.state.branch_office_level}</span>
				                                </div>
				                            </div>
				                            <div className="form-group m-form__group row" style={{padding: '5px 30px'}}>
				                                <label style={{textAlign: 'left'}} className="col-2 col-form-label">
				                                    Telepon
				                                </label>
				                                <div className="col-10" style={{margin: 'auto 0'}}>
				                                	:&nbsp;&nbsp;&nbsp;<span>{this.state.nomor_telepon}</span>
				                                </div>
				                            </div>
				                            <div className="form-group m-form__group row" style={{padding: '5px 30px'}}>
				                                <label style={{textAlign: 'left'}} className="col-2 col-form-label">
				                                    NPWP
				                                </label>
				                                <div className="col-10" style={{margin: 'auto 0'}}>
				                                	:&nbsp;&nbsp;&nbsp;<span>{this.state.nomor_npwp}</span>
				                                </div>
				                            </div>
				                            <div className="form-group m-form__group row" style={{padding: '5px 30px'}}>
				                                <label style={{textAlign: 'left'}} className="col-2 col-form-label">
				                                    Email
				                                </label>
				                                <div className="col-10" style={{margin: 'auto 0'}}>
				                                	:&nbsp;&nbsp;&nbsp;<span>{this.state.email}</span>
				                                </div>
				                            </div>
				                            <div className="form-group m-form__group row" style={{padding: '5px 30px'}}>
				                                <label style={{textAlign: 'left'}} className="col-2 col-form-label">
				                                    Fax
				                                </label>
				                                <div className="col-10" style={{margin: 'auto 0'}}>
				                                	:&nbsp;&nbsp;&nbsp;<span>{this.state.fax}</span>
				                                </div>
				                            </div>
				                            <div className="" style={{marginTop: '10px'}}></div>
				                            <div className="form-group m-form__group row" style={{paddingBottom: '5px', paddingTop: '5px'}}>
				                                <div className="col-12" style={{border: '1px solid #eee',background: '#fbfbfb',padding: '5px 10px',textAlign: 'center'}}>
				                                    <h3 className="m-form__section" style={{fontWeight:600}}>
				                                        Alamat
				                                    </h3>
				                                </div>
				                            </div>
				                            <div className="form-group m-form__group row" style={{padding: '5px 30px'}}>
				                                <label style={{textAlign: 'left'}} className="col-2 col-form-label">
				                                    Provinsi
				                                </label>
				                                <div className="col-10" style={{margin: 'auto 0'}}>
				                                	:&nbsp;&nbsp;&nbsp;<span>{this.state.propinsi_nama}</span>
				                                </div>
				                            </div>
				                            <div className="form-group m-form__group row" style={{padding: '5px 30px'}}>
				                                <label style={{textAlign: 'left'}} className="col-2 col-form-label">
				                                    Kota
				                                </label>
				                                <div className="col-10" style={{margin: 'auto 0'}}>
				                                	:&nbsp;&nbsp;&nbsp;<span>{this.state.kota_nama}</span>
				                                </div>
				                            </div>
				                        </div> 
                                    </div>
                                </form>
                            </div>
                            
                        </div>
                    </div>
                </div>

                {this.state.master_agent_id_2.length !== 0 ? 
                	<div className="col-lg-4">
	                    <div className="m-portlet m-portlet--full-height m-portlet--tabs">
	                        <div className="tab-content">
	                            <div className="tab-pane active" id="m_user_profile_tab_1">
	                                <form className="m-form m-form--fit m-form--label-align-right">
	                                    <div className="m-portlet__body">
					                    	<div className="m-card-profile__pic">
					                            <div className="m-card-profile__pic-wrapper" style={{border: '2px solid #f4f5f8', borderRadius: '100%', padding: '8px', width: '119px',margin: 'auto'}}>
					                                <img src={this.state.photo_master_url_2} style={{width:'100px',height:'100px', borderRadius: '100%'}}/>
					                            </div>
					                        </div>
					                        <div className="m-card-profile__details" style={{textAlign: 'center', marginTop:5+'px'}}>
					                            <span className="m-card-profile__name" style={{fontWeight:600,fontSize:'0.9rem'}}>
					                                {this.state.master_agent_id_2}
					                            </span>
					                        </div>
					                       
					                    	{this.state.alias.length > 0 ? 
					                    		<div className="m-card-profile__details" style={{textAlign: 'center', marginTop:0}}>
						                    		{this.state.alias_2.map(function(item, key) {
						                       			return (<span key={key} className="m-badge m-badge--brand m-badge--wide" style={{marginRight: '5px'}}>{item}</span>)
						                        	})}
					                        	</div>
					                        : ''}
					                        
					                        <div style={{margin: '20px 10px'}}>
					                            <div className="form-group m-form__group row" style={{paddingBottom: '5px'}}>
					                                <div className="col-12" style={{border: '1px solid #eee',background: '#fbfbfb',padding: '5px 10px',textAlign: 'center'}}>
					                                    <h3 className="m-form__section" style={{fontWeight:600}}>
					                                        Profil Master Agen
					                                    </h3>
					                                </div>
					                            </div>
					                            <div className="form-group m-form__group row" style={{padding: '5px 30px'}}>
					                                <label style={{textAlign: 'left'}} className="col-2 col-form-label">
					                                    Nama
					                                </label>
					                                <div className="col-10" style={{margin: 'auto 0'}}>
					                                    :&nbsp;&nbsp;&nbsp;<span>{this.state.name_full_2}</span>
					                                </div>
					                            </div>
					                            <div className="form-group m-form__group row" style={{padding: '5px 30px'}}>
					                                <label style={{textAlign: 'left'}} className="col-2 col-form-label">
					                                    Cabang
					                                </label>
					                                <div className="col-10" style={{margin: 'auto 0'}}>
					                                	<span>:&nbsp;&nbsp;&nbsp;{this.state.branch_office_level_2}</span>
					                                </div>
					                            </div>
					                            <div className="form-group m-form__group row" style={{padding: '5px 30px'}}>
					                                <label style={{textAlign: 'left'}} className="col-2 col-form-label">
					                                    Telepon
					                                </label>
					                                <div className="col-10" style={{margin: 'auto 0'}}>
					                                	:&nbsp;&nbsp;&nbsp;<span>{this.state.nomor_telepon_2}</span>
					                                </div>
					                            </div>
					                            <div className="form-group m-form__group row" style={{padding: '5px 30px'}}>
					                                <label style={{textAlign: 'left'}} className="col-2 col-form-label">
					                                    NPWP
					                                </label>
					                                <div className="col-10" style={{margin: 'auto 0'}}>
					                                	:&nbsp;&nbsp;&nbsp;<span>{this.state.nomor_npwp_2}</span>
					                                </div>
					                            </div>
					                            <div className="form-group m-form__group row" style={{padding: '5px 30px'}}>
					                                <label style={{textAlign: 'left'}} className="col-2 col-form-label">
					                                    Email
					                                </label>
					                                <div className="col-10" style={{margin: 'auto 0'}}>
					                                	:&nbsp;&nbsp;&nbsp;<span>{this.state.email_2}</span>
					                                </div>
					                            </div>
					                            <div className="form-group m-form__group row" style={{padding: '5px 30px'}}>
					                                <label style={{textAlign: 'left'}} className="col-2 col-form-label">
					                                    Fax
					                                </label>
					                                <div className="col-10" style={{margin: 'auto 0'}}>
					                                	:&nbsp;&nbsp;&nbsp;<span>{this.state.fax_2}</span>
					                                </div>
					                            </div>
					                            <div className="" style={{marginTop: '10px'}}></div>
					                            <div className="form-group m-form__group row" style={{paddingBottom: '5px', paddingTop: '5px'}}>
					                                <div className="col-12" style={{border: '1px solid #eee',background: '#fbfbfb',padding: '5px 10px',textAlign: 'center'}}>
					                                    <h3 className="m-form__section" style={{fontWeight:600}}>
					                                        Alamat
					                                    </h3>
					                                </div>
					                            </div>
					                            <div className="form-group m-form__group row" style={{padding: '5px 30px'}}>
					                                <label style={{textAlign: 'left'}} className="col-2 col-form-label">
					                                    Provinsi
					                                </label>
					                                <div className="col-10" style={{margin: 'auto 0'}}>
					                                	:&nbsp;&nbsp;&nbsp;<span>{this.state.propinsi_nama_2}</span>
					                                </div>
					                            </div>
					                            <div className="form-group m-form__group row" style={{padding: '5px 30px'}}>
					                                <label style={{textAlign: 'left'}} className="col-2 col-form-label">
					                                    Kota
					                                </label>
					                                <div className="col-10" style={{margin: 'auto 0'}}>
					                                	:&nbsp;&nbsp;&nbsp;<span>{this.state.kota_nama_2}</span>
					                                </div>
					                            </div>
					                        </div> 
	                                    </div>
	                                </form>
	                            </div>
	                            
	                        </div>
	                    </div>
	                </div>
                : ''}

            </div>

        )
    }

}

const mapStateToProps = (state) => ({
    session : state.Session,
    profile: state.Profile
})

export default connect(mapStateToProps, null)(AgentProfileMaster)