import localforage from 'localforage';
import util from 'util'

function clear_data() {
    return new Promise((resolve, reject) => {
        localforage.clear().then(function (value) {
            resolve(value)
        }).catch(function (err) {
            reject(err)
        });
    })
}

function get_session() {
    return new Promise((resolve, reject) => {
        localforage.getItem('login_result').then(function (payload) {
            if (payload.results.session_id) {
                resolve(payload.results.session_id)
            }
        }).catch(function (err) {
            reject(err)
        });
    })
}

function set_login_result(payload) {
    return new Promise((resolve, reject) => {
        localforage.setItem('login_result', payload).then(function (value) {
            resolve(value)
        }).catch(function (err) {
            reject(err)
        });
    })
}

function get_login_result() {
    return new Promise((resolve, reject) => {
        localforage.getItem('login_result').then(function (payload) {
            resolve(payload)
        }).catch(function (err) {
            reject(err)
        });
    })
}

function set_property(key, payload) {
    return new Promise((resolve, reject) => {
        localforage.setItem(key, payload).then(function (value) {
            resolve(value)
        }).catch(function (err) {
            reject(err)
        });
    })
}

function get_property(key) {
    return new Promise((resolve, reject) => {
        localforage.getItem(key).then(function (payload) {
            resolve(payload)
        }).catch(function (err) {
            reject(err)
        });
    })
}

function set_photo(payload) {
    return new Promise((resolve, reject) => {
        localforage.setItem('photo', payload).then(function (value) {
            resolve(value)
        }).catch(function (err) {
            reject(err)
        });
    })
}

function get_photo() {
    return new Promise((resolve, reject) => {
        localforage.getItem('photo').then(function (payload) {
            resolve(payload)
        }).catch(function (err) {
            reject(err)
        });
    })
}

function set_list_services(payload) {
    return new Promise((resolve, reject) => {
        localforage.setItem('list_service', payload).then(function (value) {
            resolve(value)
        }).catch(function (err) {
            reject(err)
        });
    })
}



function get_list_services() {
    return new Promise((resolve, reject) => {
        localforage.getItem('list_service').then(function (payload) {
            resolve(payload)
        }).catch(function (err) {
            reject(err)
        });
    })
}

function get_product(s_type) {
    return new Promise((resolve, reject) => {
        localforage.getItem('list_service').then(function (payload) {
            payload.map(function(service, i) {
                if (service.menu_type === s_type) {
                    resolve(service.list_products)
                }
            })
            resolve("")
        }).catch(function (err) {
            reject(err)
        });
    })
}

function get_product_by_product_code_or_product_id(product_key) {
    return new Promise((resolve, reject) => {
        localforage.getItem('list_service').then(function (payload) {
            payload.map(function(service, i) {
                let products = service.list_products
                products.map(function(product, j) {
                    let product_id = product.product_id
                    if (product.list_denoms !== undefined && product.list_denoms.length !== 0) {
                        let denoms = product.list_denoms
                        denoms.map(function(denom, k) {
                            if (denom.product_code === product_key) {
                                resolve(product)
                            }
                        })
                    } else {
                        if (product.product_id === product_key) {
                            resolve(product)
                        }
                    }
                })
            })
            resolve(null)
        }).catch(function (err) {
            reject(err)
        });
    })
}

function get_denom_by_prefix(s_type, p_prefix) {
    return new Promise((resolve, reject) => {
        localforage.getItem('list_service').then(function (payload) {
            payload.map(function(service, i) {
                if (service.menu_type === s_type) {
                    let products = service.list_products
                    products.map(function(product, j) {
                        let id = product.product_id
                        let prefixs = product.telco_prefix
                        let denoms = product.list_denoms
                        prefixs.map(function(prefix, k) {
                            if (p_prefix.startsWith(prefix)) {
                                resolve({"product_id": id, "denoms": denoms})
                            }
                        })
                    })
                }
            })
            resolve("")
        }).catch(function (err) {
            reject(err)
        });
    })
}

function get_denom_by_product(s_type, p_type) {
    return new Promise((resolve, reject) => {
        localforage.getItem('list_service').then(function (payload) {
            payload.map(function(service, i) {
                if (service.menu_type === s_type) {
                    let products = service.list_products
                    products.map(function(product, j) {
                        if (product.item_type === p_type) {
                            resolve({"product_id": product.product_id, "denoms": product.list_denoms})
                        }
                    })
                }
            })
            resolve("")
        }).catch(function (err) {
            reject(err)
        });
    })
}

function get_denom_by_product_id(s_type, p_id) {
    return new Promise((resolve, reject) => {
        localforage.getItem('list_service').then(function (payload) {
            payload.map(function(service, i) {
                if (service.menu_type === s_type) {
                    let products = service.list_products
                    products.map(function(product, j) {
                        if (product.product_id === p_id) {
                            resolve({"product_id": product.product_id, "denoms": product.list_denoms})
                        }
                    })
                }
            })
            resolve("")
        }).catch(function (err) {
            reject(err)
        });
    })
}

function get_product_by_prefix(s_type, p_prefix) {
    return new Promise((resolve, reject) => {
        localforage.getItem('list_service').then(function (payload) {
            payload.map(function(service, i) {
                if (service.menu_type === s_type) {
                    let products = service.list_products
                    products.map(function(product, j) {
                        let prefixs = product.telco_prefix
                        prefixs.map(function(prefix, k) {
                            if (p_prefix.startsWith(prefix)) {
                                resolve(product)
                            }
                        })
                    })
                }
            })
            resolve("")
        }).catch(function (err) {
            reject(err)
        });
    })
}

//feri add platinum

function set_list_platinum(payload) {
    return new Promise((resolve, reject) => {
        localforage.setItem('list_platinum', payload).then(function (value) {
            resolve(value)
        }).catch(function (err) {
            reject(err)
        });
    })
}
 
 

function get_list_platinum() {
    return new Promise((resolve, reject) => {
        localforage.getItem('list_platinum').then(function (payload) {
            resolve(payload)
        }).catch(function (err) {
            reject(err)
        });
    })
}



function get_product_platinum(s_type) {
    return new Promise((resolve, reject) => {
        localforage.getItem('list_platinum').then(function (payload) {
            payload.map(function(service, i) {
                if (service.item_type === s_type) {
                    // resolve(service)
                    let products = service.submenu
                    products.map(function(product, j) {
                        // resolve(product)
                        // if (product.item_id === 1023) {
                            let items = product.list_products
                            items.map(function(item, j) {
                                // if (item.item_type === "PRODUCT_CASHIN") {
                                //     resolve({"product_id": item.product_id, "denoms": item.product_name})

                                    resolve(items)
                                // }
                            });


                        // }
                    //     let prefixs = product.telco_prefix
                    //     prefixs.map(function(prefix, k) {
                    //         if (p_prefix.startsWith(prefix)) {
                    //             resolve(product)
                    //         }
                        });
                    // });
                }
            })
            resolve("")
        }).catch(function (err) {
            reject(err)
        });
    })
}


export default {
    clear_data: clear_data,
    get_session: get_session,
    set_login_result: set_login_result,
    get_login_result: get_login_result,
    set_list_services: set_list_services,
    get_list_services: get_list_services,
    get_login_result: get_login_result,
    get_product: get_product,
    get_denom_by_prefix: get_denom_by_prefix,
    get_denom_by_product: get_denom_by_product,
    get_product_by_prefix: get_product_by_prefix,
    get_denom_by_product_id: get_denom_by_product_id,
    get_product_by_product_code_or_product_id: get_product_by_product_code_or_product_id,
    set_property: set_property,
    get_property: get_property,
    set_list_platinum : set_list_platinum,
    get_list_platinum : get_list_platinum,
    get_product_platinum : get_product_platinum 
}