import actions from '../helper/action'
const current_page = (state = null, action) => {
	if (action.type == actions.ACTION_UPDATE_CURRENT_PAGE){
		if (state == null) {
			state = 'dashboard'
		}
		return action.payload
	}
	return state
}

export default current_page