import React, { Component } from 'react'
import moment from 'moment'

// Component
import MetronicIndex from '../layout/metronic/index'
import { Form, FormGroup } from '../component/form/form'
import { Portlet, PortletHead, PortletBody, PortletFooter } from '../component/widget/portlet'

// Helper
import api from '../helper/api'
import localdata from '../helper/localdata'
import config from '../helper/config'
import swal from 'sweetalert'

class Inbox extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
        	<div className="row">
                <div className="col-md-12">
                    <Portlet>
                        <PortletBody>
                            <div className="m-widget3">
                                Tidak Ada Pesan
                            </div>
                        </PortletBody>
                    </Portlet>   
                </div>
            </div>
        )
    }
}
export default Inbox