import React, {Component} from 'react';
import moment from 'moment';
import request from 'superagent';
import {connect} from 'react-redux';
import action from '../helper/action';
import do_action from '../action';
import {Portlet, PortletHead, PortletBody, PortletAlert} from '../component/widget/portlet';
import {Link} from "react-router-dom";
import apis from '../helper/api';
import swal from 'sweetalert';
import util from '../helper/util';
import config from '../helper/config';
import ReactGA from 'react-ga';
import DonasiIcon from './donasi.svg';
import PosAsuransiIcon from './pos-asuransi.svg';
import localdata from '../helper/localdata';

class AgentHome extends Component {
    _isMounted = false;
    constructor(props) {
        super(props)
        this.state = {
            alert_message: "",
            report_fee: "-",
            report_bonus: "-",
            report_tx: "-",
            report_tx_price: "-",
            report_month: "Ini",
            year_sel: "2018",
            year_current_opt: "",
            year_prev_opt: "",
            month_sel: "1",
            flag_processing_summary: false,
            show_summary: false,
            is_taxmpn: false
        }
        this.product_grid_click = this.product_grid_click.bind(this);
        this.is_taxmpn();

    }

 
    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        let self = this
        this.props.update_current_page('dashboard')
        let now = moment().toDate()
        self.setState({report_month: util.get_month_name(now.getMonth() + 1), month_sel: now.getMonth() + 1, year_sel: now.getFullYear(), year_current_opt: now.getFullYear(), year_prev_opt: now.getFullYear() - 1})
        ReactGA.pageview('/home')
    }

    componentWillReceiveProps(nextProps) {
        // no-op
    }

    get_summary(year, month) {
        let session_id = null
        if (this.props.session === null) {
            // no-op
        } else {
            session_id = this.props.session
        }
        if (session_id === null) return
        let url_komisi = apis.report_monthly + year + "/" + month
        let url_bonus = apis.report_monthly_bonus + year + "/" + month
        const self = this
        let body = {
            "session_id" : session_id
        }
        this.setState({flag_processing_summary: true})
        request.post(url_komisi)
            .set("Content-Type", "application/json")
            .send(body)
            .end(function(err, res) {
                if (!self._isMounted) return
                if (err || !res.ok) {
                    util.log("get_summary_komisi", err)
                } else {
                    util.log("get_summary_komisi", res)
                    if (res.statusCode === 200) {
                        let payload = res.body
                        if (payload.status === "OK") {
                            if (payload.results) {
                                if (payload.results.list && payload.results.list.length !== 0) {
                                    let report = payload.results.list[0]
                                    self.setState({report_fee: util.rupiah_format(report.total_commission_received), report_tx: util.rupiah_format(report.total_trx_event), report_tx_price: util.rupiah_format(report.total_trx_price), report_month: util.get_month_name(report.report_month)})
                                }
                            }
                        } else {
                            self.on_error(payload.error)
                        }
                    }
                }
            })
        request.post(url_bonus)
            .set("Content-Type", "application/json")
            .send(body)
            .end(function(err, res) {
                if (!self._isMounted) return
                if (err || !res.ok) {
                    util.log("get_summary_bonus", err)
                } else {
                    util.log("get_summary_bonus", res)
                    if (res.statusCode === 200) {
                        let payload = res.body
                        if (payload.status === "OK") {
                            if (payload.results) {
                                if (payload.results.list && payload.results.list.length !== 0) {
                                    let report = payload.results.list[0]
                                    self.setState({report_bonus: util.rupiah_format(report.total_bonus_received)})
                                }
                            }
                        } else {
                            self.setState({report_bonus: "0"})
                        }
                    }
                }
                self.setState({flag_processing_summary: false})
            })
    }

    on_error(err) {
        util.on_error(err)
        if (err.code === 2007) {
            this.setState({report_fee: "0", report_tx: "0", report_tx_price: "0", report_month: util.get_month_name(this.state.month_sel)})
        } else {
            let msg = "Error: " + err.code
            if (err.message !== undefined) {
                msg = err.message
            }
            swal("Ups!", msg, "warning")
        }
    }

    product_grid_click(val) {
        // no-op
    }

    on_detil_komisi(e) {
        this.props.history.push({
            pathname: '/agen/riwayat-fee',
            search: '',
            state: {month_year: this.state.year_sel + "-" + this.state.month_sel}
        })
    }

    on_detil_bonus(e) {
        this.props.history.push({
            pathname: '/agen/riwayat-bonus',
            search: '',
            state: {month_year: this.state.year_sel + "-" + this.state.month_sel}
        })
    }

    on_detil_transaksi(e) {
        this.props.history.push({
            pathname: '/agen/riwayat-transaksi',
            search: '',
            state: {month_year: this.state.year_sel + "-" + this.state.month_sel}
        })
    }

    on_summary_komisi(e) {
        let self = this;
        self.setState({show_summary: true}, function() {
            self.get_summary(this.state.year_sel, this.state.month_sel)
        });
    }

    on_select_change(e) {
        this.setState({[e.currentTarget.id] : e.currentTarget.value})
    }

    open_heksa() {
        window.open(config.heksa_url);
    }
    open_ematerai() {
        window.open(config.emeterai_url);
    }
    open_edc() {
        window.open(config.edc_url);
    }



    is_taxmpn() {
        let that = this;
        localdata.get_login_result().then((payload) => {
            util.log("allow_tax_mpn", payload.results.profile.allow_tax_mpn);
            that.setState({is_taxmpn: payload.results.profile.allow_tax_mpn});
        });
    }


    render() {
        let is_indomarco_agent = this.props.profile && util.is_indomarco_agent(this.props.profile.masterid)
        return (
            <div className="row">
                <div className="col-md-12">
                    {this.state.alert_message.length !== 0 ? <PortletAlert icon="warning" title={this.state.alert_message.title} message={this.state.alert_message.body} /> : ""}
                    <Portlet>
                        <div className="m-portlet__head">
                            <div className="m-portlet__head-caption">
                                <div className="m-portlet__head-title">
                                    <span className="m-portlet__head-icon m--hide">
                                        <i className="la la-gear"></i>
                                    </span>
                                    <h3 className="m-portlet__head-text">
                                        Komisi / Bonus 
                                        <select className="m-input" id="month_sel" value={this.state.month_sel} onChange={(e) => this.on_select_change(e)} style={{height: '2.2rem', fontSize: '15px', marginLeft: '10px', border: '1px solid #ebedf2', color: '#575962', background: '#fff', WebkitAppearance: 'none', padding: '0 10px'}}>
                                            <option value="1">Januari</option>
                                            <option value="2">Februari</option>
                                            <option value="3">Maret</option>
                                            <option value="4">April</option>
                                            <option value="5">Mei</option>
                                            <option value="6">Juni</option>
                                            <option value="7">Juli</option>
                                            <option value="8">Agustus</option>
                                            <option value="9">September</option>
                                            <option value="10">Oktober</option>
                                            <option value="11">November</option>
                                            <option value="12">Desember</option>
                                        </select>
                                        <select className="m-input" id="year_sel" value={this.state.year_sel} onChange={(e) => this.on_select_change(e)} style={{height: '2.2rem',fontSize: '15px', marginLeft: '5px', border: '1px solid #ebedf2', color: '#575962', background: '#fff', WebkitAppearance: 'none', padding: '0 10px'}}>
                                            <option value={this.state.year_prev_opt}>{this.state.year_prev_opt}</option>
                                            <option value={this.state.year_current_opt}>{this.state.year_current_opt}</option>
                                        </select>
                                        <button onClick={(e) => this.on_summary_komisi(e)} className={this.state.flag_processing_summary ? "btn m-btn--pill btn-secondary m-btn m-btn--bolder m-loader m-loader--primary m-loader--right" : "btn m-btn--pill btn-secondary m-btn m-btn--bolder"} style={{marginLeft: '10px',fontSize: 'small',paddingTop:'7px',paddingBottom:'7px'}}>Submit</button>
                                            
                                    </h3>
                                </div>
                            </div>
                            <div className="m-portlet__head-tools">
                                <ul className="m-portlet__nav">
                                    {!is_indomarco_agent ?
                                    <React.Fragment>
                                        <li className="m-portlet__nav-item">
                                            <button onClick={(e) => this.on_detil_komisi(e)} className="btn btn-secondary m-btn m-btn--pill" style={{marginLeft: '0px',fontSize: 'small',padding: '7px 15px'}}>
                                                Komisi {this.state.report_month}
                                            </button>
                                        </li>
                                        <li className="m-portlet__nav-item">
                                            <button onClick={(e) => this.on_detil_bonus(e)} className="btn btn-secondary m-btn m-btn--pill" style={{marginLeft: '5px',fontSize: 'small',padding: '7px 15px'}}>
                                                Bonus {this.state.report_month}
                                            </button>
                                        </li>
                                    </React.Fragment>
                                    : ''}
                                    <li className="m-portlet__nav-item">
                                        <button onClick={(e) => this.on_detil_transaksi(e)} className="btn btn-secondary m-btn m-btn--pill" style={{marginLeft: '5px',fontSize: 'small',padding: '7px 15px'}}>
                                            Transaksi {this.state.report_month}
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="m-portlet__body  m-portlet__body--no-padding" style={!this.state.show_summary ? {display: 'none'} : {}}>
                            <div className="row m-row--no-padding m-row--col-separator-xl">
                                <div className="col-md-12 col-lg-6 col-xl-3">
                                    <div className="m-widget24">
                                        <div className="m-widget24__item">
                                            <h4 className="m-widget24__title" style={{textAlign:'center',lineHeight:20+'px'}}>
                                                Total<br/>Komisi
                                            </h4>
                                            <br/>
                                            <span className="m-widget24__desc"></span>
                                            <span className="m-widget24__stats m--font-brand" style={{marginTop: -3+'rem',fontSize:1.4+'rem'}}>
                                                Rp. {this.state.report_fee}
                                            </span>
                                            <div className="m--space-20"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-6 col-xl-3">
                                    <div className="m-widget24">
                                        <div className="m-widget24__item">
                                            <h4 className="m-widget24__title" style={{textAlign:'center',lineHeight:20+'px'}}>
                                                Total<br/>Bonus
                                            </h4>
                                            <br/>
                                            <span className="m-widget24__desc"></span>
                                            <span className="m-widget24__stats m--font-danger" style={{marginTop: -3+'rem',fontSize:1.4+'rem'}}>
                                                Rp. {this.state.report_bonus}
                                            </span>
                                            <div className="m--space-20"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-6 col-xl-3">
                                    <div className="m-widget24">
                                        <div className="m-widget24__item">
                                            <h4 className="m-widget24__title" style={{textAlign:'center',lineHeight:20+'px'}}>
                                                Jumlah<br/>Transaksi
                                            </h4>
                                            <br/>
                                            <span className="m-widget24__desc"></span>
                                            <span className="m-widget24__stats m--font-success" style={{marginTop: -3+'rem',fontSize:1.5+'rem'}}>
                                                {this.state.report_tx}
                                            </span>
                                            <div className="m--space-20"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-6 col-xl-3">
                                    <div className="m-widget24">
                                        <div className="m-widget24__item">
                                            <h4 className="m-widget24__title" style={{textAlign:'center',lineHeight:20+'px'}}>
                                                Total<br/>Transaksi
                                            </h4>
                                            <br/>
                                            <span className="m-widget24__desc"></span>
                                            <span className="m-widget24__stats m--font-danger" style={{marginTop: -3+'rem',fontSize:1.4+'rem'}}>
                                                Rp. {this.state.report_tx_price}
                                            </span>
                                            <div className="m--space-20"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Portlet>

                    <Portlet>
                        <PortletHead headTxt={"Daftar Produk"} />
                        <PortletBody>
                            <div className="m-section m-section--last">
                                <div className="m-section__content">
                                    <div className="m-demo" data-code-preview="true" data-code-html="true" data-code-js="false">
                                        <div className="m-demo__preview">
                                            <div className="m-nav-grid">
                                            
                                                <div className="m-nav-grid__row">
                                                    <Link to="/agen/pulsa" className="m-nav-grid__item"
                                                        onClick={() => this.product_grid_click("")}>
                                                        <i className="m-nav-grid__icon flaticon-interface-4"></i>
                                                        <span className="m-nav-grid__text" style={{color:'grey'}}>
                                                            Pulsa Prabayar
                                                        </span>
                                                    </Link>
                                                    <Link to="/agen/kredit" className="m-nav-grid__item"
                                                        onClick={() => this.product_grid_click("")}>
                                                        <i className="m-nav-grid__icon flaticon-list"></i>
                                                        <span className="m-nav-grid__text" style={{color:'grey'}}>
                                                            Cicilan / Kredit
                                                        </span>
                                                    </Link>
                                                    <Link to="/agen/pln" className="m-nav-grid__item"
                                                        onClick={() => this.product_grid_click("")}>
                                                        <i className="m-nav-grid__icon flaticon-light"></i>
                                                        <span className="m-nav-grid__text" style={{color:'grey'}}>
                                                            Bayar Listrik
                                                        </span>
                                                    </Link>
                                                    <Link to="/agen/pdam" className="m-nav-grid__item"
                                                        onClick={() => this.product_grid_click("")}>
                                                        <i className="m-nav-grid__icon flaticon-placeholder-2"></i>
                                                        <span className="m-nav-grid__text" style={{color:'grey'}}>
                                                            Bayar Air
                                                        </span>
                                                    </Link>

                                                    <Link to="/agen/bpjs" className="m-nav-grid__item"
                                                        onClick={() => this.product_grid_click("")}>
                                                        <i className="m-nav-grid__icon flaticon-lifebuoy"></i>
                                                        <span className="m-nav-grid__text" style={{color:'grey'}}>
                                                            BPJS Kesehatan
                                                        </span>
                                                    </Link>
                                                    <Link to="/agen/hp-pascabayar" className="m-nav-grid__item"
                                                        onClick={() => this.product_grid_click("")}>
                                                        <i className="m-nav-grid__icon flaticon-time-3"></i>
                                                        <span className="m-nav-grid__text" style={{color:'grey'}}>
                                                            Pulsa Pascabayar
                                                        </span>
                                                    </Link>
                                                    <Link to="/agen/tv" className="m-nav-grid__item"
                                                        onClick={() => this.product_grid_click("")}>
                                                        <i className="m-nav-grid__icon flaticon-imac"></i>
                                                        <span className="m-nav-grid__text" style={{color:'grey'}}>
                                                            TV Berlangganan
                                                        </span>
                                                    </Link>

                                                </div>

                                                <div className="m-nav-grid__row">
                                                    <Link to="/agen/pajak" className="m-nav-grid__item"
                                                        onClick={() => this.product_grid_click("")}>
                                                        <i className="m-nav-grid__icon flaticon-time"></i>
                                                        <span className="m-nav-grid__text" style={{color:'grey'}}>
                                                            Pajak
                                                        </span>
                                                    </Link>
                                                    <Link to="/agen/ecommerce" className="m-nav-grid__item"
                                                        onClick={() => this.product_grid_click("")}>
                                                        <i className="m-nav-grid__icon flaticon-business"></i>
                                                        <span className="m-nav-grid__text" style={{color:'grey'}}>
                                                            E-Commerce
                                                        </span>
                                                    </Link>
                                                    <Link to="/agen/telkom" className="m-nav-grid__item"
                                                        onClick={() => this.product_grid_click("")}>
                                                        <i className="m-nav-grid__icon flaticon-technology"></i>
                                                        <span className="m-nav-grid__text" style={{color:'grey'}}>
                                                            Telkom Pay
                                                        </span>
                                                    </Link>
                                                    <Link to="/agen/asuransi" className="m-nav-grid__item"
                                                        onClick={() => this.product_grid_click("")}>
                                                        <i className="m-nav-grid__icon flaticon-technology-1"></i>
                                                        <span className="m-nav-grid__text" style={{color:'grey'}}>
                                                            Bayar Asuransi
                                                        </span>
                                                    </Link>
                                                    <Link to="/agen/tiket" className="m-nav-grid__item"
                                                        onClick={() => this.product_grid_click("")}>
                                                        <i className="m-nav-grid__icon flaticon-transport"></i>
                                                        <span className="m-nav-grid__text" style={{color:'grey'}}>
                                                            Bayar Tiket
                                                        </span>
                                                    </Link>
                                                    <Link to="/agen/paket-data" className="m-nav-grid__item"
                                                        onClick={() => this.product_grid_click("")}>
                                                        <i className="m-nav-grid__icon flaticon-interface-7"></i>
                                                        <span className="m-nav-grid__text" style={{color:'grey'}}>
                                                            Paket Data
                                                        </span>
                                                    </Link>
                                                    <Link to="/agen/voucher" className="m-nav-grid__item"
                                                        onClick={() => this.product_grid_click("")}>
                                                        <i className="m-nav-grid__icon flaticon-car"></i>
                                                        <span className="m-nav-grid__text" style={{color:'grey'}}>
                                                            E-Money
                                                        </span>
                                                    </Link>
                                                </div>
                                                <div className="m-nav-grid__row">
                                                    <Link to="/agen/gas" className="m-nav-grid__item"
                                                        onClick={() => this.product_grid_click("")}>
                                                        <i className="m-nav-grid__icon flaticon-calendar-1"></i>
                                                        <span className="m-nav-grid__text" style={{color:'grey'}}>
                                                            Bayar Gas
                                                        </span>
                                                    </Link>
                                                    {/* <a style={{cursor: 'pointer'}} className="m-nav-grid__item"
                                                        onClick={() => this.open_heksa()}>
                                                        <img src={PosAsuransiIcon} style={{height:'40px', marginTop:'10px'}} />
                                                        <span className="m-nav-grid__text" style={{color:'grey'}}>
                                                            POS Assurance
                                                        </span>
                                                    </a>
                                                    <Link to="/agen/donasi" className="m-nav-grid__item"
                                                        onClick={() => this.product_grid_click("")}>
                                                        <img src={DonasiIcon} style={{height:'40px', marginTop:'10px'}} />
                                                        <span className="m-nav-grid__text" style={{color:'grey'}}>
                                                            Donasi
                                                        </span>
                                                    </Link> */}
                                                    {/* {this.state.is_taxmpn ? 

                                                    <Link to="/agen/mpn" className="m-nav-grid__item"
                                                        onClick={() => this.product_grid_click("")}>
                                                        <i className="m-nav-grid__icon flaticon-time"></i>
                                                        <span className="m-nav-grid__text" style={{color:'grey'}}>
                                                            MPN G3
                                                        </span>
                                                    </Link>
                                                    : ''} */}


                                                    {/* <a style={{cursor: 'pointer'}} className="m-nav-grid__item"
                                                        onClick={() => this.open_ematerai()}>
                                                        <i className="m-nav-grid__icon flaticon-notes"></i>
                                                        <span className="m-nav-grid__text" style={{color:'grey'}}>
                                                        EMeterai
                                                        </span>
                                                    </a>

                                                    <a style={{cursor: 'pointer'}} className="m-nav-grid__item"
                                                        onClick={() => this.open_edc()}>
                                                        <i className="m-nav-grid__icon flaticon-analytics"></i>
                                                        <span className="m-nav-grid__text" style={{color:'grey'}}>
                                                        Pendaftaran EDC
                                                        </span>
                                                    </a> */}


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </PortletBody>
                    </Portlet>

                    <Portlet>
                        <PortletHead headTxt={"Menu Lainnya"} />
                        <PortletBody>
                            <div className="m-section m-section--last">
                                <div className="m-section__content">
                                    <div className="m-demo" data-code-preview="true" data-code-html="true" data-code-js="false">
                                        <div className="m-demo__preview">
                                            <div className="m-nav-grid">
                                            
                                                <div className="m-nav-grid__row">
                                                    <Link to="/agen/riwayat-transaksi" className="m-nav-grid__item"
                                                        onClick={() => this.product_grid_click("")}>
                                                        <i className="m-nav-grid__icon flaticon-layers"></i>
                                                        <span className="m-nav-grid__text" style={{color:'grey'}}>
                                                            Riwayat Transaksi
                                                        </span>
                                                    </Link>
                                                    {!is_indomarco_agent ?
                                                    <React.Fragment>
                                                        <Link to="/agen/riwayat-fee" className="m-nav-grid__item"
                                                            onClick={() => this.product_grid_click("")}>
                                                            <i className="m-nav-grid__icon flaticon-list-1"></i>
                                                            <span className="m-nav-grid__text" style={{color:'grey'}}>
                                                                Riwayat Komisi
                                                            </span>
                                                        </Link>
                                                        <Link to="/agen/riwayat-bonus" className="m-nav-grid__item"
                                                            onClick={() => this.product_grid_click("")}>
                                                            <i className="m-nav-grid__icon flaticon-calendar-1"></i>
                                                            <span className="m-nav-grid__text" style={{color:'grey'}}>
                                                                Riwayat Bonus
                                                            </span>
                                                        </Link>
                                                    </React.Fragment>
                                                    : ''}
                                                    <Link to="/agen/riwayat-deposit" className="m-nav-grid__item"
                                                        onClick={() => this.product_grid_click("")}>
                                                        <i className="m-nav-grid__icon flaticon-web"></i>
                                                        <span className="m-nav-grid__text" style={{color:'grey'}}>
                                                            Riwayat Deposit
                                                        </span>
                                                    </Link>
                                                    <Link to="/agen/kode-produk" className="m-nav-grid__item"
                                                        onClick={() => this.product_grid_click("")}>
                                                        <i className="m-nav-grid__icon flaticon-menu-button"></i>
                                                        <span className="m-nav-grid__text" style={{color:'grey'}}>
                                                            Kode Produk
                                                        </span>
                                                    </Link>
                                                    <Link to="/agen/batch-transaction" className="m-nav-grid__item"
                                                        onClick={() => this.product_grid_click("")}>
                                                        <i className="m-nav-grid__icon flaticon-list-2"></i>
                                                        <span className="m-nav-grid__text" style={{color:'grey'}}>
                                                            Batch Transaksi
                                                        </span>
                                                    </Link>
                                                </div>

                                                <div className="m-nav-grid__row">
                                                    <Link to="/agen/topup-va" className="m-nav-grid__item"
                                                        onClick={() => this.product_grid_click("")}>
                                                        <i className="m-nav-grid__icon flaticon-add"></i>
                                                        <span className="m-nav-grid__text" style={{color:'grey'}}>
                                                            Topup
                                                        </span>
                                                    </Link>
                                                    <Link to="/agen/report-monthly" className="m-nav-grid__item"
                                                        onClick={() => this.product_grid_click("")}>
                                                        <i className="m-nav-grid__icon flaticon-analytics"></i>
                                                        <span className="m-nav-grid__text" style={{color:'grey'}}>
                                                            Laporan Bulanan
                                                        </span>
                                                    </Link>
                                                    <Link to="/agen/report-daily" className="m-nav-grid__item"
                                                        onClick={() => this.product_grid_click("")}>
                                                        <i className="m-nav-grid__icon flaticon-graphic-1"></i>
                                                        <span className="m-nav-grid__text" style={{color:'grey'}}>
                                                            Laporan Harian
                                                        </span>
                                                    </Link>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </PortletBody>
                    </Portlet>

                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    update_current_page:  val => dispatch(do_action(action.ACTION_UPDATE_CURRENT_PAGE, val))
})

const mapStateToProps = (state) => ({
    session : state.Session,
    profile: state.Profile
})

export default connect(mapStateToProps, mapDispatchToProps)(AgentHome)
