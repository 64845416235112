import actions from '../helper/action'

const profile = (state = null, action = null) => {
	if (action.type == actions.ACTION_UPDATE_USER_INFO) {
		if (state == null) {
			state = {};
		}
		return Object.assign({}, state, action.payload)
	}
	return state
}


export default profile
