/* eslint-disable no-unused-expressions */
import React, {Component} from 'react'
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect
} from "react-router-dom";

import action from '../../helper/action';
import {connect} from 'react-redux';
import util from '../../helper/util';
import request from 'superagent';
import apis from '../../helper/api';
import do_action from '../../action';
import config from '../../helper/config';

const nav = {
    "profile" : {title:"Profil Agen", nav:[{"title" : "Home"}, {"title" : "Profil"}]},
    "profile_master" : {title:"Profil Master Agen", nav:[{"title" : "Home"}, {"title" : "Profil Master"}]},
    "finpay" : {title:"Topup FinPay", nav:[{"title" : "Home"}, {"title" : "FinPay"}]},
    "va" : {title:"Topup Virtual Account Bank", nav:[{"title" : "Home"}, {"title" : "Virtual Account"}]},
    "inbox" : {title:"Pesan Masuk", nav:[{"title" : "Home"}, {"title" : "Inbox"}]},
    "ubah_password" : {title:"Ubah Password", nav:[{"title" : "Home"}, {"title" : "Change Password"}]},
    "report_monthly" : {title:"Laporan Bulanan", nav:[{"title" : "Home"}, {"title" : "Monthly Report"}]},
    "report_daily" : {title:"Laporan Harian", nav:[{"title" : "Home"}, {"title" : "Daily Report"}]},
    "pengaturan" : {title:"Pengaturan / Setting", nav:[{"title" : "Home"}, {"title" : "settings"}]},
    "pengaturan_printer" : {title:"Pengaturan Printer", nav:[{"title" : "Home"}, {"title" : "Pengaturan Printer"}]},
    "workstation" : {title:"Pengaturan Operator", nav:[{"title" : "Home"}, {"title" : "Operator"}]},
    "kurir_syarat" : {title:"Kurir", nav:[{"title" : "Home"}, {"title" : "Kurir"}, {"title" : "Syarat & Ketentuan"}]},
    "kurir_register" : {title:"Kurir", nav:[{"title" : "Home"}, {"title" : "Kurir"}, {"title" : "Syarat & Ketentuan"}, {"title" : "Register"}]}
};

let photo_default = "../../swipe/assets/app/media/img/users/default.jpg";

class HeaderTopBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            flag_processing_balance: false,
            show_paymode: false,
            show_desktop_download: true
        };
        this.refresh_balance = this.refresh_balance.bind(this);
    }

    shouldComponentUpdate (new_props, new_state) {
        return true;
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    refresh_balance() {
        util.log("refresh_balance")
        this.setState({flag_processing_balance: true})
        let body = {
            "session_id" : this.props.session
        }
        let self = this
        request.post(apis.balance_main)
            .set("Content-Type", "application/json")
            .send(body)
            .end(function(err, res) {
                if (err || !res.ok) {
                    util.log("update_balance", err)
                } else {
                    util.log("update_balance", res)
                    if (res.statusCode == 200) {
                        let payload = res.body
                        if (payload.status == "OK") {
                            if (payload.results.list) {
                                let deposits = payload.results.list
                                util.log("deposit: ", deposits[0])
                                self.props.update_balance(deposits[0].balance_value)
                            }
                        } else {
                            self.on_error(payload.error)
                        }
                    }
                }
                self.setState({flag_processing_balance: false})
            })
    }

    render() {
        let self = this
        const BanksAccountView = (props) =>
            <ul className="m-menu__subnav">
                {this.props.profile.bank_accounts.map((bank, i) =>
                    <li key={i} className="m-menu__item" aria-haspopup="true">
                        <Link to="/agen/topup-va" className="m-menu__link" 
                            onClick={(e) => this.props.on_page_changed(e, nav.va)}>
                            <span className="m-menu__link-text" style={{textAlign: 'center'}}>
                                {bank.bank_name} {bank.account_number}
                            </span>
                        </Link>
                    </li>
                )} 
            </ul>
        let paymode_lbl = this.props.profile && this.props.profile.paymode === 'POSTPAID' ? 'Tagihan:' : 'Saldo:'
        let username_display = ''
        let name_display = ''
        let is_workstation = false
        if (this.props.profile) {
            if (this.props.profile.workstation_code) {
                is_workstation = true
                let workstation = this.props.profile.workstation_name ? this.props.profile.workstation_name : this.props.profile.workstation_code
                username_display = this.props.profile.username + " (" + workstation + ")"
                name_display = workstation
            } else {
                username_display = this.props.profile.username
                name_display = this.props.profile.name
            }
        }
        
        return (
            <div className="m-stack__item m-stack__item--fluid m-header-head" id="m_header_nav">
                <div id="m_header_menu" className="m-header-menu m-aside-header-menu-mobile m-aside-header-menu-mobile--offcanvas m-header-menu--skin-light m-header-menu--submenu-skin-light m-aside-header-menu-mobile--skin-dark m-aside-header-menu-mobile--submenu-skin-dark">
                    <ul className="m-menu__nav  m-menu__nav--submenu-arrow">

                        <li className="m-menu__item m-menu__item--submenu m-menu__item--rel" 
                        data-menu-submenu-toggle="click" aria-haspopup="true">
                            <a className="m-menu__link m-menu__toggle">
                                <i className="m-menu__link-icon flaticon-coins white-background"></i>
                                <span className="m-menu__link-text" style={{fontWeight: 500}}>
                                    {paymode_lbl}
                                </span>
                                <span className="m-menu__link-text" style={{fontWeight: 600,paddingLeft:'5px'}}>Rp. {util.rupiah_format(this.props.balance)}</span>

                                {!this.state.flag_processing_balance ? <i className="la la-refresh" onClick={() => this.refresh_balance()} 
                                style={{verticalAlign: 'middle',display: 'table-cell',paddingLeft: 10+'px'}}></i> : <div className="m-loader m-loader--brand" style={{verticalAlign: 'middle',display: 'table-cell',paddingLeft: 10+'px',width: 30+'px'}}></div>}

                                <i className="m-menu__hor-arrow la la-angle-down"></i>
                                <i className="m-menu__ver-arrow la la-angle-right"></i>
                            </a>
                            <div className="m-menu__submenu m-menu__submenu--classic m-menu__submenu--left">
                                <span className="m-menu__arrow m-menu__arrow--adjust"></span>
                                {this.props.profile && this.props.profile.bank_accounts.length !==0 ? 
                                    <BanksAccountView/> : ''}
                            </div>
                        </li>

                        {this.props.profile && this.props.profile.paymode && this.state.show_paymode ? <li className="m-menu__item m-menu__item--submenu m-menu__item--rel" 
                        data-menu-submenu-toggle="click" aria-haspopup="true">
                            <span className="m-badge m-badge--danger m-badge--wide m-badge--rounded" style={{fontWeight: 600}}>
                                {this.props.profile.paymode}
                            </span>
                        </li> : ''}

                        <li className={this.props.current_page==='topup'?'m-menu__item m-menu__item--submenu m-menu__item--rel m-menu__item--active':'m-menu__item m-menu__item--submenu m-menu__item--rel'} 
                        data-menu-submenu-toggle="click" aria-haspopup="true" style={{}}>
                            <a className="m-menu__link m-menu__toggle">
                                <span className="m-menu__link-text">
                                    Topup
                                </span>
                                <i className="m-menu__hor-arrow la la-angle-down"></i>
                                <i className="m-menu__ver-arrow la la-angle-right"></i>
                            </a>
                            <div className="m-menu__submenu m-menu__submenu--classic m-menu__submenu--left">
                                <span className="m-menu__arrow m-menu__arrow--adjust"></span>
                                <ul className="m-menu__subnav">
                                    <li className="m-menu__item" data-redirect="true" aria-haspopup="true">
                                        <Link to="/agen/topup-va" className="m-menu__link" onClick={(e) => this.props.on_page_changed(e, nav.va)}>
                                            <i className="m-menu__link-icon flaticon-add white-background"></i>
                                            <span className="m-menu__link-text">
                                                Topup POS dan Virtual Account
                                            </span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </li>

                        <li className={this.props.current_page==='laporan'?'m-menu__item m-menu__item--submenu m-menu__item--rel m-menu__item--active':'m-menu__item m-menu__item--submenu m-menu__item--rel'} 
                        data-menu-submenu-toggle="click" data-dropdown-toggle="click" aria-haspopup="true" aria-expanded="true" style={{}}>
                            <a className="m-menu__link m-menu__toggle">
                                <span className="m-menu__link-text">
                                    Laporan
                                </span>
                                <i className="m-menu__hor-arrow la la-angle-down"></i>
                                <i className="m-menu__ver-arrow la la-angle-right"></i>
                            </a>
                            <div className="m-menu__submenu m-menu__submenu--classic m-menu__submenu--left">
                                <span className="m-menu__arrow m-menu__arrow--adjust"></span>
                                <ul className="m-menu__subnav">
                                    <li className="m-menu__item" data-redirect="true" aria-haspopup="true">
                                        <Link to="/agen/report-monthly" className="m-menu__link" onClick={(e) => this.props.on_page_changed(e, nav.report_monthly)}>
                                            <i className="m-menu__link-icon flaticon-analytics white-background"></i>
                                            <span className="m-menu__link-text">
                                                Laporan Bulanan
                                            </span>
                                        </Link>
                                    </li>
                                    <li className="m-menu__item" data-redirect="true" aria-haspopup="true" style={{}}>
                                        <Link to="/agen/report-daily" className="m-menu__link" onClick={(e) => this.props.on_page_changed(e, nav.report_daily)}>
                                            <i className="m-menu__link-icon flaticon-graphic-1 white-background"></i>
                                            <span className="m-menu__link-text">
                                                Laporan Harian
                                            </span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </li>

                        <li className="m-menu__item m-menu__item--submenu m-menu__item--rel" data-menu-submenu-toggle="click" aria-haspopup="true" style={{}}>
                            <Link to="/agen/kurir-syarat" className="m-menu__link" onClick={(e) => this.props.on_page_changed(e, nav.kurir_syarat)}>
                                <span
                                    style={{fontWeight: 600, color: 'rgb(255, 105, 0)'}}
                                    className="m-menu__link-text">
                                    Daftar Kurir
                                </span>
                            </Link>
                        </li>

                    </ul>
                </div>


                <div id="m_header_topbar" className="m-topbar  m-stack m-stack--ver m-stack--general">
                    <div className="m-stack__item m-topbar__nav-wrapper">
                        <ul className="m-topbar__nav m-nav m-nav--inline" style={{margin:'0 20px 0 0px'}}>
                            {!util.is_electron() && this.state.show_desktop_download ? 
                                <li className="m-nav__item" data-menu-submenu-toggle="click" aria-haspopup="true" style={{display:'inline'}}>
                                    <span className="m-badge m-badge--wide m-badge--rounded" style={{fontWeight: 600,padding:'5px 15px',cursor:'pointer',marginTop:'-5px',backgroundColor:'#ff6900',color:'#fff'}} onClick={(e) => window.open(config.posfin_desktop_url,'_blank').focus()}>
                                        <i className="m-menu__link-icon flaticon-download" style={{fontSize:'1.1rem'}}></i>
                                        &nbsp;&nbsp;{config.posfin_desktop_name}
                                    </span>
                                </li>
                            : ''}

                            <li style={{display: 'none'}} className="m-nav__item m-dropdown m-dropdown--large m-dropdown--arrow m-dropdown--align-center m-dropdown--mobile-full-width m-dropdown--skin-light m-list-search m-list-search--skin-light" data-dropdown-toggle="click" data-dropdown-persistent="true" id="m_quicksearch" data-search-type="dropdown">
                                <a href="#" className="m-nav__link m-dropdown__toggle">
                                    <span className="m-nav__link-icon-2">
                                        <i className="flaticon-search-1"></i>
                                    </span>
                                </a>
                                <div className="m-dropdown__wrapper">
                                    <span className="m-dropdown__arrow m-dropdown__arrow--center"></span>
                                    <div className="m-dropdown__inner ">
                                        <div className="m-dropdown__header">
                                            <div  className="m-list-search__form">
                                                <div className="m-list-search__form-wrapper">
                                                    <span className="m-list-search__form-input-wrapper">
                                                        <input id="m_quicksearch_input" autoComplete="off" type="text" name="q" className="m-list-search__form-input" value="" placeholder="Search..." onChange={(e) => this.onChange(e)} />
                                                    </span>
                                                    <span className="m-list-search__form-icon-close" id="m_quicksearch_close">
                                                        <i className="la la-remove"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="m-dropdown__body">
                                            <div className="m-dropdown__scrollable m-scrollable" data-scrollable="true" data-max-height="300" data-mobile-max-height="200">
                                                <div className="m-dropdown__content"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li className="m-nav__item m-topbar__notifications m-topbar__notifications--img m-dropdown m-dropdown--large m-dropdown--header-bg-fill m-dropdown--arrow m-dropdown--align-center m-dropdown--mobile-full-width" data-dropdown-toggle="click" data-dropdown-persistent="true">
                                <Link to="/agen/inbox" className="m-nav__link" 
                                        id="" 
                                        onClick={(e) => this.props.on_page_changed(e, nav.inbox)}>
                                    <span className="m-nav__link-icon-2">
                                        <i className="flaticon-music-2"></i>
                                    </span>
                                </Link>
                            </li>

                            <li className="m-nav__item m-topbar__user-profile m-topbar__user-profile--img m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light" data-dropdown-toggle="click" aria-expanded="true">
                                <a className="m-nav__link m-dropdown__toggle" style={{cursor: 'pointer'}}>
                                    <span className="m-topbar__userpic">
                                        <img src={this.props.profile ? (this.props.profile.photo ? this.props.profile.photo : photo_default) : photo_default} className="m--img-rounded m--marginless m--img-centered" style={{height: 41+'px',width: 41+'px'}} />
                                    </span>
                                    <span style={{padding: '0px 10px',fontFamily: 'sans-serif',fontWeight: '400', display: 'table-cell', verticalAlign: 'middle', color: '#676c7b'}}>{name_display}</span>
                                </a>
                                <div className="m-dropdown__wrapper">
                                    <span className="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust" style={{ left: 'auto', right: 12.5 }}></span>
                                    <div className="m-dropdown__inner">

                                        <div className="m-dropdown__header m--align-center">
                                            <div className="m-card-user m-card-user--skin-dark">
                                                <div className="m-card-user__pic">
                                                    <img src={this.props.profile ? (this.props.profile.photo ? this.props.profile.photo : photo_default) : photo_default} className="m--img-rounded m--marginless" style={{height: 70+'px',width: 70+'px'}}/>
                                                </div>
                                                <div className="m-card-user__details">
                                                    <span className="m-card-user__name m--font-weight-500">
                                                        {username_display}
                                                    </span>
                                                    <a className="m-card-user__email m--font-weight-300 m-link">
                                                        {this.props.profile ? (this.props.profile.email ? this.props.profile.email : this.props.profile.usertype) : ""}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="m-dropdown__body">
                                            <div className="m-dropdown__content">
                                                <ul className="m-nav m-nav--skin-light">

                                                    <li className="m-nav__section m--hide">
                                                        <span className="m-nav__section-text">
                                                            Section
                                                        </span>
                                                    </li>

                                                    <li className="m-nav__item">
                                                        <Link to="/agen/profile" className="m-nav__link" 
                                                        onClick={(e) => this.props.on_page_changed(e, nav.profile)}>
                                                            <i className="m-nav__link-icon flaticon-profile-1"></i>
                                                            <span className="m-nav__link-title">
                                                                <span className="m-nav__link-wrap">
                                                                    <span className="m-nav__link-text">
                                                                        Profil Agen
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </Link>
                                                    </li>

                                                    <li className="m-nav__item">
                                                        <Link to="/agen/profile-master" className="m-nav__link" 
                                                        onClick={(e) => this.props.on_page_changed(e, nav.profile_master)}>
                                                            <i className="m-nav__link-icon flaticon-profile"></i>
                                                            <span className="m-nav__link-title">
                                                                <span className="m-nav__link-wrap">
                                                                    <span className="m-nav__link-text">
                                                                        Profil Master Agen
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </Link>
                                                    </li>

                                                    <li className="m-nav__item">
                                                        <Link to="/agen/change-password" className="m-nav__link" 
                                                        onClick={(e) => this.props.on_page_changed(e, nav.ubah_password)}>
                                                            <i className="m-nav__link-icon flaticon-visible"></i>
                                                            <span className="m-nav__link-title">
                                                                <span className="m-nav__link-wrap">
                                                                    <span className="m-nav__link-text">
                                                                        Ubah Password
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </Link>
                                                    </li>

                                                    {!is_workstation ? 
                                                        <li className="m-nav__item">
                                                            <Link to="/agen/manage-workstation" className="m-nav__link" 
                                                            onClick={(e) => this.props.on_page_changed(e, nav.workstation)}>
                                                                <i className="m-nav__link-icon flaticon-users"></i>
                                                                <span className="m-nav__link-title">
                                                                    <span className="m-nav__link-wrap">
                                                                        <span className="m-nav__link-text">
                                                                            Pengaturan Operator
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </Link>
                                                        </li>
                                                    : ''}

                                                    {util.is_electron() ? 
                                                        <li className="m-nav__item">
                                                            <Link to="/agen/settings_printer" className="m-nav__link" 
                                                            onClick={(e) => this.props.on_page_changed(e, nav.pengaturan_printer)}>
                                                                <i className="m-nav__link-icon flaticon-settings"></i>
                                                                <span className="m-nav__link-title">
                                                                    <span className="m-nav__link-wrap">
                                                                        <span className="m-nav__link-text">
                                                                            Pengaturan Printer
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </Link>
                                                        </li>
                                                    : ''}

                                                    <li className="m-nav__item">
                                                        <Link to="/agen/settings" className="m-nav__link" 
                                                        onClick={(e) => this.props.on_page_changed(e, nav.pengaturan)}>
                                                            <i className="m-nav__link-icon flaticon-settings"></i>
                                                            <span className="m-nav__link-title">
                                                                <span className="m-nav__link-wrap">
                                                                    <span className="m-nav__link-text">
                                                                        Pengaturan / Setting
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </Link>
                                                    </li>

                                                    <li className="m-nav__separator m-nav__separator--fit"></li>

                                                    <li className="m-nav__item">
                                                        <button onClick={() => this.props.on_logout()} className="btn m-btn--pill    btn-secondary m-btn m-btn--custom m-btn--label-brand m-btn--bolder logout">
                                                            Logout
    													</button>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    profile: state.Profile,
    balance: state.Balance,
    session : state.Session,
    current_page: state.CurrentPage
})

const mapDispatchToProps = (dispatch) => ({
    update_balance: val => dispatch(do_action(action.ACTION_UPDATE_USER_BALANCE, val))
})

export default connect(mapStateToProps, mapDispatchToProps)(HeaderTopBar)
