import React, { Component } from 'react';

export class Form extends Component {
    render() {
        return (
            <div className="m-form m-form--fit m-form--label-align-right">
                {this.props.children}
            </div>
        )
    }
}

export class FormGroup extends Component {
    render() {
        return (
            <div className="form-group m-form__group">
                {this.props.children}
            </div>
        )
    }
}