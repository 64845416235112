import React, { Component } from 'react';
import { connect } from 'react-redux';
import swal from 'sweetalert';
import Swal from 'sweetalert2'
import action from '../helper/action';
import do_action from '../action';
import apis from '../helper/api';
import util from '../helper/util';
import request from 'superagent';
import Modal from 'react-responsive-modal';
import CetakResiPaket from '../component/form/cetak_resi_paket';

let data_resi_paket = null;
let data_resi_paket_detail = null;

class AgentMLOPosting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            input_service: "",
            draft: null,
            show_modal_kode_pos: false,
            show_modal_review: false,
            show_resi_paket: false,
            loading_simpan_pengirim: false,
            loading_simpan_penerima: false,
            loading_simpan_pengiriman: false,
            loading_simpan_kiriman: false,
            loading_cari_pos_pengirim: false,
            loading_cari_pos_penerima: false,
            loading_get_draft: false,
            loading_buat_transaksi: false,
            disable_buat_transaksi: true,
            input_nama_pengirim: "",
            input_telp_pengirim: "",
            input_alamat_pengirim: "",
            input_kota_pengirim: "",
            input_kelurahan_pengirim: "",
            input_kode_pos_pengirim: "",
            input_nama_penerima: "",
            input_telp_penerima: "",
            input_alamat_penerima: "",
            input_kota_penerima: "",
            input_kelurahan_penerima: "",
            input_kode_pos_penerima: "",
            kode_booking: "",
            input_service: "",
            input_cod: "",
            input_jenis_barang: "",
            input_instruksi: "",
            input_deskripsi: "",
            input_biaya_kirim: "",
            input_nilai_barang: "",
            input_panjang: "",
            input_tinggi: "",
            input_lebar: "",
            input_berat: "",
            input_asuransi: "",
            data_kode_pos: [],
            data_services: [],
            tag_kode_pos: "",
            tmp_kode_pos: "",
            show_resi_paket: false
        };
        this.on_item_kode_pos_clicked = this.on_item_kode_pos_clicked.bind(this);
        this.on_btn_kode_pos_ok = this.on_btn_kode_pos_ok.bind(this);
        this.on_btn_review_cancel = this.on_btn_review_cancel.bind(this);
        this.on_btn_review_ok = this.on_btn_review_ok.bind(this);
    }

    componentDidMount() {
        this.scroll_to_top();
        this.props.update_current_page('mlo-posting');
        this.get_draft();
    }

    get_draft() {
        let self = this;
        let body = {
            "session_id": this.props.session
        };
        self.setState({ loading_get_draft: true });
        request.post(apis.kurir_get_draft_paket)
            .set("Content-Type", "application/json")
            .send(body)
            .end(function (err, res) {
                if (err || !res.ok) {
                    util.log("get_draft", err);
                } else {
                    util.log("get_draft", res);
                    if (res.statusCode == 200) {
                        let payload = res.body;
                        if (payload.status == "OK") {
                            if (payload.results) {
                                let results = payload.results;
                                self.mapping(results);
                                self.setState({ loading_get_draft: false });
                            }
                        } else {
                            self.on_error(payload.error);
                            self.setState({ loading_get_draft: false });
                        }
                    }
                }
            });
    }

    save_draft(e, payload) {
        let self = this;
        let params = {
            "session_id": this.props.session
        };
        let body = Object.assign({}, params, payload);
        let x = e.target;
        if (x.name == 'btn_simpan_pengirim') {
            self.setState({ loading_simpan_pengirim: true });
        } else if (x.name == 'btn_simpan_penerima') {
            self.setState({ loading_simpan_penerima: true });
        } else if (x.name == 'btn_simpan_pengiriman') {
            self.setState({ loading_simpan_pengiriman: true });
        } else if (x.name == 'btn_simpan_kiriman') {
            self.setState({ loading_simpan_kiriman: true });
        }
        request.post(apis.kurir_edit_draft_paket)
            .set("Content-Type", "application/json")
            .send(body)
            .end(function (err, res) {
                if (err || !res.ok) {
                    util.log("save_draft", err);
                } else {
                    util.log("save_draft", res);
                    if (res.statusCode == 200) {
                        let payload = res.body;
                        if (payload.status == "OK") {
                            if (payload.results) {
                                let results = payload.results;
                                self.mapping(results);
                            }
                        } else {
                            self.on_error(payload.error);
                        }
                        if (x.name == 'btn_simpan_pengirim') {
                            self.setState({ loading_simpan_pengirim: false });
                        } else if (x.name == 'btn_simpan_penerima') {
                            self.setState({ loading_simpan_penerima: false });
                        } else if (x.name == 'btn_simpan_pengiriman') {
                            self.setState({ loading_simpan_pengiriman: false });
                        } else if (x.name == 'btn_simpan_kiriman') {
                            self.setState({ loading_simpan_kiriman: false });
                        }
                    }
                }
            });
    }

    save_service_code(e, code) {
        let self = this;
        let body = {
            "session_id": this.props.session,
            "service_code": code
        };
        request.post(apis.kurir_set_service_code_draft_paket)
            .set("Content-Type", "application/json")
            .send(body)
            .end(function (err, res) {
                if (err || !res.ok) {
                    util.log("save_service_code", err);
                } else {
                    util.log("save_service_code", res);
                    if (res.statusCode == 200) {
                        let payload = res.body;
                        if (payload.status == "OK") {
                            if (payload.results) {
                                let results = payload.results;
                                self.mapping(results);
                            }
                        } else {
                            self.on_error(payload.error);
                        }
                    }
                }
            });
    }

    add_koli(e) {
        let self = this;
        let insurance = "";
        if (this.state.input_asuransi == 1) {
            insurance = 'INS';
        }
        let body = {
            "session_id": this.props.session,
            "description": this.state.input_deskripsi,
            "weight": Number(this.state.input_berat),
            "harga_barang": this.state.input_nilai_barang,
            "surcharge_code": insurance,
            "volumetric": {
                "length": Number(this.state.input_panjang),
                "width": Number(this.state.input_lebar),
                "height": Number(this.state.input_tinggi),
                "diameter": 0
            }
        };
        request.post(apis.kurir_add_koli)
            .set("Content-Type", "application/json")
            .send(body)
            .end(function (err, res) {
                if (err || !res.ok) {
                    util.log("add_koli", err);
                } else {
                    util.log("add_koli", res);
                    if (res.statusCode == 200) {
                        let payload = res.body;
                        if (payload.status == "OK") {
                            if (payload.results) {
                                let results = payload.results;
                                self.mapping(results);
                            }
                        } else {
                            self.on_error(payload.error);
                        }
                    }
                }
            });
    }

    edit_koli(e) {
        let self = this;
        let insurance = "";
        if (this.state.input_asuransi == 1) {
            insurance = 'INS';
        }
        let body = {
            "session_id": this.props.session,
            "item_id": this.state.data_koli[0].item_id,
            "item_code": this.state.data_koli[0].item_code,
            "description": this.state.input_deskripsi,
            "weight": Number(this.state.input_berat),
            "harga_barang": this.state.input_nilai_barang,
            "surcharge_code": insurance,
            "volumetric": {
                "length": Number(this.state.input_panjang),
                "width": Number(this.state.input_lebar),
                "height": Number(this.state.input_tinggi),
                "diameter": 0
            }
        };
        request.post(apis.kurir_edit_koli)
            .set("Content-Type", "application/json")
            .send(body)
            .end(function (err, res) {
                if (err || !res.ok) {
                    util.log("kurir_edit_koli", err);
                } else {
                    util.log("kurir_edit_koli", res);
                    if (res.statusCode == 200) {
                        let payload = res.body;
                        if (payload.status == "OK") {
                            if (payload.results) {
                                let results = payload.results;
                                self.mapping(results);
                            }
                        } else {
                            self.on_error(payload.error);
                        }
                    }
                }
            });
    }

    mapping(results) {
        let nama_pengirim, alamat_pengirim, kode_pos_pengirim, telpon_pengirim, kota_pengirim, kelurahan_pengirim;
        if (results.shipper) {
            nama_pengirim = results.shipper.fullname;
            alamat_pengirim = results.shipper.address;
            kode_pos_pengirim = results.shipper.postal_code;
            telpon_pengirim = results.shipper.phone_number;
            let note = results.shipper.note;

            kota_pengirim = "";
            kelurahan_pengirim = "";
            if (note) {
                let kota_kelurahan = note.split(":");
                kota_pengirim = kota_kelurahan[0];
                kelurahan_pengirim = kota_kelurahan[1];
            }
        }
        let nama_penerima, alamat_penerima, kode_pos_penerima, telpon_penerima, kota_penerima, kelurahan_penerima;
        if (results.receiver) {
            nama_penerima = results.receiver.fullname;
            alamat_penerima = results.receiver.address;
            kode_pos_penerima = results.receiver.postal_code;
            telpon_penerima = results.receiver.phone_number;
            let note_ = results.receiver.note;

            kota_penerima = "";
            kelurahan_penerima = "";
            if (note_) {
                let kota_kelurahan_ = note_.split(":");
                kota_penerima = kota_kelurahan_[0];
                kelurahan_penerima = kota_kelurahan_[1];
            }
        }

        let instruksi = "";
        if (results.instruksi_pengiriman) {
            instruksi = results.instruksi_pengiriman;
        }

        let berat, tinggi, lebar, panjang, deskripsi, asuransi, nilai_barang;
        if (results.koli && results.koli.length != 0) {
            let paket = results.koli[0];
            berat = paket.weight;
            deskripsi = paket.description;
            asuransi = paket.surcharge_code;
            nilai_barang = paket.harga_barang;
            if (paket.volumetric) {
                tinggi = paket.volumetric.height;
                lebar = paket.volumetric.width;
                panjang = paket.volumetric.length;
            }
        }

        let biaya_kirim = results.total;

        let disable_buat_transaksi = true;
        if (results.total != 0 && results.service_code) {
            disable_buat_transaksi = false;
        }

        this.setState({
            draft: results,
            input_nama_pengirim: nama_pengirim,
            input_telp_pengirim: telpon_pengirim,
            input_alamat_pengirim: alamat_pengirim,
            input_kode_pos_pengirim: kode_pos_pengirim,
            input_kota_pengirim: kota_pengirim,
            input_kelurahan_pengirim: kelurahan_pengirim,
            input_nama_penerima: nama_penerima,
            input_telp_penerima: telpon_penerima,
            input_alamat_penerima: alamat_penerima,
            input_kode_pos_penerima: kode_pos_penerima,
            input_kota_penerima: kota_penerima,
            input_kelurahan_penerima: kelurahan_penerima,
            data_services: results.list_service,
            data_koli: results.koli,
            kode_booking: results.item_code,
            input_instruksi: instruksi,
            input_service: results.service_code,
            input_jenis_barang: results.jenis_barang,
            input_berat: berat,
            input_tinggi: tinggi,
            input_lebar: lebar,
            input_panjang: panjang,
            input_deskripsi: deskripsi,
            input_nilai_barang: nilai_barang,
            input_asuransi: (asuransi == 'INS') ? '1' : '0',
            input_biaya_kirim: biaya_kirim,
            disable_buat_transaksi
        });
    }

    get_kode_pos(e) {
        let self = this;
        let x = e.target;
        let kota = "";
        let kelurahan = "";
        if (x.name == 'kode_pos_pengirim') {
            if (this.state.input_kota_pengirim.length == 0) {
                swal("Error", "Lengkapi data kota pengirim", "error");
                return;
            }
            if (this.state.input_kelurahan_pengirim.length == 0) {
                swal("Error", "Lengkapi data kelurahan pengirim", "error");
                return;
            }
            kota = this.state.input_kota_pengirim;
            kelurahan = this.state.input_kelurahan_pengirim;
            self.setState({ loading_cari_pos_pengirim: true });
        } else if (x.name == 'kode_pos_penerima') {
            if (this.state.input_kota_penerima.length == 0) {
                swal("Error", "Lengkapi data kode pos penerima", "error");
                return;
            }
            if (this.state.input_kelurahan_penerima.length == 0) {
                swal("Error", "Lengkapi data kode pos penerima", "error");
                return;
            }
            kota = this.state.input_kota_penerima;
            kelurahan = this.state.input_kelurahan_penerima;
            self.setState({ loading_cari_pos_penerima: true });
        }
        let body = {
            "session_id": this.props.session,
            "city": kota,
            "address": kelurahan
        };
        request.post(apis.kurir_find_postal_code)
            .set("Content-Type", "application/json")
            .send(body)
            .end(function (err, res) {
                if (err || !res.ok) {
                    util.log("get_kode_pos", err);
                } else {
                    util.log("get_kode_pos", res);
                    if (res.statusCode == 200) {
                        let payload = res.body;
                        if (payload.status == "OK") {
                            if (payload.results) {
                                let results = payload.results;
                                let tag = "pengirim";
                                if (x.name == 'kode_pos_penerima') {
                                    tag = "penerima";
                                }
                                self.setState({
                                    data_kode_pos: results,
                                    tag_kode_pos: tag,
                                    show_modal_kode_pos: true,
                                    loading_cari_pos_pengirim: false,
                                    loading_cari_pos_penerima: false
                                });
                            }
                        } else {
                            self.on_error(payload.error);
                            self.setState({
                                data_kode_pos: [],
                                loading_cari_pos_pengirim: false,
                                loading_cari_pos_penerima: false
                            });
                        }
                    }
                }
            });
    }

    save_transaksi(e) {
        let self = this;
        let body = {
            "session_id": this.props.session
        };
        let x = e.target;
        this.setState({ loading_buat_transaksi: true });
        request.post(apis.kurir_save_draft_paket)
            .set("Content-Type", "application/json")
            .send(body)
            .end(function (err, res) {
                if (err || !res.ok) {
                    util.log("save_transaksi", err);
                } else {
                    util.log("save_transaksi", res);
                    if (res.statusCode == 200) {
                        let payload = res.body;
                        if (payload.status == "OK") {
                            let results = payload.results;
                            self.get_draft();
                            self.setState({ show_modal_review: false, loading_buat_transaksi: false });
                            Swal.fire({
                                title: '<strong>Sukses</strong>',
                                icon: 'success',
                                html: 'Transaksi berhasil dibuat',
                                showCloseButton: true,
                                showCancelButton: true,
                                focusConfirm: false,
                                confirmButtonText: '<i class="fa fa-print"></i> Cetak',
                                confirmButtonAriaLabel: 'Cetak',
                                cancelButtonText: 'OK',
                                cancelButtonAriaLabel: 'OK'
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    self.on_btn_cetak_struk(results.item_id, results.item_code);
                                } else {
                                    // nothing
                                }
                            });
                        } else {
                            self.setState({ loading_buat_transaksi: false });
                            self.on_error(payload.error);
                        }
                    }
                }
            });
    }

    on_btn_buat_transaksi(e) {
        this.setState({ show_modal_review: true });
    }

    scroll_step() {
        if (window.pageYOffset === 0) {
            clearInterval(this.state.scroll_interval_id);
        }
        window.scroll(0, window.pageYOffset - 50);
    }

    scroll_to_top() {
        let scroll_interval_id = setInterval(this.scroll_step.bind(this), 16.66);
        this.setState({ scroll_interval_id: scroll_interval_id });
    }

    on_error(err) {
        let message = err.message ? err.message : err.code;
        swal("Ups!", "Error: " + message, "warning");
    }

    on_input_change(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    on_select_change(e) {
        this.setState({ [e.target.id]: e.target.value })
    }

    on_btn_pengirim(e) {
        let valid = true;
        if (this.state.input_nama_pengirim.length == 0
            || this.state.input_alamat_pengirim.length == 0
            || this.state.input_kode_pos_pengirim.length == 0
            || this.state.input_telp_pengirim.length == 0
            || this.state.input_kota_pengirim.length == 0
            || this.state.input_kelurahan_pengirim.length == 0) {
            valid = false;
        }
        if (!valid) {
            swal("Error", "Lengkapi semua data pengirim", "error")
            return;
        }
        let note = this.state.input_kota_pengirim + ":" + this.state.input_kelurahan_pengirim;
        let payload = {
            shipper: {
                fullname: this.state.input_nama_pengirim,
                address: this.state.input_alamat_pengirim,
                postal_code: this.state.input_kode_pos_pengirim,
                phone_number: this.state.input_telp_pengirim,
                note: note
            }
        };
        this.save_draft(e, payload);
    }

    on_btn_penerima(e) {
        let valid = true;
        if (this.state.input_nama_penerima.length == 0
            || this.state.input_alamat_penerima.length == 0
            || this.state.input_kode_pos_penerima.length == 0
            || this.state.input_telp_penerima.length == 0
            || this.state.input_kota_penerima.length == 0
            || this.state.input_kelurahan_penerima.length == 0) {
            valid = false;
        }
        if (!valid) {
            swal("Error", "Lengkapi semua data penerima", "error");
            return;
        }
        let note = this.state.input_kota_penerima + ":" + this.state.input_kelurahan_penerima;
        let payload = {
            receiver: {
                fullname: this.state.input_nama_penerima,
                address: this.state.input_alamat_penerima,
                postal_code: this.state.input_kode_pos_penerima,
                phone_number: this.state.input_telp_penerima,
                note: note
            }
        };
        this.save_draft(e, payload);
    }

    on_btn_pengiriman(e) {
        let valid = true;
        if (this.state.input_service.length == 0) {
            valid = false;
        }
        if (!valid) {
            swal("Error", "Lengkapi data yang dibutuhkan", "error");
            return;
        }
        let service_code = this.state.input_service;
        let jenis_barang = this.state.input_jenis_barang;
        let cod_value = this.state.input_cod;
        let instruksi_pengiriman = this.state.input_instruksi;
        let payload = {
            jenis_barang,
            instruksi_pengiriman,
            cod_value
        };
        this.save_service_code(e, service_code);
        this.save_draft(e, payload);
    }

    on_btn_kiriman(e) {
        if (this.state.data_koli && this.state.data_koli.length != 0) {
            this.edit_koli(e);
        } else {
            this.add_koli(e);
        }
    }

    on_click_sub_menu(e, tag) {
        if (tag == 'riwayat') {
            this.props.history.push({
                pathname: '/agen/mlo-riwayat',
                search: '',
                state: {}
            });
        } else if (tag == 'posting') {
            this.props.history.push({
                pathname: '/agen/mlo-posting',
                search: '',
                state: {}
            });
        }
    }

    on_btn_kode_pos_ok() {
        const tag = this.state.tag_kode_pos;
        const val = this.state.tmp_kode_pos;
        if (tag == 'pengirim') {
            this.setState({ input_kode_pos_pengirim: val, show_modal_kode_pos: false });
        } else if (tag == 'penerima') {
            this.setState({ input_kode_pos_penerima: val, show_modal_kode_pos: false });
        }
    }

    on_btn_review_cancel(e) {
        this.setState({ show_modal_review: false });
    }

    on_btn_review_ok(e) {
        this.save_transaksi(e);
    }

    on_item_kode_pos_clicked(e) {
        const dataVal = e.currentTarget.getAttribute("data-val");
        this.setState({ tmp_kode_pos: dataVal, selected_kode_pos: dataVal });
    }

    on_btn_cetak_struk(item_id, item_code) {
        let self = this;
        util.log("on_btn_cetak_struk: ", { item_id, item_code });
        if (util.is_electron()) {
            // TODO
        } else {
            data_resi_paket = { item_id, item_code };
            let body = {
                session_id: this.props.session,
                item_id: item_id,
                item_code: item_code
            };
            Swal.showLoading();
            request.post(apis.kurir_detail_transaksi)
                .set("Content-Type", "application/json")
                .send(body)
                .end(function (err, res) {
                    let message_error = ""
                    if (err || !res.ok) {
                        util.log(err);
                        message_error = { "body": err.message, "title": "Generate Resi Gagal" }
                        if (res !== undefined) {
                            message_error.body = "Error code " + res.statusCode;
                        }
                    } else {
                        util.log(res);
                        if (res.statusCode == 200) {
                            let payload = res.body;
                            util.log(payload);
                            if (payload.status == "OK") {
                                data_resi_paket_detail = payload.results;
                                self.setState({ show_resi_paket: true });
                            } else {
                                message_error = { "body": res.body.error.message, "title": "Generate Resi Gagal" }
                                if (res.body.display_message !== undefined) {
                                    message_error.title = res.body.error.message;
                                    message_error.body = res.body.display_message;
                                }
                            }
                        } else {
                            message_error = { "body": "HTTP error " + res.statusCode, "title": "Generate Resi Gagal" }
                        }
                    }
                    if (message_error.length != 0) {
                        swal(message_error.title, message_error.body, "warning");
                    }
                    Swal.close();
                });
        }
    }

    render() {
        let self = this;
        const content_style = { fontWeight: 500 };
        const body_style = { padding: '5px 5px' };
        const div_input_style = { marginBottom: '0.5rem' };
        const label_style = {
            fontSize: '13px',
            fontWeight: 500,
            marginBottom: '.2rem'
        };
        const input_text_style = { padding: '7px' };
        const text_area_style = { padding: '7px', height: '78px' };
        const head_style = {
            paddingLeft: '0.5rem',
            paddingRight: '0.5rem',
            height: '3rem'
        };
        const panel_height = '444px';
        const center_style = { textAlign: 'center' };
        const row_review = { paddingLeft: '5px' };
        const label_review = { marginBottom: 0, fontWeight: 600, padding: 0 };

        return (
            <div>
                <div className="row" style={{ marginBottom: '10px' }}>
                    <div className="btn-group" role="group" style={{ marginLeft: '1rem' }}>
                        <button type="button" className="m-btn btn btn-success m-btn--icon" onClick={(e) => this.on_click_sub_menu(e, 'posting')} style={{ padding: '0px 10px', height: '35px' }} title="">
                            <span>Pengiriman Baru</span>
                        </button>
                        <button type="button" className="m-btn btn btn-warning m-btn--icon" onClick={(e) => this.on_click_sub_menu(e, 'riwayat')} style={{ padding: '0px 10px', height: '35px' }} title="">
                            <span>Riwayat Transaksi</span>
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12" style={{ paddingRight: 0 }}>
                        <div className="m-portlet" style={{
                            marginBottom: '0.8rem',
                            backgroundColor: 'transparent'
                        }}>
                            <div className="m-portlet__body" style={{
                                paddingLeft: '25px',
                                paddingTop: '10px',
                                paddingBottom: '10px'
                            }}>
                                <div className="row">
                                    <label style={{ fontWeight: 600, marginTop: 'auto' }}>Kode Booking: </label>
                                    {!this.state.loading_get_draft ? <label style={{ fontWeight: 600, marginTop: 'auto', marginLeft: '5px' }}>{this.state.kode_booking}</label> : <div className="m-loader m-loader--brand" style={{ width: '30px' }}></div>}
                                    <button type="button"
                                        disabled={this.state.disable_buat_transaksi ? true : false}
                                        className="m-btn btn btn-primary m-btn--icon"
                                        onClick={(e) => this.on_btn_buat_transaksi(e)} style={{
                                            padding: '0px 10px',
                                            height: '33px',
                                            marginLeft: 'auto'
                                        }} title="">
                                        <span>Buat Transaksi</span>
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3" style={{ paddingRight: 0 }}>
                        <div className="m-portlet" style={{ marginBottom: '1rem' }}>
                            <div className="m-portlet__head" style={head_style}>
                                <div className="m-portlet__head-caption">
                                    <div className="m-portlet__head-title">
                                        <h3 className="m-portlet__head-text">
                                            Pengirim
                                        </h3>
                                    </div>
                                </div>
                                <div className="m-portlet__head-tools">
                                    <ul className="m-portlet__nav">
                                        <li className="m-portlet__nav-item" style={{ padding: 0 }}>
                                            <button name="btn_simpan_pengirim"
                                                disabled={this.state.loading_get_draft ? true : false}
                                                onClick={(e) => this.on_btn_pengirim(e)}
                                                className={this.state.loading_simpan_pengirim ? 'm-portlet__nav-link btn btn-secondary m-btn m-loader m-loader-primary m-loader--right' : 'm-portlet__nav-link btn btn-secondary m-btn'}
                                                style={{ padding: "2px 8px", marginLeft: "21px", fontSize: "0.8em", height: '25px', minWidth: '40px', fontWeight: 600 }}>
                                                {this.state.loading_simpan_pengirim ? '' : 'Simpan'}
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="m-portlet__body" style={body_style}>
                                <div style={div_input_style}>
                                    <label style={label_style}>Nama Lengkap:</label>
                                    <input placeholder="" value={this.state.input_nama_pengirim} onChange={(e) => this.on_input_change(e)} className="form-control m-input" type="text" name="input_nama_pengirim" style={input_text_style} />
                                </div>
                                <div style={div_input_style}>
                                    <label style={label_style}>Telpon:</label>
                                    <input placeholder="" value={this.state.input_telp_pengirim} onChange={(e) => this.on_input_change(e)} className="form-control m-input" type="text" name="input_telp_pengirim" style={input_text_style} />
                                </div>
                                <div style={div_input_style}>
                                    <label style={label_style}>Alamat Lengkap:</label>
                                    <textarea onChange={(e) => this.on_input_change(e)} placeholder="" className="form-control m-input" type="text" name="input_alamat_pengirim" style={text_area_style} value={this.state.input_alamat_pengirim} />
                                </div>
                                <div style={div_input_style}>
                                    <label style={label_style}>Kota:</label>
                                    <input placeholder="" value={this.state.input_kota_pengirim} onChange={(e) => this.on_input_change(e)} className="form-control m-input" type="text" name="input_kota_pengirim" style={input_text_style} />
                                </div>
                                <div style={div_input_style}>
                                    <label style={label_style}>Kelurahan:</label>
                                    <input placeholder="" value={this.state.input_kelurahan_pengirim} onChange={(e) => this.on_input_change(e)} className="form-control m-input" type="text" name="input_kelurahan_pengirim" style={input_text_style} />
                                </div>
                                <div style={div_input_style}>
                                    <label style={label_style}>Kode Pos:</label>
                                    <div className="row">
                                        <div className="col-lg-8" style={{
                                            paddingRight: '5px',
                                            paddingLeft: '1rem'
                                        }}>
                                            <input placeholder="" value={this.state.input_kode_pos_pengirim} onChange={(e) => this.on_input_change(e)} className="form-control m-input" type="text" name="input_kode_pos_pengirim" style={input_text_style} />
                                        </div>
                                        <div className="col-lg-4" style={{
                                            paddingRight: '5px',
                                            paddingLeft: '0'
                                        }}>
                                            <button name="kode_pos_pengirim" onClick={(e) => this.get_kode_pos(e)} className={this.state.loading_cari_pos_pengirim ? 'm-portlet__nav-link btn btn-secondary m-btn m-loader m-loader-primary m-loader--right' : 'm-portlet__nav-link btn btn-secondary m-btn'} style={{ padding: "7px 10px", minWidth: '40px', minHeight: '32px' }}>
                                                {this.state.loading_cari_pos_pengirim ? '' : 'Cari'}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3" style={{ paddingRight: '0px' }}>
                        <div className="m-portlet">
                            <div className="m-portlet__head" style={head_style}>
                                <div className="m-portlet__head-caption">
                                    <div className="m-portlet__head-title">
                                        <h3 className="m-portlet__head-text">
                                            Penerima
                                        </h3>
                                    </div>
                                </div>
                                <div className="m-portlet__head-tools">
                                    <ul className="m-portlet__nav">
                                        <li className="m-portlet__nav-item" style={{ padding: 0 }}>
                                            <button name="btn_simpan_penerima"
                                                disabled={this.state.loading_get_draft ? true : false}
                                                onClick={(e) => this.on_btn_penerima(e)} className={this.state.loading_simpan_penerima ? 'm-portlet__nav-link btn btn-secondary m-btn m-loader m-loader-primary m-loader--right' : 'm-portlet__nav-link btn btn-secondary m-btn'}
                                                style={{ padding: "2px 8px", marginLeft: "21px", fontSize: "0.8em", height: '25px', minWidth: '40px', fontWeight: 600 }}>
                                                {this.state.loading_simpan_penerima ? '' : 'Simpan'}
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="m-portlet__body" style={body_style}>
                                <div style={div_input_style}>
                                    <label style={label_style}>Nama Lengkap:</label>
                                    <input placeholder="" value={this.state.input_nama_penerima} onChange={(e) => this.on_input_change(e)} className="form-control m-input" type="text" name="input_nama_penerima" style={input_text_style} />
                                </div>
                                <div style={div_input_style}>
                                    <label style={label_style}>Telpon:</label>
                                    <input placeholder="" value={this.state.input_telp_penerima} onChange={(e) => this.on_input_change(e)} className="form-control m-input" type="text" name="input_telp_penerima" style={input_text_style} />
                                </div>
                                <div style={div_input_style}>
                                    <label style={label_style}>Alamat Lengkap:</label>
                                    <textarea onChange={(e) => this.on_input_change(e)} placeholder="" className="form-control m-input" type="text" name="input_alamat_penerima" style={text_area_style} value={this.state.input_alamat_penerima} />
                                </div>
                                <div style={div_input_style}>
                                    <label style={label_style}>Kota:</label>
                                    <input placeholder="" value={this.state.input_kota_penerima} onChange={(e) => this.on_input_change(e)} className="form-control m-input" type="text" name="input_kota_penerima" style={input_text_style} />
                                </div>
                                <div style={div_input_style}>
                                    <label style={label_style}>Kelurahan:</label>
                                    <input placeholder="" value={this.state.input_kelurahan_penerima} onChange={(e) => this.on_input_change(e)} className="form-control m-input" type="text" name="input_kelurahan_penerima" style={input_text_style} />
                                </div>
                                <div style={div_input_style}>
                                    <label style={label_style}>Kode Pos:</label>
                                    <div className="row">
                                        <div className="col-lg-8" style={{
                                            paddingRight: '5px',
                                            paddingLeft: '1rem'
                                        }}>
                                            <input placeholder="" value={this.state.input_kode_pos_penerima} onChange={(e) => this.on_input_change(e)} className="form-control m-input" type="text" name="input_kode_pos_penerima" style={input_text_style} />
                                        </div>
                                        <div className="col-lg-4" style={{
                                            paddingRight: '5px',
                                            paddingLeft: '0'
                                        }}>
                                            <button name="kode_pos_penerima" onClick={(e) => this.get_kode_pos(e)} className={this.state.loading_cari_pos_penerima ? 'm-portlet__nav-link btn btn-secondary m-btn m-loader m-loader-primary m-loader--right' : 'm-portlet__nav-link btn btn-secondary m-btn'} style={{padding: '7px 10px', minWidth: '40px', minHeight: '32px' }}>
                                                {this.state.loading_cari_pos_penerima ? '' : 'Cari'}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3" style={{ paddingRight: 0 }}>
                        <div className="m-portlet">
                            <div className="m-portlet__head" style={head_style}>
                                <div className="m-portlet__head-caption">
                                    <div className="m-portlet__head-title">
                                        <h3 className="m-portlet__head-text">
                                            Pengiriman
                                        </h3>
                                    </div>
                                </div>
                                <div className="m-portlet__head-tools">
                                    <ul className="m-portlet__nav">
                                        <li className="m-portlet__nav-item" style={{ padding: 0 }}>
                                            <button name="btn_simpan_pengiriman"
                                                disabled={this.state.loading_get_draft ? true : false}
                                                onClick={(e) => this.on_btn_pengiriman(e)}
                                                className={this.state.loading_simpan_pengiriman ? 'm-portlet__nav-link btn btn-secondary m-btn m-loader m-loader--primary m-loader--right' : 'm-portlet__nav-link btn btn-secondary m-btn'}
                                                style={{ padding: "2px 8px", marginLeft: "21px", fontSize: "0.8em", height: '25px', minWidth: '40px', fontWeight: 600 }}>
                                                {this.state.loading_simpan_pengiriman ? '' : 'Simpan'}
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="m-portlet__body" style={{ ...body_style, minHeight: panel_height }}>
                                <div style={div_input_style}>
                                    <label style={label_style}>Service:</label>
                                    <select className="form-control m-input" id="input_service" value={this.state.input_service} onChange={(e) => this.on_select_change(e)} style={input_text_style}>
                                        <option key={999} value=""> -- Pilih -- </option>
                                        {
                                            this.state.data_services.length != 0 ? this.state.data_services.map((item, i) =>
                                                <option key={i} value={item.service_code}>
                                                    {item.service_name} ({item.etd})
                                                </option>
                                            ) : ''
                                        }
                                    </select>
                                </div>
                                <div style={div_input_style}>
                                    <label style={label_style}>Jenis Barang:</label>
                                    <select className="form-control m-input" id="input_jenis_barang" value={this.state.input_jenis_barang} onChange={(e) => this.on_select_change(e)} style={input_text_style}>
                                        <option key={1} value="paket"> Paket </option>
                                        <option key={2} value="dokumen"> Dokumen </option>
                                    </select>
                                </div>
                                <div style={div_input_style}>
                                    <label style={label_style}>COD:</label>
                                    <select className="form-control m-input" id="input_cod" value={this.state.input_cod} onChange={(e) => this.on_select_change(e)} style={input_text_style}>
                                        <option key={999} value="0"> Tidak </option>
                                    </select>
                                </div>
                                <div style={div_input_style}>
                                    <label style={label_style}>Instruksi Pengiriman:</label>
                                    <textarea onChange={(e) => this.on_input_change(e)} className="form-control m-input" type="text" name="input_instruksi" style={text_area_style} value={this.state.input_instruksi} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3" style={{ paddingRight: 0 }}>
                        <div className="m-portlet">
                            <div className="m-portlet__head" style={head_style}>
                                <div className="m-portlet__head-caption">
                                    <div className="m-portlet__head-title">
                                        <h3 className="m-portlet__head-text">Kiriman</h3>
                                    </div>
                                </div>
                                <div className="m-portlet__head-tools">
                                    <ul className="m-portlet__nav">
                                        <li className="m-portlet__nav-item" style={{ padding: 0 }}>
                                            <button name="btn_simpan_kiriman"
                                                disabled={this.state.loading_get_draft ? true : false}
                                                onClick={() => this.on_btn_kiriman()}
                                                className={this.state.loading_simpan_kiriman ? 'm-portlet__nav-link btn btn-secondary m-btn m-loader m-loader--primary m-loader--right' : 'm-portlet__nav-link btn btn-secondary m-btn'}
                                                style={{ padding: "2px 8px", marginLeft: "21px", fontSize: "0.8em", height: '25px', minWidth: '40px', fontWeight: 600 }}>
                                                {this.state.loading_simpan_kiriman ? '' : 'Simpan'}
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="m-portlet__body" style={{ ...body_style, minHeight: panel_height }}>
                                <div className="row" style={{ marginBottom: '0.5rem' }}>
                                    <div className="col-lg-6" style={{
                                        paddingRight: '5px',
                                        paddingLeft: '1rem'
                                    }}>
                                        <label style={label_style}>Berat (kg)</label>
                                        <input placeholder="0" value={this.state.input_berat} onChange={(e) => this.on_input_change(e)} className="form-control m-input" type="text" name="input_berat" style={input_text_style} />
                                    </div>
                                    <div className="col-lg-6" style={{
                                        paddingRight: '1rem',
                                        paddingLeft: '0px'
                                    }}>
                                        <label style={label_style}>Panjang (cm)</label>
                                        <input placeholder="0" value={this.state.input_panjang} onChange={(e) => this.on_input_change(e)} className="form-control m-input" type="text" name="input_panjang" style={input_text_style} />
                                    </div>
                                </div>

                                <div className="row" style={{ marginBottom: '0.5rem' }}>
                                    <div className="col-lg-6" style={{
                                        paddingRight: '5px',
                                        paddingLeft: '1rem'
                                    }}>
                                        <label style={label_style}>Lebar (cm)</label>
                                        <input placeholder="0" value={this.state.input_lebar} onChange={(e) => this.on_input_change(e)} className="form-control m-input" type="text" name="input_lebar" style={input_text_style} />
                                    </div>
                                    <div className="col-lg-6" style={{
                                        paddingRight: '1rem',
                                        paddingLeft: '0px'
                                    }}>
                                        <label style={label_style}>Tinggi (cm)</label>
                                        <input placeholder="0" value={this.state.input_tinggi} onChange={(e) => this.on_input_change(e)} className="form-control m-input" type="text" name="input_tinggi" style={input_text_style} />
                                    </div>
                                </div>

                                <div style={div_input_style}>
                                    <label style={label_style}>Deskripsi</label>
                                    <textarea onChange={(e) => this.on_input_change(e)} placeholder="" className="form-control m-input" type="text" name="input_deskripsi" style={text_area_style} value={this.state.input_deskripsi} />
                                </div>
                                <div style={div_input_style}>
                                    <label style={label_style}>Asuransi:</label>
                                    <select className="form-control m-input" id="input_asuransi" value={this.state.input_asuransi} onChange={(e) => this.on_select_change(e)} style={input_text_style}>
                                        <option key={0} value="1"> Ya </option>
                                        <option key={1} value="0"> Tidak </option>
                                    </select>
                                </div>
                                <div style={div_input_style}>
                                    <label style={label_style}>Nilai Barang (Rp):</label>
                                    <input value={this.state.input_nilai_barang} onChange={(e) => this.on_input_change(e)} className="form-control m-input" type="text" name="input_nilai_barang" style={input_text_style} />
                                </div>
                                <div style={div_input_style}>
                                    <label style={label_style}>Biaya Kirim (Rp):</label>
                                    <input disabled value={this.state.input_biaya_kirim} onChange={(e) => this.on_input_change(e)} className="form-control m-input" type="text" name="input_biaya_kirim" style={input_text_style} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal className="modal" open={this.state.show_modal_kode_pos} onClose={() => this.setState({ show_modal_kode_pos: false })} center showCloseIcon={true} closeOnOverlayClick={false}>
                    <div className="modal-dialog modal-lg" role="document" style={{ marginTop: '0px', marginBottom: '0px' }}>
                        <div className="modal-content" style={{ border: "white" }}>
                            <div className="modal-header" style={{ borderBottom: 'none', paddingBottom: 0 }}>
                                <h5 className="modal-title">Pilih Kode Pos</h5>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="m-portlet__body">
                                            <div className="m-widget4">
                                                {this.state.data_kode_pos.length !== 0
                                                    && this.state.data_kode_pos !== undefined ?
                                                    this.state.data_kode_pos.map(function (item, key) {
                                                        return (<div onClick={(e) => self.on_item_kode_pos_clicked(e)} key={key} className="m-widget4__item" data-val={item.posCode}
                                                            style={{
                                                                paddingTop: '10px', paddingBottom: '10px', width: '100%',
                                                                backgroundColor: self.state.selected_kode_pos == item.posCode ? 'grey' : 'white'
                                                            }}>
                                                            <div className="m-widget4__info" style={{ padding: 0 }}>
                                                                <span className="m-widget4__title" style={{ color: self.state.selected_kode_pos == item.posCode ? 'white' : 'black' }}>
                                                                    {item.posCode} - {item.address}
                                                                </span>
                                                            </div>
                                                        </div>)
                                                    })
                                                    : <div style={{ textAlign: 'center' }}>Tidak ada kode pos</div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer" style={{ borderTop: 'none' }}>
                                <button type="button" onClick={this.on_btn_kode_pos_ok} className={this.state.tx_flag_processing ? 'btn btn-primary m-loader m-loader--light m-loader--right' : 'btn btn-primary'}>
                                    OK
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal className="modal" open={this.state.show_modal_review} onClose={this.on_btn_confirm_cancel} center showCloseIcon={false} closeOnOverlayClick={false}>
                    <div className="modal-dialog modal-lg"
                        role="document"
                        style={{ marginTop: '0px', marginBottom: '0px', width: '700px' }}
                    >
                        <div className="modal-content" style={{ border: "white" }}>
                            <div className="modal-header" style={{ borderBottom: 'none', paddingBottom: 0 }}>
                                <h5 className="modal-title">Review Transaksi Pengiriman</h5>
                            </div>
                            <div className="modal-body">
                                <div className="row" style={row_review}>
                                    <div className="col-lg-6">
                                        <div style={{ margin: '0 10px' }}>
                                            <div className="m-form__group row">
                                                <label style={label_review}>
                                                    1. Data Pengirim
                                                </label>
                                            </div>
                                            <div className="m-form__group row">
                                                <label style={label_review}>Nama Lengkap</label>
                                            </div>
                                            <div className="m-form__group row">
                                                <label style={{}}>{this.state.input_nama_pengirim}</label>
                                            </div>
                                        </div>
                                        <div style={{ margin: '0 10px' }}>
                                            <div className="m-form__group row">
                                                <label style={label_review}>Telpon</label>
                                            </div>
                                            <div className="m-form__group row">
                                                <label style={{}}>{this.state.input_telp_pengirim}</label>
                                            </div>
                                        </div>
                                        <div style={{ margin: '0 10px' }}>
                                            <div className="m-form__group row">
                                                <label style={label_review}>Alamat</label>
                                            </div>
                                            <div className="m-form__group row">
                                                <label style={{}}>{this.state.input_alamat_pengirim}</label>
                                            </div>
                                        </div>
                                        <div style={{ margin: '0 10px' }}>
                                            <div className="m-form__group row">
                                                <label style={label_review}>Kota / Kelurahan</label>
                                            </div>
                                            <div className="m-form__group row">
                                                <label style={{}}>
                                                    {this.state.input_kota_pengirim} / {this.state.input_kelurahan_pengirim}
                                                </label>
                                            </div>
                                        </div>
                                        <div style={{ margin: '0 10px' }}>
                                            <div className="m-form__group row">
                                                <label style={label_review}>Kode Pos</label>
                                            </div>
                                            <div className="m-form__group row">
                                                <label style={{}}>{this.state.input_kode_pos_pengirim}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div style={{ margin: '0 10px' }}>
                                            <div className="m-form__group row">
                                                <label style={label_review}>
                                                    2. Data Penerima
                                                </label>
                                            </div>
                                            <div className="m-form__group row">
                                                <label style={label_review}>Nama Lengkap</label>
                                            </div>
                                            <div className="m-form__group row">
                                                <label style={{}}>{this.state.input_nama_penerima}</label>
                                            </div>
                                        </div>
                                        <div style={{ margin: '0 10px' }}>
                                            <div className="m-form__group row">
                                                <label style={label_review}>Telpon</label>
                                            </div>
                                            <div className="m-form__group row">
                                                <label style={{}}>{this.state.input_telp_penerima}</label>
                                            </div>
                                        </div>
                                        <div style={{ margin: '0 10px' }}>
                                            <div className="m-form__group row">
                                                <label style={label_review}>Alamat</label>
                                            </div>
                                            <div className="m-form__group row">
                                                <label style={{}}>{this.state.input_alamat_penerima}</label>
                                            </div>
                                        </div>
                                        <div style={{ margin: '0 10px' }}>
                                            <div className="m-form__group row">
                                                <label style={label_review}>Kota / Kelurahan</label>
                                            </div>
                                            <div className="m-form__group row">
                                                <label style={{}}>
                                                    {this.state.input_kota_penerima} / {this.state.input_kelurahan_penerima}
                                                </label>
                                            </div>
                                        </div>
                                        <div style={{ margin: '0 10px' }}>
                                            <div className="m-form__group row">
                                                <label style={label_review}>Kode Pos</label>
                                            </div>
                                            <div className="m-form__group row">
                                                <label style={{}}>{this.state.input_kode_pos_penerima}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={row_review}>
                                    <div className="col-lg-12">
                                        <div style={{ margin: '0 10px' }}>
                                            <div className="m-form__group row">
                                                <label style={label_review}>
                                                    3. Pengiriman
                                                </label>
                                            </div>
                                        </div>
                                        <div style={{ margin: '0 10px' }}>
                                            <div className="m-form__group row">
                                                <label className="col-2" style={label_review}>
                                                    Service:
                                                </label>
                                                <div className="col-10">
                                                    {this.state.input_service}
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ margin: '0 10px' }}>
                                            <div className="m-form__group row">
                                                <label className="col-2" style={label_review}>
                                                    Jenis Barang:
                                                </label>
                                                <div className="col-10">
                                                    {this.state.input_jenis_barang}
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ margin: '0 10px' }}>
                                            <div className="m-form__group row">
                                                <label className="col-2" style={label_review}>
                                                    COD:
                                                </label>
                                                <div className="col-10">
                                                    {this.state.input_cod == 1 ? 'Ya' : 'Tidak'}
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ margin: '0 10px' }}>
                                            <div className="m-form__group row">
                                                <label className="col-2" style={label_review}>
                                                    Instruksi:
                                                </label>
                                                <div className="col-10">
                                                    {this.state.input_instruksi}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={row_review}>
                                    <div className="col-lg-12">
                                        <div style={{ margin: '0 10px', marginTop: '7px' }}>
                                            <div className="m-form__group row">
                                                <label style={label_review}>
                                                    4. Info Barang Kiriman
                                                </label>
                                            </div>
                                        </div>
                                        <div style={{ margin: '0 10px' }}>
                                            <div className="m-form__group row">
                                                <div className="col-lg-3" style={{ padding: 0 }}>
                                                    <label style={{ fontWeight: 600 }}>Berat: </label>
                                                    {this.state.input_berat} kg
                                                </div>
                                                <div className="col-lg-3" style={{ padding: 0 }}>
                                                    <label style={{ fontWeight: 600 }}>Panjang:</label>
                                                    {this.state.input_panjang} cm
                                                </div>
                                                <div className="col-lg-3" style={{ padding: 0 }}>
                                                    <label style={{ fontWeight: 600 }}>Lebar:</label>
                                                    {this.state.input_lebar} cm
                                                </div>
                                                <div className="col-lg-3" style={{ padding: 0 }}>
                                                    <label style={{ fontWeight: 600 }}>Tinggi:</label>
                                                    {this.state.tinggi} cm
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ margin: '0 10px' }}>
                                            <div className="m-form__group row">
                                                <label className="col-2" style={label_review}>Deskripsi:</label>
                                                <div className="col-10">
                                                    {this.state.input_deskripsi}
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ margin: '0 10px' }}>
                                            <div className="m-form__group row">
                                                <label className="col-2" style={label_review}>Asuransi:</label>
                                                <div className="col-10">
                                                    {this.state.input_asuransi == 1 ? 'Ya' : 'Tidak'}
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ margin: '0 10px' }}>
                                            <div className="m-form__group row">
                                                <label className="col-2" style={label_review}>Nilai Barang:</label>
                                                <div className="col-10">
                                                    Rp. {this.state.input_nilai_barang}
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ margin: '0 10px' }}>
                                            <div className="m-form__group row">
                                                <label className="col-2" style={label_review}>Biaya Kirim:</label>
                                                <div className="col-10">
                                                    Rp. {this.state.input_biaya_kirim}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" onClick={this.on_btn_review_cancel} className="btn btn-secondary" data-dismiss="modal">
                                    Batal
                                </button>
                                <button type="button" onClick={this.on_btn_review_ok} className={this.state.loading_buat_transaksi ? 'btn btn-primary m-loader m-loader--light m-loader--right' : 'btn btn-primary'}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
                {this.state.show_resi_paket ? <CetakResiPaket
                    data={data_resi_paket}
                    data_detail={data_resi_paket_detail}
                    is_history={false}
                    on_close={() => {
                        self.setState({ show_resi_paket: false })
                    }} /> : ''}
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    update_current_page: val => dispatch(do_action(action.ACTION_UPDATE_CURRENT_PAGE, val))
});

const mapStateToProps = (state) => ({
    profile: state.Profile,
    session: state.Session
});

export default connect(mapStateToProps, mapDispatchToProps)(AgentMLOPosting);