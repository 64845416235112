import React, {Component} from 'react';
import {connect} from 'react-redux';
import Modal from 'react-responsive-modal';
import moment from 'moment';
import request from 'superagent';
import util from '../../helper/util';
import apis from '../../helper/api';
import swal from 'sweetalert';
import {Form, FormGroup} from '../../component/form/form'
import {DatePicker, DatePickerInput} from 'rc-datepicker';

class ModalDownload extends Component {

    constructor(props) {
        super(props)
        this.state = {
            year_sel: '2019', 
            month_sel: '1',
            date_sel: '',
            i_date_sel: '',
            flag_processing: false,
            url_download: '',
            total_page: 0
        };
        this.date_change = this.date_change.bind(this);
        this.on_request_data_per_page = this.on_request_data_per_page.bind(this);
    }

    componentDidMount() {
        let self = this;
        util.log(this.props.data);
    }

    on_input_change(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    on_select_change(e) {
        this.setState({[e.target.id]: e.target.value})
    }

    date_change(date) {
        try {
            util.log(date)
            let onlyDate = ''
            if (date.length !== 0) {
                let bulan = (date.getMonth() + 1).toString()
                let hari = date.getDate().toString()
                let m = bulan.length === 1 ? '0' + bulan : bulan
                let h = hari.length === 1 ? '0' + hari : hari
                onlyDate = date.getFullYear() + "-" + m + "-" + h
            }
            util.log(onlyDate)
            this.setState({date_sel : onlyDate, i_date_sel : date});
        } catch(err) {
            util.log("[date_change] error", err)
        }
    }

    on_request_data_per_page() {
        if (this.state.flag_processing) return
        let self = this
        var cboxes = document.getElementsByName('download-pages[]')
        var len = cboxes.length;
        for (var i = 0; i < len; i++) {
            util.log(i + (cboxes[i].checked ? 'checked' : 'unchecked') + cboxes[i].value);
            if (cboxes[i].checked) {
                window.open(self.state.url_download + "?page=" + cboxes[i].value);
            }
        }
    }

    submit_monthly() {
        if (this.state.year_sel.length === 0) return;
        if (this.state.month_sel.length === 0) return;
        let self = this;
        let url = this.props.url;
        let body = {
            "session_id" : self.props.session,
            "year" : this.state.year_sel,
            "month" : this.state.month_sel,
            "day" : 0
        };
        self.setState({flag_processing: true});
        request.post(url)
            .set("Content-Type", "application/json")
            .send(body)
            .end(function(err, res) {
                let message_error = "";
                if (err || !res.ok) {
                    util.log(err);
                    message_error = {"body": err.message, "title": "Failed"};
                    if (res !== undefined) {
                        message_error.body = "Error code " + res.statusCode;
                    }
                } else {
                    util.log(res);
                    if (res.statusCode == 200) {
                        let payload = res.body;
                        if (payload.status == "OK") {
                            if (payload.results) {
                                let results = payload.results;
                                if (results.total_page > 1) {
                                    self.setState({url_download: results.url_download, total_page: results.total_page})
                                } else {
                                    window.open(results.url_download);
                                }
                            }
                        } else {
                            message_error = {"body": res.body.error.message, "title": "Failed"};
                            if (res.body.display_message !== undefined) {
                                message_error.title = res.body.error.message;
                                message_error.body = res.body.display_message;
                            }
                        }
                    } else {
                        message_error = {"body": "HTTP error " + res.statusCode, "title": "Failed"};
                    }
                }
                if (message_error.length != 0) {
                    swal(message_error.title, message_error.body, "warning");
                }
                self.setState({flag_processing: false});
            });
    }

    submit_daily() {
        if (this.state.date_sel.length === 0) return;
        let self = this;
        let date = this. state.i_date_sel;
        let bulan = (date.getMonth() + 1).toString();
        let hari = date.getDate().toString();
        let y = date.getFullYear();
        let m = bulan.length === 1 ? '0' + bulan : bulan;
        let h = hari.length === 1 ? '0' + hari : hari;

        let url = this.props.url;
        let body = {
            "session_id" : self.props.session,
            "year" : y,
            "month" : m,
            "day" : h
        };
        self.setState({flag_processing: true});
        request.post(url)
            .set("Content-Type", "application/json")
            .send(body)
            .end(function(err, res) {
                let message_error = "";
                if (err || !res.ok) {
                    util.log(err);
                    message_error = {"body": err.message, "title": "Failed"};
                    if (res !== undefined) {
                        message_error.body = "Error code " + res.statusCode;
                    }
                } else {
                    util.log(res);
                    if (res.statusCode == 200) {
                        let payload = res.body;
                        if (payload.status == "OK") {
                            if (payload.results) {
                                let results = payload.results;
                                if (results.total_page > 1) {
                                    self.setState({url_download: results.url_download, total_page: results.total_page})
                                } else {
                                    window.open(results.url_download);
                                }
                            }
                        } else {
                            message_error = {"body": res.body.error.message, "title": "Failed"};
                            if (res.body.display_message !== undefined) {
                                message_error.title = res.body.error.message;
                                message_error.body = res.body.display_message;
                            }
                        }
                    } else {
                        message_error = {"body": "HTTP error " + res.statusCode, "title": "Failed"};
                    }
                }
                if (message_error.length != 0) {
                    swal(message_error.title, message_error.body, "warning");
                }
                self.setState({flag_processing: false});
            });
    }

    createPages = () => {
        let pages = [];
        let total = this.state.total_page;
        for (let i = 0; i < total; i++) {
            pages.push(<div className="">
                <div className="col m--align-left m-login__form-left" style={{paddingLeft: 0}}>
                    <label className="m-checkbox  m-checkbox--dark" style={{fontWeight: 500}}>
                        <input type="checkbox" name="download-pages[]" defaultChecked="true" value={i + 1} />
                        {i + 1}
                    <span></span>
                    </label>
                </div>
            </div>)
        }
        return pages;
    }

    render() {
        let self = this;
    	let data = this.props.data;

    	return (
	    	<Modal className="modal" open={true} center onClose={this.props.close} showCloseIcon={true} closeOnOverlayClick={false}>
	            <div className="modal-dialog modal-lg" role="document" style={{marginTop: '0px',marginBottom: '0px',width: '600px'}}>
	                <div className="modal-content" style={{border:"white"}}>
	                    <div className="modal-header">
	                        <h5 className="modal-title">
	                            {this.props.title}
	                        </h5>
	                    </div>
	                    <div className="modal-body" style={{padding: '15px 0'}}>
                            <div className="row">
                                <h5 style={{marginLeft:'30px'}}>Data Per Bulanan</h5>
                            </div>
                            <div className="row" style={{marginTop:'10px'}}>
                                <label className="col-3 col-form-label" style={{fontWeight:600,textAlign:'right'}}>
                                    Tahun
                                </label>
                                <div className="col-9" style={{margin: 'auto 0'}}>
                                    <select className="form-control m-input" id="year_sel" value={this.state.year_sel} 
                                        onChange={(e) => this.on_select_change(e)}>
                                        <option value="2018"> 2018 </option>
                                        <option value="2019"> 2019 </option>
                                        <option value="2020"> 2020 </option>
                                        <option value="2021"> 2021 </option>
                                        <option value="2022"> 2022 </option>
					<option value="2023"> 2023 </option>
					<option value="2024"> 2024 </option>
                                    </select>
                                </div>
                            </div>

	                        <div className="row" style={{marginTop:'10px'}}>
	                        	<label className="col-3 col-form-label" style={{fontWeight:600,textAlign:'right'}}>
                                    Bulan
                                </label>
                                <div className="col-9" style={{margin: 'auto 0'}}>
                                    <select className="form-control m-input" id="month_sel" value={this.state.month_sel} 
                                        onChange={(e) => this.on_select_change(e)}>
                                        <option value="1"> Januari </option>
                                        <option value="2"> Februari </option>
                                        <option value="3"> Maret </option>
                                        <option value="4"> April </option>
                                        <option value="5"> Mei </option>
                                        <option value="6"> Juni </option>
                                        <option value="7"> Juli </option>
                                        <option value="8"> Agustus </option>
                                        <option value="9"> September </option>
                                        <option value="10"> Oktober </option>
                                        <option value="11"> November </option>
                                        <option value="12"> Desember </option>
                                    </select>
                                </div>
				            </div>

                            <div className="row" style={{marginTop:'10px', textAlign:'right'}}>
                                <div className="col-12">
                                    <button type="submit" disabled={this.state.url_download.length !== 0 ? true : false}
                                        onClick={(e) => this.submit_monthly()} 
                                        className="btn btn-danger m-btn m-btn--air m-btn--custom">
                                        Download
                                    </button>
                                </div>
                            </div>

                            <div className="row" style={{marginTop:'20px'}}>
                                <h5 style={{marginLeft:'30px'}}>Data Per Hari</h5>
                            </div>

                            <div className="row" style={{marginTop:'10px'}}>
                                <label className="col-3 col-form-label" style={{fontWeight:600,textAlign:'right'}}>
                                    Hari
                                </label>
                                <div className="col-9" style={{margin: 'auto 0'}}>
                                    <DatePickerInput onChange={this.date_change} value={this.state.i_date_sel}/>
                                </div>
                            </div>

                            <div className="row" style={{marginTop:'10px', textAlign:'right'}}>
                                <div className="col-12">
                                    <button type="submit" disabled={this.state.url_download.length !== 0 ? true : false}
                                        onClick={(e) => this.submit_daily()} 
                                        className="btn btn-danger m-btn m-btn--air m-btn--custom">
                                        Download
                                    </button>
                                </div>
                            </div>

                            {this.state.url_download !== '' ?
                                <div style={{margin:'20px 5px 0 16px'}}>
                                    <FormGroup>
                                        <div style={{}}>
                                            <label className="col-form-label" style={{fontWeight:600}}>
                                                Data yg Anda request terdiri dari {this.state.total_page} page (max data per page 1000 rows): 
                                            </label>
                                        </div>
                                        {this.createPages()}
                                        <div style={{marginTop:'10px'}}>
                                            <button type="submit" 
                                                onClick={this.on_request_data_per_page} 
                                                className="btn btn-danger m-btn m-btn--air m-btn--custom">
                                                Download Data per Page Sekarang
                                            </button>
                                        </div>
                                    </FormGroup>
                                </div>
                            : ''}

	                    </div>
	                    <div className="modal-footer" style={{borderTop: 0, display:'none'}}></div>
	                </div>
	            </div>
	        </Modal>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
})

const mapStateToProps = (state) => ({
    session : state.Session,
    balance: state.Balance,
    profile: state.Profile
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalDownload)
