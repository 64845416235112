import { connect } from 'react-redux';
import React, { Component } from 'react';
import moment from 'moment';
import request from 'superagent';
import { Form } from './form/form';
import { PortletTab, PortletTabHeader, PortletTabHeaderList, PortletTabBodyList, PortletAlert, PortletStruk } from './widget/portlet';
import apis from '../helper/api';
import action from '../helper/action';
import do_action from '../action';
import config from '../helper/config';
import swal from 'sweetalert';
import Modal from 'react-responsive-modal';
import util from '../helper/util';
import CetakStrukCashin from './form/cetak_resi_cashin';
import CetakStrukCashout from './form/cetak_resi_cashout';
import CetakStrukTransfer from './form/cetak_resi_transfer';
import localdata from '../helper/localdata';
import Select from 'react-select';
let tx_product = "";
let start_time_process_seconds = 0;

class AgentCashinCashout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            denoms_sel: [],
            denoms_sel_selected: "",
            no_pelanggan: "",
            no_pelanggan_prepaid: "",
            tx_denom_prepaid: "",
            jenis_postpaid: "",
            denom_prepaid: [],
            product_postpaid: [],
            tx_show_modal: false,
            tx_show_modal_cashin: false,
            tx_show_modal_transfer: false,
            tx_show_struk: false,
            tx_tagihan_prepaid: "",
            tx_detail_transfer: "",
            tx_tagihan_pelanggan: "",
            tx_flag_processing: false,
            tx_flag_inquiry_processing: false,
            tx_result_message: "",
            tx_result_success: "",
            show_info_pelanggan: false,
            nama_pelanggan: "",
            nomor_pelanggan: "",
            nomor_kap: "",
            nomor_pin: "",
            nomor_rek_giro: "",
            nominal: "",
            keterangan: "",
            sender_nik: "",
            sender_name: "",
            sender_address: "",
            sender_phone: "",
            source_of_funds: "",
            description: "",
            show_btn_bayar: true
        };
        this.on_btn_tagihan = this.on_btn_tagihan.bind(this);
        this.on_btn_submit_prepaid = this.on_btn_submit_prepaid.bind(this);
        this.on_btn_reset = this.on_btn_reset.bind(this);
        this.on_btn_confirm_cancel = this.on_btn_confirm_cancel.bind(this);
        this.on_btn_confirm_ok = this.on_btn_confirm_ok.bind(this);
        this.on_btn_confirm_ok_prepaid = this.on_btn_confirm_ok_prepaid.bind(this);
        this.on_btn_confirm_ok_transfer = this.on_btn_confirm_ok_transfer.bind(this);
        this.on_btn_struk_cancel = this.on_btn_struk_cancel.bind(this);
        this.on_btn_struk_ok = this.on_btn_struk_ok.bind(this);
        this.on_btn_cetak_struk = this.on_btn_cetak_struk.bind(this);
        this.total_bayar_cashin = this.total_bayar_cashin.bind(this);
        this.on_key_press = this.on_key_press.bind(this);
        this.total_bayar_transfer = this.total_bayar_transfer.bind(this);
    }

    componentDidMount() {
        let self = this;
        this.props.update_current_page('cashin');        
        tx_product = "";
        localdata.get_product_platinum("PLATINUM_REMITTANCE").then((payload) => {
            util.log("LIST_PLATINUM: ", payload)

            if (payload.length !== 0) {
                let options = []
                payload.map(function(item, i) {
                    let opt = {value : item.product_id, label : item.product_name}
                    options.push(opt)
                })
                self.setState({denoms : payload, denoms_sel : options})
            }

        //     localdata.get_list_services_platinum("LIST_SERVICE").then((payload) => {
        //         util.log("LIST_SERVICE: ", payload)
        //         // let tmp = []
        //         // if (payload.denoms) {
        //         //     payload.denoms.map(den =>
        //         //         tmp.push({product_id:payload.product_id,product_name:den.product_name,product_code:den.product_code})
        //         //     )
        //         //     this.setState({denoms:tmp,not_support:""})
        //         // } else {
        //         //     tx_product = ""
        //         //     this.setState({denoms:[],not_support:"Nomor yang anda masukkan belum kami support, silakan kontak admin untuk info lebih lanjut"})
        //         // }

          
        }).catch((err) => {
            util.log("error : ", err)
        })

    }


    on_input_change(e) {
        if (e.target.name == "no_pelanggan") {
            this.setState({ no_pelanggan: e.target.value });
        } else if (e.target.name == "no_pelanggan_prepaid") {
            this.setState({ no_pelanggan_prepaid: e.target.value });
        } else {
            this.setState({ [e.target.name]: e.target.value });
        }
    }

    on_key_press(e) {
        util.log(e);
        if (e.keyCode == 13) {
            if (e.target.name == "nomor_pin") {
                this.on_btn_tagihan();
            } else if (e.target.name == "keterangan") {
                this.on_btn_submit_prepaid();
            }
        }
    }

    on_btn_cetak_struk(el) {
        util.log("on_btn_cetak_struk");
        if (this.state.tx_result_success.length !== 0) {
            util.log("tx_result_success="+this.state.tx_result_success);
            util.log("tx_result_success.data="+this.state.tx_result_success.data);
            if (util.is_electron()) {
                const btn = el.target;
                btn.classList.add("m-loader", "m-loader--primary", "m-loader--right");
                setTimeout(function () {
                    btn.classList.remove("m-loader", "m-loader--primary", "m-loader--right");
                }, 3000);
                const agent_info = this.props.profile;
                util.electron_print_receipt(agent_info, this.state.tx_result_success.data);
            } else {
                this.setState({ tx_show_struk: true });
            }
        }
    }

    on_btn_struk_ok() {
        this.setState({ tx_show_struk: false })
    }

    on_btn_struk_cancel() {
        this.setState({ tx_show_struk: false })
    }

    on_select_change(e) {
        util.log("on_select_change: ", e.target.value);
        this.setState({ jenis_postpaid: e.target.value });
        this.nomor_pelanggan_postpaid_input.focus();
    }

    on_select_change_dana(e) {
        util.log("on_select_change_dana: ", e.target.value);
        this.setState({ tx_denom_prepaid: e.target.value });
        this.nomor_pelanggan_prepaid_input.focus();
    }

    on_btn_tagihan() {
        if (this.state.tx_flag_inquiry_processing) return;
        let self = this;
        if (self.state.nomor_kap.length === 0) {
            swal("Ups!", "Nomor KAP masih kosong", "warning");
            return;
        }
        if (self.state.nomor_pin.length === 0) {
            swal("Ups!", "Nomor PIN masih kosong", "warning");
            return;
        }
        let body = {
            "session_id": self.props.session,
            "no_kap": self.state.nomor_kap,
            "no_pin": self.state.nomor_pin
        };
        self.setState({ tx_flag_inquiry_processing: true, tx_tagihan_pelanggan: "", tx_result_success: "" });
        start_time_process_seconds = Math.floor(Date.now() / 1000);
        request.post(apis.inquiry_cashout)
            .set("Content-Type", "application/json")
            .timeout(120 * 1000)
            .send(body)
            .end(function (err, res) {
                let message_error = "";
                if (err || !res.ok) {
                    util.log(err);
                    message_error = { "body": err.message, "title": "Transaksi Gagal" };
                    if (res != undefined) {
                        message_error.body = "Error code " + res.statusCode;
                    }
                } else {
                    util.log(res);
                    if (res.statusCode == 200) {
                        let payload = res.body;
                        util.log(payload);
                        if (payload.status == "OK") {
                            let results = payload.results;
                            results.info = `Account No: ${results.account_number}|Account Name: ${results.account_name}|KAP: ${results.no_kap}`;
                            self.setState({ tx_tagihan_pelanggan: results, show_btn_bayar: true });
                        } else {
                            util.on_error(payload.error);
                            message_error = { "body": res.body.error.message, "title": "Transaksi Gagal" };
                            if (res.body.display_message !== undefined) {
                                message_error.title = res.body.error.message;
                                message_error.body = res.body.display_message;
                            }
                        }
                    } else {
                        message_error = { "body": "HTTP error " + res.statusCode, "title": "Transaksi Gagal" };
                    }
                }
                if (message_error.length != 0) {
                    swal(message_error.title, message_error.body, "error");
                }
                self.setState({ tx_flag_inquiry_processing: false });
                start_time_process_seconds = 0;
            });
    }


    
    select_handle_change = (selected_option) => {
        let selected_value = "";
        // let label_sumberdana = "";
        tx_product = "";
        if (selected_option !== null) {
            tx_product = {
                "product_id" : selected_option.value,
                "product_name" : selected_option.label
            }
            selected_value = selected_option.value;
            // label_sumberdana = selected_option.label;
        } else {
            tx_product = ""
        }
        
        // let show_tahun = false;
        // if (selected_option.value === dki_jakarta_product_id) {
        //     show_tahun = true;
        // }
        // let show_pkb_jawa_timur_input = false;
        // if (selected_option.value === pkb_jawa_timur) {
        //     show_pkb_jawa_timur_input = true;
        // }
        this.setState({
           
            // no_pelanggan: "",
            // tx_daerah: selected_value, 
            denoms_sel_selected: selected_option, 
            // show_tahun_input: show_tahun,
            // show_pkb_jawa_timur_input
        });
        if (this.nomor_pelanggan_input)
            this.nomor_pelanggan_input.focus();
    }



    on_btn_reset() {
        this.setState({ no_pelanggan: "", denoms: [], tx_result_message: "", tx_result_success: "", tx_tagihan_pelanggan: "", nomor_kap: "", nomor_pin: "" });
    }

    on_btn_confirm_cancel() {
        this.setState({ tx_show_modal: false, tx_show_modal_cashin: false ,tx_show_modal_transfer:false});
    }

    on_btn_bayar() {
        this.setState({ tx_show_modal: true, show_info_pelanggan: false, nama_pelanggan: "", nomor_pelanggan: "" });
    }

    on_btn_submit_prepaid() {
        if (this.state.denoms_sel_selected.length === 0) {
            swal("Ups!", "Sumber Dana tidak boleh kosong", "warning");
            return;
        }
        if (this.state.nomor_rek_giro.length === 0) {
            swal("Ups!", "Nomor rekening tidak boleh kosong", "warning");
            return;
        }
        if (this.state.nominal.length === 0) {
            swal("Ups!", "Nominal tidak boleh kosong", "warning");
            return;
        }
        util.log(this.state.denoms_sel_selected.value);

        if( this.state.denoms_sel_selected.value === config.cash_in_productid)
        {

            this.setState({ tx_show_modal_cashin: true,tx_show_modal_transfer:false, show_info_pelanggan: false, nama_pelanggan: "", nomor_pelanggan: "", tx_result_success: "", tx_tagihan_pelanggan: "" });
            this.total_bayar_cashin();
        } 
        else
        {

            this.setState({tx_show_modal_cashin:false, tx_show_modal_transfer: true, show_info_pelanggan: false, nama_pelanggan: "", nomor_pelanggan: "", tx_result_success: "", tx_tagihan_pelanggan: "" });
            this.total_bayar_transfer();
        }

    }

    on_btn_confirm_ok() {
        if (this.state.tx_flag_processing) return;
        let self = this;
        let body = {
            session_id: this.props.session,
            item_id: this.state.tx_tagihan_pelanggan.item_id,
            item_code: this.state.tx_tagihan_pelanggan.item_code
        }
        if (this.state.nama_pelanggan !== undefined && this.state.nama_pelanggan.length !== 0) {
            body.customer_name = this.state.nama_pelanggan
        }
        if (this.state.nomor_pelanggan !== undefined && this.state.nomor_pelanggan.length !== 0) {
            body.customer_phone_number = this.state.nomor_pelanggan
        }
        self.setState({ tx_flag_processing: true })
        start_time_process_seconds = Math.floor(Date.now() / 1000)
        request.post(apis.payment_cashout)
            .set("Content-Type", "application/json")
            .timeout(120 * 1000)
            .send(body)
            .end(function (err, res) {
                let message_result = ""
                let success_result = ""
                if (err || !res.ok) {
                    util.log(err)
                    message_result = { title: "Error", body: err.message }
                    if (res != undefined) {
                        message_result.body = "Error code " + res.statusCode
                    }
                    if (err.code === 504) {
                        message_result.body = config.timeout_message
                    }
                } else {
                    util.log(res);
                    if (res.statusCode == 200) {
                        let payload = res.body
                        util.log(payload)
                        if (payload.status == "OK") {
                            let results = payload.results;
                            results.info = `Account No: ${results.payment.account_number}|Account Name: ${results.payment.account_name}|Nominal: ${results.payment.nominal}|KAP: ${results.payment.kap}`;
                            let success_info = results.info;
                            success_result = { title: "Transaksi Sukses", body: success_info, data: results, isCashin: false, isTransfer:false };
                            swal(success_result.title, payload.display_message, "success");
                            self.setState({ show_btn_bayar: false });
                        } else {
                            util.on_error(payload.error)
                            message_result = { title: "Error", body: res.body.error.message }
                            if (res.body.display_message != undefined) {
                                message_result.body = message_result.body + ". " + res.body.display_message
                            }
                        }
                    } else {
                        message_result = { title: "Error", body: res.statusCode }
                    }
                }
                if (message_result.length != 0) {
                    swal(message_result.title, message_result.body, "error")
                    message_result.title = "[" + moment().format("DD-MM-YYYY hh:mm") + "] " + message_result.title
                }
                self.setState({
                    tx_flag_processing: false,
                    tx_result_message: message_result,
                    tx_result_success: success_result,
                    tx_show_modal: false
                })
                start_time_process_seconds = 0
            });
    }

    on_btn_confirm_ok_prepaid() {
        util.log("payment start");
        if (this.state.tx_flag_processing) return;
        let self = this;
        let body = {
            "session_id": this.props.session,
            "item_id": this.state.tx_tagihan_prepaid.item_id,
            "item_code": this.state.tx_tagihan_prepaid.item_code,
            "sender": {
                "nik": self.state.sender_nik,
                "name": self.state.sender_name,
                "address": self.state.sender_address,
                "phone": self.state.sender_phone,
                "source_of_funds": self.state.source_of_funds,
                "description": self.state.description
            }
        };
        self.setState({ tx_flag_processing: true });
        start_time_process_seconds = Math.floor(Date.now() / 1000);
        request.post(apis.payment_cashin)
            .set("Content-Type", "application/json")
            .timeout(120 * 1000)
            .send(body)
            .end(function (err, res) {
                let message_result = "";
                let success_result = "";
                if (err || !res.ok) {
                    util.log(err);
                    message_result = { title: "Error", body: err.message };
                    if (res != undefined) {
                        message_result.body = "Error code " + res.statusCode;
                    }
                    if (err.code === 504) {
                        message_result.body = config.timeout_message;
                    }
                } else {
                    util.log(res);
                    if (res.statusCode == 200) {
                        let payload = res.body;
                        util.log(payload);
                        if (payload.status == "OK") {
                            let results = payload.results;
                            results.info = `Nama: ${results.payment.sender.name}|NIK: ${results.payment.sender.nik}|Alamat: ${results.payment.sender.address}|No HP: ${results.payment.sender.phone}|Asal Dana: ${results.payment.sender.source_of_funds}|Keterangan: ${results.payment.sender.description}|No Giro: ${results.payment.account_cgs}|Nama: ${results.payment.account_name}`;
                            success_result = { title: "Transaksi Sukses", body: '', data: results, isCashin: true , isTransfer : false };
                        } else {
                            util.on_error(payload.error);
                            message_result = { title: "Error", body: res.body.error.message };
                            if (res.body.display_message != undefined) {
                                message_result.body = message_result.body + ". " + res.body.display_message;
                            }
                        }
                    } else {
                        message_result = { title: "Error", body: res.statusCode };
                    }
                }
                if (message_result != null && message_result.length != 0) {
                    swal(message_result.title, message_result.body, "error");
                    message_result.title = "[" + moment().format("DD-MM-YYYY hh:mm") + "] " + message_result.title;
                }
                self.setState({
                    tx_flag_processing: false,
                    tx_result_message: message_result,
                    tx_result_success: success_result,
                    tx_show_modal_cashin: false,
                    tx_show_modal_transfer:false
                });
                start_time_process_seconds = 0;
            });
    }

    on_btn_confirm_ok_transfer() {
        util.log("payment transfer start");
        if (this.state.tx_flag_processing) return;
        let self = this
        let whitelabel_36 = config.whitelabel.toString(36).toUpperCase()
        let user_id = this.props.profile.userid.toString(36).toUpperCase()
        let prefix = whitelabel_36.substring(whitelabel_36.length - 3)
        let suffix = user_id.substring(user_id.length - 3)
        let token = util.gen_token(prefix, this.props.session, suffix)
        let body = {
            "session_id" : this.props.session,
            "product_id" : tx_product.product_id,
            "product_code" : tx_product.product_denom,
            "biller_number": self.state.nomor_rek_giro+"|"+self.state.nominal,
            "sign_token" : token
        }
        self.setState({ tx_flag_processing: true });
        start_time_process_seconds = Math.floor(Date.now() / 1000);
        request.post(apis.payment)
            .set("Content-Type", "application/json")
            .timeout(120 * 1000)
            .send(body)
            .end(function (err, res) {
                let message_result = "";
                let success_result = "";
                if (err || !res.ok) {
                    util.log(err);
                    message_result = { title: "Error", body: err.message };
                    if (res != undefined) {
                        message_result.body = "Error code " + res.statusCode;
                    }
                    if (err.code === 504) {
                        message_result.body = config.timeout_message;
                    }
                } else {
                    util.log(res);
                    if (res.statusCode == 200) {
                        let payload = res.body;
                        util.log(payload);
                        if (payload.status == "OK") {
                            let results = payload.results;
                            let success_info = "Biller No: " + results.biller_number + "|Produk: " + results.product_id + " " + results.product_name + "|Harga Produk: Rp. " + util.rupiah_format(results.nominal) + "|Biaya Admin: Rp. " + util.rupiah_format(results.fee) + "|Total Bayar: Rp. " + util.rupiah_format(results.total) 
                                // success_result = {title: "Transaksi Sukses", body: success_info, data: results}
                                success_result = { title: "Transaksi Sukses", body: success_info, data: results, isCashin: false ,isTransfer:true };
                                self.props.update_balance(results.balance_after)
                                swal(success_result.title, payload.display_message, "success")
                                self.setState({show_btn_bayar:false})
                        } else {
                            util.on_error(payload.error);
                            message_result = { title: "Error", body: res.body.error.message };
                            if (res.body.display_message != undefined) {
                                message_result.body = message_result.body + ". " + res.body.display_message;
                            }
                        }
                    } else {
                        message_result = { title: "Error", body: res.statusCode };
                    }
                }
                if (message_result != null && message_result.length != 0) {
                    swal(message_result.title, message_result.body, "error");
                    message_result.title = "[" + moment().format("DD-MM-YYYY hh:mm") + "] " + message_result.title;
                }
                self.setState({
                    tx_flag_processing: false,
                    tx_result_message: message_result,
                    tx_result_success: success_result,
                    tx_show_modal_cashin: false,
                    tx_show_modal_transfer:false
                });
                start_time_process_seconds = 0;
            });
    }

    //---------------------------------------------------
    //------------------- transfer bank ----------------

    total_bayar_transfer() {
        util.log("total_bayar_transfer transfer start");
        let self = this;
        let body = {
            "session_id" : self.props.session,
            "product_id" : self.state.denoms_sel_selected.value,
            "biller_number": self.state.nomor_rek_giro+"|"+self.state.nominal
        };

        self.setState({ tx_detail_transfer: "" });
        request.post(apis.inquiry)
            .set("Content-Type", "application/json")
            .timeout(120 * 1000)
            .send(body)
            .end(function (err, res) {
                let tagihan = "";
                if (err || !res.ok) {
                    util.log(err);
                    tagihan = "-";
                } else {
                    util.log(res);
                    if (res.statusCode == 200) {
                        let payload = res.body;
                        util.log(payload);
                        if (payload.status == "OK") {
                            let results = payload.results;
                            tagihan = results;
                        } else {
                            util.on_error(payload.error);
                            tagihan = "-";
                            if (payload.display_message.length !== 0) {
                                tagihan = payload.display_message;
                            } else {
                                tagihan = payload.error.message;
                            }
                        }
                    } else {
                        tagihan = "-";
                    }
                }
                console.log(JSON.stringify(tagihan));
                self.setState({ tx_detail_transfer: tagihan });
                start_time_process_seconds = 0;
            })
    }

    total_bayar_cashin() {
        util.log("total_bayar_cashin start");
        //request.post(apis.inquiry) PLATINUM_GIRO_CASHIN
        let self = this;
        let body = {
            "session_id": self.props.session,
            "account_cgs": self.state.nomor_rek_giro,
            "amount": self.state.nominal,
            "description": self.state.keterangan
        };
        self.setState({ tx_tagihan_prepaid: "" });
        request.post(apis.inquiry_cashin)
            .set("Content-Type", "application/json")
            .timeout(120 * 1000)
            .send(body)
            .end(function (err, res) {
                let tagihan = "";
                if (err || !res.ok) {
                    util.log(err);
                    tagihan = "-";
                } else {
                    util.log(res);
                    if (res.statusCode == 200) {
                        let payload = res.body;
                        util.log(payload);
                        if (payload.status == "OK") {
                            let results = payload.results;
                            tagihan = results;
                        } else {
                            util.on_error(payload.error);
                            tagihan = "-";
                            if (payload.display_message.length !== 0) {
                                tagihan = payload.display_message;
                            } else {
                                tagihan = payload.error.message;
                            }
                        }
                    } else {
                        tagihan = "-";
                    }
                }
                self.setState({ tx_tagihan_prepaid: tagihan });
                start_time_process_seconds = 0;
            })
    }

    on_btn_cetak_tagihan() {
        this.setState({ show_cetak_tagihan: true });
    }

    render() {
        let self = this;
        let tx_result_success = this.state.tx_result_success.data;
        const padding_field = { paddingTop: '6px', paddingBottom: '6px' };
        const label_tagihan_style = { textAlign: 'left', padding: 0, marginTop: 'auto', marginBottom: 'auto', fontWeight: 600 };
        const value_tagihan_style = { fontWeight: 600, width: '100%', textAlign: 'right', padding: 0 };

        const TagihanView = (props) =>
            <div className="col-lg-6">
                <div className="m-portlet m-portlet--success m-portlet--head-solid-bg m-portlet--rounded" style={{ minHeight: '456px', background: '#fff' }}>
                    <div className="m-portlet__head">
                        <div className="m-portlet__head-caption">
                            <div className="m-portlet__head-title">
                                <span className="m-portlet__head-icon">
                                    <i className="flaticon-interface-5"></i>
                                </span>
                                <h3 className="m-portlet__head-text">
                                    Tarik Tunai {this.state.nomor_kap}
                                </h3>
                            </div>
                        </div>
                        <div className="m-portlet__head-tools">
                            <ul className="m-portlet__nav">
                                {this.state.show_btn_bayar ?
                                    <li className="m-portlet__nav-item">
                                        <button onClick={() => this.on_btn_bayar()} className="m-portlet__nav-link btn btn-primary m-btn m-btn--pill m-btn--air" style={{ padding: "7px 15px" }}>
                                            Bayar
                                        </button>
                                    </li>
                                    : ''}
                                <li className="m-portlet__nav-item" style={{ display: 'none' }}>
                                    <button onClick={() => this.on_btn_cetak_tagihan()} className="m-portlet__nav-link btn btn-light m-btn m-btn--pill m-btn--air" style={{ padding: "7px 15px" }}>
                                        Cetak
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="m-portlet__body" style={{ paddingTop: '10px' }}>
                        <div className="row">
                            <div className="col-lg-12">
                                <form className="m-form m-form--fit m-form--label-align-right m-form--group-seperator-dashed">
                                    <div className="form-group m-form__group row" style={padding_field}>
                                        <label className="col-lg-3 col-form-label" style={label_tagihan_style}>
                                            Produk :
                                        </label>
                                        <div className="col-lg-9" style={{ paddingLeft: 0, paddingRight: 0 }}>
                                            <label className="col-form-label" style={{ padding: 0 }}>
                                                Tarik Tunai Giro Pos
                                            </label>
                                        </div>
                                    </div>
                                    <div className="form-group m-form__group row" style={padding_field}>
                                        <label className="col-lg-3 col-form-label" style={label_tagihan_style}>
                                            Agen :
                                        </label>
                                        <div className="col-lg-9" style={{ paddingLeft: 0, paddingRight: 0 }}>
                                            <label className="col-form-label" style={{ padding: 0 }}>
                                                {`${this.state.tx_tagihan_pelanggan.inquiry.agent_name} (${this.state.tx_tagihan_pelanggan.inquiry.agent_code})`}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="form-group m-form__group row" style={padding_field}>
                                        <label className="col-lg-3 col-form-label" style={label_tagihan_style}>
                                            Account :
                                        </label>
                                        <div className="col-lg-9" style={{ paddingLeft: 0, paddingRight: 0 }}>
                                            <label className="col-form-label" style={{ lineHeight: '20px', padding: 0 }}>
                                                {this.state.tx_tagihan_pelanggan.info.split('|').map(function (item, key) {
                                                    return (
                                                        <span key={key}>
                                                            {item}
                                                            <br />
                                                        </span>
                                                    )
                                                })}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="form-group m-form__group row" style={padding_field}>
                                        <label className="col-lg-3 col-form-label" style={label_tagihan_style}>
                                            Tanggal Transaksi :
                                        </label>
                                        <div className="col-lg-9" style={{ paddingLeft: 0, paddingRight: 0, position: 'relative' }}>
                                            <label className="col-form-label" style={{ padding: 0, position: 'absolute', top: '30%' }}>
                                                {`${this.state.tx_tagihan_pelanggan.inquiry.transaction_date}`}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="form-group m-form__group row" style={padding_field}>
                                        <label className="col-lg-3 col-form-label" style={label_tagihan_style}>
                                            Tanggal Kadaluarsa :
                                        </label>
                                        <div className="col-lg-9" style={{ paddingLeft: 0, paddingRight: 0, position: 'relative' }}>
                                            <label className="col-form-label" style={{ padding: 0, position: 'absolute', top: '30%' }}>
                                                {`${this.state.tx_tagihan_pelanggan.inquiry.expired_date}`}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="form-group m-form__group row" style={padding_field}>
                                        <label className="col-lg-4 col-form-label" style={label_tagihan_style}>
                                            Nominal :
                                        </label>
                                        <div className="col-lg-8">
                                            <label className="col-form-label" style={value_tagihan_style}>
                                                Rp. {this.state.tx_tagihan_pelanggan ? util.rupiah_format(this.state.tx_tagihan_pelanggan.trx_nominal_to_user) : ''}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="form-group m-form__group row" style={padding_field}>
                                        <label className="col-lg-4 col-form-label" style={label_tagihan_style}>
                                            Biaya Admin :
                                        </label>
                                        <div className="col-lg-8">
                                            <label className="col-form-label" style={value_tagihan_style}>
                                                Rp. {this.state.tx_tagihan_pelanggan ? util.rupiah_format(this.state.tx_tagihan_pelanggan.trx_fee_admin_to_user) : '0'}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="form-group m-form__group row" style={padding_field}>
                                        <label className="col-lg-4 col-form-label" style={label_tagihan_style}>
                                            Total Bayar :
                                        </label>
                                        <div className="col-lg-8">
                                            <label className="col-form-label" style={value_tagihan_style}>
                                                Rp. {this.state.tx_tagihan_pelanggan ? util.rupiah_format(this.state.tx_tagihan_pelanggan.trx_total) : ''}
                                            </label>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        const TagihanSetorTunaiView = (props) =>
            <div className="col-lg-6">
                <div className="m-portlet m-portlet--success m-portlet--head-solid-bg m-portlet--rounded" style={{ minHeight: '456px', background: '#fff' }}>
                    <div className="m-portlet__head">
                        <div className="m-portlet__head-caption">
                            <div className="m-portlet__head-title">
                                <span className="m-portlet__head-icon">
                                    <i className="flaticon-interface-5"></i>
                                </span>
                                <h3 className="m-portlet__head-text">
                                    Informasi Setor Tunai
                                </h3>
                            </div>
                        </div>
                        <div className="m-portlet__head-tools"></div>
                    </div>
                    <div className="m-portlet__body" style={{ paddingTop: '10px' }}>
                        <div className="row">
                            <div className="col-lg-12">
                                <form className="m-form m-form--fit m-form--label-align-right m-form--group-seperator-dashed">
                                    <div className="form-group m-form__group row" style={padding_field}>
                                        <label className="col-lg-3 col-form-label" style={label_tagihan_style}>
                                            Produk :
                                        </label>
                                        <div className="col-lg-9" style={{ paddingLeft: 0, paddingRight: 0 }}>
                                            <label className="col-form-label" style={{ padding: 0 }}>
                                                Setor Tunai Giro Pos
                                            </label>
                                        </div>
                                    </div>
                                    <div className="form-group m-form__group row" style={padding_field}>
                                        <label className="col-lg-3 col-form-label" style={label_tagihan_style}>
                                            Penyetor :
                                        </label>
                                        <div className="col-lg-9" style={{ paddingLeft: 0, paddingRight: 0 }}>
                                            <label className="col-form-label" style={{ lineHeight: '20px', padding: 0 }}>
                                                <span>{`Nama: ${tx_result_success.payment.sender.name}`}<br /></span>
                                                <span>{`NIK: ${tx_result_success.payment.sender.nik}`}<br /></span>
                                                <span>{`Alamat: ${tx_result_success.payment.sender.address}`}<br /></span>
                                                <span>{`Nomor HP: ${tx_result_success.payment.sender.phone}`}<br /></span>
                                                <span>{`Asal Dana: ${tx_result_success.payment.sender.source_of_funds}`}<br /></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="form-group m-form__group row" style={padding_field}>
                                        <label className="col-lg-3 col-form-label" style={label_tagihan_style}>
                                            Tanggal Transaksi :
                                        </label>
                                        <div className="col-lg-9" style={{ paddingLeft: 0, paddingRight: 0, position: 'relative' }}>
                                            <label className="col-form-label" style={{ padding: 0, position: 'absolute', top: '30%' }}>
                                                {`${tx_result_success.payment.transaction_date}`}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="form-group m-form__group row" style={padding_field}>
                                        <label className="col-lg-4 col-form-label" style={label_tagihan_style}>
                                            Nominal :
                                        </label>
                                        <div className="col-lg-8">
                                            <label className="col-form-label" style={value_tagihan_style}>
                                                Rp. {tx_result_success.trx_nominal_to_user ? util.rupiah_format(tx_result_success.trx_nominal_to_user) : ''}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="form-group m-form__group row" style={padding_field}>
                                        <label className="col-lg-4 col-form-label" style={label_tagihan_style}>
                                            Biaya Admin :
                                        </label>
                                        <div className="col-lg-8">
                                            <label className="col-form-label" style={value_tagihan_style}>
                                                Rp. {tx_result_success.trx_fee_admin_to_user ? util.rupiah_format(tx_result_success.trx_fee_admin_to_user) : '0'}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="form-group m-form__group row" style={padding_field}>
                                        <label className="col-lg-4 col-form-label" style={label_tagihan_style}>
                                            Total Bayar :
                                        </label>
                                        <div className="col-lg-8">
                                            <label className="col-form-label" style={value_tagihan_style}>
                                                Rp. {tx_result_success.trx_total ? util.rupiah_format(tx_result_success.trx_total) : ''}
                                            </label>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-12">
                        {this.state.tx_result_message.length != 0 ? <PortletAlert icon="warning" title={this.state.tx_result_message.title} message={this.state.tx_result_message.body} /> : ""}
                        {this.state.tx_result_success.length != 0 ? <PortletStruk icon="warning" title={this.state.tx_result_success.title} message={this.state.tx_result_success.body} on_btn_cetak_struk={this.on_btn_cetak_struk} /> : ""}
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="m-portlet m-portlet--tab">
                            <PortletTab>
                                <PortletTabHeader>
                                    <PortletTabHeaderList id={'pln-postpaid-tab'} title={'Tarik Tunai'} icon={' la-align-justify'} active />
                                    <PortletTabHeaderList id={'pln-prepaid-tab'} title={'Transfer'} icon={' la-align-justify'} />
                                </PortletTabHeader>
                                <div className="m-portlet__body" style={{}}>
                                    <div className="tab-content">
                                        <PortletTabBodyList id={'pln-postpaid-tab'} active>
                                            <Form>
                                                <label style={{ fontWeight: 400 }}>Sumber Dana</label>
                                                <br />
                                                <label style={{ fontWeight: 400 }}>Nomor KAP</label>
                                                <input
                                                    onChange={(e) => this.on_input_change(e)}
                                                    onKeyDown={this.on_key_press}
                                                    type="text"
                                                    className="form-control m-input"
                                                    name="nomor_kap"
                                                    value={this.state.nomor_kap}
                                                    placeholder="" />
                                                <br />
                                                <label style={{ fontWeight: 400 }}>Nomor PIN</label>
                                                <input
                                                    onChange={(e) => this.on_input_change(e)}
                                                    onKeyDown={this.on_key_press}
                                                    type="text"
                                                    className="form-control m-input"
                                                    name="nomor_pin"
                                                    value={this.state.nomor_pin}
                                                    placeholder="" />
                                                <br />
                                                <div style={{ marginTop: '20px' }}>
                                                    <div className="row" style={{ marginBottom: '10px' }}>
                                                        <div className="col-10">
                                                            <button onClick={() => this.on_btn_tagihan()} type="button" className={this.state.tx_flag_inquiry_processing ? 'btn btn-success m-loader m-loader--light m-loader--right' : 'btn btn-success'}>
                                                                Lanjut
                                                            </button>
                                                            &nbsp;&nbsp;
                                                            <button onClick={() => this.on_btn_reset()} type="reset" className="btn btn-secondary">
                                                                Reset
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                        </PortletTabBodyList>

                                        <PortletTabBodyList id={'pln-prepaid-tab'}>
                                            <Form>
                                                <label style={{ fontWeight: 400 }}>Pilih Dana</label>
                                               

                                                <Select
                                        value={this.state.denoms_sel_selected}
                                         onChange={this.select_handle_change}
                                        options={this.state.denoms_sel}
                                        placeholder="Pilih Dana"
                                        isClearable={true}/>

                                        
                                                <br />
                                                <label style={{ fontWeight: 400 }}>Nomor Rek Tujuan</label>
                                                <input
                                                    onChange={(e) => this.on_input_change(e)}
                                                    onKeyDown={this.on_key_press}
                                                    type="text"
                                                    className="form-control m-input"
                                                    name="nomor_rek_giro"
                                                    value={this.state.nomor_rek_giro}
                                                    placeholder="" />
                                                <br />
                                                <label style={{ fontWeight: 400 }}>Nominal</label>
                                                <input
                                                    onChange={(e) => this.on_input_change(e)}
                                                    onKeyDown={this.on_key_press}
                                                    type="text"
                                                    className="form-control m-input"
                                                    name="nominal"
                                                    value={this.state.nominal} />
                                                <br />
                                                <label style={{ fontWeight: 400 }}>Keterangan</label> <label  style={{fontStyle: 'italic'}}>*Opsional</label>
                                                <input
                                                    onChange={(e) => this.on_input_change(e)}
                                                    onKeyDown={this.on_key_press}
                                                    type="text"
                                                    className="form-control m-input"
                                                    name="keterangan"
                                                    value={this.state.keterangan} />
                                                <br />
                                                <div style={{ marginTop: '20px' }}>
                                                    <div className="row" style={{ marginBottom: '10px' }}>
                                                        <div className="col-10">
                                                            <button onClick={() => this.on_btn_submit_prepaid()} type="button" className="btn btn-success">
                                                                Lanjut
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                        </PortletTabBodyList>
                                    </div>
                                </div>
                            </PortletTab>
                        </div>
                    </div>
                    {this.state.tx_tagihan_pelanggan ? <TagihanView /> : ''}
                    {this.state.tx_result_success && this.state.tx_result_success.isCashin ? <TagihanSetorTunaiView /> : ''}
                    <Modal className="modal" open={this.state.tx_show_modal} onClose={this.on_btn_confirm_cancel} center showCloseIcon={false} closeOnOverlayClick={false}>
                        <div className="modal-dialog modal-lg" role="document" style={{ marginTop: '0px', marginBottom: '0px' }}>
                            <div className="modal-content" style={{ border: "white" }}>
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLongTitle">
                                        Konfirmasi Tarik Tunai
                                    </h5>
                                </div>
                                <div className="modal-body">
                                    <form>
                                        <div className="form-group m-form__group">
                                            <span className="m--font-boldest">
                                                <b>Nomor Pelanggan:</b>
                                            </span>
                                            <p className="form-control-static">{this.state.tx_tagihan_pelanggan ? this.state.tx_tagihan_pelanggan.kap : ''}</p>
                                        </div>
                                        <div className="form-group">
                                            <span className="m--font-boldest">
                                                Produk:
                                            </span>
                                            <p className="form-control-static">Tarik Tunai Giro Pos</p>
                                        </div>
                                        <div className="form-group">
                                            <span className="m--font-boldest">
                                                Total Bayar:
                                            </span>
                                            <p className="form-control-static">Rp. {this.state.tx_tagihan_pelanggan ? this.state.tx_tagihan_pelanggan.trx_total : ''}</p>
                                        </div>
                                        <div className="" style={{ cursor: 'pointer', textAlign: 'right', marginBottom: '7px' }} onClick={() => { self.setState({ show_info_pelanggan: !self.state.show_info_pelanggan }) }}>
                                            <i className={this.state.show_info_pelanggan ? 'la la-minus' : 'la la-plus'} style={{ fontSize: '15px', marginRight: '5px' }}></i>
                                            <span className="m--font-boldest">
                                                Informasi Pembeli
                                            </span>
                                            <span style={{ backgroundColor: '#666', height: '8px', marginLeft: '5px' }} />
                                        </div>
                                        <div className="m-form__section m-form__section--first" style={this.state.show_info_pelanggan ? { display: 'block' } : { display: 'none' }}>
                                            <div className="m-form__group row" style={{ marginBottom: '10px' }}>
                                                <label className="col-lg-3" style={{ margin: 'auto', fontWeight: 600, textAlign: 'left' }}>
                                                    Nama:
                                                </label>
                                                <div className="col-lg-9">
                                                    <input onChange={(e) => this.on_input_change(e)}
                                                        type="text"
                                                        className="form-control m-input"
                                                        name="nama_pelanggan"
                                                        value={this.state.nama_pelanggan}
                                                        placeholder="" />
                                                </div>
                                            </div>
                                            <div className="m-form__group row" style={{}}>
                                                <label className="col-lg-3" style={{ margin: 'auto', fontWeight: 600, textAlign: 'left' }}>
                                                    Nomor HP:
                                                </label>
                                                <div className="col-lg-9">
                                                    <input onChange={(e) => this.on_input_change(e)}
                                                        type="text"
                                                        className="form-control m-input"
                                                        name="nomor_pelanggan"
                                                        value={this.state.nomor_pelanggan}
                                                        placeholder="" />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="modal-footer">
                                    <p className="m--font-boldest" style={{ marginLeft: 10 + 'px', marginRight: 10 + 'px', marginTop: 10 + 'px', textAlign: 'center', fontWeight: 600, fontSize: 'larger' }}>
                                        Apakah Anda yakin untuk melanjutkan transaksi ini?
                                    </p>
                                    <button type="button" onClick={this.on_btn_confirm_cancel} className="btn btn-secondary" data-dismiss="modal">
                                        Batal
                                    </button>
                                    <button type="button" onClick={this.on_btn_confirm_ok} className={this.state.tx_flag_processing ? 'btn btn-primary m-loader m-loader--light m-loader--right' : 'btn btn-primary'}>
                                        OK
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal>

                    <Modal className="modal" open={this.state.tx_show_modal_cashin} onClose={this.on_btn_confirm_cancel} center showCloseIcon={false} closeOnOverlayClick={false}>
                        <div className="modal-dialog modal-lg" role="document" style={{ marginTop: 0 + 'px', marginBottom: 0 + 'px' }}>
                            <div className="modal-content" style={{ border: "white" }}>
                                <div className="modal-header">
                                    <h5 className="modal-title">
                                        Setor Tunai Giro Pos
                                    </h5>
                                </div>
                                <div className="modal-body">
                                    <form>
                                        <div className="form-group m-form__group">
                                            <span className="m--font-boldest" style={{ width: '100px', display: 'table-cell' }}>
                                                <b>Nama Agen:</b>
                                            </span>
                                            <div style={{ display: 'table-cell' }}>
                                                {this.state.tx_tagihan_prepaid.length !== 0 ? (this.state.tx_tagihan_prepaid.inquiry !== undefined ? this.state.tx_tagihan_prepaid.inquiry.agent_name : this.state.tx_tagihan_prepaid) :
                                                    <div className="m-loader m-loader--brand" style={{ width: '30px', display: 'inline-block' }}>
                                                    </div>}
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <span className="m--font-boldest" style={{ width: '100px', display: 'table-cell' }}>
                                                Nomor Giro:
                                            </span>
                                            <p style={{ display: 'table-cell' }}>{this.state.tx_tagihan_prepaid.inquiry ? this.state.tx_tagihan_prepaid.inquiry.account_cgs : '-'}</p>
                                        </div>
                                        <div className="form-group">
                                            <span className="m--font-boldest" style={{ width: '100px', display: 'table-cell' }}>
                                                Nominal:
                                            </span>
                                            <p style={{ display: 'table-cell' }}>{this.state.tx_tagihan_prepaid.inquiry ? this.state.tx_tagihan_prepaid.trx_nominal_to_user : '-'}</p>
                                        </div>
                                        <div className="form-group">
                                            <span className="m--font-boldest" style={{ width: '100px', display: 'table-cell' }}>
                                                Fee:
                                            </span>
                                            <p style={{ display: 'table-cell' }}>{this.state.tx_tagihan_prepaid.inquiry ? this.state.tx_tagihan_prepaid.trx_fee_admin_to_user : '-'}</p>
                                        </div>
                                        <div className="form-group">
                                            <span className="m--font-boldest" style={{ width: '100px', display: 'table-cell' }}>
                                                Total:
                                            </span>
                                            <p style={{ display: 'table-cell' }}>{this.state.tx_tagihan_prepaid.inquiry ? this.state.tx_tagihan_prepaid.trx_total : '-'}</p>
                                        </div>
                                        <div className="form-group">
                                            <span className="m--font-boldest">
                                                Keterangan:
                                            </span>
                                            <p className="form-control-static">{this.state.tx_tagihan_prepaid.cashin ? this.state.tx_tagihan_prepaid.cashin.description : '-'}</p>
                                        </div>

                                        <div className="" style={{ cursor: 'pointer', textAlign: 'right', marginBottom: '7px' }} onClick={() => { self.setState({ show_info_pelanggan: !self.state.show_info_pelanggan }) }}>
                                            <i className='la la-plus' style={{ fontSize: '15px', marginRight: '5px' }}></i>
                                            <span className="m--font-boldest">
                                                Data Penyetor
                                            </span>
                                            <span style={{ backgroundColor: '#666', height: '8px', marginLeft: '5px' }} />
                                        </div>
                                        <div className="m-form__section m-form__section--first">
                                            <div className="m-form__group row" style={{ marginBottom: '5px' }}>
                                                <label className="col-lg-3" style={{ margin: 'auto', fontWeight: 600, textAlign: 'left' }}>
                                                    Nama:
                                                </label>
                                                <div className="col-lg-9">
                                                    <input onChange={(e) => this.on_input_change(e)}
                                                        type="text"
                                                        className="form-control m-input"
                                                        name="sender_name"
                                                        value={this.state.sender_name} />
                                                </div>
                                            </div>
                                            <div className="m-form__group row" style={{ marginBottom: '5px' }}>
                                                <label className="col-lg-3" style={{ margin: 'auto', fontWeight: 600, textAlign: 'left' }}>
                                                    NIK:
                                                </label>
                                                <div className="col-lg-9">
                                                    <input onChange={(e) => this.on_input_change(e)}
                                                        type="text"
                                                        className="form-control m-input"
                                                        name="sender_nik"
                                                        value={this.state.sender_nik} />
                                                </div>
                                            </div>
                                            <div className="m-form__group row" style={{ marginBottom: '5px' }}>
                                                <label className="col-lg-3" style={{ margin: 'auto', fontWeight: 600, textAlign: 'left' }}>
                                                    Alamat:
                                                </label>
                                                <div className="col-lg-9">
                                                    <input onChange={(e) => this.on_input_change(e)}
                                                        type="text"
                                                        className="form-control m-input"
                                                        name="sender_address"
                                                        value={this.state.sender_address} />
                                                </div>
                                            </div>
                                            <div className="m-form__group row" style={{ marginBottom: '5px' }}>
                                                <label className="col-lg-3" style={{ margin: 'auto', fontWeight: 600, textAlign: 'left' }}>
                                                    No HP:
                                                </label>
                                                <div className="col-lg-9">
                                                    <input onChange={(e) => this.on_input_change(e)}
                                                        type="text"
                                                        className="form-control m-input"
                                                        name="sender_phone"
                                                        value={this.state.sender_phone} />
                                                </div>
                                            </div>
                                            <div className="m-form__group row" style={{ marginBottom: '5px' }}>
                                                <label className="col-lg-3" style={{ margin: 'auto', fontWeight: 600, textAlign: 'left' }}>
                                                    Asal Dana:
                                                </label>
                                                <div className="col-lg-9">
                                                    <input onChange={(e) => this.on_input_change(e)}
                                                        type="text"
                                                        className="form-control m-input"
                                                        name="source_of_funds"
                                                        value={this.state.source_of_funds} />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="modal-footer">
                                    <p className="m--font-boldest" style={{ marginLeft: '10px', marginRight: '10px', marginTop: '10px', textAlign: 'center', fontWeight: 600, fontSize: 'larger' }}>
                                        Apakah Anda yakin untuk melanjutkan transaksi ini?
                                    </p>
                                    <button type="button" onClick={this.on_btn_confirm_cancel} className="btn btn-secondary" data-dismiss="modal">
                                        Batal
                                    </button>
                                    {/* <button disabled={this.state.tx_tagihan_prepaid.inquiry ? false : true} type="button" onClick={this.on_btn_confirm_ok_prepaid} className={this.state.tx_flag_processing ? 'btn btn-primary m-loader m-loader--light m-loader--right' : 'btn btn-primary'}> */}

                                    <button type="button" onClick={this.on_btn_confirm_ok_prepaid} className={this.state.tx_flag_processing ? 'btn btn-primary m-loader m-loader--light m-loader--right' : 'btn btn-primary'}>
                                        OK
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    <Modal className="modal" open={this.state.tx_show_modal_transfer} onClose={this.on_btn_confirm_cancel} center showCloseIcon={false} closeOnOverlayClick={false}>
                        <div className="modal-dialog modal-lg" role="document" style={{ marginTop: 0 + 'px', marginBottom: 0 + 'px' }}>
                            <div className="modal-content" style={{ border: "white" }}>
                                <div className="modal-header">
                                    <h5 className="modal-title">
                                        Transfer Bank 
                                    </h5>
                                </div>  
                                <div className="modal-body">
                                <form className="m-form m-form--fit m-form--label-align-right m-form--group-seperator-dashed">
                                <div className="form-group m-form__group row" style={padding_field}>
                                    <label className="col-lg-2 col-form-label" style={label_tagihan_style}>
                                        Produk:
                                    </label>


                                    <div className="col-lg-10" style={{paddingLeft:0,paddingRight:0}}>
                                        <label className="col-form-label" style={{padding:0}}>


                                        {this.state.tx_detail_transfer.length !== 0 ? this.state.tx_detail_transfer.product_id + " " + this.state.tx_detail_transfer.product_name:
                                                    <div className="m-loader m-loader--brand" style={{ width: '30px', display: 'inline-block' }}>
                                                    </div>}

 
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group m-form__group row" style={padding_field}>
                                    <label className="col-lg-2 col-form-label" style={label_tagihan_style}>
                                        Info:
                                    </label>
                                    <div className="col-lg-10" style={{paddingLeft:0,paddingRight:0}}>
                                        <label className="col-form-label" style={{lineHeight:'20px',padding:0}}>

                                            
                                        {this.state.tx_detail_transfer.length !== 0 ? this.state.tx_detail_transfer.info.split('|').map(function(item, key) {
                                                return (
                                                    <span key={key}>
                                                        {item}
                                                        <br/>
                                                    </span>
                                                )
                                            }) :
                                                    <div className="m-loader m-loader--brand" style={{ width: '30px', display: 'inline-block' }}>
                                                    </div>}
 
                                        </label>
                                    </div>
                                </div>

                                <div className="form-group m-form__group row" style={padding_field}>
                                    <label className="col-lg-4 col-form-label" style={label_tagihan_style}>
                                        Tagihan:
                                    </label>
                                    <div className="col-lg-8">
                                        <label className="col-form-label" style={value_tagihan_style}>
                                            Rp. {this.state.tx_detail_transfer?util.rupiah_format(this.state.tx_detail_transfer.nominal):'0'}
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group m-form__group row" style={padding_field}>
                                    <label className="col-lg-4 col-form-label" style={label_tagihan_style}>
                                        Biaya Admin:
                                    </label>
                                    <div className="col-lg-8">
                                        <label className="col-form-label" style={value_tagihan_style}>
                                            Rp. {this.state.tx_detail_transfer?util.rupiah_format(this.state.tx_detail_transfer.fee):'0'}
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group m-form__group row" style={padding_field}>
                                    <label className="col-lg-4 col-form-label" style={label_tagihan_style}>
                                        Total Bayar:
                                    </label>
                                    <div className="col-lg-8">
                                        <label className="col-form-label" style={value_tagihan_style}>
                                            Rp. {this.state.tx_detail_transfer?util.rupiah_format(this.state.tx_detail_transfer.total):''}
                                        </label>
                                    </div>
                                </div>
                            </form>
                                   
                                </div>
                                <div className="modal-footer">
                                    <p className="m--font-boldest" style={{ marginLeft: '10px', marginRight: '10px', marginTop: '10px', textAlign: 'center', fontWeight: 600, fontSize: 'larger' }}>
                                        Apakah Anda yakin untuk melanjutkan transaksi ini?
                                    </p>
                                    <button type="button" onClick={this.on_btn_confirm_cancel} className="btn btn-secondary" data-dismiss="modal">
                                        Batal
                                    </button>
                                     <button type="button" onClick={this.on_btn_confirm_ok_transfer} className={this.state.tx_flag_processing ? 'btn btn-primary m-loader m-loader--light m-loader--right' : 'btn btn-primary'}>  
                                         OK
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    {this.state.tx_show_struk && !this.state.tx_result_success.isCashin  && this.state.tx_result_success.isTransfer ? <CetakStrukTransfer data={tx_result_success} on_close={() => {
                        self.setState({ tx_show_struk: false })
                    }} /> : ''}
                    {this.state.tx_show_struk && !this.state.tx_result_success.isCashin && !this.state.tx_result_success.isTransfer ? <CetakStrukCashout data={tx_result_success} on_close={() => {
                        self.setState({ tx_show_struk: false })
                    }} /> : ''}
                    {this.state.tx_show_struk && this.state.tx_result_success.isCashin  && !this.state.tx_result_success.isTransfer ? <CetakStrukCashin data={tx_result_success} on_close={() => {
                        self.setState({ tx_show_struk: false })
                    }} /> : ''}
                </div>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    update_current_page: val => dispatch(do_action(action.ACTION_UPDATE_CURRENT_PAGE, val)),
    update_balance: val => dispatch(do_action(action.ACTION_UPDATE_USER_BALANCE, val))
})

const mapStateToProps = (state) => ({
    session: state.Session,
    profile: state.Profile
})

export default connect(mapStateToProps, mapDispatchToProps)(AgentCashinCashout);