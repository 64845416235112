import {connect} from 'react-redux'
import React, {Component} from 'react'
import moment from 'moment'
import request from 'superagent'
import {history} from '../helper/history'
import {Link} from "react-router-dom"

import MetronicIndex from '../layout/metronic/index'
import {Form, FormGroup} from '../component/form/form'
import {Portlet, PortletHead, PortletBody, PortletFooter, PortletTab} from '../component/widget/portlet'
import BootstrapTable from 'react-bootstrap-table-next'
import {DatePicker, DatePickerInput} from 'rc-datepicker'
import 'rc-datepicker/lib/style.css';
import apis from '../helper/api'
import localdata from '../helper/localdata'
import action from '../helper/action'
import do_action from '../action'
import config from '../helper/config'
import swal from 'sweetalert'
import Modal from 'react-responsive-modal'
import util from '../helper/util'
import ReactToPrint from "react-to-print"
import DailyReportExportExcel from '../component/widget/DailyReportExportExcel';

let next_load_url = ""
class ReportDaily extends Component {
    constructor(props) {
        super(props)
        this.now = moment().toDate()
        this.state = {
            flag_processing: false,
            data_report: [],
            data_report_grouped: [],
            data_report_summary: [{id: 0, key:'Jumlah Transaksi', value:0},{id: 1, key:'Total Nilai Transaksi', value:0},{id: 2, key:'Total Komisi', value:0},{id: 3, key:'Total Bonus', value:0}],
            report_date: '',
            report_date_display: '',
            show_more_btn: false,
            is_excel_generated: false
        }
        this.options = {
            hideSizePerPage: true
        }
        this.date_change = this.date_change.bind(this)
        this.on_btn_load_more = this.on_btn_load_more.bind(this)
        this.on_grouped_change = this.on_grouped_change.bind(this)
        this.is_grouped = false
    }

    componentDidMount() {
        this.scroll_to_top()
        let self = this
        this.props.update_current_page('laporan')
        let now = moment().toDate()
        let onlyDate = ''
        if (now.length !== 0) {
            let bulan = (now.getMonth() + 1).toString()
            let hari = now.getDate().toString()
            let m = bulan.length === 1 ? '0' + bulan : bulan
            let h = hari.length === 1 ? '0' + hari : hari
            onlyDate = now.getFullYear() + "-" + m + "-" + h
        }
        this.setState({report_date: onlyDate}, () => {
            self.get_summary()
        })
    }

    scroll_step() {
        if (window.pageYOffset === 0) {
            clearInterval(this.state.scroll_interval_id);
        }
        window.scroll(0, window.pageYOffset - 50);
    }

    scroll_to_top() {
        let scroll_interval_id = setInterval(this.scroll_step.bind(this), 16.66);
        this.setState({scroll_interval_id: scroll_interval_id});
    }

    on_submit_summary(e) {
        this.get_summary()
    }

    get_summary() {
        if (this.state.flag_processing) return
        let session_id = this.props.session
        if (session_id === null) return
        let self = this
        let body = {
            "session_id" : session_id,
            "date" : self.state.report_date
        }
        if (this.props.profile.workstation_code) {
            body.workstation_code = this.props.profile.workstation_code
        }
        self.setState({flag_processing: true, data_report: [], data_report_grouped: [], data_report_summary: [], report_date_display: body.date})
        if (this.is_grouped) {
            request.post(apis.report_daily_grouped)
                .set("Content-Type", "application/json")
                .send(body)
                .end(function(err, res) {
                    if (err || !res.ok) {
                        util.log("report_daily", err)
                    } else {
                        util.log("report_daily", res)
                        if (res.statusCode == 200) {
                            let payload = res.body
                            if (payload.status == "OK") {
                                if (payload.results) {
                                    let results = payload.results
                                    if (payload.results.list && payload.results.list.length !== 0) {
                                        self.setState({data_report_grouped: payload.results.list})
                                    }
                                    if (results.next_page !== null) {
                                        next_load_url = results.next_page.url
                                        self.setState({show_more_btn: true})
                                    } else {
                                        next_load_url = ""
                                        self.setState({show_more_btn: false})
                                    }
                                }
                            } else {
                                self.on_error(payload.error)
                            }
                        }
                    }
                    self.setState({flag_processing: false})
                })
        } else {
            request.post(apis.report_daily)
                .set("Content-Type", "application/json")
                .send(body)
                .end(function(err, res) {
                    if (err || !res.ok) {
                        util.log("report_daily", err)
                    } else {
                        util.log("report_daily", res)
                        if (res.statusCode == 200) {
                            let payload = res.body
                            if (payload.status == "OK") {
                                if (payload.results) {
                                    let results = payload.results
                                    if (payload.results.list && payload.results.list.length !== 0) {
                                        self.setState({data_report: payload.results.list})
                                    }
                                    if (results.next_page !== null) {
                                        next_load_url = results.next_page.url
                                        self.setState({show_more_btn: true})
                                    } else {
                                        next_load_url = ""
                                        self.setState({show_more_btn: false})
                                    }
                                }
                            } else {
                                self.on_error(payload.error)
                            }
                        }
                    }
                    self.setState({flag_processing: false})
                })
        }

        request.post(apis.report_daily_summary)
            .set("Content-Type", "application/json")
            .send(body)
            .end(function(err, res) {
                if (err || !res.ok) {
                    util.log("report_daily_summary", err)
                } else {
                    util.log("report_daily_summary", res)
                    if (res.statusCode == 200) {
                        let payload = res.body
                        if (payload.status == "OK") {
                            if (payload.results) {
                                let results = payload.results
                                let summary = [{id:0,key:'Jumlah Transaksi', value:results.total_transaction},{id:1,key:'Total Nilai Transaksi', value:"Rp. " + (results.total_amount != undefined ? util.rupiah_format(results.total_amount) : "0")},{id:2,key:'Total Komisi', value:"Rp. " + (results.total_commission != undefined ? util.rupiah_format(results.total_commission) : "0")},{id:3,key:'Total Bonus', value:"Rp. " + (results.total_bonus != undefined ? util.rupiah_format(results.total_bonus) : "0")}]
                                self.setState({data_report_summary: summary})
                            }
                        }
                    }
                }
            })
    }

    on_btn_load_more() {
        if (this.state.flag_processing) return
        if (next_load_url.length !== 0) {
            let self = this
        let body = {
            "session_id" : this.props.session,
            "date" : self.state.report_date
        }
        if (this.props.profile.workstation_code) {
            body.workstation_code = this.props.profile.workstation_code
        }
        self.setState({flag_processing: true})
        request.post(next_load_url)
            .set("Content-Type", "application/json")
            .send(body)
            .end(function(err, res) {
                if (err || !res.ok) {
                    util.log("report_daily", err)
                } else {
                    util.log("report_daily", res)
                    if (res.statusCode == 200) {
                        let payload = res.body
                        if (payload.status == "OK") {
                            if (payload.results) {
                                let results = payload.results
                                if (payload.results.list && payload.results.list.length !== 0) {
                                    if (next_load_url.indexOf("daily/group/product") !== -1) {
                                        var joined = self.state.data_report_grouped.concat(payload.results.list);
                                        self.setState({data_report_grouped: joined})
                                    } else {
                                        var joined = self.state.data_report.concat(payload.results.list);
                                        self.setState({data_report: joined})
                                    }
                                }
                                if (results.next_page !== null) {
                                    next_load_url = results.next_page.url
                                    self.setState({show_more_btn: true})
                                } else {
                                    next_load_url = ""
                                    self.setState({show_more_btn: false})
                                }
                            }
                        } else {
                            self.on_error(payload.error)
                        }
                    }
                }
                self.setState({flag_processing: false})
            })
        }
    }

    on_error(err) {
        if (err.code === 2006) {
            localdata.clear_data().then((res) => {
                history.replace('/login')
            }).catch((err) => {
                util.log("error: ", err)
            })
        } else if (err.code === 2007) {
            this.setState({show_more_btn: false})
        } else {
            let msg = "Error: " + err.code
            if (err.message !== undefined) {
                msg = err.message
            }
            swal("Ups!", msg, "warning")
        }
    }

    on_select_change(e) {
        this.setState({[e.currentTarget.id] : e.currentTarget.value})
    }

    handleAfterPrint = () => {
    }

    handleBeforePrint = () => {
    }

    renderContent = () => {
        return this.componentRef
    }

    renderTrigger = () => {
        return <button className="m-portlet__nav-link btn btn-light m-btn m-btn--pill m-btn--air" style={{fontSize:'small'}}>Cetak</button>
    }

    setRef = (ref) => {
        this.componentRef = ref
    }

    date_change(date) {
        try {
            let onlyDate = ''
            if (date.length !== 0) {
                let bulan = (date.getMonth() + 1).toString()
                let hari = date.getDate().toString()
                let m = bulan.length === 1 ? '0' + bulan : bulan
                let h = hari.length === 1 ? '0' + hari : hari
                onlyDate = date.getFullYear() + "-" + m + "-" + h
            }
            this.setState({report_date : onlyDate})
        } catch(err) {
            util.log("[date_change] error", err)
        }
    }

    on_grouped_change(e) {
        util.log(e.target.name + " " + e.target.checked)
        if (e.target.name === 'grouped_checkbox') {
            this.is_grouped = e.target.checked
        }
    }

    gen_export_excel() {
        this.setState({is_excel_generated: true});
    }

    reset_export_excel() {
        this.setState({is_excel_generated: false});
    }

    render() {
        let uid = this.props.profile ? this.props.profile.username : ""
        let ug = navigator.userAgent
        let self = this

        const RowNumber = (cell, row, i) =>
            <div>{i + 1}</div>
        
        const ProductColumn = (cell, row) =>
            <div>
                {cell}
            </div>

        const BonusColumn = (cell, row) =>
            <div>
                {(cell !== null ? util.rupiah_format(cell) : "0")}
            </div>

        const KomisiColumn = (cell, row) =>
            <div>
                {(cell !== null ? util.rupiah_format(cell) : "0")}
            </div>

        const PriceColumn = (cell, row) =>
            <div>
                {util.rupiah_format(cell)}
            </div>

        const TransaksiColumn = (cell, row) =>
            <div>
                {cell}
            </div>

        const LembarColumn = (cell, row) =>
            <div>
                {row.sheet_number ? row.sheet_number : '0'}
            </div>

        const columns = [{
            dataField: 'id',
            text: 'No.',
            formatter: RowNumber,
            headerAlign: 'center',
            align: 'center',
            style: {width: '3%'}
        },
        {
            dataField: 'transaction_id',
            text: 'TXID',
            headerAlign: 'left',
            align: 'left',
            style: {width: '10%'}
        },
        {
            dataField: 'product_name',
            text: 'Produk',
            headerAlign: 'center',
            formatter: ProductColumn,
            align: 'center',
            style: {width: '15%'}
        },
        {
            dataField: 'bill_number',
            text: 'Bill No.',
            headerAlign: 'center',
            align: 'center',
            style: {width: '15%'}
        },
        {
            dataField: 'price_total',
            text: 'Total Harga (Rp)',
            headerAlign: 'center',
            formatter: PriceColumn,
            align: 'center',
            style: {width: '14%'}
        },
        {
            dataField: 'commission',
            text: 'Total Komisi (Rp)',
            headerAlign: 'center',
            formatter: KomisiColumn,
            align: 'center',
            style: {width: '12%'}
        },
        {
            dataField: 'bonus',
            text: 'Total Bonus (Rp)',
            headerAlign: 'center',
            align: 'center',
            formatter: BonusColumn,
            style: {width: '12%'}
        },
        {
            dataField: 'sheet_number',
            text: 'Jumlah Lembar',
            headerAlign: 'center',
            align: 'center',
            formatter: LembarColumn,
            style: {width: '10%'}
        },
        {
            dataField: 'terminal_name',
            text: 'Operator',
            headerAlign: 'center',
            align: 'center',
            style: {width: '15%'}
        }]

        const columnsGrouped = [{
            dataField: 'id',
            text: 'No.',
            formatter: RowNumber,
            headerAlign: 'center',
            align: 'center',
            style: {width: '3%'}
        },
        {
            dataField: 'product_name',
            text: 'Produk',
            headerAlign: 'center',
            formatter: ProductColumn,
            align: 'center',
            style: {width: '15%'}
        },
        {
            dataField: 'total_transaction',
            text: 'Jumlah',
            headerAlign: 'center',
            align: 'center',
            style: {width: '20%'}
        },
        {
            dataField: 'total_amount',
            text: 'Total Transaksi',
            headerAlign: 'center',
            formatter: PriceColumn,
            align: 'center',
            style: {width: '20%'}
        },
        {
            dataField: 'total_commission',
            text: 'Total Komisi',
            headerAlign: 'center',
            formatter: KomisiColumn,
            align: 'center',
            style: {width: '20%'}
        },
        {
            dataField: 'total_bonus',
            text: 'Total Bonus',
            headerAlign: 'center',
            align: 'center',
            formatter: BonusColumn,
            style: {width: '20%'}
        }]

        const columnsSummary = [{
            dataField: 'id',
            text: 'No.',
            formatter: RowNumber,
            headerAlign: 'center',
            align: 'center',
            style: {width: '3%'}
        },
        {
            dataField: 'key',
            text: 'Resume',
            headerAlign: 'left',
            align: 'left',
            style: {width: '50%'}
        },
        {
            dataField: 'value',
            text: 'Jumlah',
            headerAlign: 'right',
            align: 'right',
            style: {width: '45%'}
        }]

        const TableData = (props) =>
            <BootstrapTable 
                keyField='id'
                data={this.state.data_report} striped hover bordered={true} 
                options={this.options} 
                noDataIndication="Tidak ada transaksi" 
                columns={columns}/>

        const TableDataGrouped = (props) =>
            <BootstrapTable 
                keyField='id'
                data={this.state.data_report_grouped} striped hover bordered={true} 
                options={this.options} 
                noDataIndication="Tidak ada transaksi" 
                columns={columnsGrouped}/>

        const TableSummary = (props) =>
            <BootstrapTable 
                keyField='id'
                data={this.state.data_report_summary} striped hover bordered={true} 
                options={this.options} 
                noDataIndication="Tidak ada data" 
                columns={columnsSummary}/>

        return (
            <div className="row">
                <div className="col-md-12">
                    <Portlet>
                        <div className="m-portlet__head" style={{paddingRight:'10px'}}>
                            <div className="m-portlet__head-caption">
                                <div className="m-portlet__head-title">
                                    <h3 className="m-portlet__head-text">
                                        Hari &nbsp;
                                        <DatePickerInput onChange={this.date_change} value={this.state.report_date} style={{display:'inline-grid'}}/>
                                        <label className="m-checkbox m-checkbox--focus" style={{fontWeight: 500,display:'inline-grid',marginLeft:'15px',paddingLeft:'23px'}}>
                                            <input type="checkbox" name="grouped_checkbox" onChange={this.on_grouped_change} />
                                            Display Transaksi Per Produk
                                            <span style={{height:'13px',width:'13px'}}></span>
                                        </label>
                                        <button onClick={(e) => this.on_submit_summary(e)} className={this.state.flag_processing ? 'btn m-btn--pill btn-secondary m-btn m-btn--custom  m-btn--bolder m-loader m-loader--primary m-loader--right' : 'btn m-btn--pill btn-secondary m-btn m-btn--custom m-btn--bolder'} style={{marginLeft:'10px',fontSize: 'small',paddingTop:'7px',paddingBottom:'7px'}}>Submit</button>
                                    </h3>
                                </div>
                            </div>
                            <div className="m-portlet__head-tools">
                                <ul className="m-portlet__nav">
                                    <li className="m-portlet__nav-item">
                                        <ReactToPrint trigger={this.renderTrigger}
                                            content={this.renderContent}
                                            onBeforePrint={this.handleBeforePrint}
                                            onAfterPrint={this.handleAfterPrint}
                                            pageStyle="@page{size: auto A4 portrait;}"/>
                                    </li>
                                    <li className="m-portlet__nav-item">
                                        {this.state.is_excel_generated ? 
                                            <React.Fragment>
                                                <DailyReportExportExcel 
                                                    is_grouped={this.is_grouped}
                                                    data_report={this.state.data_report} 
                                                    data_report_grouped={this.state.data_report_grouped} 
                                                    data_report_summary={this.state.data_report_summary} 
                                                    agent_name={this.props.profile.name}
                                                    tanggal={this.state.report_date_display} />
                                                &nbsp;&nbsp;<button className='m-portlet__nav-link btn btn-light m-btn m-btn--pill m-btn--air' 
                                                    onClick={() => this.reset_export_excel()} style={{fontSize:'small'}}>
                                                    Reset Excel
                                                </button>
                                            </React.Fragment>
                                        : <button className='m-portlet__nav-link btn btn-light m-btn m-btn--pill m-btn--air' 
                                            onClick={() => this.gen_export_excel()} style={{fontSize:'small'}}>
                                            Export ke Excel
                                        </button>}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <PortletBody ref={this.setRef}>
                            <FormGroup>
                                <label style={{fontWeight:600}}>
                                    Rincian Transaksi Agen {self.props.profile.name} Tanggal {util.date_indo_format(self.state.report_date_display)}
                                </label>
                                {this.is_grouped ? <TableDataGrouped/> : <TableData/>}
                                {this.state.show_more_btn ? <button type="button" style={{width: '-webkit-fill-available'}} onClick={this.on_btn_load_more} className={this.state.flag_processing ? 'btn btn-secondary m-loader m-loader--primary m-loader--right' : 'btn btn-secondary'}>
                                    Load More
                                </button> : ''}
                            </FormGroup>
                            <br/>
                            <FormGroup>
                                <label style={{fontWeight:600}}>Rekap Transaksi Agen {self.props.profile.name} Tanggal {util.date_indo_format(self.state.report_date_display)}</label>
                                <TableSummary/>
                            </FormGroup>
                            <h6 style={{marginTop:'20px',color:'gray',fontWeight:'600'}}>* Komisi dan bonus belum memperhitungkan pajak</h6>
                        </PortletBody>
                    </Portlet>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    update_current_page:  val => dispatch(do_action(action.ACTION_UPDATE_CURRENT_PAGE, val))
})

const mapStateToProps = (state) => ({
    session : state.Session,
    profile: state.Profile
})

export default connect(mapStateToProps, mapDispatchToProps)(ReportDaily)
