import React, { Component } from 'react'
import config from '../../helper/config'
import util from '../../helper/util'

class Footer extends Component {
    render() {
        let body_contact_admin = '%0A%0A%0Aug: ' + navigator.userAgent
        let app_version = 'v.1.10 -'
        if (util.is_electron()) {
          app_version = util.get_desktop_version() + ' '
        }
        return (
            <footer className="m-grid__item	m-footer">
                <div className="m-container m-container--fluid m-container--full-height m-page__container">
                    <div className="m-stack m-stack--flex-tablet-and-mobile m-stack--ver m-stack--desktop">
                        <div className="m-stack__item m-stack__item--left m-stack__item--middle m-stack__item--last">
                            <span className="m-footer__copyright">
                                {app_version} 2024 &copy; PT NUSA MULTI TEKNOLOGI
                            </span>
                        </div>
                        <div className="m-stack__item m-stack__item--right m-stack__item--middle m-stack__item--first">
                            <ul className="m-footer__nav m-nav m-nav--inline m--pull-right">
                                <li className="m-nav__item">
                                    <a className="m-nav__link" href={config.privacy_url} target="__blank">
                                        <span className="m-nav__link-text">Privacy</span>
                                    </a>
                                </li>
                                <li className="m-nav__item">
                                    <a className="m-nav__link" href={config.faq_url} target="__blank">
                                        <span className="m-nav__link-text">FAQ</span>
                                    </a>
                                </li>
                                <li className="m-nav__item">
                                    <a className="m-nav__link" href={config.terms_url} target="__blank">
                                        <span className="m-nav__link-text">T&C</span>
                                    </a>
                                </li>
                                <li className="m-nav__item m-nav__item">
                                    <a href={'mailto:' + config.helpdesk_email + '?body=' + body_contact_admin} className="m-nav__link" data-toggle="m-tooltip" title="Support Center" data-placement="left">
                                        <i className="m-nav__link-icon flaticon-info m--icon-font-size-lg3"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer