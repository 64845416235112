import React, {Component} from 'react';
import { connect } from 'react-redux';
import {Form, FormGroup} from '../component/form/form';
import {Portlet, PortletHead, PortletBody, PortletFooter, PortletAlert} from '../component/widget/portlet';
import api from '../helper/api';
import localdata from '../helper/localdata';
import config from '../helper/config';
import swal from 'sweetalert';
import action from '../helper/action';
import do_action from '../action';
import apis from '../helper/api';
import util from '../helper/util';
import request from 'superagent';

class AgentKurirRegister extends Component {
    constructor(props) {
        super(props);
        this.state = {
            flag_processing: false,
            nama: '',
            nama_penanggung_jawab: '',
            nomor_ktp: '',
            email: '',
            nomor_hp: '',
            alamat: '',
            kota_nama: '',
            provinsi: '',
            propinsi_nama: '',
            kode_pos: '',
            nomor_hp: '',
            email: '',
            valid_profile: true,
            valid_email: true,
            valid_phone: true,
            valid_balance: true,
            file_foto: '',
            file_ktp: '',
            file_npwp: '',
            file_denah: '',
            file_tampak: '',
            file_foto_nama: '',
            file_ktp_nama: '',
            file_npwp_nama: '',
            file_denah_nama: '',
            file_tampak_nama: ''
        };
    }

    componentDidMount() {
        this.scroll_to_top();
        this.props.update_current_page('kurir_ketentuan');
        // this.val_balance();
        this.val_profile();
    }

    val_balance() {
        let self = this;
        let body = { "session_id" : this.props.session };
        request.post(apis.balance_main)
            .set("Content-Type", "application/json")
            .send(body)
            .end(function(err, res) {
                if (err || !res.ok) {
                    util.log("val_balance", err)
                } else {
                    util.log("val_balance", res);
                    if (res.statusCode == 200) {
                        let payload = res.body
                        if (payload.status == "OK") {
                            if (payload.results.list) {
                                let deposits = payload.results.list;
                                let amount = deposits[0].balance_value;
                                if (amount < 1000000) {
                                    self.setState({valid_balance: false});
                                }
                            }
                        } else {
                            self.setState({valid_balance: false});
                            util.on_error(payload.error);
                        }
                    }
                }
            });
    }

    val_profile() {
        let self = this;
        let body = { "session_id" : this.props.session };
        request.post(apis.kurir_get_profile)
            .set("Content-Type", "application/json")
            .send(body)
            .end(function(err, res) {
                if (err || !res.ok) {
                    util.log("val_profile", err);
                } else {
                    util.log("val_profile", res);
                    if (res.statusCode == 200) {
                        let payload = res.body
                        if (payload.status == "OK") {
                            if (payload.results) {
                                let results = payload.results;
                                let res = {
                                    "nama" : results.fullname !== null ? results.fullname : "",
                                    "nomor_ktp" : results.id_card_number !== null ? results.id_card_number : "",
                                    "nomor_hp" : results.phone_number !== null ? results.phone_number : "",
                                    "email" : results.email !== null ? results.email : "",
                                    "propinsi" : results.province_id !== null ? results.province_id : "",
                                    "propinsi_nama" : results.province_name !== null ? results.province_name : "",
                                    "kota" : results.city_id !== null ? results.city_id : "",
                                    "kota_nama" : results.city_name !== null ? results.city_name : "",
                                    "kecamatan" : results.district_id !== null ? results.district_id : "",
                                    "kecamatan_nama" : results.district_name !== null ? results.district_name : "",
                                    "kelurahan" : results.village_id !== null ? results.village_id : "",
                                    "kelurahan_nama" : results.village_name !== null ? results.village_name : "",
                                    "alamat" : results.address !== null ? results.address : "",
                                    "kode_pos" : results.postal_code !== null ? results.postal_code : "",
                                    "valid_profile" : false,                                                     // results.is_profile_verified !== null ? results.is_profile_verified : false
                                    "valid_email": results.email && results.email.length > 0 ? true : false,     // results.is_email_verified !== null ? results.is_email_verified : false
                                    "valid_phone": results.is_phone_number_verified !== null ? results.is_phone_number_verified : false
                                };
                                if (res.nama.length != 0
                                && res.nomor_ktp.length != 0
                                && res.nomor_hp.length != 0
                                && res.email.length != 0
                                && res.propinsi_nama.length != 0
                                && res.kota_nama.length != 0
                                && res.kode_pos.length != 0
                                && res.alamat.length != 0) {
                                    res.valid_profile = true;
                                }
                                self.setState(res);
                            }
                        } else {
                            if (payload.error && payload.error.code == 6001) {
                                swal({
                                    title: "Informasi",
                                    text: payload.error.message,
                                    icon: "success"
                                }).then(d => {
                                    self.props.history.push({
                                        pathname: '/agen/home',
                                        search: '',
                                        state: {}
                                    });
                                });
                            } else {
                                swal("Error", payload.error.message, "error");
                            }
                        }
                    }
                }
            });
    }

    submit() {
        let self = this;

        let warning = "";
        if (this.state.nama_penanggung_jawab.length == 0) {
            warning += "- Nama penanggung jawab\n";
        }
        if (this.state.file_ktp_nama.length == 0) {
            warning += "- Foto KTP\n";
        }
        if (this.state.file_foto_nama.length == 0) {
            warning += "- Foto diri\n";
        }
        if (this.state.file_npwp_nama.length == 0) {
            warning += "- Foto NPWP\n";
        }
        if (this.state.file_denah_nama.length == 0) {
            warning += "- Gambar denah lokasi\n";
        }
        if (this.state.file_tampak_nama.length == 0) {
            warning += "- Foto toko\n";
        }

        if (warning) {
            warning = "Lengkapi semua data yang dibutuhkan:\n" + warning;
            swal("Peringatan", warning, "warning");
            return;
        }

        self.setState({flag_processing: true});
        request.post(apis.kurir_submit_register)
            .attach('filefoto', this.state.file_foto)
            .attach('filektp', this.state.file_ktp)
            .attach('filenpwp', this.state.file_npwp)
            .attach('filedenah', this.state.file_denah)
            .attach('filetampak', this.state.file_tampak)
            .field('nama_penanggung', this.state.nama_penanggung_jawab)
            .field('session_id', this.props.session)
            .then(res => {
                util.log(res);
                if (res.ok) {
                    let failed = "";
                    if (res.statusCode === 200) {
                        let payload = res.body;
                        if (payload.status === "OK") {
                            swal({
                                title: "Berhasil",
                                text: "Pengiriman data menjadi agen kurir berhasil dilakukan. Admin kurir akan menghubungi Anda.",
                                icon: "success"
                            }).then(d => {
                                self.props.history.push({
                                    pathname: '/agen/home',
                                    search: '',
                                    state: {}
                                });
                            });
                        } else {
                            failed = payload.error.message;
                        }
                    } else {
                        failed = res.statusCode;
                    }
                    if (failed.length !== 0) {
                        swal("Gagal", failed, "warning");
                    }
                }
                self.setState({flag_processing: false});
            });
    }

    scroll_step() {
        if (window.pageYOffset === 0) {
            clearInterval(this.state.scroll_interval_id);
        }
        window.scroll(0, window.pageYOffset - 50);
    }

    scroll_to_top() {
        let scroll_interval_id = setInterval(this.scroll_step.bind(this), 16.66);
        this.setState({scroll_interval_id: scroll_interval_id});
    }

    file_denah_changed = (event) => {
        let upload_img = event.target.files[0];
        util.log(upload_img);
        if (upload_img === undefined) { return; }
        if (upload_img.type === "image/jpeg" || upload_img.type === "image/png") {
            this.setState({ file_denah: upload_img, file_denah_nama: upload_img.name });
        } else {
            return swal("Ups !", "Hanya boleh file gambar", "warning");
        }
    }

    file_ktp_changed = (event) => {
        let upload_img = event.target.files[0];
        util.log(upload_img);
        if (upload_img === undefined) { return; }
        if (upload_img.type === "image/jpeg" || upload_img.type === "image/png") {
            this.setState({ file_ktp: upload_img, file_ktp_nama: upload_img.name });
        } else {
            return swal("Ups !", "Hanya boleh file gambar", "warning");
        }
    }

    file_npwp_changed = (event) => {
        let upload_img = event.target.files[0];
        util.log(upload_img);
        if (upload_img === undefined) { return; }
        if (upload_img.type === "image/jpeg" || upload_img.type === "image/png") {
            this.setState({ file_npwp: upload_img, file_npwp_nama: upload_img.name });
        } else {
            return swal("Ups !", "Hanya boleh file gambar", "warning");
        }
    }

    file_foto_changed = (event) => {
        let upload_img = event.target.files[0];
        util.log(upload_img);
        if (upload_img === undefined) { return; }
        if (upload_img.type === "image/jpeg" || upload_img.type === "image/png") {
            this.setState({ file_foto: upload_img, file_foto_nama: upload_img.name });
        } else {
            return swal("Ups !", "Hanya boleh file gambar", "warning");
        }
    }

    file_tampak_changed = (event) => {
        let upload_img = event.target.files[0];
        util.log(upload_img);
        if (upload_img === undefined) { return; }
        let self = this;
        if (upload_img.type === "image/jpeg" || upload_img.type === "image/png") {
            this.setState({ file_tampak: upload_img, file_tampak_nama: upload_img.name });
        } else {
            return swal("Ups !", "Hanya boleh file gambar", "warning");
        }
    }

    render() {
        const s_label = {fontWeight: 400, textAlign: 'left', margin: 'auto'};
        const s_label_2 = {fontWeight: 400, textAlign: 'left', margin: 'auto'};
        const s_label_3 = {fontWeight: 400, textAlign: 'right', margin: 'auto'};
        const s_button_upload = {fontSize: 'small',marginLeft: '0px', padding: '5px 15px'};

        let warning = "";
        if (!this.state.valid_profile) {
            warning += "\n- Data profil belum lengkap, pindah ke halaman edit profil untuk melengkapi data";
        }
        if (!this.state.valid_email) {
            warning += "\n- Email masih kosong";
        }
        if (!this.state.valid_phone) {
            warning += "\n- Telpon belum tervalidasi";
        }
        if (!this.state.valid_balance) {
            warning += "\n- Saldo belum cukup (minimal 1 juta)";
        }

        if (warning) {
            warning = "Anda belum bisa register untuk menjadi agen kurir, silakan hubungi admin untuk informasi lebih lanjut" + warning;
        }

        return (
            <div>
                <div className="row">
                    <div className="col-lg-12">
                        { warning ? <PortletAlert 
                            icon="warning" 
                            title="Peringatan" 
                            message={warning} /> 
                            : ""}
                        <div className="m-portlet">
                            <PortletHead headTxt={"Formulir Pendaftaran Layanan Kurir Pospay Agen"} />
                            
                            <div className="m-portlet__body" style={{}}>
                                <div style={{}}>

                                    <div className="m-form__group row" style={{marginBottom: '10px'}}>
                                        <label className="col-lg-2" style={s_label}>
                                            Nama Lengkap:
                                        </label>
                                        <div className="col-lg-10">
                                            <input disabled onChange={(e) => this.setState({nama: e.target.value})}
                                                type="text"
                                                className="form-control m-input"
                                                name="nama"
                                                value={this.state.nama}
                                                style={{padding: '5px', borderColor: this.state.nama != 0 ? 'black' : 'red'}}/>
                                        </div>
                                    </div>
                                    
                                    <div className="m-form__group row" style={{marginBottom: '10px'}}>
                                        <label className="col-lg-2" style={s_label}>
                                            Nama Penanggung Jawab:
                                        </label>
                                        <div className="col-lg-10">
                                            <input onChange={(e) => this.setState({nama_penanggung_jawab: e.target.value})}
                                                type="text"
                                                className="form-control m-input"
                                                name="nama_penanggung_jawab"
                                                value={this.state.nama_penanggung_jawab}
                                                style={{padding: '5px', borderColor: this.state.nama_penanggung_jawab.length != 0 ? 'black' : 'red'}}/>
                                        </div>
                                    </div>

                                    <div className="m-form__group row" style={{marginBottom: '10px'}}>
                                        <label className="col-lg-2" style={s_label}>
                                            Nomor KTP:
                                        </label>
                                        <div className="col-lg-10">
                                            <input disabled onChange={(e) => this.setState({nomor_ktp: e.target.value})}
                                                type="text"
                                                className="form-control m-input"
                                                name="nomor_ktp"
                                                value={this.state.nomor_ktp}
                                                style={{padding: '5px', borderColor: this.state.nomor_ktp != 0 ? 'black' : 'red'}}/>
                                        </div>
                                    </div>

                                    <div className="m-form__group row" style={{marginBottom: '10px'}}>
                                        <label className="col-lg-2" style={s_label}>
                                            Nomor HP:
                                        </label>
                                        <div className="col-lg-10">
                                            <input disabled onChange={(e) => this.setState({nomor_hp: e.target.value})}
                                                type="text"
                                                className="form-control m-input"
                                                name="nomor_hp"
                                                value={this.state.nomor_hp}
                                                style={{padding: '5px', borderColor: this.state.nomor_hp != 0 ? 'black' : 'red'}}/>
                                        </div>
                                    </div>

                                    <div className="m-form__group row" style={{marginBottom: '10px'}}>
                                        <label className="col-lg-2" style={s_label}>
                                            Email:
                                        </label>
                                        <div className="col-lg-10">
                                            <input disabled onChange={(e) => this.setState({email: e.target.value})}
                                                type="text"
                                                className="form-control m-input"
                                                name="email"
                                                value={this.state.email}
                                                style={{padding: '5px', borderColor: this.state.email != 0 ? 'black' : 'red'}}/>
                                        </div>
                                    </div>

                                    <div className="m-form__group row" style={{marginBottom: '10px'}}>
                                        <label className="col-lg-2" style={s_label}>
                                            Alamat:
                                        </label>
                                        <div className="col-lg-10">
                                            <input disabled onChange={(e) => this.setState({alamat: e.target.value})}
                                                type="text"
                                                className="form-control m-input"
                                                name="alamat"
                                                value={this.state.alamat}
                                                style={{padding: '5px', borderColor: this.state.alamat != 0 ? 'black' : 'red'}}/>
                                        </div>
                                    </div>

                                    <div className="m-form__group row" style={{marginBottom: '10px'}}>
                                        <label className="col-lg-2" style={s_label}>
                                            Kota / Kabupaten:
                                        </label>
                                        <div className="col-lg-2">
                                            <input disabled onChange={(e) => this.setState({kota_nama: e.target.value})}
                                                type="text"
                                                className="form-control m-input"
                                                name="kota_nama"
                                                value={this.state.kota_nama}
                                                style={{padding: '5px', borderColor: this.state.kota_nama != 0 ? 'black' : 'red'}}/>
                                        </div>
                                        <label className="col-lg-2" style={s_label_3}>
                                            Provinsi:
                                        </label>
                                        <div className="col-lg-2">
                                            <input disabled onChange={(e) => this.setState({propinsi_nama: e.target.value})}
                                                type="text"
                                                className="form-control m-input"
                                                name="propinsi_nama"
                                                value={this.state.propinsi_nama}
                                                style={{padding: '5px', borderColor: this.state.propinsi_nama != 0 ? 'black' : 'red'}}/>
                                        </div>
                                        <label className="col-lg-2" style={s_label_3}>
                                            Kode Pos:
                                        </label>
                                        <div className="col-lg-2">
                                            <input disabled onChange={(e) => this.setState({kode_pos: e.target.value})}
                                                type="text"
                                                className="form-control m-input"
                                                name="kode_pos"
                                                value={this.state.kode_pos}
                                                style={{padding: '5px', borderColor: this.state.kode_pos != 0 ? 'black' : 'red'}}/>
                                        </div>
                                    </div>

                                    <div style={{borderTop: '1px dotted #ebedf2', marginTop: '20px', marginBottom: '15px', marginLeft: '-40px', marginRight: '-40px'}}></div>

                                    <div className="m-form__group row" style={{marginBottom: '10px'}}>
                                        <label className="col-lg-12" style={{...s_label, fontWeight: 600}}>
                                            Data Pendukung
                                        </label>
                                    </div>

                                    <div className="m-form__group row" style={{marginBottom: '10px'}}>
                                        <label className="col-lg-2" style={s_label_2}>
                                            Foto KTP:
                                        </label>
                                        <div className="col-lg-8">
                                            <input disabled onChange={(e) => this.setState({file_ktp_nama: e.target.value})}
                                                type="text"
                                                className="form-control m-input"
                                                name="file_ktp_nama"
                                                value={this.state.file_ktp_nama}
                                                style={{padding: '5px', borderColor: this.state.file_ktp_nama.length != 0 ? 'black' : 'red'}}/>
                                            <input type="file" 
                                                id="upload_ktp" 
                                                name="upload_ktp" 
                                                onChange={this.file_ktp_changed} 
                                                style={{display: 'none'}}/>
                                        </div>
                                        <div className="col-lg-2">
                                            <button 
                                                className="btn btn-secondary m-btn"
                                                onClick={(e) => document.getElementById("upload_ktp").click()}
                                                style={s_button_upload}>Upload</button> 
                                        </div>
                                    </div>

                                    <div className="m-form__group row" style={{marginBottom: '10px'}}>
                                        <label className="col-lg-2" style={s_label_2}>
                                            Foto NPWP:
                                        </label>
                                        <div className="col-lg-8">
                                            <input disabled onChange={(e) => this.setState({file_npwp_nama: e.target.value})}
                                                type="text"
                                                className="form-control m-input"
                                                name="file_npwp_nama"
                                                value={this.state.file_npwp_nama}
                                                style={{padding: '5px', borderColor: this.state.file_npwp_nama.length != 0 ? 'black' : 'red'}}/>
                                            <input type="file" 
                                                id="upload_npwp" 
                                                name="upload_npwp" 
                                                onChange={this.file_npwp_changed} 
                                                style={{display: 'none'}}/>
                                        </div>
                                        <div className="col-lg-2">
                                            <button 
                                                className="btn btn-secondary m-btn"
                                                onClick={(e) => document.getElementById("upload_npwp").click()}
                                                style={s_button_upload}>Upload</button> 
                                        </div>
                                    </div>

                                    <div className="m-form__group row" style={{marginBottom: '10px'}}>
                                        <label className="col-lg-2" style={s_label_2}>
                                            Pas Foto:
                                        </label>
                                        <div className="col-lg-8">
                                            <input disabled onChange={(e) => this.setState({file_foto_nama: e.target.value})}
                                                type="text"
                                                className="form-control m-input"
                                                name="file_foto_nama"
                                                value={this.state.file_foto_nama}
                                                style={{padding: '5px', borderColor: this.state.file_foto_nama.length != 0 ? 'black' : 'red'}}/>
                                            <input type="file" 
                                                id="upload_pas_foto" 
                                                name="upload_pas_foto" 
                                                onChange={this.file_foto_changed} 
                                                style={{display: 'none'}}/>
                                        </div>
                                        <div className="col-lg-2">
                                            <button 
                                                className="btn btn-secondary m-btn"
                                                onClick={(e) => document.getElementById("upload_pas_foto").click()}
                                                style={s_button_upload}>Upload</button> 
                                        </div>
                                    </div>

                                    <div className="m-form__group row" style={{marginBottom: '10px'}}>
                                        <label className="col-lg-2" style={s_label_2}>
                                            Gambar Denah Lokasi:
                                        </label>
                                        <div className="col-lg-8">
                                            <input disabled onChange={(e) => this.setState({file_denah_nama: e.target.value})}
                                                type="text"
                                                className="form-control m-input"
                                                name="file_denah_nama"
                                                value={this.state.file_denah_nama}
                                                style={{padding: '5px', borderColor: this.state.file_denah_nama.length != 0 ? 'black' : 'red'}}/>
                                            <input type="file" 
                                                id="upload_denah_lokasi" 
                                                name="upload_denah_lokasi" 
                                                onChange={this.file_denah_changed} 
                                                style={{display: 'none'}}/>
                                        </div>
                                        <div className="col-lg-2">
                                            <button 
                                                className="btn btn-secondary m-btn"
                                                onClick={(e) => document.getElementById("upload_denah_lokasi").click()}
                                                style={s_button_upload}>Upload</button> 
                                        </div>
                                    </div>

                                    <div className="m-form__group row" style={{marginBottom: '10px'}}>
                                        <label className="col-lg-2" style={s_label_2}>
                                            Foto Toko:
                                        </label>
                                        <div className="col-lg-8">
                                            <input disabled onChange={(e) => this.setState({file_tampak_nama: e.target.value})}
                                                type="text"
                                                className="form-control m-input"
                                                name="file_tampak_nama"
                                                value={this.state.file_tampak_nama}
                                                style={{padding: '5px', borderColor: this.state.file_tampak_nama != 0 ? 'black' : 'red'}}/>
                                            <input type="file" 
                                                id="upload_toko" 
                                                name="upload_toko" 
                                                onChange={this.file_tampak_changed} 
                                                style={{display: 'none'}}/>
                                        </div>
                                        <div className="col-lg-2">
                                            <button 
                                                className="btn btn-secondary m-btn"
                                                onClick={(e) => document.getElementById("upload_toko").click()}
                                                style={s_button_upload}>Upload</button> 
                                        </div>
                                    </div>

                                    <div style={{borderTop: '1px dotted #ebedf2', marginTop: '20px', marginBottom: '15px', marginLeft: '-40px', marginRight: '-40px'}}></div>

                                    <div className="m-form__group row" style={{marginBottom: '10px'}}>
                                        <label className="col-lg-12" style={{...s_label, fontWeight: 600}}>
                                            *Disclaimer: <br/>
                                            Dengan ini menyatakan bahwa informasi yang kami berikan adalah benar adanya dan bersedia memenuhi ketentuan dan persyaratan yang berlaku
                                        </label>
                                    </div>
                                    
                                    <button 
                                        disabled={warning ? true : false}
                                        onClick={(e) => this.submit(e)} 
                                        className={this.state.flag_processing ? 'btn btn-primary m-btn--bolder m-loader m-loader--secondary m-loader--right' : 'btn btn-primary m-btn  m-btn--bolder'} 
                                        style={{fontSize: 'small', paddingTop:'7px', paddingBottom:'7px', display:'flex',marginBottom:'10px', marginTop:'50px'}}>
                                        Submit Registrasi
                                    </button>  
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    update_current_page:  val => dispatch(do_action(action.ACTION_UPDATE_CURRENT_PAGE, val))
});

const mapStateToProps = (state) => ({
    session : state.Session,
    profile: state.Profile
});

export default connect(mapStateToProps, mapDispatchToProps)(AgentKurirRegister);