import React, {Component} from 'react';
import ReactExport from 'react-export-excel';
import util from '../../helper/util';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const headerColStyle = {fill: {patternType: "solid", fgColor: {rgb: "ffd300"}}};

class DailyReportExportExcel extends Component {

    constructor(props) {
        super(props)
        this.state = {data: []};
    }

    componentDidMount() {
        console.log("didMount", this.props);
        let dataArr = [
            [{value: "Rincian Transaksi Agen " + this.props.agent_name + " Tanggal " + util.date_indo_format(this.props.tanggal), style: {font: {sz: "10", bold: true}}}]
        ];
        let i = 0;
        if (!this.props.is_grouped) {
            dataArr.push([{value: "No", style: headerColStyle}, {value: "TX ID", style: headerColStyle}, {value: "Produk", style: headerColStyle}, {value: "Bill No.", style: headerColStyle}, {value: "Total Harga (Rp)", style: headerColStyle}, {value: "Total Komisi (Rp)", style: headerColStyle}, {value: "Total Bonus (Rp)", style: headerColStyle}, {value: "Jumlah Lembar", style: headerColStyle}, {value: "Operator", style: headerColStyle}]);
            this.props.data_report.forEach(function(row) {
                i++;
                dataArr.push([{value: i.toString()}, {value: row.transaction_id.toString()}, {value: row.product_name.toString()}, {value: row.bill_number.toString()}, {value: util.rupiah_format(row.price_total).toString()}, {value: util.rupiah_format(row.commission).toString()}, {value: util.rupiah_format(row.bonus).toString()}, {value: row.sheet_number.toString()}, {value: row.terminal_name.toString()}]);
            });
        } else {
            dataArr.push([{value: "No", style: headerColStyle}, {value: "Produk", style: headerColStyle}, {value: "Jumlah", style: headerColStyle}, {value: "Total Transaksi", style: headerColStyle}, {value: "Total Komisi", style: headerColStyle}, {value: "Total Bonus", style: headerColStyle}]);
            this.props.data_report_grouped.forEach(function(row) {
                i++;
                dataArr.push([{value: i.toString()}, {value: row.product_name.toString()}, {value: row.total_transaction.toString()}, {value: util.rupiah_format(row.total_amount).toString()}, {value: util.rupiah_format(row.total_commission).toString()}, {value: util.rupiah_format(row.total_bonus).toString()}]);
            });
        }
        dataArr.push([]);
        dataArr.push([{value: "Rekap Transaksi Agen " + this.props.agent_name + " Tanggal " + util.date_indo_format(this.props.tanggal), style: {font: {sz: "10", bold: true}}}]);
        let j = 0;
        dataArr.push([{value: "No", style: headerColStyle}, {value: "Keterangan", style: headerColStyle}, {value: "Jumlah", style: headerColStyle}]);
        this.props.data_report_summary.forEach(function(row) {
            j++;
            dataArr.push([{value: j.toString()}, {value: row.key}, {value: row.value.toString()}]);
        });
        let dataMultiSet = [{columns: [], data: dataArr}];
        console.log("dataMultiSet", dataMultiSet);
        this.setState({data: dataMultiSet});
    }

    render() {
        return (
            <React.Fragment>
                <ExcelFile filename="laporan-harian" element={<button className="m-portlet__nav-link btn btn-light m-btn m-btn--pill m-btn--air" style={{fontSize:'small'}}>Download</button>}>
                    <ExcelSheet dataSet={this.state.data} name="Laporan Harian"/>
                </ExcelFile>
            </React.Fragment>
        );
    }
}

export default DailyReportExportExcel;